import React, { useRef } from "react";
import logoImage from "../../assets/img/safecamp_BRAND.1-nobg.png";
import html2canvas from "html2canvas";
import QRCode from "qrcode.react";
import Default_Profile from "../../assets/img/default_profile.png";
import { Button } from "react-bootstrap";

const QRCodeView = (props) => {
  const { newUser, profileImage } = props;
  const downloadRef = useRef();

  const downloadQRCode = async () => {
    const element = downloadRef.current;
    await html2canvas(element, { allowTaint: true, useCORS: true, logging: true }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      if (typeof link.download === "string") {
        link.href = imgData;
        link.download = "image.jpg";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(imgData);
      }
    });
  };

  return (
    <div className="codeScannerWrapper">
      <div className="registered-successful">
        <h3>Thank you for Registering</h3>
        <p className="mb-0">Please present this QR code at Event Check In</p>
        <div className="registered-inner" ref={downloadRef}>
          <h4 className="my-0 card-name">{`${newUser.first} ${newUser.last}`}</h4>
          <div className="justify-content-center align-items-center">
            <div className="qrWrapper">
              <img
                crossOrigin="true"
                src={
                  profileImage === Default_Profile
                    ? Default_Profile
                    : profileImage instanceof Object
                    ? URL.createObjectURL(profileImage)
                    : profileImage
                }
                alt="user icon"
                className="userIcon"
              />
              <QRCode
                className="qrCode"
                id="qr-gen"
                value={newUser.phone_number}
                size={270}
                level={"H"}
                fgColor="#008000"
                includeMargin={true}
              />
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <Button className="w-50 mx-auto rounded-pill btn btn-secondary" variant="secondary" onClick={downloadQRCode}>
            {" "}
            Download QR Code
          </Button>
          <Button
            className="w-25 me-2 rounded-pill btn btn-secondary"
            href={`safecampmd://app/${newUser.first || ""}/${newUser.last || ""}/${newUser.email || ""}/${
              newUser.phone_number || ""
            }`}
            variant="secondary"
          >
            Add to Wallet
          </Button>
        </div>
      </div>
    </div>
  );
};

export default QRCodeView;
