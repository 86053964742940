import parsePhoneNumber, { AsYouType, isValidNumber } from "libphonenumber-js";
import { isPossiblePhoneNumber, isValidPhoneNumber } from "react-phone-number-input";
import postalCodes from "postal-codes-js";
import moment from "moment";
import { AMAZON_CLIENT_RESULT, TEST_TYPE_Duarations, PRE_REG_PERSONALIZE, TEST_TYPE_VALUE } from "constant";
import { stringify } from "uuid";
import { retry } from "@aws-amplify/core";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { LAB_ORDER_STATUS } from "constant";

export const formatPhoneNumber = (phone_number_value, ccCode) => {
  try {
    let phone_number = phone_number_value;
    if (!phone_number?.includes("+")) {
      phone_number = ccCode ? `${ccCode}${phone_number}` : `+1${phone_number}`;
    }
    const phone = formatPhoneNumberIntl(phone_number);
    return phone;
  } catch (err) {
    console.log("Error", err);
  }
  return phone_number_value;
};

export const formatPhoneWithOutSpaces = (phone_number_value, ccCode) => {
  try {
    let phone_number = phone_number_value;
    if (!phone_number?.includes("+")) {
      phone_number = ccCode ? `${ccCode}${phone_number}` : `+1${phone_number}`;
    }
    return phone_number;
  } catch (err) {
    console.log("Error", err);
  }
  return phone_number_value;
};

export const phoneFormatter = (phone) => {
  if (phone) {
    const d = setPhoneNo(phone);
    return new AsYouType("US").input(d);
  }
  return phone;
};

export const getValidName = (name) => {
  if (!name) return "";
  return toTitleCase(name.replace(/  +/g, " "));
};

export const isValidPhone = (phone) => isValidNumber(phone, "US");

export const isValidPhoneWithCode = (phone) => {
  if (phone) return isPossiblePhoneNumber(phone);
  return false;
};
export const isValidZipCode = (code, zip) => {
  if (!zip) return false;
  const validate = postalCodes.validate(`${code}`, `${zip}`);
  console.log("zipValid", validate, code, zip);
  return typeof validate !== "string" ? validate : false;
};

export const getPageNumbers = (items) => {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(items.length / usersPerPage); i++) {
    pageNumbers.push(i);
  }
  return pageNumbers;
};

export const parseBooleanValue = (val) => (val ? "Yes" : "No");

export const parseIntValue = (val) => {
  if (typeof variable === "undefined" || variable === null) {
    return "0";
  }
  parseInt(val).toString();
};

export const parseBooleanVal = (val) => {
  if (val) {
    if (typeof val === "string" && val === "false") return false;
    return true;
  }
  return false;
};

export const convertToUpper = (val) => {
  if (val) return val.toUpperCase();
  return val;
};

export const convertToLower = (val) => {
  const v = val.trim();
  if (v) return v.toLowerCase();
  return val;
};

export const capitalizeLetter = (value) => {
  if (!value) return "";
  const val = value.trim();
  return `${val.substring(0, 1).toUpperCase()}${val.substring(1).toLowerCase()}`;
};

export const getCrewsName = (empDemos) => {
  // console.log(typeof empDemos);
  if (!empDemos) return "";
  let parsed = typeof empDemos === "string" ? JSON.parse(empDemos) : empDemos;
  let first = parsed.firstName.charAt(0).toUpperCase() + parsed.firstName.slice(1).toLowerCase();
  let last = parsed.lastName.charAt(0).toUpperCase() + parsed.lastName.slice(1).toLowerCase();
  return `${first} ${last}`;
};

export const testAppVersion = (test) => {
  if (test.appVer) return test.appVer;

  const val = test.sr_no;
  if (!val) return "-";
  if (!isNaN(val)) return "-";
  try {
    const obj = typeof val === "string" ? JSON.parse(val) : val;
    if (obj.version) {
      return obj.version;
    }
  } catch (error) {
    console.log("Error ", error);
  }
  return "-";
};

export const formatTest = (testData, clients, employees) => {
  let demos =
    typeof testData.employee_demographics === "string"
      ? JSON.parse(testData.employee_demographics)
      : testData.employee_demographics;
  let name;
  let firstName;
  let lastName;
  let dob;
  let isVaccinated;
  let employeeType;
  let preRegTest;
  let labStatusTest;
  let transitionStatus;

  if (demos) {
    name = demos.firstName + " " + demos.lastName;
    firstName = capitalizeLetter(demos.firstName);
    lastName = capitalizeLetter(demos.lastName);
    dob = formatDateOfBirthDOB(demos.dob);
    isVaccinated = demos.isVaccinated;
    employeeType = demos.employeeType;
  }
  if (typeof testData.labOrderDetail === "string") {
    testData.labOrderDetail = JSON.parse(testData.labOrderDetail);
  }
  if (employees) {
    const emp = employees.find((e) => e.id === testData.employeeID);

    if (emp) {
      employeeType = emp.employeeType;
    } else {
      console.log("Employee not found ", testData);
    }
  }

  if (typeof employeeType === "undefined") {
    if (testData?.employeeType) {
      employeeType = testData?.employeeType;
    }
  }

  const test = { ...testData };
  preRegTest = test.createSource === "PR" ? true : false;
  labStatusTest = LAB_ORDER_STATUS[test.labOrderDetail?.status] || "0";
  Object.assign(test, { preRegTest, labStatusTest });
  test.viewedResult = testData.result;
  if (clients && clients.length > 0) {
    const client = clients.find((c) => c.id === test.clientID);
    if (client && client.resultType === "P/F") {
      test.viewedResult = AMAZON_CLIENT_RESULT[test.result];
    }
  }

  if (test.test_type === "PCR") {
    if (test.scanByLabUserName) {
      transitionStatus = "Accepted";
    } else if (test.scanByTesterName) {
      transitionStatus = "In-transit";
    } else {
      transitionStatus = "Pending";
    }
  } else {
    transitionStatus = "";
  }

  test.status =
    testData.status?.toLowerCase() === "pending" && testData.test_type === "PCR" && !testData.result
      ? "New"
      : testData.status;
  test.test_type =
    testData.test_type === "Antigen"
      ? "Rapid Antigen"
      : testData.test_type === "Other"
      ? "Rapid PCR"
      : testData.test_type;

         

  return {
    ...test,
    name,
    firstName,
    lastName,
    dob,
    sex: demos?.sex,
    street: demos?.street,
    street2: demos?.street2,
    state: demos?.state,
    city: demos?.city,
    idNumber: demos?.idNumber,
    zip: demos?.zip,
    isVaccinated,
    employeeType,
    phoneNumber: formatPhoneNumber(test.phoneNumber, demos?.countryCode),
    transitionStatus,
  };
};

export const formatExternalTest = (testData, clients) => {
  let demos =
    typeof testData.employee_demographics === "string"
      ? JSON.parse(testData.employee_demographics)
      : testData.employee_demographics;
  let name;
  let firstName;
  let lastName;
  let phoneNumber= testData.phoneNumber;
  if (demos) {
    name = demos.firstName + " " + demos.lastName;
    firstName = capitalizeLetter(demos.firstName);
    lastName = capitalizeLetter(demos.lastName);
    phoneNumber = `${demos.countryCode}${demos.phoneNumber}`
  }

  const testType = TEST_TYPE_VALUE[testData.test_type];

  return { ...testData,phoneNumber, name, firstName, lastName, test_type: testType || testData.test_type };
};

export const toLowerCase = (val) => {
  if (val) val.toLowerCase();
  return val;
};

export const validSpace = (value) => {
  if (!value) return "";
  return value.replace(/  +/g, " ");
};

export const isValidName = (name) => {
  return true;
  if (!name) return false;
  if (new RegExp(/^(?!.*([a-z])\1{2})[A-Za-z ]{2,75}$/i).test(name) && window.navigator.language === "en-US") {
    return true;
  }
  if (window.navigator.language === "ja-JP" && new RegExp(/^.{2,75}$/i).test(name)) return true;
  return true;
};
export const isValidState = (state) => {
  return true;
  if (!state) return false;
  if (new RegExp(/^[A-Z]{2}$/).test(state)) {
    return true;
  } else return false;
};
export const isValidIdNum = (id) => {
  return true;
  if (!id) return false;
  if (new RegExp(/^(?!.*([a-zA-Z0-9])\1{4})[a-zA-Z0-9]{5,16}$/).test(id)) {
    return true;
  } else return false;
};
export const isValidDigits = (num) => {
  if (!num) return false;
  if (new RegExp(/^[1-9]\d*$/g).test(num)) {
    return true;
  } else return false;
};

export const testTypeValid = (test, TestTypeDuaration) => {
  if (!test) return "";
  if (!TestTypeDuaration) return "";
  const { employee_demographics } = test;
  const findval = JSON.parse(TestTypeDuaration).find((c) => c.testType === employee_demographics.externalTestType);
  if (!findval) return "";
  const date = new Date(test.createdAt);
  date.setHours(date.getHours() + parseInt(findval.hours));
  if (date <= new Date()) {
    return "EXPIRED";
  }
  return moment(date).format("MM-DD-YYYY HH:mm:ss");
};
export const getDropDownValue = (val) => {
  if (val === null || val === undefined) return null;
  if (val) return { value: true, label: "Yes" };
  return { value: false, label: "No" };
};

export const getDemoGraphics = (test) => {
  if (!test) return;
  return typeof test.employee_demographics === "string"
    ? JSON.parse(test.employee_demographics)
    : test.employee_demographics;
};

export const formatDateOfBirth = (dob) =>
  dob ? `${dob.substring(0, 2)}/${dob.substring(2, 4)}/${dob.substring(4)}` : "";

export const getAge = (dob) => {
  const val = formatDateOfBirthDOB(dob);
  return dob ? moment().diff(val, "years") : "";
};
export const isValidDate = (val) => {
  const date = val.replace(/\/|-|,/g, "");
  if (date?.length === 8) {
    const day = date.substring(0, 2);
    const month = date.substring(2, 4);
    const year = date.substring(4);
    const testDate = moment(`${year}-${month}-${day}`).format("YYYY-MM-DD");
    if (testDate !== "Invalid date") {
      return true;
    }
    return false;
  }
  return false;
};

export const isValidDobField = (dob, format) => {
  if (!dob) return false;
  return moment(dob, `${format}`, true).isValid();
};
export const setDate = (val) => {
  if (!val) return;
  const date = val.replace(/\/|-|,/g, "");
  const day = date.substring(0, 2);
  const month = date.substring(2, 4);
  const year = date.substring(4);
  return moment(`${year}-${month}-${day}`).format();
};

export const formatDate = (date) => (date ? moment(date).format("DD/MM/YYYY") : "");

export const formatDOB = (date) => (date ? moment(date).format("MM/DD/YYYY") : "");

export const formatDateMDY = (date) => (date ? moment(date).format("MM/DD/YYYY") : "");

export const formatUTCDate = (date) => (date ? moment(date).format("MM-DD-YYYY") : "");

export const formatLogDate = (date) => (date ? moment(date).format("MM-DD-YYYY HH:mm:ss") : "");

export const formatOrderDate = (date) => (date ? moment(date).format("MM-DD-YYYY hh:mm A") : "");

export const formateDateLL = (date) => (date ? moment(date).format("LL") : "");

export const formateDatellll = (date) => (date ? moment(date).format("llll") : "");

export const currentDate = () => moment().format("MM/DD/YYYY");

export const formatPDFName = (demos) => {
  let name = "";
  if (demos.lastName) name = `${demos.lastName.substring(0, 1)}${demos.lastName.substring(1).toLowerCase()}`;
  if (name) name = `${name}, `;
  if (demos.firstName) name = `${name}${demos.firstName.substring(0, 1)}${demos.firstName.substring(1).toLowerCase()}`;
  return name;
};

export const changeSort = (
  sortBy,
  sortDescending,
  filteredTests,
  setFilteredTests,
  getSiteName,
  getLabName,
  getClientName,
  parseTestResult
) => {
  console.log("filteredTests", filteredTests, sortBy);
  switch (sortBy) {
    case "employee_demographics":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) =>
              getDemoGraphics(b).lastName < getDemoGraphics(a).lastName
                ? 1
                : getDemoGraphics(a).lastName < getDemoGraphics(b).lastName
                ? -1
                : 0
            )
          : [...filteredTests].sort((a, b) =>
              getDemoGraphics(b).lastName > getDemoGraphics(a).lastName
                ? 1
                : getDemoGraphics(a).lastName > getDemoGraphics(b).lastName
                ? -1
                : 0
            )
      );
      break;
    case "clientID":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) =>
              getClientName(b[sortBy]) < getClientName(a[sortBy])
                ? 1
                : getClientName(a[sortBy]) < getClientName(b[sortBy])
                ? -1
                : 0
            )
          : [...filteredTests].sort((a, b) =>
              getClientName(b[sortBy]) > getClientName(a[sortBy])
                ? 1
                : getClientName(a[sortBy]) > getClientName(b[sortBy])
                ? -1
                : 0
            )
      );
      break;
    case "siteID":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) =>
              getSiteName(b[sortBy]) < getSiteName(a[sortBy])
                ? 1
                : getSiteName(a[sortBy]) < getSiteName(b[sortBy])
                ? -1
                : 0
            )
          : [...filteredTests].sort((a, b) =>
              getSiteName(b[sortBy]) > getSiteName(a[sortBy])
                ? 1
                : getSiteName(a[sortBy]) > getSiteName(b[sortBy])
                ? -1
                : 0
            )
      );
      break;
    case "labID":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) =>
              getLabName(b[sortBy]) < getLabName(a[sortBy]) ? 1 : getLabName(a[sortBy]) < getLabName(b[sortBy]) ? -1 : 0
            )
          : [...filteredTests].sort((a, b) =>
              getLabName(b[sortBy]) > getLabName(a[sortBy]) ? 1 : getLabName(a[sortBy]) > getLabName(b[sortBy]) ? -1 : 0
            )
      );
      break;
    case "result":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) =>
              parseTestResult(b) < parseTestResult(a) ? 1 : parseTestResult(a) < parseTestResult(b) ? -1 : 0
            )
          : [...filteredTests].sort((a, b) =>
              parseTestResult(b) > parseTestResult(a) ? 1 : parseTestResult(a) > parseTestResult(b) ? -1 : 0
            )
      );
      break;
    case "employeeID":
    case "status":
    case "test_type":
    case "barcode":
    case "phoneNumber":
    case "phone":
    case "email":
    case "name":
    case "firstName":
    case "lastName":
    case "tester_name":
    case "isExternalTest":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) => {
              const testerB = b[sortBy] ?? "";
              const testerA = a[sortBy] ?? "";
              return testerB < testerA ? 1 : testerA < testerB ? -1 : 0;
            })
          : [...filteredTests].sort((a, b) => {
              const testerB = b[sortBy] ?? "";
              const testerA = a[sortBy] ?? "";
              return testerB > testerA ? 1 : testerA > testerB ? -1 : 0;
            })
      );
      break;
    case "sequenceNo":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) => {
              return parseInt(b[sortBy]) < parseInt(a[sortBy]) ? 1 : parseInt(a[sortBy]) < parseInt(b[sortBy]) ? -1 : 0;
            })
          : [...filteredTests].sort((a, b) => {
              return parseInt(b[sortBy]) > parseInt(a[sortBy]) ? 1 : parseInt(a[sortBy]) > parseInt(b[sortBy]) ? -1 : 0;
            })
      );
      break;
    case "resultDate":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) => {
              const testerB = b[sortBy] ? b[sortBy] : b.createdAt;
              const testerA = a[sortBy] ? a[sortBy] : a.createdAt;
              return testerB < testerA ? 1 : testerA < testerB ? -1 : 0;
            })
          : [...filteredTests].sort((a, b) => {
              const testerB = b[sortBy] ? b[sortBy] : b.createdAt;
              const testerA = a[sortBy] ? a[sortBy] : a.createdAt;
              return testerB > testerA ? 1 : testerA > testerB ? -1 : 0;
            })
      );
      break;
    case "totalTests":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) =>
              parseTestResult(b.id) < parseTestResult(a.id) ? 1 : parseTestResult(a.id) < parseTestResult(b.id) ? -1 : 0
            )
          : [...filteredTests].sort((a, b) =>
              parseTestResult(b.id) > parseTestResult(a.id) ? 1 : parseTestResult(a.id) > parseTestResult(b.id) ? -1 : 0
            )
      );
      break;
    default:
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) => (b[sortBy] < a[sortBy] ? 1 : a[sortBy] < b[sortBy] ? -1 : 0))
          : [...filteredTests].sort((a, b) => (b[sortBy] > a[sortBy] ? 1 : a[sortBy] > b[sortBy] ? -1 : 0))
      );
  }
};

export const sortTestList = (
  sortBy,
  sortDescending,
  filteredTests,
  getSiteName,
  getLabName,
  getClientName,
  parseTestResult
) => {
  console.log("filteredTests", filteredTests, sortBy);
  switch (sortBy) {
    case "employee_demographics":
      return sortDescending
        ? [...filteredTests].sort((a, b) =>
            getDemoGraphics(b).lastName < getDemoGraphics(a).lastName
              ? 1
              : getDemoGraphics(a).lastName < getDemoGraphics(b).lastName
              ? -1
              : 0
          )
        : [...filteredTests].sort((a, b) =>
            getDemoGraphics(b).lastName > getDemoGraphics(a).lastName
              ? 1
              : getDemoGraphics(a).lastName > getDemoGraphics(b).lastName
              ? -1
              : 0
          );

    case "clientID":
      return sortDescending
        ? [...filteredTests].sort((a, b) =>
            getClientName(b[sortBy]) < getClientName(a[sortBy])
              ? 1
              : getClientName(a[sortBy]) < getClientName(b[sortBy])
              ? -1
              : 0
          )
        : [...filteredTests].sort((a, b) =>
            getClientName(b[sortBy]) > getClientName(a[sortBy])
              ? 1
              : getClientName(a[sortBy]) > getClientName(b[sortBy])
              ? -1
              : 0
          );
    case "siteID":
      return sortDescending
        ? [...filteredTests].sort((a, b) =>
            getSiteName(b[sortBy]) < getSiteName(a[sortBy])
              ? 1
              : getSiteName(a[sortBy]) < getSiteName(b[sortBy])
              ? -1
              : 0
          )
        : [...filteredTests].sort((a, b) =>
            getSiteName(b[sortBy]) > getSiteName(a[sortBy])
              ? 1
              : getSiteName(a[sortBy]) > getSiteName(b[sortBy])
              ? -1
              : 0
          );
    case "labID":
      return sortDescending
        ? [...filteredTests].sort((a, b) =>
            getLabName(b[sortBy]) < getLabName(a[sortBy]) ? 1 : getLabName(a[sortBy]) < getLabName(b[sortBy]) ? -1 : 0
          )
        : [...filteredTests].sort((a, b) =>
            getLabName(b[sortBy]) > getLabName(a[sortBy]) ? 1 : getLabName(a[sortBy]) > getLabName(b[sortBy]) ? -1 : 0
          );
    case "result":
      return sortDescending
        ? [...filteredTests].sort((a, b) =>
            parseTestResult(b) < parseTestResult(a) ? 1 : parseTestResult(a) < parseTestResult(b) ? -1 : 0
          )
        : [...filteredTests].sort((a, b) =>
            parseTestResult(b) > parseTestResult(a) ? 1 : parseTestResult(a) > parseTestResult(b) ? -1 : 0
          );
    case "employeeID":
    case "status":
    case "test_type":
    case "barcode":
    case "phoneNumber":
    case "phone":
    case "email":
    case "name":
    case "firstName":
    case "lastName":
    case "tester_name":
      return sortDescending
        ? [...filteredTests].sort((a, b) => {
            const testerB = b[sortBy] ?? "";
            const testerA = a[sortBy] ?? "";
            return testerB < testerA ? 1 : testerA < testerB ? -1 : 0;
          })
        : [...filteredTests].sort((a, b) => {
            const testerB = b[sortBy] ?? "";
            const testerA = a[sortBy] ?? "";
            return testerB > testerA ? 1 : testerA > testerB ? -1 : 0;
          });
    case "sequenceNo":
      return sortDescending
        ? [...filteredTests].sort((a, b) => {
            return parseInt(b[sortBy]) < parseInt(a[sortBy]) ? 1 : parseInt(a[sortBy]) < parseInt(b[sortBy]) ? -1 : 0;
          })
        : [...filteredTests].sort((a, b) => {
            return parseInt(b[sortBy]) > parseInt(a[sortBy]) ? 1 : parseInt(a[sortBy]) > parseInt(b[sortBy]) ? -1 : 0;
          });
    case "totalTests":
      return sortDescending
        ? [...filteredTests].sort((a, b) =>
            b.tests?.length < a.tests?.length ? 1 : a.tests?.length < b.tests?.length ? -1 : 0
          )
        : [...filteredTests].sort((a, b) =>
            b.tests?.length > a.tests?.length ? 1 : a.tests?.length > b.tests?.length ? -1 : 0
          );
    default:
      return sortDescending
        ? [...filteredTests].sort((a, b) => (b[sortBy] < a[sortBy] ? 1 : a[sortBy] < b[sortBy] ? -1 : 0))
        : [...filteredTests].sort((a, b) => (b[sortBy] > a[sortBy] ? 1 : a[sortBy] > b[sortBy] ? -1 : 0));
  }
};

export const isValidEmail = (email) =>
  new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  ).test(email);
export const checkValidity = (name) => {
  if (!name) return "";
  return name.replace(/[^A-Z0-9]/gi, "");
};
export const isValidFile = (file) => {
  const fileName = file.name;

  const exts = ["png", "jpg", "jpeg", "gif"];

  if (fileName) {
    let getExt = fileName.split(".");
    getExt = getExt.reverse();

    if (!exts.includes(getExt[0].toLowerCase())) {
      return "only image files are allowed";
    }

    if (file.size / 1024 / 1020 > 10) {
      return "max. 10MB file size allow";
    }

    return "";
  }
  return "";
};

export const setPhoneNo = (phone) => {
  if (!phone) return null;
  return phone.includes("+1") ? `+${phone.replace(/\D/g, "")}` : `+1${phone.replace(/\D/g, "")}`;
};

export const formatZipCode = (id) => {
  if (id && id.length > 6) {
    return `${id.substring(0, 5)}`;
  }
  return id;
};

export const formatZip = (val) => {
  if (!val) return "";
  const input = val.replaceAll("[^0-9]+", "");
  if (input.toString().length > 5) {
    return input.substring(0, 5) + "-" + input.substring(6);
  }
  if (input.toString().length === 5) {
    return input.toString();
  }
  return input;
};

export const medFlowInLocalStorage = {
  save: (type) => localStorage.setItem("medFlow", type),
  get: () => localStorage.getItem("medFlow") || null,
  clear: () => localStorage.clear(),
};

export const medFlowPortalTitle = {
  save: (type) => localStorage.setItem("medFlowTitle", type),
  get: () => localStorage.getItem("medFlowTitle") || null,
  clear: () => localStorage.clear(),
};

export const roleInLocalStorage = {
  save: (type) => localStorage.setItem("role", type),
  get: () => localStorage.getItem("role") || null,
  clear: () => localStorage.clear(),
};

export const connectedIDInLocalStorage = {
  save: (type) => localStorage.setItem("connectedID", type),
  get: () => localStorage.getItem("connectedID") || null,
  clear: () => localStorage.removeItem("connectedID"),
};

export const registrationShowSelectInLocalStorage = {
  save: (data) => localStorage.setItem("showSelected", data),
  get: () => JSON.parse(localStorage.getItem("showSelected")) || null,
  clear: () => localStorage.removeItem("showSelected"),
};

export const sortingFilterInLC = {
  save: (obj) => localStorage.setItem("medflowmd", JSON.stringify(obj)),
  get: () => {
    if (localStorage.getItem("medflowmd")) {
      return JSON.parse(localStorage.getItem("medflowmd"));
    }
    return {
      alltests: {},
      unprocessedtest: {},
      senttest: {},
      pendingreleasetest: {},
      totaltests: {},
      totaltest: {},
      expiredtests: {},
      processing: {},
      processedtests: {},
      positivetest: {},
      quarantine: {},
      tests: {},
      sites: {},
      labs: {},
      clients: {},
      users: {},
      crews: {},
      crewhs: {},
      employees: {},
      crewprofile: {},
    };
  },
  clear: () => localStorage.removeItem("medflowmd"),
};

export const showsInSS = {
  save: (obj) => sessionStorage.setItem("medflowSs", JSON.stringify(obj)),
  get: () => {
    if (sessionStorage.getItem("medflowSs")) {
      return JSON.parse(sessionStorage.getItem("medflowSs"));
    }
    return {
      selectedShow: {},
    };
  },
  clear: () => sessionStorage.removeItem("medflowSs"),
};

export const personalisationInLC = {
  save: (key, obj) => {
    const dataInLc = localStorage.getItem("medflowpersonalisation");
    let newObj = obj;
    if (key && dataInLc && dataInLc !== "undefined") {
      const data = typeof dataInLc === "string" ? JSON.parse(dataInLc) : dataInLc;
      Object.assign(data, { [key]: obj });
      newObj = data;
    } else if (key) {
      newObj = { [key]: obj };
    }
    localStorage.setItem("medflowpersonalisation", JSON.stringify(newObj));
  },
  get: (key) => {
    const dataInLC = localStorage.getItem("medflowpersonalisation");
    if (!key) return dataInLC;
    const persolizeToMap = getPersonalizationData(key);
    if (dataInLC && dataInLC !== "undefined") {
      const data = JSON.parse(dataInLC);
      const isExist = Object.keys(data).filter((i) => i === key);
      if (isExist.length > 0) {
        return persolizeToMap.map((d) => {
          return { ...d, isCheck: data[key].indexOf(d.id) !== -1 };
        });
      }
    }
    return persolizeToMap;
  },
  clear: () => localStorage.removeItem("medflowpersonalisation"),
};

export const formatDateOfBirthDOB = (dob) => {
  if (!dob || dob == "") return "";
  if (dob.includes("-")) {
    const index = dob.indexOf("-");
    let dateFormat = "MM-DD-YYYY";
    if (index !== 2) {
      dateFormat = "YYYY-MM-DD";
    }
    return moment(dob, dateFormat).format("MM/DD/YYYY");
  }
  if (!dob.includes("-")) {
    const dobDate = FormatAWSDate(dob);
    return moment(dobDate).format("MM/DD/YYYY");
  }
  return moment(dob, "MM-DD-YYYY").format("MM/DD/YYYY");
};

export const formatDoBForDemo = (dob) => {
  if (!dob || dob == "") return "";
  if (dob.includes("-")) {
    const index = dob.indexOf("-");
    let dateFormat = "MM-DD-YYYY";
    if (index !== 2) {
      dateFormat = "YYYY-MM-DD";
    }
    return moment(dob, dateFormat).format("MMDDYYYY");
  }
  return dob;
};
export const FormatAWSDate = (dobFromID) => {
  if (dobFromID && !dobFromID?.includes("-")) {
    const awsdob = [];
    awsdob.push(dobFromID.substring(4));
    awsdob.push(dobFromID.substring(0, 2));
    awsdob.push(dobFromID.substring(2, 4));
    return awsdob.join("-");
  }
  return dobFromID;
};
export const formatQuaratineDate = (value) => {
  if (!value || value == "") return value;
  return moment(value).format("YYYY-MM-DD");
};

export const formatQuaratineDisplayDate = (value) => {
  if (!value || value == "") return "-";
  return moment(value).format("MM-DD-YYYY");
};

export const calculateTdWidth = (width, numberOfCol) => Math.ceil(width / numberOfCol);

export const toTitleCase = (str) => {
  if (!str) return "";
  // if(str.length === 2 || str.length === 3) return str.toUpperCase();
  return str.toLowerCase().replace(/(^\w)|([-\s]\w)/g, (match) => match.toUpperCase());
};

export const parseTestType = (test_type) => {
  if (!test_type) return "";
  return test_type === "Antigen" ? "Rapid Antigen" : test_type === "Other" ? "Rapid PCR" : test_type;
};

export const getPersonalizationIds = (key) => {
  if (!key) return [];
  if (key === "preregistration") return PRE_REG_PERSONALIZE.map((c) => c.id);
};

export const getPersonalizationData = (key) => {
  if (!key) return [];
  if (key === "preregistration") return PRE_REG_PERSONALIZE;
  return [];
};

export const getPhoneNo = (phone_number_value, ccCode) => {
  try {
    let phone_number = phone_number_value;
    if (!phone_number?.includes("+")) {
      phone_number = ccCode ? `${ccCode}${phone_number}` : `+1${phone_number}`;
    }

    const phone = formatPhoneNumberIntl(phone_number);
    const phoneArr = phone.split(" ");
    const countryCode = phoneArr[0];
    phoneArr.splice(0, 1);
    const phoneNo = phoneArr.join("");
    return [countryCode, phoneNo, phone];
  } catch (err) {
    console.log("Error", err);
  }
  return ["", phone_number_value, phone_number_value];
};

export const parseIntVal = (val) => {
  if (val) return parseInt(val);
  return 0;
};

export const searchPhone = (phone) => {
  if (!phone) return null;
  return phone.includes("+") ? `${phone.replace(/\D/g, "")}` : `+1${phone.replace(/\D/g, "")}`;
};

export const makeAlphabetInput = (value) => {
  if (!value) return null;
  return getValidName(value);
};

export const makeBarcode = (id_number, sequenceNo) => {
  if (!id_number) {
    id_number = moment().valueOf().toString();
  }
  if (id_number && sequenceNo) {
    return id_number.concat("-", sequenceNo.toString());
  }
  return "";
};

// Generate Track URL
export const DeliveryTrackUrl = (company, trackingNo) => {
  if (!company || !trackingNo) return null;
  if (company && trackingNo) {
    if (company === "DHL") {
      return `https://www.dhl.com/us-en/home/tracking/tracking-global-forwarding.html?submit=1&tracking-id=${trackingNo}`;
    } else if (company === "FedEx") {
      return `https://www.fedex.com/fedextrack/?trknbr=${trackingNo}`;
    } else if (company === "UPS") {
      return `https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=${trackingNo}`;
    } else {
      return "";
    }
  }
  return "";
};


// Generate Track URL
export const siteStatus = (status) => {
  if (status === 0) return "Pending";
  if (status === 1) return "Active";
  if (status === 2) return "Hold";
  return "Active";
};

// image url validation
export const isValidUrl = (file) => {
  if (!file) return false;
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = typeof file === "object" ? URL.createObjectURL(file) : file;
    img.onload = () => {
      resolve(true);
    };
    img.onerror = () => {
      resolve(false);
    };
  });
};

//Get Connected ID From URL
export const connectedIDByURL = (url) => {
  let urlparams =  url.split('/');
  let connectedID = "";
  let testID = "";

   if (urlparams.length === 4){
    connectedID = urlparams[3];
   } 
   if (urlparams.length === 5) {
    connectedID = urlparams[3];
    testID = urlparams[4];

   } 
   return {connectedID , testID};
 
};