import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { formatPhoneNumber, formatDOB, formatDateMDY } from "../../utils";
import { GENDER_TEXT } from "../../constant.js";
import { Storage } from "aws-amplify";
import Loader from "components/Loader/Loader";
import FullPageImageView from "components/FullPageImageView";
import API from "api";

const VaccinationDetailModal = (props) => {
  const { show, handleClose, user } = props;
  const [vaccinationImg, setVaccinationImg] = useState("");
  const [externalTestImg, setExternalTestImg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [fullView, setFullView] = useState("");

  useEffect(() => {
    getCardImage();
  }, []);

  const getCardImage = async () => {
    try {
      setIsLoading(true);
      if (user.hasOwnProperty("vaccinationDetails") && Object.keys(user.vaccinationDetails).length > 0) {
        if (user?.vaccineCardFile && user.vaccineCardFile.includes("/")) {
          let getVacinationCardImage = await API.getFileExternalStorage(user?.vaccineCardFile);
          setVaccinationImg(getVacinationCardImage);
        } else {
          const vaccinationCard = await Storage.get(
            user?.vaccineCardFile || `${user.phone_number}-${user.showId}-vaccination-card.png`
          );
          console.log("vaccinationCard", vaccinationCard);
          setVaccinationImg(vaccinationCard);
        }
      }
      if (user.isExternalTest === "true") {
        if (user?.externalFile && user.externalFile.includes("/")) {
          let getExternalTestImage = await API.getFileExternalStorage(user?.externalFile);
          setExternalTestImg(getExternalTestImage);
        } else {
          const externalCard = await Storage.get(
            user?.externalFile || `${user.phone_number}-${user.showId}-external-test.png`
          );
          console.log("externalCard", externalCard);
          setExternalTestImg(externalCard);
        }
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  const handleImageClick = (image) => {
    setFullView(image);
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Modal show={show} animation={true} onHide={() => handleClose()} centered size="xl">
          <Modal.Header closeButton>
            <Modal.Title className="my-0" id="contained-modal-title-vcenter">
              Pre Registration Details
            </Modal.Title>
          </Modal.Header>

          <Modal.Body style={{ paddingTop: 0 }}>
            <div>
              <h4 className="mt-2 mb-3 fw-bolder">User Details</h4>
              <Row className="vaccination-rows-data">
                <Col className="pr-1 vc-form-group" lg="3">
                  <Form.Group>
                    <label className="weightBold">First Name</label>
                    <Form.Control readOnly plaintext value={user.first} type="text" />
                  </Form.Group>
                </Col>
                <Col className="pr-1 vc-form-group" lg="3">
                  <Form.Group>
                    <label className="weightBold">Last Name</label>
                    <Form.Control readOnly plaintext value={user.last} type="text" />
                  </Form.Group>
                </Col>
                <Col className="pr-1 vc-form-group" lg="3">
                  <Form.Group>
                    <label className="weightBold">Email</label>
                    <Form.Control readOnly plaintext value={user.email} type="text" />
                  </Form.Group>
                </Col>
                <Col className="pr-1 vc-form-group" lg="3">
                  <Form.Group>
                    <label className="weightBold">Phone Number</label>
                    <Form.Control
                      readOnly
                      plaintext
                      value={formatPhoneNumber(user.phone_number, user.countryCode)}
                      type="text"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="vaccination-rows-data">
                <Col className="pr-1 vc-form-group" lg="3">
                  <Form.Group>
                    <label className="weightBold">Address</label>
                    <Form.Control readOnly plaintext value={user.street || "-"} type="text" />
                  </Form.Group>
                </Col>
                <Col className="pr-1 vc-form-group" lg="3">
                  <Form.Group>
                    <label className="weightBold">Address2</label>
                    <Form.Control
                      readOnly
                      plaintext
                      value={user.street2 && user.street2 !== "undefined" ? user.street2 : "-"}
                      type="text"
                    />
                  </Form.Group>
                </Col>

                <Col className="pr-1 vc-form-group" lg="3">
                  <Form.Group>
                    <label className="weightBold">City</label>
                    <Form.Control readOnly plaintext value={user.city || "-"} type="text" />
                  </Form.Group>
                </Col>
                <Col className="pr-1 vc-form-group" lg="3">
                  <Form.Group>
                    <label className="weightBold">state</label>
                    <Form.Control readOnly plaintext value={user.state || "-"} type="text" />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="vaccination-rows-data">
                <Col className="pr-1 vc-form-group" lg="4">
                  <Form.Group>
                    <label className="weightBold">Gender</label>
                    <Form.Control readOnly plaintext value={user.sex ? GENDER_TEXT[user.sex] : "-"} type="text" />
                  </Form.Group>
                </Col>
                <Col className="pr-1 vc-form-group" lg="4">
                  <Form.Group>
                    <label className="weightBold">ID Number</label>
                    <Form.Control readOnly plaintext value={user.id_number ? user.id_number : "-"} type="text" />
                  </Form.Group>
                </Col>

                <Col className="pr-1 vc-form-group" lg="4">
                  <Form.Group>
                    <label className="weightBold">Date Of Birth</label>
                    <Form.Control readOnly plaintext value={user.dob ? formatDOB(user.dob) : "-"} type="text" />
                  </Form.Group>
                </Col>
              </Row>
              {user.hasOwnProperty("vaccinationDetails") && Object.keys(user.vaccinationDetails).length > 0 && (
                <>
                  <h4 className="mt-2 mb-3 fw-bolder">Vaccination Details</h4>
                  <Row className="vaccination-rows-data align-items-center">
                    <Col className="pr-1" md="9">
                      <Row>
                        <Col className="pr-1 vc-form-group" lg="4">
                          <Form.Group>
                            <label className="weightBold">Vaccination Type</label>
                            <Form.Control
                              className="ellipsis w-100"
                              style={{ maxWidth: "100%" }}
                              readOnly
                              plaintext
                              value={user.vaccinationDetails.vaccinationType}
                              type="text"
                            />
                          </Form.Group>
                        </Col>
                        <Col className="pr-1 vc-form-group" lg="4">
                          <Form.Group>
                            <label className="weightBold">Vaccination Date</label>
                            <Form.Control
                              className="ellipsis w-100"
                              style={{ maxWidth: "100%" }}
                              readOnly
                              plaintext
                              value={formatDateMDY(user.vaccinationDetails.vaccinationDate)}
                              type="text"
                            />
                          </Form.Group>
                        </Col>

                        <Col className="pr-1 vc-form-group" lg="4">
                          <Form.Group className="vc-right-image">
                            <label className="weightBold">Vaccination Location</label>
                            <Form.Control
                              className="ellipsis w-100"
                              style={{ maxWidth: "100%" }}
                              readOnly
                              plaintext
                              value={user.vaccinationDetails.vaccinationLocation}
                              type="text"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      {user.vaccinationDetails.vaccinationDate1 && (
                        <Row>
                          {/*<Col className="pr-1" lg="4"></Col>*/}
                          <Col className="pr-1 vc-form-group" lg="4">
                            <Form.Group className="vc-right-image">
                              <label className="weightBold">Vaccination Date 1</label>
                              <Form.Control
                                className="ellipsis w-100"
                                style={{ maxWidth: "100%" }}
                                readOnly
                                plaintext
                                value={formatDateMDY(user.vaccinationDetails.vaccinationDate1)}
                                type="text"
                              />
                            </Form.Group>
                          </Col>
                          <Col className="pr-1 vc-form-group" lg="4">
                            <Form.Group className="vc-right-image">
                              <label className="weightBold">Vaccination Location 1</label>
                              <Form.Control
                                className="ellipsis w-100"
                                style={{ maxWidth: "100%" }}
                                readOnly
                                plaintext
                                value={user.vaccinationDetails.vaccinationLocation1}
                                type="text"
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      )}
                    </Col>
                    <Col className="pr-1" lg="3">
                      <Form.Group>
                        <label className="weightBold">Vaccination Card Image</label>
                        {vaccinationImg && (
                          <div className="w-75 mx-auto">
                            <img
                              src={vaccinationImg}
                              style={{ minHeight: "130px", maxHeight: "130px", width: '80%' }}
                              className="w-100 rounded-3"
                              alt="Vaccination Card"
                              onClick={() => handleImageClick(vaccinationImg)}
                            ></img>
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              )}
              {Object.keys(user.vaccinationDetails).length > 0 &&
                user.vaccinationDetails.hasOwnProperty("isBooster") &&
                user.vaccinationDetails.isBooster === "true" && (
                  <>
                    <h4 className="mt-2 mb-3 fw-bolderer">Booster Details</h4>
                    <Row className="vaccination-rows-data">
                      <Col className="pr-1 vc-form-group" lg="3">
                        <Form.Group>
                          <label className="weightBold">Booster Type</label>
                          <Form.Control
                            className="ellipsis w-100"
                            style={{ maxWidth: "100%" }}
                            readOnly
                            plaintext
                            value={user.vaccinationDetails.boosterType}
                            type="text"
                          />
                        </Form.Group>
                      </Col>
                      <Col className="pr-1 vc-form-group" lg="3">
                        <Form.Group>
                          <label className="weightBold">Booster Date</label>
                          <Form.Control
                            className="ellipsis w-100"
                            style={{ maxWidth: "100%" }}
                            readOnly
                            plaintext
                            value={formatDateMDY(user.vaccinationDetails.boosterDate)}
                            type="text"
                          />
                        </Form.Group>
                      </Col>
                      <Col className="pr-1 vc-form-group" lg="3">
                        <Form.Group>
                          <label className="weightBold">Booster Location</label>
                          <Form.Control
                            className="ellipsis w-100"
                            style={{ maxWidth: "100%" }}
                            readOnly
                            plaintext
                            value={user.vaccinationDetails.boosterLocation}
                            type="text"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                )}
              {user.isExternalTest === "true" && (
                <>
                  <h4 className="mt-2 mb-3 fw-bolder">External Test Details</h4>
                  <Row className="vaccination-rows-data align-items-center">
                    <Col className="pr-1 vc-form-group" lg="3">
                      <Form.Group>
                        <label className="weightBold">External Test Type</label>
                        <Form.Control
                          className="ellipsis w-100"
                          style={{ maxWidth: "100%" }}
                          readOnly
                          plaintext
                          value={user.externalTestType}
                          type="text"
                        />
                      </Form.Group>
                    </Col>
                    <Col className="pr-1 vc-form-group" lg="3">
                      <Form.Group>
                        <label className="weightBold">External Test Date</label>
                        <Form.Control
                          className="ellipsis w-100"
                          style={{ maxWidth: "100%" }}
                          readOnly
                          plaintext
                          value={formatDateMDY(user.externalTestDate)}
                          type="text"
                        />
                      </Form.Group>
                    </Col>
                    <Col className="pr-1 vc-form-group" lg="3">
                      <Form.Group>
                        <label className="weightBold">External Test Result</label>
                        <Form.Control
                          className="ellipsis w-100"
                          style={{ maxWidth: "100%" }}
                          readOnly
                          plaintext
                          value={user.externalTestResult}
                          type="text"
                        />
                      </Form.Group>
                    </Col>
                    <Col className="pr-1 vc-form-group" lg="3">
                      <Form.Group>
                        <label className="weightBold">External Test Card</label>
                        {externalTestImg && (
                          <div className="w-75 mx-auto">
                            <img
                              style={{ minHeight: "130px", maxHeight: "130px", width: '80%' }}
                              src={externalTestImg}
                              className="w-100 rounded-3"
                              alt="External Test Card"
                              onClick={() => handleImageClick(externalTestImg)}
                            ></img>
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              )}
            </div>
            <FullPageImageView image={fullView} handleClose={() => setFullView("")} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => handleClose()}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default VaccinationDetailModal;
