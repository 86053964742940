import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import PersonalizationItem from "components/Personalization/PersonalizationItem";

const PersonalizationModal = (props) => {
  const { show, handleClose, handleChange, data } = props;
  const [personalize, setPersonalize] = useState([]);

  useEffect(() => {
    setPersonalize(data);
  }, []);

  const handleCheckBox = (event) => {
    const index = personalize.findIndex((i) => i.itemKey === event.target.value);
    if (event.target.checked && index !== -1) {
      personalize[index].isCheck = true;
      return;
    }
    if (index !== -1) personalize[index].isCheck = false;
  };

  const handleSubmit = () => handleChange(personalize);

  return (
    <Modal show={show} animation={true} onHide={() => handleClose()} centered size={"lg"}>
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          Personalize
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="mb-3">
          {personalize.map((ek, index) => (
            <PersonalizationItem key={index} handleCheck={handleCheckBox} item={ek} />
          ))}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={() => handleClose()}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Apply
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PersonalizationModal;
