import React, { useState, useRef, useContext } from "react";

// react-bootstrap components
import { Button, Card, Table, Row, Col, DropdownButton, Dropdown, Modal } from "react-bootstrap";
import { useEffect, useMemo } from "react";
import api from "api";
import "react-activity/dist/Bounce.css";
import { capitalizeLetter } from "../utils";

import { AppContext } from "../context/app-context";
import { PAGE_LIMIT } from "constant";
import QRCode from "qrcode.react";
import html2canvas from "html2canvas";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const CrewQRCode = (props) => {
  const [crew, setCrew] = useState([]);
  const [filter, setFilter] = useState({});

  const appContext = useContext(AppContext);
  const downloadRef = useRef();
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.data) {
      setCrew(location.state.data);
    }
  }, []);

  console.log("Historu", location);
  const sortCrewData = (list) =>
    [...list].sort((a, b) => {
      const testerB = b["lastName"] ?? "";
      const testerA = a["lastName"] ?? "";
      return testerB.toLowerCase() < testerA.toLowerCase() ? 1 : testerA.toLowerCase() < testerB.toLowerCase() ? -1 : 0;
    });

  const downloadQRCode = async () => {
    const element = downloadRef.current;
    await html2canvas(element, { allowTaint: true, useCORS: true, logging: true }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      if (typeof link.download === "string") {
        link.href = imgData;
        link.download = "image.jpg";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(imgData);
      }
    });
  };

  return (
    <div>
      <Button className="headerButton mb-3" onClick={() => downloadQRCode()}>
        Download
      </Button>
      <div className="d-flex align-items-center column-gap-15 row-gap-15 flex-wrap" ref={downloadRef}>
        {crew.map((user, index) => {
          return (
            <div className="scanner-wrapper" key={index}>
              <div className="mb-2">
                <QRCode
                  className="qrCode"
                  id="qr-gen"
                  value={user.phoneNumber.replace(/\D/g, "")}
                  size={90}
                  level={"H"}
                  fgColor="#000"
                  includeMargin={true}
                />
              </div>
              <p className="mb-0">{`${capitalizeLetter(user.firstName)} ${capitalizeLetter(user.lastName)}`}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CrewQRCode;
