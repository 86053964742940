import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";

const SitePDF = ({ site, client }) => {
//   const getAge = (demos) => {
//     let monthIndex =
//       demos.dob.substring(0, 1) === "0"
//         ? parseInt(demos.dob.substring(1, 2)) - 1
//         : parseInt(demos.dob.substring(0, 2)) - 1;
//     let today = new Date();
//     let birthDate = new Date(
//       demos.dob.substring(4),
//       monthIndex,
//       demos.dob.substring(2, 4)
//     );
//     let age = today.getFullYear() - birthDate.getFullYear();
//     let m = today.getMonth() - birthDate.getMonth();
//     if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
//       age = age - 1;
//     }
//     return age;
//   };

const data = {
    maintainAspectRatio: false,
    responsive: false,
    labels: ["a", "b", "c", "d"],
    datasets: [
      {
        data: [300, 50, 100, 50],
        backgroundColor: ['red', 'white'],
        hoverBackgroundColor: ['red', 'white']
      }
    ]
  };

  const today = new Date();

  const time =
    today.getHours() > 12
      ? `${today.getHours() - 12}:${today.getMinutes()} PM`
      : `${today.getHours()}:${today.getMinutes()} AM`;

  return (
    <Document title={site.name}>
      <Page size="A4" style={styles.page}>
        <Text style={{ fontSize: 28 }}>Show: {site.name}</Text>
        <Text style={{ position: "absolute", top: "10", right: "20", fontSize: 28 }}>Client: {client.name}</Text>
        {/* <Text style={{ fontSize: 16 }}>
          Phone: ({testsLab.contact_phone.substring(0, 3)}){" "}
          {testsLab.contact_phone.substring(3, 6)}-
          {testsLab.contact_phone.substring(6)}
        </Text> */}
        <View style={{ ...styles.hr, marginTop: 10, marginBottom: 20 }} />
        <Text style={{ fontSize: 16, borderBottomColor: 'black', borderBottomWidth: 1, textAlign: 'center', width: '25%', marginLeft: 'auto', marginRight: 'auto' }}>Date Show Started</Text>
        <Text style={{ fontSize: 20, marginLeft: 'auto', marginRight: 'auto' }}>{new Date(site.createdAt).toString().substring(0, 15)}</Text>
        <Text style={{ fontSize: 16, borderBottomColor: 'black', borderBottomWidth: 1, textAlign: 'center', width: '25%', marginLeft: 'auto', marginRight: 'auto', marginTop: '10' }}>Today's Date</Text>
        <Text style={{ fontSize: 20, marginLeft: 'auto', marginRight: 'auto' }}>{new Date().toString().substring(0, 15)}</Text>
        <View>
        <Doughnut data={data} />
        </View>
        {/* <View style={styles.patientInfo}>
          <View style={styles.infoCol}>
            <Text style={styles.infoText}>Patient:</Text>
            <Text style={styles.infoText}>Patient #:</Text>
            <Text style={styles.infoText}>Doctor:</Text>
            <Text style={styles.infoText}>Phone:</Text>
          </View> */}
          {/* <View style={styles.infoCol}>
            <Text style={styles.infoText}>
              {demos.lastName.substring(0, 1)}
              {demos.lastName.substring(1).toLowerCase()},{" "}
              {demos.firstName.substring(0, 1)}
              {demos.firstName.substring(1).toLowerCase()}
            </Text>
            <Text style={styles.infoText}>NA</Text>
            <Text style={styles.infoText}>{testsLab.lab_director}</Text>
            <Text style={styles.infoText}>{test.phoneNumber}</Text>
          </View> */}
          {/* <View style={styles.infoCol}>
            <Text style={{ fontSize: 12, opacity: 0 }}>fake</Text>
            <Text style={styles.infoText}>Birth:</Text>
            <Text style={styles.infoText}>Age:</Text>
            <Text style={styles.infoText}>Gender:</Text>
          </View> */}
          {/* <View style={styles.infoCol}>
            <Text style={{ fontSize: 12, opacity: 0 }}>fake</Text>
            <Text style={styles.infoText}>
              {demos.dob.substring(0, 2) +
                "/" +
                demos.dob.substring(2, 4) +
                "/" +
                demos.dob.substring(4)}
            </Text>
            <Text style={styles.infoText}>{getAge(demos)}</Text>
            <Text style={styles.infoText}>
              {demos.sex === "F" ? "Female" : "Male"}
            </Text>
          </View> */}
          {/* <View style={styles.infoCol}>
            <Text style={styles.infoText}>Accession:</Text>
            <Text style={{ fontSize: 12, opacity: 0 }}>fake</Text>
            <Text style={styles.infoText}>Collection Date:</Text>
            <Text style={styles.infoText}>Received Date:</Text>
          </View> */}
          {/* <View style={styles.infoCol}>
            <Text style={styles.infoText}>{test.barcode}</Text>
            <Text style={{ fontSize: 12, opacity: 0 }}>fake</Text>
            <Text style={styles.infoText}>
              {test.createdAt.substring(5, 7) +
                "/" +
                test.createdAt.substring(8, 10) +
                "/" +
                test.createdAt.substring(0, 4)}
            </Text>
            <Text style={styles.infoText}>
              {test.createdAt.substring(5, 7) +
                "/" +
                test.createdAt.substring(8, 10) +
                "/" +
                test.createdAt.substring(0, 4)}
            </Text>
          </View> */}
        {/* </View> */}

        {/* <View style={styles.address}>
          <Text style={styles.infoText}>
            {demos.lastName.substring(0, 1)}
            {demos.lastName.substring(1).toLowerCase()},{" "}
            {demos.firstName.substring(0, 1)}
            {demos.firstName.substring(1).toLowerCase()}
          </Text>
          <Text style={styles.infoText}>
            {demos.street2 ? demos.street + " " + demos.street2 : demos.street}
          </Text>
          <Text style={styles.infoText}>
            {demos.city + ", " + demos.state + " " + demos.zip.substring(0, 5)}
          </Text>
        </View> */}

        {/* <View style={styles.hr} />
        <View style={styles.patientInfo}>
          <View style={styles.infoCol}>
            <Text style={styles.infoText}>Test Name</Text>
            <Text style={styles.infoText}>2019-nCoV rRT-PCR</Text>
            <Text style={styles.infoText}>2019-nCoV</Text>
          </View>
          <View style={styles.infoCol}>
            <Text style={styles.infoText}>Result</Text>
            <Text style={{ fontSize: 12, opacity: 0 }}>fake</Text>
            <Text style={styles.infoText}>
              {test.result.toLowerCase() === "positive"
                ? "Detected"
                : "Not Detected"}
            </Text>
          </View>
          <View style={styles.infoCol}>
            <Text style={styles.infoText}>Units</Text>
            <Text style={{ fontSize: 12, opacity: 0 }}>fake</Text>
            <Text style={{ fontSize: 12, opacity: 0 }}>fake</Text>
          </View>
          <View style={styles.infoCol}>
            <Text style={styles.infoText}>Flag</Text>
            <Text style={{ fontSize: 12, opacity: 0 }}>fake</Text>
            <Text style={{ fontSize: 12, opacity: 0 }}>fake</Text>
          </View>
          <View style={styles.infoCol}>
            <Text style={styles.infoText}>Reference Range/Cutoff</Text>
            <Text style={{ fontSize: 12, opacity: 0 }}>fake</Text>
            <Text style={styles.infoText}>
              {test.result.toLowerCase() === "positive"
                ? "Detected"
                : "Not Detected"}
            </Text>
          </View>
        </View>
        <View style={{ ...styles.hr, opacity: 0.75, marginBottom: 20 }} />
        <View
          style={{ ...styles.patientInfo, marginVertical: 0, width: "90%" }}
        >
          <Text
            style={{ ...styles.infoText, fontWeight: "200", marginRight: 20 }}
          >
            Notes:
          </Text>
          <Text
            style={{ ...styles.infoText, fontWeight: "200", marginRight: 10 }}
          >
            {testsLab.testing_notes}
          </Text>
        </View> */}
        {/* <View
          style={{
            ...styles.infoCol,
            alignSelf: "center",
            alignItems: "center",
            marginTop: 20,
          }}
        >
          <Text style={{ ...styles.infoText, fontFamily: "Times-Bold" }}>
            CLIA #{testsLab.clia_number}
          </Text>
          <Text style={{ ...styles.infoText, fontFamily: "Times-Bold" }}>
            Laboratory Director: Dr. {testsLab.lab_director}
          </Text>
        </View> */}

        {/* <View
          style={{
            position: "absolute",
            bottom: "75",
            left: "20",
            width: "100%"
          }}
        >
          <View style={{ ...styles.hr }} />
          <View style={{ display: "flex", flexDirection: "row" }}>
            <View style={{display: 'flex', flexDirection: 'column'}}>
              <Text
                style={{
                  ...styles.infoText,
                  fontWeight: "200",
                  fontSize: 10,
                  marginRight: 20,
                }}
              >
                Originally Reported On:{" "}
                {test.createdAt.substring(5, 7) +
                  "/" +
                  test.createdAt.substring(8, 10) +
                  "/" +
                  test.createdAt.substring(0, 4)}
              </Text>
              <Text
                style={{
                  ...styles.infoText,
                  fontWeight: "200",
                  fontSize: 10,
                  marginRight: 20
                }}
              >
                Printed:{" "}
                {today.getMonth() +
                  "/" +
                  today.getDate() +
                  "/" +
                  today.getFullYear()}{" "}
                {time}
              </Text>
              <Text
                style={{
                  ...styles.infoText,
                  fontWeight: "200",
                  marginRight: 20,
                  fontSize: 10
                }}
              >
                (UTC-08:00) Pacific Time (US & Canada)
              </Text>
            </View>
            <View style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
              <Text style={{ ...styles.infoText, fontSize: 10 }}>
                STAT[S] Corrected [C] Amended [A]
              </Text>
            </View>
            <View style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                <Text style={{...styles.infoText, fontSize: 10 }}>Accession: {test.barcode}    Patient #: N/A</Text>
                <Text style={{...styles.infoText, fontSize: 10 }}>Lab Results for: {demos.lastName}, {demos.firstName}</Text>
                <Text style={{...styles.infoText, fontSize: 10 }}>Page: 1/1</Text>
            </View> */}
          {/* </View> */}
        {/* </View> */}
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    flex: 1,
    backgroundColor: "white",
    paddingVertical: 10,
    paddingHorizontal: 20,
  },
  infoText: {
    fontSize: 12,
  },
  patientInfo: {
    flexDirection: "row",
    justifyContent: "space-around",
  },
  infoCol: {
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  hr: {
    borderBottomColor: "black",
    borderBottomWidth: 1,
    width: "100%",
  },
  address: {
    marginLeft: 50,
    marginVertical: 20,
  },
});

export default SitePDF;
