/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component, useState, useEffect, useLayoutEffect, useCallback } from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import { withAuthenticator } from "@aws-amplify/ui-react";
import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import Sidebar from "components/Sidebar/Sidebar";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import localApi from "api";
import GQAPI from "views/GraphQL/gqApi";
import graphApi from "graphQLApi";
import { AppContext } from "../context/app-context";
import labRoutes from "routes/labRoutes";
import whiteLabelLabRoutes from "routes/WhiteLabellabRoutes";
import adminRoutes from "routes/adminRoutes";
import siteRoutes from "routes/HSRoutes";
import sidebarImage from "assets/img/sidebar-3.jpg";
import { Hub, Auth, Storage, API, graphqlOperation } from "aws-amplify";
import * as subscriptions from "graphql/subscriptions";
import HealthAndSafetyHome from "views/HealthAndSafetyHome";
import HealthAndSafetyDashBoard from "views/GraphQL/HealthAndSafetyDashboard";
import AdminHomeApi from "views/GraphQL/AdminHomeApi";
import { Test, Employee, TestConsent, EmployeeBarCodes, ExternalTest, Site } from "../models";
import {
  formatTest,
  medFlowInLocalStorage,
  isValidEmail,
  formatExternalTest,
  connectedIDInLocalStorage,
  roleInLocalStorage,
} from "utils";
import TableListApi from "views/GraphQL/TableListApi";
import api from "api";
import { useIdleTimer } from "react-idle-timer";
import IdleModal from "components/IdleModal";
import { useHistory } from "react-router-dom";
import IdleTimer from "react-idle-timer";
import { debounce } from "debounce";
import { AMAZON_CLIENT_RESULT, RESULT_PDF, RESULT_CHECK, ROUTES, CONFIG } from "../constant";
import SweetAlert from "react-bootstrap-sweetalert";
import PDF from "components/PDF";

import ReactPDF from "@react-pdf/renderer";
import { DataStore, syncExpression } from "@aws-amplify/datastore";
import awsconfig from "../aws-exports";
import moment from "moment";
import { LencoBarcodes } from "models";
import Loader from "components/Loader/Loader";
import { useWindowScroll } from "react-use";
import { showsInSS, formatPhoneNumber } from "utils";

function AdminNew() {
  const timeout = 1800000;
  const [showIdleModal, setShowIdleModal] = useState(false);
  const [remaining, setRemaining] = useState(timeout);
  const [elapsed, setElapsed] = useState(0);
  const [lastActive, setLastActive] = useState(+new Date());
  const [lastEvent, setLastEvent] = useState("Events Emitted on Leader");
  const [leader, setLeader] = useState(true);
  const [timedOut, setTimedOut] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [successMessageText, setSuccessMessageText] = useState("");
  const [errorMessageText, setErrorMessageText] = useState("");
  const [pageState, setPageState] = useState("New");
  const [role, setRole] = useState(null);

  useLayoutEffect(() => {
    let userType = medFlowInLocalStorage.get();
    if (!userType || userType === null) handleLogOut();
  }, []);

  useEffect(() => {
    let roleSelected = roleInLocalStorage.get();
    setRole(roleSelected);
  }, []);

  let history = useHistory();

  const handleCloseModal = () => {
    setShowIdleModal(false);
  };

  const handleLogOut = async () => {
    setShowIdleModal(false);
    try {
      // await DataStore.clear();
      await Auth.signOut();
      medFlowInLocalStorage.clear();
      roleInLocalStorage.clear();
      showsInSS.clear();
      // console.log("PAUSED");
      handlePause();
      history.push("/login");
    } catch (error) {
      console.log("Error signing out: ", error);
    }
  };

  const handleOnIdle = (event) => {
    setShowIdleModal(true);
  };

  const handleOnActive = (event) => {
    setTimedOut(false);
  };

  const handleOnAction = (event) => {
    setTimedOut(false);
  };

  const handleReset = () => reset();
  const handlePause = () => pause();
  const handleResume = () => resume();
  const handleStart = () => start();

  const {
    getRemainingTime,
    getLastActiveTime,
    getElapsedTime,
    getTotalIdleTime,
    getLastIdleTime,
    isIdle,
    pause,
    resume,
    start,
    isLeader,
  } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
    onAction: handleOnAction,
    debounce: 500,
    crossTab: {
      emitOnAllTabs: false,
    },
  });

  // useEffect(() => {
  //   setRemaining(getRemainingTime());
  //   setLastActive(getLastActiveTime());
  //   setElapsed(getElapsedTime());
  //   setInterval(() => {
  //     setRemaining(getRemainingTime());
  //     setLastActive(getLastActiveTime());
  //     setElapsed(getElapsedTime());
  //   }, 1000);
  // }, []);

  const removeUserFromLC = (e) => {
    e.preventDefault();
    e.returnValue = "";
    alert("Are you sure you want to close");
    return "Not";
  };

  useEffect(() => {
    // window.addEventListener("beforeunload", removeUserFromLC);
    DataStore.stop();
    // return () => {
    //   window.removeEventListener("beforeunload",removeUserFromLC);
    // };
  }, []);

  useEffect(() => {
    if (showIdleModal) {
      const timeout = setTimeout(() => {
        if (showIdleModal) {
          handleLogOut();
        }
      }, 60000); // TODO: - This time is the extra time you want to see how long they're idle for until they get logged out 300000
      return () => clearTimeout(timeout);
    }
  }, [showIdleModal]);

  const [image, setImage] = React.useState(sidebarImage);
  const [color, setColor] = React.useState(process.env.REACT_APP_COLOR);
  const [hasImage, setHasImage] = React.useState(true);
  const [userRoutes, setUserRoutes] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [clients, setClients] = React.useState([]);
  const [sites, setSites] = React.useState([]);
  const [labs, setLabs] = React.useState([]);
  const [admins, setAdmins] = React.useState([]);
  const [pendingTests, setPendingTests] = React.useState([]);
  const [invalidTests, setInvalidTests] = React.useState([]);
  const [completedTests, setCompletedTests] = React.useState([]);
  const [unprocessedTests, setUnprocessedTests] = React.useState();
  const [unSubmittedTests, setUnsubmittedTests] = React.useState();
  const [externalTest, setExternalTest] = useState([]);
  const [newTests, setNewTests] = React.useState([]);
  const [totalTests, setTotalTests] = React.useState([]);
  const [adminTotalTests, setAdminTotalTests] = React.useState([]);
  const [userLab, setUserLab] = React.useState(null);
  const [userLabName, setUserLabName] = React.useState(null);
  const [userSiteName, setUserSiteName] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [testFilter, setTestFilter] = useState(null);
  const [eventObject, setEventObject] = useState(0);
  const [testObject, setTestObject] = useState(0);
  const [isShowUpdated, setIsShowUpdated] = useState(null);

  const location = useLocation();
  const mainPanel = React.useRef(null);

  // useEffect(() => {
  //   if (loading) {
  //     const timeout = setTimeout(() => {
  //       if (loading) {
  //         window.location.reload();
  //       }
  //     }, 380000);
  //     return () => {
  //       console.log("In Clear Timmer");
  //       clearTimeout(timeout);
  //     };
  //   }
  // }, [loading]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return <Route path={prop.layout + prop.path} render={(props) => <prop.component {...props} />} key={key} />;
      } else {
        return null;
      }
    });
  };

  const fetchUserGroups = async () => {
    const admins = await localApi.fetchAllUsersInGroup("Admins");
    const labs = await localApi.fetchAllUsersInGroup("Labs");
    const testers = await localApi.fetchAllUsersInGroup("Testers");
    const employers = await localApi.fetchAllUsersInGroup("Employers");
    const clients = await localApi.fetchAllUsersInGroup("Clients");
    return Promise.all([admins, labs, testers, employers, clients]).then((values) => [
      admins,
      labs,
      testers,
      employers,
      clients,
    ]);
  };

  const fetchUserFromLocalDB = async (id = "") => {
    const admins = await localApi.getUserFromLocalDB(id);

    setUsers(
      admins.sort((a, b) =>
        a?.userRole?.toLowerCase() > b?.userRole?.toLowerCase()
          ? 1
          : a?.userRole?.toLowerCase() < b?.userRole?.toLowerCase()
          ? -1
          : 0
      )
    );
  };

  const formatUserObject = (user, role) => {
    const newUser = {};
    const email = user.Attributes.find((attr) => {
      return attr.Name === "email";
    }).Value;
    newUser.email = email;
    const phone = user.Attributes.find((attr) => {
      return attr.Name === "phone_number";
    }).Value;
    const firstName = user.Attributes.find((attr) => {
      return attr.Name === "custom:firstName";
    })?.Value;
    const lastName = user.Attributes.find((attr) => {
      return attr.Name === "custom:lastName";
    })?.Value;

    const note = user.Attributes.find((attr) => {
      return attr.Name === "custom:note";
    })?.Value;

    const adRole = user.Attributes.find((attr) => {
      return attr.Name === "custom:adRole";
    })?.Value;

    const clientID = user.Attributes.find((attr) => {
      return attr.Name === "custom:clientID";
    })?.Value;

    const connectedID = user.Attributes.find((attr) => {
      return attr.Name === "custom:connectedID";
    })?.Value;

    newUser.name = firstName && lastName ? `${firstName} ${lastName}` : "-";
    newUser.phone_number = formatPhoneNumber(phone);
    newUser.phone = phone;
    newUser.username = user.Username;
    newUser.firstName = firstName ? `${firstName}` : "-";
    newUser.lastName = lastName ? `${lastName}` : "-";
    newUser.status = user.UserStatus;
    newUser.role = role === "Employee" ? "Crew" : role === "Employer" ? "Health & Safety" : role;
    newUser.associated = adRole || note || "N/A";
    newUser.clientID = clientID;
    newUser.connectedID = connectedID;
    const obj = Object.assign(
      {
        role: role === "Employee" ? "Crew" : role === "Employer" ? "Health & Safety" : role,
      },
      newUser
    );
    return {...obj, label: `${firstName} ${lastName}`, value: user.Username};
  };


  const fetchAdmins = async () => {
    const list = [];
    const admins = await localApi.fetchUsersInGroup("Admins");
    console.log("admins::" , admins);
    list.push(...admins.map((admin) => formatUserObject(admin, "Admin")));
    console.log("list:" , list);
    setAdmins(list);
    return admins;

       
  };

  const fetchClients = async () => {
    const models = await localApi.getClients();
    setClients(models);
  };

  const fetchSites = async () => {
    const models = await localApi.getSites();
    // setSites(models.filter((s) => s.isArchive === false));
    setSites(models);
  };

  const fetchSitesByUser = async (siteID) => {
    const models = await localApi.getUserSiteData(siteID);
    setUserSiteName(models[0].name);
    return models;
  };

  const fetchSitesByClient = async (clientID) => {
    const models = await localApi.getSitesByClient(clientID);
    return models;
  };

  const findTotalTestsBySites = async (sites) => {
    const passFailClients = await localApi.getResultClients();
    const tests = [];
    sites.forEach((site) => {
      return site?.tests.map((test) => {
        return tests.push(formatTest(test, passFailClients));
      });
    });
    setTotalTests(tests);

    return tests;
  };

  const fetchExternalTests = async () => {
    const models = await localApi.getExternalTest();

    setExternalTest(models.map((t) => formatExternalTest(t)));
  };

  const updatedShow = (obj) => {
    if (obj) {
      setIsShowUpdated(obj);
    } else {
      setIsShowUpdated(null);
    }
  };

  const getClientName = (id) => {
    if (!id) return "";
    if (clients) {
      const client = clients.find((c) => c.id === id);
      if (client) return client.name;
    }
    return "";
  };

  const parseResult = (test) => {
    if (!test) return "";
    if (!test.result) return "";
    if (clients) {
      const client = clients.find((c) => c.id === test.clientID);
      if (client && client.resultType === "P/F") {
        return AMAZON_CLIENT_RESULT[test.result];
      }
    }

    return test.result && test.result.charAt(0).toUpperCase() + test.result.slice(1);
  };

  const parseTestResultForPDF = (test) => {
    if (!test) return "";

    if (clients) {
      const client = clients.find((c) => c.id === test.clientID);
      if (client && client.resultType === "P/F") {
        return AMAZON_CLIENT_RESULT[test.result];
      }
    }

    return test.result && RESULT_PDF[test.result.toLowerCase()];
  };

  const getEmployeeByID = async (id) => {
    const emp = await localApi.getEmployeeByID(id);
    return emp;
  };

  const fetchLabs = async () => {
    const models = await localApi.getLabs();
    setLabs(models);
    const labData = models;
    if (user.isLab()) {
      const lab = labData.filter((l) => l.id === connectedIDInLocalStorage.get());
      if (lab.length > 0) {
        setUserLab(lab[0]);
        setUserLabName(lab[0].name);
      }
    }
  };

  const fetchPendingTests = async (ids) => {
    if (ids) {
      const newList = pendingTests.filter((t) => ids.indexOf(t.id) === -1 && (t.invalidResultStatus || 0) === 0);
      setPendingTests(newList);
      return;
    }
    const params = { pendingRelease: "Pending" };
    if (CONFIG.isWhiteLabel) {
      Object.assign(params, { labID: connectedIDInLocalStorage.get() || "A" });
    }
    const models = await localApi.getTestListFromPG(params);

    const tests = models.rows
      .map((test) => {
        return formatTest(test);
      })
      .filter((f) => (f.invalidResultStatus || 0) === 0);
    setPendingTests(tests);
  };

  const fetchInvalidTests = async (ids) => {
    if (ids) {
      const newList = invalidTests.filter((t) => ids.indexOf(t.id) === -1 && (t.invalidResultStatus || 0) === 0);
      setInvalidTests(newList);
      return;
    }
    const models = await localApi.getTestListFromPG({ limit: 1000, page: 1, result: "Invalid" });
    const tests = models.rows
      .map((test) => {
        return formatTest(test);
      })
      .filter((f) => (f.invalidResultStatus || 0) === 0);
    setInvalidTests(tests);
  };

  const fetchAllAdminTests = async () => {
    const passFailClients = await localApi.getResultClients();
    const models = await localApi.getTotalAdminTests();
    const tests = models.map((test) => {
      return formatTest(test, passFailClients);
    });
    setAdminTotalTests(tests);
  };

  const fetchNewTests = async () => {
    const models = await localApi.getCurrentLabUserTests();
    setNewTests(models);
    return models;
  };

  const onSubscriptionTrigger = debounce(() => refreshDataOnSubscribe(), 6000);

  const refreshDataOnSubscribe = () => {
    console.log("Refresh Data");
    const userType = medFlowInLocalStorage.get() || "A";
    if (userType === "L") {
      fetchNewTests();
    }
  };
  const [user, setUser] = useState();

  const loadSiteData = async () => {
    try {
      const connectedID = connectedIDInLocalStorage.get() || "A";
      if ("A" !== connectedID) {
        const usites = await fetchSitesByUser(connectedID);
        findTotalTestsBySites(usites);
      }
    } catch (ex) {
      console.log("Error", ex);
    }
  };

  useEffect(() => {
    if (!testObject) return;
    const eventType = testObject.opType;
    const model = formatTest(testObject.element);
    const result = model.result;

    if (result && result.toLowerCase() === RESULT_CHECK[result.toLowerCase()]) {
      const newList = [...pendingTests];
      const index = pendingTests.findIndex((t) => t.id === model.id);

      if (
        (eventType === "DELETE" || (model.status === "Processed" && result.toLowerCase() === "positive")) &&
        index !== -1
      ) {
        newList.splice(index, 1);
      } else if (
        (eventType === "INSERT" || eventType === "UPDATE") &&
        index === -1 &&
        (model.status === "Pending" || result.toLowerCase() === "inconclusive" || result.toLowerCase() === "invalid")
      ) {
        newList.unshift(model);
      } else if (eventType === "UPDATE" && index !== -1) {
        newList.splice(index, 1, model);
      }
      setPendingTests(newList.filter((f) => (f.invalidResultStatus || 0) === 0));
    }
    // const newList = [...invalidTests];
    // const index = invalidTests.findIndex((t) => t.id === model.id);
    // if (eventType === "DELETE" && index !== -1) {
    //   newList.splice(index, 1);
    // } else if ((eventType === "INSERT" || eventType === "UPDATE") && index === -1) {
    //   newList.unshift(model);
    // } else if (eventType === "UPDATE" && index !== -1) {
    //   newList.splice(index, 1, model);
    // }
    // setInvalidTests(newList.filter((f) => (f.invalidResultStatus || 0) == 0));
  }, [testObject]);

  React.useEffect(() => {
    let subscription = null;
    let apiSubscription = null;
    let showSubscription = null;
    const userType = medFlowInLocalStorage.get() || "A";

    if (userType === "A") {
      setUserRoutes(adminRoutes);
    } else if (userType === "L") {
      setUserRoutes(CONFIG.isWhiteLabel ? whiteLabelLabRoutes : labRoutes);
    } else if (userType === "E") {
      setUserRoutes(siteRoutes);
    }

    const getUSer = async () => {
      const days = 2;
      const date = moment().subtract(days, "days").format("YYYY-MM-DD");
      const user = await localApi.getCurrentUser();
      let syncExpressionsByUserType = [];
      if (user) {
        if (user.isAdmin() || user.isLab() || user.isSite() || user.isClient()) {
          syncExpressionsByUserType = [
            syncExpression(TestConsent, () => {
              return (test) => test.employeeID("eq", "123");
            }),
            syncExpression(EmployeeBarCodes, () => {
              return (emp) => emp.employeeID("eq", "123");
            }),
            syncExpression(Employee, () => {
              return (test) => test.id("eq", "123");
            }),
            syncExpression(ExternalTest, () => {
              return (test) => test.id("eq", "000");
            }),
          ];
          if (user.isAdmin()) {
            syncExpressionsByUserType.push(
              syncExpression(Test, () => {
                return (test) => test.status("ne", "Processed");
              })
            );
          }
          if (user.isLab()) {
            let connectedIDValue = connectedIDInLocalStorage.get();
            connectedIDValue = connectedIDValue || user["custom:connectedID"];
            syncExpressionsByUserType.push(
              syncExpression(Test, () => {
                return (test) => test.labID("eq", connectedIDValue).test_type("eq", "PCR").createdAt("ge", date); //get All Tests from Lab ID
              })
            );
          }
          if (user.isSite()) {
            let connectedIDValue = connectedIDInLocalStorage.get();
            connectedIDValue = connectedIDValue || user["custom:connectedID"];
            syncExpressionsByUserType.push(
              syncExpression(Test, () => {
                return (test) => test.siteID("eq", connectedIDValue).createdAt("ge", date); //get All Tests from Lab ID
              })
            );
            syncExpressionsByUserType.push(
              syncExpression(LencoBarcodes, () => {
                return (test) => test.id("eq", "123");
              })
            );
          }

          if (user.isClient()) {
            let connectedIDValue = connectedIDInLocalStorage.get();
            connectedIDValue = connectedIDValue || user["custom:connectedID"];
            syncExpressionsByUserType.push(
              syncExpression(Test, () => {
                return (test) => test.clientID("eq", connectedIDValue).createdAt("ge", date); //get All Tests from Lab ID
              })
            );
          }

          DataStore.configure({
            maxRecordsToSync: 90000,
            syncPageSize: 2000,
            syncExpressions: syncExpressionsByUserType,
          });
          DataStore.start();
          setUser(user);
          handleStart();
        } else {
          setLoading(false);
          setShowAlert(true);
        }
      } else {
        setLoading(false);
        console.log("push to login");
        history.push("/login");
      }
    };
    getUSer().then((res) => {
      subscription = DataStore.observe(Test).subscribe((msg) => {
        const noSubs = ROUTES.some((r) => window.location.href.includes(r));
        if (noSubs) return;
        const userType = medFlowInLocalStorage.get() || "A";
        const connectedID = connectedIDInLocalStorage.get() || "A";
        // if(msg.opType === 'UPDATE') return;
        if (userType === "A") {
          setTestObject(msg);
        } else if (
          (userType === "L" && msg.element.labID === connectedID) ||
          (userType === "E" && msg.element.siteID === connectedID)
        ) {
          setEventObject(msg);
        }
        onSubscriptionTrigger();
      });
      showSubscription = DataStore.observe(Site).subscribe((msg) => {
        updatedShow(msg.element);
        fetchSites();
      });
      apiSubscription = API.graphql(graphqlOperation(subscriptions.onUpdateTest)).subscribe({
        next: ({ value: { data }, value }) => {
          console.log("Here are in event");
          const userType = medFlowInLocalStorage.get() || "A";
          const connectedID = connectedIDInLocalStorage.get() || "A";
          let model = data.onUpdateTest;
          if (userType === "A") {
            setTestObject({ opType: "UPDATE", element: model });
          } else if (
            (userType === "L" && model.labID === connectedID) ||
            (userType === "E" && model.siteID === connectedID)
          ) {
            setEventObject({ opType: "UPDATE", element: model });
          }
        },
        error: (error) => console.warn(error),
      });
    });
    return () => {
      if (subscription) subscription.unsubscribe();
      if (apiSubscription) apiSubscription.unsubscribe();
      if (showSubscription) showSubscription.unsubscribe();
    };
  }, []);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    // mainPanel.current.scrollTop = 0;
    if (window.innerWidth < 993 && document.documentElement.className.indexOf("nav-open") !== -1) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location]);

  const contextSwitch = (role) => {
    if (user?.isAdmin()) {
      return {
        testObject,
        user,
        users,
        resetUsers: () => fetchUserFromLocalDB(),
        clients,
        resetClients: () => fetchClients(),
        sites,
        resetSites: () => fetchSites(),
        isShowUpdated,
        labs,
        resetLabs: () => fetchLabs(),
        pendingTests,
        invalidTests,
        resetPendingTests: (ids) => fetchPendingTests(ids),
        // resetInvalidTests: (ids) => fetchInvalidTests(ids),
        completedTests,
        unprocessedTests,
        unSubmittedTests,
        totalTests,
        adminTotalTests,
        externalTest,
        isSuperAdmin: () => isSuperAdmin(),
        sendEmailToCrew: (tests) => sendResultOnEmail(tests),
        resetUnprocessedTests: () => fetchUnprocessedTests(),
        resetUnSubmittedTests: () => fetchUnSubmittedTests(),
        resetAllTests: () => refreshDataOnSubscribe(),
        resetTotalTest: () => fetchAllAdminTests(),
        resetExternalTest: () => fetchExternalTests(),
        getSiteName: (id) => getSiteName(id),
        getLabName: (id) => getLabName(id),
        getLab: (test) => getLab(test),
        getSiteTests: (id) => getSiteTests(id),
        getLabTests: (id) => getLabTests(id),
        getClientTests: (id) => getClientTests(id),
        admins,
        newTests,
        parseResult: (test) => parseResult(test),
        parseTestResultForPDF: (test) => parseTestResultForPDF(test),
        getClientName: (id) => getClientName(id),
        testFilter,
        setTestFilter: (filter) => setTestFilter(filter),
        deleteTests: (tests) => deleteTests(tests),
        showSuccessMessage: (message) => setSuccessMessageText(message),
        showErrorMessage: (message) => setErrorMessageText(message),
        getEmployeeID: (id) => getEmployeeByID(id),
      };
    }

    if (user?.isLab()) {
      return {
        eventObject,
        user,
        users,
        userLab,
        resetUsers: (id) => fetchUserFromLocalDB(id),
        labs,
        resetLabs: () => fetchLabs(),
        userLabName,
        clients,
        admins,
        newTests,
        pendingTests,
        sites,
        clients,
        resetClients: () => fetchClients(),
        resetSites: () => fetchSites(),
        resetPendingTests: (ids) => fetchPendingTests(ids),
        sendEmailToCrew: (tests) => sendResultOnEmail(tests),
        resetNewTests: () => fetchNewTests(),
        getLab: (test) => getLab(test),
        parseResult: (test) => parseResult(test),
        parseTestResultForPDF: (test) => parseTestResultForPDF(test),
        getLabName: (id) => getLabName(id),
        getClientName: (id) => getClientName(id),
        getSiteName: (id) => getSiteName(id),
        user,
        testFilter,
        setTestFilter: (filter) => setTestFilter(filter),
        showSuccessMessage: (message) => setSuccessMessageText(message),
        showErrorMessage: (message) => setErrorMessageText(message),
        getEmployeeID: (id) => getEmployeeByID(id),
      };
    }

    if (user?.isSite()) {
      return {
        eventObject,
        user,
        sites,
        totalTests,
        labs,
        clients,
        externalTest,
        userSiteName,
        sendEmailToCrew: (tests) => sendResultOnEmail(tests),
        resetHSTests: () => fetchSiteTotalTest(),
        getLab: (test) => getLab(test),
        parseResult: (test) => parseResult(test),
        parseTestResultForPDF: (test) => parseTestResultForPDF(test),
        resetExternalTest: () => fetchExternalTests(),
        getLabName: (id) => getLabName(id),
        getClientName: (id) => getClientName(id),
        getSiteName: (id) => getSiteName(id),
        testFilter,
        setTestFilter: (filter) => setTestFilter(filter),
        showSuccessMessage: (message) => setSuccessMessageText(message),
        showErrorMessage: (message) => setErrorMessageText(message),
        getEmployeeID: (id) => getEmployeeByID(id),
      };
    }

    if (user?.isClient()) {
      return {
        user,
        sites,
        totalTests,
        labs,
        clients,
        sendEmailToCrew: (tests) => sendResultOnEmail(tests),
        resetHSTests: () => fetchClientTotalTest(),
        getLab: (test) => getLab(test),
        parseResult: (test) => parseResult(test),
        parseTestResultForPDF: (test) => parseTestResultForPDF(test),
        getLabName: (id) => getLabName(id),
        getClientName: (id) => getClientName(id),
        getSiteName: (id) => getSiteName(id),
        testFilter,
        setTestFilter: (filter) => setTestFilter(filter),
        showSuccessMessage: (message) => setSuccessMessageText(message),
        showErrorMessage: (message) => setErrorMessageText(message),
        getEmployeeID: (id) => getEmployeeByID(id),
      };
    }
  };

  const isSuperAdmin = () => {
    console.log("isSuperAdmin", user?.isAdmin());
    if (user?.isAdmin()) {
      return user?.phone_number === "+18888888888" || user?.phone_number === "+12222222222";
    }
    return false;
  };

  const getClientTests = (id) => {
    const clientObj = adminTotalTests.filter((t) => t.clientID === id);
    return clientObj.length;
  };

  const getSiteTests = (id) => {
    const siteObj = adminTotalTests.filter((t) => t.siteID === id);
    return siteObj.length;
  };
  const getLabTests = (id) => {
    const labObj = adminTotalTests.filter((t) => t.labID === id);
    return labObj.length;
  };

  const getSiteName = (id) => {
    const siteObj = sites.find((site) => site.id === id);
    return siteObj ? siteObj.name : "NA";
  };

  const getLabName = (id) => {
    const labObj = labs.find((lab) => lab.id === id);
    return labObj ? labObj.name : "NA";
  };

  const getLab = async (test) => {
    let lab = labs.find((lab) => lab.id === test.labID);
    if (!lab) {
      lab = await localApi.getLabByID(test?.labID);
    }

    if (lab && "logo" in lab && lab.logo) {
      const path = await Storage.get(lab.logo);
      return { ...lab, logoSrc: path };
    }
    return lab;
  };

  const deleteTests = async (tests) => {
    if (tests.length === 0) return;

    await GQAPI.deleteWrongTest(tests.map((t) => t.id));

    setSuccessMessageText(`${tests.length} test${tests.length === 1 ? "" : "s"} were deleted!`);
  };

  const sendResultOnEmail = async (param, slug) => {
    if (param.length === 0) return;
    setLoading(true);
    let ttlEmails = param.length;
    try {
      await GQAPI.sendEmailToPendingRelease(param);
      const params = { ids: param, userID: user?.sub, userName: user?.name, slug };
      api.addTestLogs(params);

      setLoading(false);
      setSuccessMessageText(`Email has been successfully sent to ${ttlEmails} Crew Member`);
    } catch (error) {
      console.log("error:-", error.message);
      setLoading(false);
      setErrorMessageText("Fail to send the Email");
    }
  };

  const triggerFirebaseListener = async (updateSelectedTestResult, type) => {
    let firestoreName = "PCR";
    if (type === "rapid antigen") firestoreName = "Antigen";
    else if (type === "rapid pcr") firestoreName = "Other";
    else if (type === "molecular") firestoreName = "Molecular";

    const tests = updateSelectedTestResult.filter((t) => t.test_type.toLowerCase() === type);
    if (tests.length > 0) {
      const testCollection = collection(db, firestoreName);
      addDoc(testCollection, {
        date: new Date().toISOString(),
      });
    }
  };

  const adminListener = () => {
    const hubListener = Hub.listen("datastore", (hubData) => {
      const { event } = hubData.payload;
      if (event === "ready") {
        console.log("EVENT READY");

        fetchPendingTests();
        fetchAdmins();
        // fetchInvalidTests();
        fetchClients();
        fetchSites();
        fetchLabs();
        fetchExternalTests();
        Hub.remove("datastore", hubListener);
        setLoading(false);
        if (mainPanel.current) mainPanel.current.scrollTop = 0;
      }
    });
  };

  const labListener = () => {
    const hubListener = Hub.listen("datastore", (hubData) => {
      const { event } = hubData.payload;
      if (event === "ready") {
        setUserRoutes(CONFIG.isWhiteLabel ? whiteLabelLabRoutes : labRoutes);
        if (CONFIG.isWhiteLabel) {
          fetchClients();
          fetchPendingTests();
          fetchSites();
        }
        fetchLabs();
        fetchNewTests();
        setLoading(false);
        Hub.remove("datastore", hubListener);
      }
    });
  };

  const siteListener = () => {
    const hubListener = Hub.listen("datastore", async (hubData) => {
      const { event } = hubData.payload;

      if (event === "ready") {
        fetchLabs();
        setSites(await fetchSitesByUser(connectedIDInLocalStorage.get()));
        fetchClients();
        fetchExternalTests();
        // mainPanel.current.scrollTop = 0;
        Hub.remove("datastore", hubListener);
        setLoading(false);
      }
    });
  };

  const clientListener = () => {
    const hubListener = Hub.listen("datastore", async (hubData) => {
      const { event } = hubData.payload;
      if (event === "ready") {
        fetchLabs();
        setSites(await fetchSitesByClient(user.clientID));
        fetchClients();
        fetchClientTotalTest();
        setUserRoutes(siteRoutes);
        setLoading(false);
        // mainPanel.current.scrollTop = 0;
        Hub.remove("datastore", hubListener);
      }
    });
  };

  const fetchSiteTotalTest = () => {
    if (user?.isSite() && sites.length > 0) {
      findTotalTestsBySites(sites);
    }
  };

  const fetchClientTotalTest = () => {
    if (user?.isClient() && sites.length > 0) {
      findTotalTestsBySites(sites);
    }
  };

  React.useEffect(() => {
    user?.isAdmin() && adminListener();
    user?.isLab() && labListener();
    user?.isSite() && siteListener();
    user?.isClient() && clientListener();
  }, [user]);

  React.useEffect(() => {
    user?.isClient() && fetchClientTotalTest();
  }, [sites]);

  return (
    <>
      <div className="wrapper">
        {showAlert && (
          <SweetAlert
            show={showAlert}
            error
            title="Error"
            onConfirm={async () => {
              await Auth.signOut();
              window.location.reload();
            }}
          >
            Your account does not have access to the Med Flow portal
          </SweetAlert>
        )}
        {successMessageText && (
          <SweetAlert
            show={successMessageText ? true : false}
            success
            title="Success"
            onConfirm={() => setSuccessMessageText("")}
          >
            {successMessageText}
          </SweetAlert>
        )}
        {errorMessageText && (
          <SweetAlert
            show={errorMessageText ? true : false}
            error
            title="Error"
            onConfirm={() => setErrorMessageText("")}
          >
            {errorMessageText}
          </SweetAlert>
        )}
        <Sidebar
          color={color}
          image={""}
          routes={userRoutes}
          user={user}
          pendingTests={pendingTests}
          invalidTests={invalidTests}
          testFilter={testFilter}
          setTestFilter={setTestFilter}
        />
        {/* comment back in to enable log out timer!!!!!!! */}
        {/* <IdleTimer
            crossTab={{
              emitOnAllTabs: false,
            }}
            startOnMount={false}
            startManually={true}
          /> */}
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar
            routes={userRoutes}
            completedTests={completedTests}
            pendingTests={pendingTests}
            role={role || user?.roles[0]}
            userLabName={userLabName}
            userSiteName={userSiteName}
          />
          {!loading ? (
            <div className="content">
              <AppContext.Provider value={contextSwitch(user?.roles[0])}>
                <Switch>{getRoutes(userRoutes)}</Switch>
                {window.location.pathname === "/admin" && user?.isAdmin() && (
                  <AdminHomeApi completedTests={adminTotalTests} />
                )}
                {window.location.pathname === "/admin" && user?.isSite() && (
                  <HealthAndSafetyDashBoard site={sites[0]} />
                )}
                {window.location.pathname === "/admin" && user?.isClient() && <HealthAndSafetyHome sites={sites} />}
                {window.location.pathname === "/admin" && user?.isLab() && <TableListApi />}
              </AppContext.Provider>
            </div>
          ) : (
            <Loader />
          )}
          {/* <Footer /> */}
        </div>
      </div>

      {/* <FixedPlugin
        hasImage={hasImage}
        setHasImage={() => setHasImage(!hasImage)}
        color={color}
        setColor={(color) => setColor(color)}
        image={image}
        setImage={(image) => setImage(image)}
      /> */}
      <IdleModal
        showIdleModal={showIdleModal}
        animation={true}
        handleLogOut={handleLogOut}
        handleCloseModal={handleCloseModal}
      />
    </>
  );
}

export default AdminNew;
