import React, { useState, useContext, useMemo, useRef } from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  DropdownButton,
  Dropdown,
  Modal,
  Form,
} from "react-bootstrap";
import { useEffect } from "react";
import API from "api";
import { CSVLink } from "react-csv";
import { Importer, ImporterField } from "react-csv-importer";
import { Link } from "react-router-dom";
import Filter from "../components/Filter";
import TimeFilter from "../components/TimeFilter";
import { useLocation } from "react-router-dom";
import { Bounce } from "react-activity";
import { SortDirection, Storage } from "aws-amplify";
import "react-activity/dist/Bounce.css";
import {
  changeSort,
  getCrewsName,
  formatTest,
  formatPhoneNumber,
  sortTestList,
  calculateTdWidth,
  testTypeValid,
  formatExternalTest,
} from "../utils";
import FilterSelect from "components/FilterSelect";
import ContactInfo from "../components/Modal/ContactInfo";
import MFPagination from "../components/Pagination/MFPagination";
import { AppContext } from "../context/app-context";
import { PAGE_LIMIT, TEST_HEADER_TYPE, RESULT_COLOR, SUPER_ADMIN_ID } from "../constant";
import PDFLayout from "components/PDF/PDFLayout";
import Select from "react-select";
import ReactPDF from "@react-pdf/renderer";
import PDF from "components/PDF";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import ExportToExcel from "components/ExportToExcel";
import TestPDFLayout from "components/PDF/TestPDFLayout";
import HeaderItem from "components/Table/HeaderItem";

import moment from "moment";
import Loader from "../components/Loader/Loader";

const ExternalTest = () => {
  const importResult = [];
  const [tests, setTests] = useState([]);
  const [filteredTests, setFilteredTests] = useState([]);
  const [labs, setLabs] = useState([]);
  const [sites, setSites] = useState([]);
  const [moreInfo, setMoreInfo] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [testsLab, setTestsLab] = useState();
  const [testPdf, setTestPdf] = useState();
  const [showPdf, setShowPdf] = useState(false);
  const [timeFilter, setTimeFilter] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const [triggerFilter, setTriggerFilter] = useState();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemToDelete, setItemToDelete] = useState({});
  const [selectedShow, setSelectedShow] = useState("");
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const { filterDates } = TimeFilter;

  const location = useLocation();

  const appContext = useContext(AppContext);
  const componentRef = useRef();
  const mainWidth = useRef();
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(componentRef.current.offsetWidth);
  }, [componentRef]);

  const handleResize = () => {
    if (componentRef.current) setWidth(componentRef.current.offsetWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => handleResize(), [mainWidth]);

  const tdWidth = calculateTdWidth(width - 130, 9);

  const searchTerms = [
    "First Name",
    "Last Name",
    "Date of Birth",
    "Email",
    "Phone",
    "Show",
    "Result",
    "Test Type",
    "Reason",
    "Tester",
  ];

  const displayOwner = (test) => {
    let displayedId = test.owner.substr(-12);
    return displayedId;
  };
  const selectedItem = [
    "createdAt",
    "firstName",
    "lastName",
    "email",
    "phoneNumber",
    "site_name",
    "reason",
    "tester_name",
  ];

  const createMoreInfo = (type, id) => {
    let infoObj = null;
    if (type === "site") infoObj = appContext.sites.find((site) => site.id === id);
    else if (type === "client") infoObj = appContext.clients.find((client) => client.id === id);
    else infoObj = labs.find((lab) => lab.id === id);

    setMoreInfo({
      type,
      name: infoObj.name,
      contact: infoObj.contact_name,
      phone: infoObj.contact_phone,
      email: infoObj.contact_email,
    });
    setModalShow(true);
  };
  const handleSavesFilter = () => {
    appContext.setTestFilter({ location: "externaltests", filter, timeFilter });
  };
  const testsToMap = useMemo(() => {
    const list = filteredTests ? [...filteredTests] : [];
    const ttlPages = list.length > PAGE_LIMIT ? Math.ceil(list.length / PAGE_LIMIT) : 1;
    setTotalPages(ttlPages);
    if (list.length < PAGE_LIMIT || currentPage > ttlPages) {
      setCurrentPage(1);
    }
    if (ttlPages > 1) {
      const indexOfLastUser = currentPage * PAGE_LIMIT;
      const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
      return list.slice(indexOfFirstUser, indexOfLastUser);
    }
    return list;
  }, [filteredTests, currentPage]);

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filter).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length) {
          return true;
        }
        if (key === "Lab") {
          return obj.labID && appContext.getLabName(obj.labID).toLowerCase().includes(filters[key].toLowerCase());
        }
        if (key === "Show") {
          return obj.site_name && obj.site_name.toLowerCase().includes(filters[key].toLowerCase());
        }
        if (key === "ShowID") {
          return obj.siteID === filters[key];
        }
        if (key === "Client") {
          return (
            obj.clientID && appContext.getClientName(obj.clientID).toLowerCase().includes(filters[key].toLowerCase())
          );
        }
        if (key === "sequenceNo") {
          return obj[key] && obj["sequenceNo"].toString().includes(filters[key]);
        }
        if (key === "emailSend") {
          if (filters[key].toLowerCase() === "y") {
            return obj[key] && obj["emailSend"];
          }
          if (filters[key].toLowerCase() === "n") {
            return obj[key] && !obj["emailSend"];
          }
          return obj[key];
        }

        return obj[key] && obj[key].toLowerCase().includes(filters[key].toLowerCase());
      });
    });
    return models;
  };

  const displayPdf = async (test) => {
    if(!test.externalTestFile) return;

    let getExternalTestImage = await API.getFileExternalStorage(test.externalTestFile);
    setExternalTestImage(getExternalTestImage);

  };

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };
  console.log("external Test", filteredTests);
  useEffect(() => {
    if (
      appContext.testFilter &&
      appContext.testFilter.location === "alltests" &&
      Object.keys(appContext.testFilter.filter).length > 0
    ) {
      console.log("In UseEffect ", appContext.testFilter);
      setFilter(appContext.testFilter.filter);
      setShowFilter(true);
    }
  }, []);

  useEffect(() => {
    loadData();
  }, [selectedShow]);

  const loadData = async () => {
    try {
      if (selectedShow) {
        let list = [];
        console.log("loadData", selectedShow);
        setLoading(true);
        list = await API.getExternalTestList(selectedShow.value);
        console.log("list", list);
        // list = list.map((d) => {
        //   const obj = d;
        //   obj.vaccinationDetails =
        //     typeof d.vaccinationDetails === "string" ? JSON.parse(d.vaccinationDetails) : d.vaccinationDetails;
        //   if (obj.hasOwnProperty("orderTracking") && d.orderTracking.length > 0) {
        //     obj.orderTracking = typeof d.orderTracking === "string" ? JSON.parse(d.orderTracking) : d.orderTracking;
        //   }
        //   return obj;
        // });
        setLoading(false);
        const formatList = list?.map((t) => formatExternalTest(t)) || [];
        refreshTestList(formatList);
        setTests(formatList);
      }
    } catch (err) {
      console.log("Error", err);
      setLoading(false);
      appContext.showErrorMessage("Fail to load the data");
    }
  };

  useEffect(() => {
    setLoading(true);
    setLabs(appContext.labs);
    setSites(appContext.sites);
    setLoading(false);
  }, [appContext.labs, appContext.sites, appContext.externalTest]);
  useEffect(() => {
    !location.state && refreshTestList(tests);
    // !location.state && setTests(appContext.externalTest);
    // setLoading(false);
  }, [sites, labs, appContext.externalTest]);

  useEffect(() => {
    setLoading(false);
  }, [tests]);

  useEffect(() => {
    changeSort(
      sortBy,
      sortDescending,
      filteredTests,
      setFilteredTests,
      appContext.getSiteName,
      appContext.getLabName,
      appContext.getClientName,
      appContext.parseResult
    );
  }, [sortBy, sortDescending]);

  useEffect(() => {
    if (location.state) {
      if (location.state.term === "Lab") setLabs(location.state.models);

      setShowFilter(true);

      let filterTerm = location.state.term;
      let filterTermName = location.state.name;

      if (filterTerm === "Show") {
        const obj = location.state.models.find((c) => c.name === filterTermName);
        if (obj) {
          filterTerm = "ShowID";
          filterTermName = obj.id;
        }
      }

      if (location.state.positiveTests) {
        let resultValue = "positive";
        if (filterTerm === "Client") {
          const obj = location.state.models.find((c) => c.name === filterTermName);
          if (obj && obj.resultType === "P/F") resultValue = "fail";
        }
        setFilter({
          ...filter,
          [filterTerm]: filterTermName,
          result: "positive",
        });
      } else if (location.state.negativeTests) {
        let resultValue = "negative";
        if (filterTerm === "Client") {
          const obj = location.state.models.find((c) => c.name === filterTermName);
          if (obj && obj.resultType === "P/F") resultValue = "pass";
        }
        setFilter({
          ...filter,
          [filterTerm]: filterTermName,
          result: resultValue,
        });
      } else {
        setFilter({ ...filter, [filterTerm]: filterTermName });
      }
      setTriggerFilter(true);
      setTimeout(() => setShowFilter(false), 1);
    }
  }, [location.state]);
  const getsiteExternaltestType = (obj) => {
    const siteObj = sites.find((c) => c.id == obj.siteID);
    return testTypeValid(obj, siteObj?.externalTestType);
  };
  useEffect(() => {
    refreshTestList(tests);
  }, [filter, triggerFilter, timeFilter]);

  const getSiteOptions = useMemo(() => {
    if (!appContext.sites) return [];
    return appContext.sites
      .filter((s) => s.externalTest)
      .map((l) => {
        return { value: l.id, label: l.name, ...l };
      });
  }, [appContext.sites]);

  const refreshTestList = (lists) => {
    
    const list = filterDates(nestedFilter(lists || [], filter), timeFilter);
    if (sortBy) {
      setFilteredTests(
        sortTestList(
          sortBy,
          sortDescending,
          list,
          appContext.getSiteName,
          appContext.getLabName,
          appContext.getClientName,
          appContext.parseResult
        )
      );
    } else {
      setFilteredTests(list);
    }
  };

  const handleDeleteConfirm = async (isConfirm) => {
    setOpenDeleteConfirmation(false);
    if (!isConfirm) {
      return;
    }
    if (Object.keys(itemToDelete).length > 0) {
      await API.deleteWrongTest(itemToDelete.id);
      setItemToDelete({});
    }
  };
  const alertMessage = (val) => {
    if (val === "EXPIRED") return val;
    const date = new Date(val);
    const dt = new Date();
    dt.setHours(dt.getHours() + 12);
    if (date <= dt && date >= new Date()) {
      return (
        <div>
          <i className="fas fa-bell" title={ValidDateInfo(date)} size="20px" style={{ color: "#ab2330" }} />
          {moment(date).format("MM-DD-YYYY HH:mm:ss")}
        </div>
      );
    }
    return val;
  };
  const handleSaveFilter = () => {
    appContext.setTestFilter({ location: "alltests", filter, timeFilter, sortBy, sortDescending });
  };
  const ValidDateInfo = (info) => {
    if (info === "EXPIRED") return info;
    let dateFuture = new Date(info);
    let dateNow = new Date();
    let seconds = Math.floor((dateFuture - dateNow) / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / 24);

    hours = hours - days * 24;
    minutes = minutes - days * 24 * 60 - hours * 60;
    seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
    return `Alert ${hours}h : ${minutes}m reamaining`;
  };
  const handleCheckboxChange = (e, id) => {
    const filteredList = checkboxes.filter((c) => c !== id);
    if (e.target.checked) {
      filteredList.push(id);
    }
    setCheckboxes(filteredList);
  };

  const toggleCheckboxes = (val) => setCheckboxes(val ? filteredTests.map((t) => t.id) : []);

  const TableRow = ({ test }) => {
    console.log('test_type',test);
    return (
      <tr>
        <td className="ellipsis" style={{ textAlign: "left" }}>
          <Form.Check>
            <Form.Check.Input
              type="checkbox"
              value={test.id}
              checked={checkboxes.indexOf(test.id) !== -1}
              onChange={(e) => handleCheckboxChange(e, test.id)}
            ></Form.Check.Input>
          </Form.Check>
        </td>
        <td className="table-column ellipsis">{moment(test.createdAt).format("MM-DD-YYYY")}</td>

        <td className="table-column ellipsis" title={test.firstName}>
          {test.firstName}
        </td>
        <td className="table-column ellipsis" title={test.lastName}>
          {test.lastName}
        </td>
        {/* <td className="ellipsis" title={test.dob}>
          {test.dob}
        </td> */}
        <td
          className="table-column ellipsis"
          style={{
            textDecoration: "underline",
            color: "#A82632",
          }}
          onMouseOver={(e) => {
            e.target.style.cursor = "pointer";
            e.target.style.textDecoration = "underline";
          }}
          onClick={() => test?.email && window.open(`mailto:${test.email}`)}
          title={test.email}
        >
          {test.email}
        </td>
        <td
          className="table-column ellipsis"
          style={{ textDecoration: "underline", color: "#A82632" }}
          onMouseOver={(e) => {
            e.target.style.cursor = "pointer";
            e.target.style.textDecoration = "none";
          }}
          onMouseLeave={(e) => {
            e.target.style.textDecoration = "underline";
          }}
          onClick={() => {
            test.phoneNumber && window.open(`tel:+1${test.phoneNumber}`);
          }}
          title={test.phoneNumber && formatPhoneNumber(test.phoneNumber)}
        >
          {test.phoneNumber && formatPhoneNumber(test.phoneNumber)}
        </td>
        <td className="table-column ellipsis" style={{ textAlign: "center" }}>
          <a
            style={{ color: "#42cef5" }}
            onClick={() => createMoreInfo("site", test.siteID)}
            onMouseOver={(e) => (e.target.style.cursor = "pointer")}
            title={appContext.getSiteName(test.siteID)}
          >
            {appContext.getSiteName(test.siteID)}
          </a>
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }} title={test.result}>
          <span
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
              e.target.style.textDecoration = "none";
            }}
            onMouseLeave={(e) => {
              e.target.style.textDecoration = "underline";
            }}
            style={{
              color: RESULT_COLOR[test.result?.toLowerCase()],
              textDecoration: "underline",
            }}
           
          >
            {test.result}
          </span>
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }} title={test.test_type}>
          {test.test_type}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }} title={test.reason}>
          {test.reason}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }} title={test.tester_name || "NA"}>
          {test.tester_name || "NA"}
        </td>
        {/* <td
          className="ellipsis"
          style={{ textAlign: "center" }}
          onMouseOver={(e) => (e.target.style.cursor = "pointer")}
          title={test.validUntil}
        >
          {alertMessage(test.validUntil)}
        </td>
        <td>
          <Link
            style={{ color: "#42cef5" }}
            to={{
              pathname: "/admin/externaltest/" + test.id,
              state: { type: "externalTest" },
            }}
            onClick={handleSavesFilter}
          >
            View
          </Link>
        </td> */}
      </tr>
    );
  };

  const handleChangePage = (page) => setCurrentPage(page);
  const handleCloseContactInfo = () => {
    setMoreInfo({});
    setModalShow(false);
  };

  const handleSendEmail = () => {
    const selectedTest = filteredTests.filter((test) => checkboxes.includes(test.id));
    if (!selectedTest.length) {
      return;
    }
    appContext.sendEmailToCrew(selectedTest);
  };

  return showPdf ? (
    <PDFLayout setShowPdf={setShowPdf} testPdf={testPdf} testsLab={testsLab} />
  ) : (
    <>
      {!loading ? (
        <Container fluid style={{ flex: 1 }}>
          <Row>
            <Col md="12">
              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <Card.Title
                    as="h4"
                    style={{
                      marginBottom: 10,
                      fontWeight: "bold",
                      color: "#565656",
                    }}
                  >
                    External Tests ({filteredTests ? filteredTests.length : 0})
                  </Card.Title>
                  <div className="buttonHeader">
                    <Button className="headerButton" onClick={() => setShowFilter(!showFilter)}>
                      Filter
                    </Button>
                    <FilterSelect setTimeFilter={setTimeFilter} />
                    <Button
                      className="headerButton"
                      onClick={() => {
                        if (filteredTests && filteredTests.length > 0) {
                          setAllSelected(!allSelected);
                          toggleCheckboxes(!allSelected);
                        }
                      }}
                    >
                      {!allSelected ? "Select All" : "Deselect All"}
                    </Button>

                    {filteredTests.length > 0 && (
                      <>
                        <ExportToExcel
                          items={filteredTests}
                          selectedItem={selectedItem}
                          appContext={appContext}
                          filter={filter}
                          timeFilter={timeFilter}
                          title="External Tests"
                          sortBy={sortBy}
                        />
                        <TestPDFLayout
                          tests={filteredTests}
                          selectedItem={selectedItem}
                          filter={filter}
                          timeFilter={timeFilter}
                          title="External Tests"
                          appContext={appContext}
                          sortBy={sortBy}
                        />
                      </>
                    )}
                    <Select
                      className="selectorHeader w-100"
                      options={getSiteOptions}
                      blurInputOnSelect={true}
                      defaultValue={null}
                      value={selectedShow}
                      menuPlacement="auto"
                      placeholder="Select Show"
                      onChange={(e) => setSelectedShow(e)}
                    />
                    {/* <Button
                      className="headerButton"
                      onClick={() => {
                        const testList = filteredTests.filter((test) => checkboxes.includes(test.id));
                        if (!testList.length) {
                          return;
                        }
                        appContext.deleteTests(testList);
                      }}
                    >
                      Delete Test
                    </Button> */}
                  </div>
                  {showFilter && (
                    <Filter
                      filterTerms={searchTerms}
                      setFilter={setFilter}
                      filter={filter}
                      triggerFilter={triggerFilter}
                      setTriggerFilter={setTriggerFilter}
                    />
                  )}
                  <MFPagination
                    currentPage={currentPage}
                    handleChangePage={handleChangePage}
                    totalPages={totalPages}
                    showSelectedRecord
                    totalSelected={checkboxes.length}
                  />
                </Card.Header>

                <Card.Body className="table-full-width px-0 desktop-noScroll">
                  <div className="table-responsive pendingReleaseTable">
                    <Table className="table-hover table-striped" ref={mainWidth}>
                      <thead ref={componentRef}>
                        <tr>
                          <th></th>

                          <HeaderItem
                            width={tdWidth}
                            ItemKey="createdAt"
                            title="Date Admitted"
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                          />
                          <HeaderItem
                            width={tdWidth}
                            ItemKey="firstName"
                            title="First Name"
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                          />
                          <HeaderItem
                            width={tdWidth}
                            ItemKey="lastName"
                            title="Last Name"
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                          />
                          {/* <HeaderItem
                            width={tdWidth}
                            ItemKey="dob"
                            title="DoB"
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                          /> */}
                          <HeaderItem
                            width={tdWidth}
                            ItemKey="email"
                            title="Email"
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                          />
                          <HeaderItem
                            width={tdWidth}
                            ItemKey="phoneNumber"
                            title="Phone"
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                          />
                          <HeaderItem
                            width={tdWidth}
                            ItemKey="siteID"
                            title="Show"
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                            aligned="centered"
                          />
                          <HeaderItem
                            width={tdWidth}
                            ItemKey="result"
                            title="Result"
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                            aligned="centered"
                          />
                          <HeaderItem
                            width={tdWidth}
                            ItemKey="test_type"
                            title="Test Type"
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                            aligned="centered"
                          />
                          <HeaderItem
                            width={tdWidth}
                            ItemKey="reason"
                            title="Reason"
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                            aligned="centered"
                          />

                          <HeaderItem
                            width={tdWidth}
                            ItemKey="tester_name"
                            title="Tester"
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                            aligned="centered"
                          />
                          {/* <HeaderItem
                            width={tdWidth}
                            ItemKey="validUntil"
                            title="Valid Until"
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                            aligned="centered"
                          />
                          <th
                            className="border-0 left text-grey"
                            style={{ fontWeight: "bold", width: "80px", maxWidth: "80px" }}
                          >
                            Actions
                          </th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {testsToMap &&
                          testsToMap.map((test) => {
                            return <TableRow key={test.id} test={test} />;
                          })}
                      </tbody>
                    </Table>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      ) : (
        <Loader />
      )}
      <ContactInfo show={modalShow} moreInfo={moreInfo} handleClose={handleCloseContactInfo} />
      {openDeleteConfirmation && (
        <ConfirmationModal
          show={openDeleteConfirmation}
          title="Delete User"
          message={`Are you sure, you want to remove Test of ${itemToDelete.name}?`}
          handleConfirm={handleDeleteConfirm}
        />
      )}
    </>
  );
};

export default ExternalTest;
