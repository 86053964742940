import React, { useState, useEffect, useRef, useMemo } from "react";
import logoImage from "../../assets/img/safecamp_BRAND.1-nobg.png";
import PhoneNoInput from "components/Phone/PhoneNoInput";
import { formateDateLL, formateDatellll } from "utils";
import { convertToLower, isValidEmail } from "utils";

const RegisterStepOne = (props) => {
  const { show, handleProcced, errorMessageText, linkCrew } = props;
  const [newUser, setNewUser] = useState({});
  const [error, setError] = useState("");
  const [phoneError, setPhoneError] = useState("");

  useEffect(() => {
    setError(errorMessageText);
  }, [errorMessageText]);

  const showErrorMessage = (message) => {
    setError(message);
    setTimeout(() => setError(""), 2000);
  };

  const handleSubmit = () => {
    if (!linkCrew && !newUser.phone?.trim() && !newUser.email?.trim()) {
      showErrorMessage("Phone or Email is Required");
      return;
    }
    if (!newUser.phone && linkCrew) {
      showErrorMessage("Phone is required");
      return;
    }
    if (newUser.phone && phoneError) {
      showErrorMessage("Invalid Phone Number");
      return;
    }
    if (!linkCrew && newUser.email?.trim() && !isValidEmail(newUser.email)) {
      showErrorMessage("Invalid Email");
      return;
    }
    handleProcced(newUser);
  };
  console.log("newUSerPrg", newUser);
  return (
    <div className="registerFormWrapper">
      <div className="registerWrapper">
        <div className="text-center">
          <img src={logoImage} alt="logo" className="logo-width" />
        </div>
        <div className="codeScannerWrapper codeScannerPhoneInput">
          <div className="registered-successful">
            <h4 className="w-100 text-center mt-4">
              <b>{show && `${show.name}`}</b>
            </h4>
            {show && (
              <>
                <h4 className="w-100 text-center mt-4">
                  <b>Event Date: {`${formateDateLL(show.startDate)}`}</b>
                </h4>
                {show.orderKitDate && (
                  <h4>
                    <b>Test Ordering Deadline: {`${formateDatellll(show.orderKitDate)}`}</b>
                  </h4>
                )}
              </>
            )}
            <div className={linkCrew ? "form-group-wrapper mt-5 mb-5" : "form-group-wrapper mt-5 mb-0"}>
              <label className="mb-1">Phone Number:</label>
              <PhoneNoInput
                error={setPhoneError}
                handleChange={(e) => {
                  setNewUser({ ...newUser, phone: e });
                }}
                value={newUser.phone}
                cssCode="w-100"
              />
            </div>
            {!linkCrew && (
              <>
                <div className="py-1 mx-auto">OR</div>
                <div className="form-group-wrapper mb-5">
                  <label className="mb-1">Email:</label>
                  <input
                    value={newUser.email}
                    type="text"
                    className="form-control"
                    placeholder="Email"
                    onChange={(e) => setNewUser({ ...newUser, email: convertToLower(e.target.value) })}
                  />
                </div>
              </>
            )}
            {error && (
              <div className="error-message">
                <h6>{error}</h6>
              </div>
            )}
            <div className="mt-2 w-50 mx-auto">
              <button type="submit" className="text-uppercase w-100 btn signup-btn" onClick={handleSubmit}>
                Proceed
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterStepOne;
