import React, {useState, useEffect, useMemo, useRef} from "react";
import MFPagination from "../components/Pagination/MFPagination";
import TimeFilter from "../components/TimeFilter";
import HeaderItem from "components/Table/HeaderItem";
import { PAGE_LIMIT } from "../constant";
import { Card, Table, Row, Col } from "react-bootstrap";
import ClientDashboardItem from "./ClientDashboardItem";
import CameraImage from "assets/img/video-camera.png";
import TotalTestImage from "assets/img/total-test.png";
import PositiveImage from "assets/img/positive-test.png";
import NegativeImage from "assets/img/negative-test.png";
import {calculateTdWidth, changeSort} from "../utils";
import ClientDashboardCard from "./ClientDashboardCard";

const ClientDashboard = (props) => {

  const componentRef = useRef();
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(componentRef.current.offsetWidth);
  }, [componentRef]);

  const handleResize = () => {
    if (componentRef.current) setWidth(componentRef.current.offsetWidth);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  const tdWidth = calculateTdWidth(width, 7);

  const {
    sites,
    showStartDate,
    positiveTests,
    lastPositiveTest,
    getShowRunningDays,
    negativeTests,
    lastPositiveTestDate,
  } = props;
  const [filteredSites, setFilteredSites] = useState(sites);
  const { filterDates } = TimeFilter;
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filter, setFilter] = useState({});
  const [timeFilter, setTimeFilter] = useState({});
  const [stats, setStats] = useState("");

  useEffect(() => {
    changeSort(sortBy, sortDescending, filteredSites, setFilteredSites);
  }, [sortBy, sortDescending]);

  const sitesToMap = useMemo(() => {
    const list = filteredSites ? [...filteredSites] : [];
    const ttlPages = list.length > PAGE_LIMIT ? Math.ceil(list.length / PAGE_LIMIT) : 1;
    setTotalPages(ttlPages);
    if (list.length < PAGE_LIMIT || currentPage > ttlPages) {
      setCurrentPage(1);
    }
    if (ttlPages > 1) {
      const indexOfLastUser = currentPage * PAGE_LIMIT;
      const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
      return list.slice(indexOfFirstUser, indexOfLastUser);
    }
    return list;
  }, [filteredSites, currentPage, sites]);

  useEffect(() => {
    setFilteredSites(filterDates(nestedFilter(sites, filter), timeFilter));
  }, [filter, timeFilter]);

  const handleChangePage = (page) => setCurrentPage(page);
  const nestedFilter = (targetArray, filters) => {
    console.log("TOP NESTED FILTER", targetArray);
    if (Object.keys(filter).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length) {
          return true;
        }
        if (key === "sequenceNo") {
          return obj[key] && obj["sequenceNo"].toString().includes(filters[key]);
        }
        console.log(obj[key], filters[key]);
        return obj[key] && obj[key].toLowerCase().includes(filters[key].toLowerCase());
      });
    });
    console.log("FROM NESTED FILTER", models);
    return models;
  };
  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(sortBy === by ? !sortDescending : true);
  };

  useEffect(() => {
    const data = sites.reduce((obj, site) => {
      if (site.name) obj["totalShows"] = (obj["totalShows"] || 0) + 1;
      obj["totalTests"] = (obj["totalTests"] || 0) + site.tests.length;
      obj["positiveTests"] = (obj["positiveTests"] || 0) + positiveTests(site.tests).length;
      obj["negativeTests"] = (obj["negativeTests"] || 0) + negativeTests(site.tests);
      return obj;
    }, {});
    setStats(data);
  }, []);

  return (
    <>
      <Row>
        <Col md="12">
          <Card>
            <Card.Body className="p-4">
              <div className="client-dashboard-wrapper">
                <ClientDashboardCard title="Total Shows" value={stats.totalShows} imgSrc={CameraImage} />
                <ClientDashboardCard title="Total Tests" value={stats.totalTests} imgSrc={TotalTestImage} />
                <ClientDashboardCard title="Positive Tests" value={stats.positiveTests} imgSrc={PositiveImage} />
                <ClientDashboardCard title="Negative Tests" value={stats.negativeTests} imgSrc={NegativeImage} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
           
            <Card.Body className="table-full-width px-0 desktop-noScroll">
              <div className="table-responsive pendingReleaseTable">
                <Table className="table-hover table-striped">
                  <thead ref={componentRef}>
                  <tr>
                    <HeaderItem
                        width={tdWidth}
                        ItemKey="name"
                        title="Show Name"
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                    />
                    <HeaderItem
                        width={tdWidth}
                        ItemKey="startDate"
                        title="Show Start Date"
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                    />
                    <HeaderItem
                        width={tdWidth}
                        ItemKey="tests"
                        title="Last Positive Test"
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        aligned="centered"
                    />
                    <HeaderItem
                        width={tdWidth}
                        ItemKey="tests"
                        title="Total Tests"
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        aligned="centered"
                    />
                    <HeaderItem
                        width={tdWidth}
                        ItemKey="startDate"
                        title="Show Running Days"
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        aligned="centered"
                    />

                    <HeaderItem
                        width={tdWidth}
                        ItemKey="tests"
                        title="Positive Tests"
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        aligned="centered"
                    />

                    <HeaderItem
                        width={tdWidth}
                        ItemKey="tests"
                        title="Negative Tests"
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        aligned="centered"
                    />
                  </tr>
                  </thead>
                  <tbody>
                  {filteredSites &&
                  filteredSites.map((site, index) => (
                      <ClientDashboardItem
                          lastPositiveTestDate={lastPositiveTestDate}
                          key={index}
                          site={site}
                          showStartDate={showStartDate}
                          positiveTests={positiveTests}
                          lastPositiveTest={lastPositiveTest}
                          getShowRunningDays={getShowRunningDays}
                          negativeTests={negativeTests}
                      />
                  ))}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ClientDashboard;


