import React, { useState } from "react";
import logoImage from "../../assets/img/safecamp_BRAND.1-nobg.png";

const MultiRole = (props) => {
  const { roles, handleRoleSelection } = props;
  const [error, setError] = useState("");
  const [selectedRole, setSelectedRole] = useState("");

  const showErrorMessage = (message) => {
    setError(message);
    setTimeout(() => setError(""), 2000);
  };

  const handleSubmit = () => {
    if (!selectedRole) {
      showErrorMessage("Select the role");
      return;
    }
    handleRoleSelection(selectedRole);
  };

  const onChangeSelection = (e) => {
    setSelectedRole(e);
  };

  const PORTAL_TITLE  = {
    Lab: ' Lab Portal',
    Labs: ' Lab Portal',
    Employer: 'H&S Portal',
    Employers: 'H&S Portal',
    Client: 'Client Portal',
    Clients: 'Client Portal',
  }

  return (
    <div className="registerFormWrapper">
      <div className="registerWrapper">
        <div className="text-center">
          <img src={logoImage} alt="logo" className="logo-width" />
        </div>
        <div className="codeScannerWrapper codeScannerPhoneInput">
          <div className="registered-successful">
            <h4 className="w-100 text-center mt-5">
              <b>Login into</b>
            </h4>
            <div className="custom-radio-btn-wrapper">
              {roles.map((role, index) => {
                return (
                  <div key={index}>
                    <input
                      id={index}
                      className="radio-custom"
                      name="radio-group"
                      type="radio"
                      value={role.role}
                      onChange={() => onChangeSelection(role)}
                    />
                    <label htmlFor={index} className="radio-custom-label">
                      <div className="d-inline-flex flex-column align-items-start">
                        {role.role !== "Admins" && (
                          <>
                            <span>{`${role.title} ${PORTAL_TITLE[role.role]}`}</span>
                          </>
                        )}
                        {role.role === "Admins" && (
                          <>
                            <span>Admin Portal</span>
                          </>
                        )}
                      </div>
                    </label>
                  </div>
                );
              })}
            </div>

            {error && (
              <div className="error-message">
                <h6>{error}</h6>
              </div>
            )}
            <div className="mt-5 w-50 mx-auto">
              <button type="submit" className="text-uppercase w-100 btn signup-btn" onClick={handleSubmit}>
                Proceed
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiRole;
