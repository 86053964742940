import React, { useState, useEffect } from "react";
import Login from "components/Login/Login";
import { Bounce } from "react-activity";
import API from "api";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AdminNew from "layouts/AdminNew";
import PreRegister from "views/PreRegister";
import RegisterCrew from "views/RegisterCrew";
import QRLinkView from "components/QRLinkView";
import Loader from "./components/Loader/Loader";
import { CONFIG } from "constant";
import LabNew from "layouts/LabNew";
import SiteNew from "layouts/SiteNew";

export default function App() {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const getUSer = async () => {
      const user = await API.getCurrentUser();
      if (user) {
        setUser(user);
        setLoading(false);
      } else {
        setLoading(false);
      }
    };
    getUSer();
  }, []);

  if (loading) {
    return <Loader />;
  }

  sessionStorage.setItem('connectedID', localStorage.getItem('state'))

  if (CONFIG.isWhiteLabel) {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/admin" render={(props) => <AdminNew {...props} />} />
          <Route path="/lab" render={(props) => <LabNew {...props} />} />
          <Route path="/site" render={(props) => <SiteNew {...props} />} />
          <Route path="/login" render={(props) => <Login />} />
          <Redirect from="/" to="/login" />
        </Switch>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/pre-register" render={(props) => <PreRegister />} />
        <Route path="/register/:id" render={(props) => <RegisterCrew {...props} />} />
        <Route path="/register" render={(props) => <RegisterCrew {...props} />} />
        <Route path="/preregister/:testId" render={(props) => <QRLinkView {...props} />} />
        <Route path="/admin" render={(props) => <AdminNew {...props} />} />
        <Route path="/lab" render={(props) => <LabNew {...props} />} />
        <Route path="/site" render={(props) => <SiteNew {...props} />} />
        <Route path="/login" render={(props) => <Login />} />
        <Redirect from="/" to="/login" />
      </Switch>
    </BrowserRouter>
  );
}
