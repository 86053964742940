import React, { useState, useContext, useMemo, useRef, useCallback } from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  DropdownButton,
  Dropdown,
  Modal,
  Form,
} from "react-bootstrap";
import { useEffect } from "react";
import API from "api";
import GQAPI from "views/GraphQL/gqApi";
import { CSVLink } from "react-csv";
import { Importer, ImporterField } from "react-csv-importer";
import { Link } from "react-router-dom";
import Filter from "components/Filter";
import TimeFilter from "components/TimeFilter";
import { useLocation } from "react-router-dom";
import { SortDirection, Storage } from "aws-amplify";
import "react-activity/dist/Bounce.css";
import {
  changeSort,
  getCrewsName,
  formatTest,
  formatPhoneNumber,
  sortTestList,
  sortingFilterInLC,
  formatUTCDate,
} from "utils";
import FilterSelect from "components/FilterSelect";
import ContactInfo from "components/Modal/ContactInfo";
import Header from "components/Table/Header";
import PGPagination from "components/Pagination/PGPagination";
import { AppContext } from "context/app-context";
import { PAGE_LIMIT, TEST_HEADER_TYPE, RESULT_COLOR, SUPER_ADMIN_ID } from "constant";
import PDFLayout from "components/PDF/PDFLayout";
import ReactPDF from "@react-pdf/renderer";
import PDF from "components/PDF";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import ExportToExcelData from "components/ExportToExcelData";
import TestPDFLayout from "components/PDF/TestPDFLayout";
import ViewOnlyCheckBox from "components/CheckBox/ViewOnlyCheckBox";
import TestLogsModal from "components/Modal/TestLogsModal";
import moment from "moment";
import { debounce } from "debounce";
import { useInitialRender } from "components/useInitialRender";
import Loader from "components/Loader/Loader";

const TestListAdminApi = () => {
  const mainRef = useRef();
  const [width, setWidth] = useState(0);
  useEffect(() => {
    setWidth(mainRef.current.offsetWidth);
  }, [mainRef]);

  const handleResize = () => {
    setWidth(mainRef.current?.offsetWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const importResult = [];
  const [totalRecord, setTotalRecord] = useState(0);
  const [tests, setTests] = useState([]);
  const [filteredTests, setFilteredTests] = useState([]);
  const [labs, setLabs] = useState([]);
  const [moreInfo, setMoreInfo] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [testsLab, setTestsLab] = useState();
  const [testPdf, setTestPdf] = useState();
  const [showPdf, setShowPdf] = useState(false);
  const [timeFilter, setTimeFilter] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const [triggerFilter, setTriggerFilter] = useState();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemToDelete, setItemToDelete] = useState({});
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [showLog, setShowLog] = useState(false);
  const [testLogs, setTestLogs] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const { filterDates } = TimeFilter;

  const location = useLocation();
  const initialRender = useInitialRender();
  const appContext = useContext(AppContext);

  const searchTerms = [
    "First Name",
    "Last Name",
    "Date of Birth",
    "Email",
    "Phone",
    "Client",
    "Show",
    "Lab",
    "Status",
    "Result",
    "Test Type",
    "Sequence",
    "Tester",
    "Result Sent",
    "isVaccinated",
    "White Glove",
    "Pattern",
    "Capacity",
  ];

  const selectedItem = [
    "createdAt",
    "firstName",
    "lastName",
    "dob",
    "email",
    "phoneNumber",
    "clientID",
    "siteID",
    "labID",
    "status",
    "result",
    "test_type",
    "sequenceNo",
    "tester_name",
    "emailSend",
    "barcode",
    "isVaccinated",
    "employeeType",
    "whiteGlove",
    "patternTestAnswer",
  ];

  const displayOwner = (test) => {
    let displayedId = test.owner.substr(-12);
    return displayedId;
  };

  const createMoreInfo = (type, id) => {
    let infoObj = null;
    if (type === "site") infoObj = appContext.sites.find((site) => site.id === id);
    else if (type === "client") infoObj = appContext.clients.find((client) => client.id === id);
    else infoObj = labs.find((lab) => lab.id === id);

    setMoreInfo({
      type,
      name: infoObj.name,
      contact: infoObj.contact_name,
      phone: infoObj.contact_phone,
      email: infoObj.contact_email,
    });
    setModalShow(true);
  };

  const fetchFromApi = useCallback(
    debounce((filter, timeFilter, page, sortBy, sortDescending) => {
      loadTestFromPG(filter, timeFilter, page, sortBy, sortDescending);
    }, 800),
    []
  );

  const testsToMap = useMemo(() => {
    const list = filteredTests ? [...filteredTests] : [];
    const ttlPages = list.length > PAGE_LIMIT ? Math.ceil(list.length / PAGE_LIMIT) : 1;
    setTotalPages(ttlPages);
    if (list.length < PAGE_LIMIT || currentPage > ttlPages) {
      setCurrentPage(1);
    }
    if (ttlPages > 1) {
      const indexOfLastUser = currentPage * PAGE_LIMIT;
      const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
      return list.slice(indexOfFirstUser, indexOfLastUser);
    }
    return list;
  }, [filteredTests, currentPage]);

  const displayPdf = async (test) => {
    handleSaveFilter();
    const empID = await appContext.getEmployeeID(test.employeeID);
    const t = test;
    t.empID = empID;
    appContext.getLab(test).then(async (resp) => {
      setTestsLab(resp);
      setShowPdf(true);
    });
    setTestPdf(formatTest(test));
  };

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  useEffect(() => {
    if (pageNo > 1) {
      loadTestFromPG(Object.keys(filter).length === 0 ? { id: 1 } : filter, timeFilter);
    }
  }, [pageNo]);

  useEffect(() => {
    setLabs(appContext.labs);
  }, [appContext.labs]);

  useEffect(() => {
    const savedFilters = appContext.testFilter;
    if (savedFilters && savedFilters.location === "alltests") {
      if (Object.keys(savedFilters.filter).length > 0) {
        setFilter(savedFilters.filter);
        setShowFilter(true);
      }
      if (Object.keys(savedFilters.timeFilter).length > 0) {
        setTimeFilter(savedFilters.timeFilter);
      }
    }
    const sortingFilters = sortingFilterInLC.get();
    if (sortingFilters.alltests.sortBy) {
      setSortDescending(sortingFilters.alltests.sortDescending);
      setSortBy(sortingFilters.alltests.sortBy);
    }
  }, []);

  useEffect(() => {
    const prevSortingState = sortingFilterInLC.get();
    console.log("prevSortingState", prevSortingState);
    prevSortingState["alltests"] = { filter, timeFilter, sortBy, sortDescending };
    sortingFilterInLC.save(prevSortingState);
    if (filteredTests.length === 0) return;
  }, [sortBy, sortDescending]);

  useEffect(() => {
    if (location.state) {
      if (location.state.term === "Lab") setLabs(location.state.models);

      setShowFilter(true);

      let filterTerm = location.state.term;
      let filterTermName = location.state.name;

      if (filterTerm === "Show") {
        const obj = location.state.models.find((c) => c.name === filterTermName);
        if (obj) {
          filterTerm = "ShowID";
          filterTermName = obj.id;
        }
      }

      if (location.state.positiveTests) {
        let resultValue = "positive";
        if (filterTerm === "Client") {
          const obj = location.state.models.find((c) => c.name === filterTermName);
          if (obj && obj.resultType === "P/F") resultValue = "fail";
        }
        setFilter({
          ...filter,
          [filterTerm]: filterTermName,
          result: "positive",
        });
      } else if (location.state.negativeTests) {
        let resultValue = "negative";
        if (filterTerm === "Client") {
          const obj = location.state.models.find((c) => c.name === filterTermName);
          if (obj && obj.resultType === "P/F") resultValue = "pass";
        }
        setFilter({
          ...filter,
          [filterTerm]: filterTermName,
          result: resultValue,
        });
      } else {
        setFilter({ ...filter, [filterTerm]: filterTermName });
      }
      setTriggerFilter(true);
      setTimeout(() => setShowFilter(false), 1);
    }
  }, [location.state]);

  useEffect(() => {
    if (initialRender) return;
    if (appContext.testObject && appContext.testObject !== 0) {
      const eventType = appContext.testObject.opType;
      const model = formatTest(appContext.testObject.element);
      if (model.status !== "Processed") return;
      const newList = [...filteredTests];
      if (eventType === "INSERT") {
        newList.unshift(model);
      } else if (eventType === "UPDATE") {
        const index = newList.findIndex((t) => t.id === model.id);
        if (index !== -1) {
          newList.splice(index, 1, model);
        } else {
          newList.unshift(model);
        }
      } else if (eventType === "DELETE") {
        const index = newList.findIndex((t) => t.id === model.id);
        if (index !== -1) {
          newList.splice(index, 1);
        }
      }
      const testAfterFilter = filterDates(nestedFilter([...newList], filter), timeFilter);
      if (filteredTests.length < testAfterFilter.length) {
        setTotalRecord(totalRecord + 1);
      }
      refreshTestList(testAfterFilter);
    }
  }, [appContext.testObject]);

  useEffect(() => {
    fetchFromApi({ ...filter }, { ...timeFilter }, 1, sortBy, sortDescending);
  }, [filter, triggerFilter, timeFilter, sortBy, sortDescending]);

  const loadTestFromPG = async (params, dateFilter, page, orderBy, orderByDirection) => {
    setLoading(true);
    const filterParam = params;
    Object.assign(filterParam, { page: pageNo, status: "Processed" });

    if (dateFilter) {
      const { start, end } = dateFilter;
      if (start)
        Object.assign(filterParam, { startDate: moment(start.format("YYYY-MM-DD")).startOf("day").toISOString() });
      if (end) Object.assign(filterParam, { endDate: moment(end.format("YYYY-MM-DD")).endOf("day").toISOString() });
    }

    if (orderBy) {
      Object.assign(filterParam, { orderBy, orderByDirection });
    }

    const pgList = await API.getTestListFromPG(filterParam);
    setLoading(false);
    const ttlTests = tests.concat(pgList.rows.map((t) => formatTest(t)));
    if (pageNo === 1) {
      setTotalRecord(pgList.total);
    }
    setTests(ttlTests);
    refreshTestList(ttlTests);
  };

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filter).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length) {
          return true;
        }
        if (key === "phoneNumber") {
          return obj[key] && obj[key].replace(/\D/g, "").includes(filters[key].replace(/\D/g, ""));
        }
        if (key === "Lab") {
          return obj.labID && appContext.getLabName(obj.labID).toLowerCase().includes(filters[key].toLowerCase());
        }
        if (key === "Show") {
          const siteIds = appContext.sites
            .filter((s) => s.name.toLowerCase().includes(filters[key].toLowerCase()))
            .map((s) => s.id);
          return siteIds.includes(obj.siteID);
        }
        if (key === "ShowID") {
          return obj.siteID === filters[key];
        }
        if (key === "Client") {
          return (
            obj.clientID && appContext.getClientName(obj.clientID).toLowerCase().includes(filters[key].toLowerCase())
          );
        }
        if (key === "result") {
          return obj[key] && obj["viewedResult"].toLowerCase().includes(filters[key]);
        }
        if (key === "sequenceNo") {
          return obj[key] && obj["sequenceNo"].toString().includes(filters[key]);
        }
        if (key === "emailSend") {
          return obj.emailSend !== null && obj.emailSend == filters[key];
        }
        if (key === "dob") {
          return obj[key] && obj[key] === filters[key];
        }

        if (key === "test_type" && filters[key] && filters[key].length > 0) {
          return obj[key] && filters[key].indexOf(obj[key]) !== -1;
        }

        return obj[key] && obj[key].toLowerCase().includes(filters[key].toLowerCase());
      });
    });
    return models;
  };

  const refreshTestList = (list) => {
    if (sortBy) {
      setFilteredTests(
        sortTestList(
          sortBy,
          sortDescending,
          list,
          appContext.getSiteName,
          appContext.getLabName,
          appContext.getClientName,
          appContext.parseResult
        )
      );
    } else {
      setFilteredTests(list);
    }
  };

  const handleDeleteConfirm = async (isConfirm) => {
    setOpenDeleteConfirmation(false);
    if (!isConfirm) {
      return;
    }
    if (Object.keys(itemToDelete).length > 0) {
      await API.deleteWrongTest(itemToDelete.id);
      setItemToDelete({});
    }
  };

  const handleSaveFilter = () => {
    appContext.setTestFilter({ location: "alltests", filter, timeFilter, sortBy, sortDescending });
  };

  const handleCheckboxChange = (e, id) => {
    const filteredList = checkboxes.filter((c) => c !== id);
    if (e.target.checked) {
      filteredList.push(id);
    }
    setCheckboxes(filteredList);
  };

  const toggleCheckboxes = (val) => setCheckboxes(val ? filteredTests.map((t) => t.id) : []);

  const showTestLogs = async (num) => {
    setShowLog(true);
    const logs = await API.getTestLogs(num);
    setTestLogs([...logs].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
  };

  const handleConfirmation = async () => {
    setOpenConfirmation(false);
    const updateSelectedTestResult = tests.filter((test) => checkboxes.includes(test.id));
    const ttlTest = updateSelectedTestResult.length;
    if (!ttlTest) {
      return;
    }
    setLoading(true);

    try {
      if (!loading) setLoading(true);
      const testList = updateSelectedTestResult.map((t) => {
        return {
          id: t.id,
          sequenceNo: t.sequenceNo,
          StampByName: appContext.user.name,
        };
      });
      await GQAPI.unResultTests(testList);
      setFilteredTests(filteredTests.filter((test) => !checkboxes.includes(test.id)));
      setTotalRecord(totalRecord - ttlTest);
      appContext.showSuccessMessage(`${ttlTest} test${ttlTest === 1 ? "" : "s"} has been UnResult successfully`);
    } catch (err) {
      console.log("Error", err);
      appContext.showErrorMessage(`Fail to UnResult the Test`);
    }
    setLoading(false);
    setCheckboxes([]);
  };

  const TableRow = ({ test }) => {
    return (
      <tr>
        <td className="ellipsis" style={{ textAlign: "left" }}>
          <Form.Check>
            <Form.Check.Input
              type="checkbox"
              value={test.id}
              checked={checkboxes.indexOf(test.id) !== -1}
              onChange={(e) => handleCheckboxChange(e, test.id)}
            ></Form.Check.Input>
          </Form.Check>
        </td>
        <td className="table-column ellipsis">{formatUTCDate(test.createdAt)}</td>

        <td className="table-column ellipsis" title={test.firstName}>
          {test.firstName}
        </td>
        <td className="table-column ellipsis" title={test.lastName}>
          {test.lastName}
        </td>
        <td className="ellipsis" title={test.dob}>
          {test.dob}
        </td>
        <td
          className="table-column ellipsis"
          style={{
            textDecoration: "underline",
            color: "#A82632",
          }}
          onMouseOver={(e) => {
            e.target.style.cursor = "pointer";
            e.target.style.textDecoration = "underline";
          }}
          onClick={() => test?.email && window.open(`mailto:${test.email}`)}
          title={test.email}
        >
          {test.email}
        </td>
        <td
          className="table-column ellipsis"
          style={{ textDecoration: "underline", color: "#A82632" }}
          onMouseOver={(e) => {
            e.target.style.cursor = "pointer";
            e.target.style.textDecoration = "none";
          }}
          onMouseLeave={(e) => {
            e.target.style.textDecoration = "underline";
          }}
          onClick={() => {
            test.phoneNumber && window.open(`tel:${test.phoneNumber}`);
          }}
          title={test.phoneNumber && formatPhoneNumber(test.phoneNumber)}
        >
          {test.phoneNumber && formatPhoneNumber(test.phoneNumber)}
        </td>
        <td className="table-column ellipsis" style={{ textAlign: "center" }}>
          <a
            style={{ color: "#42cef5" }}
            onClick={() => createMoreInfo("client", test.clientID)}
            onMouseOver={(e) => (e.target.style.cursor = "pointer")}
            title={appContext.getClientName(test.clientID)}
          >
            {appContext.getClientName(test.clientID)}
          </a>
        </td>
        <td className="table-column ellipsis" style={{ textAlign: "center" }}>
          <a
            style={{ color: "#42cef5" }}
            onClick={() => createMoreInfo("site", test.siteID)}
            onMouseOver={(e) => (e.target.style.cursor = "pointer")}
            title={appContext.getSiteName(test.siteID)}
          >
            {appContext.getSiteName(test.siteID)}
          </a>
        </td>
        <td className="table-column ellipsis" style={{ textAlign: "center" }}>
          <a
            style={{ color: "#42cef5" }}
            onClick={() => createMoreInfo("lab", test.labID)}
            onMouseOver={(e) => (e.target.style.cursor = "pointer")}
            title={appContext.getLabName(test.labID)}
          >
            {appContext.getLabName(test.labID)}
          </a>
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }} title={test.status}>
          {test.status}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }} title={appContext.parseResult(test)}>
          <span
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
              e.target.style.textDecoration = "none";
            }}
            onMouseLeave={(e) => {
              e.target.style.textDecoration = "underline";
            }}
            onClick={() => displayPdf(test)}
            style={{
              color: RESULT_COLOR[test.result?.toLowerCase()],
              textDecoration: "underline",
            }}
          >
            {appContext.parseResult(test)}
          </span>
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }} title={test.test_type || "-"}>
          {test.test_type || "-"}
        </td>
        <td
          className="ellipsis"
          style={{ textAlign: "center", cursor: "Pointer" }}
          title={test.sequenceNo}
          onClick={() => showTestLogs(test.sequenceNo)}
        >
          {test.sequenceNo}
        </td>

        <td className="ellipsis" style={{ textAlign: "center" }} title={test.tester_name || "NA"}>
          {test.tester_name || "NA"}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          <Link
            onClick={handleSaveFilter}
            style={{ color: "#42cef5", textAlign: "center" }}
            to={{ pathname: "/admin/test/" + test.id, state: { admin: true, type: "allTest", test: test } }}
          >
            View
          </Link>
        </td>

        <td className="ellipsis" style={{ textAlign: "center" }}>
          {test.emailSend ? <ViewOnlyCheckBox handleChange={() => {}} id={test.id} checked={test.emailSend} /> : "-"}
        </td>
      </tr>
    );
  };

  const handleChangePage = (page, direction) => {
    setCurrentPage(page);
    const currentRecord = filteredTests.length;
    if (direction === "next" && page === totalPages && totalRecord > 0 && currentRecord < totalRecord) {
      setPageNo(pageNo + 1);
    }
  };

  const handleCloseContactInfo = () => {
    setMoreInfo({});
    setModalShow(false);
  };

  const handleSendEmail = async () => {
    const selectedTest = filteredTests.filter((test) => checkboxes.includes(test.id));
    if (!selectedTest.length) {
      return;
    }
    const params = selectedTest.map((t) => {
      return {
        id: t.id,
        sequenceNo: t.sequenceNo,
      };
    });
    await appContext.sendEmailToCrew(params, "Email");
  };

  return showPdf ? (
    <PDFLayout setShowPdf={setShowPdf} testPdf={testPdf} testsLab={testsLab} />
  ) : (
    <>
      <Container fluid style={{ flex: 1 }}>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                    color: "#565656",
                  }}
                >
                  Completed Tests ({totalRecord})
                </Card.Title>
                <div className="buttonHeader">
                  <Button className="headerButton" onClick={() => setShowFilter(!showFilter)}>
                    Filter
                  </Button>
                  <FilterSelect setTimeFilter={setTimeFilter} />
                  <Button
                    className="headerButton"
                    onClick={() => {
                      if (filteredTests && filteredTests.length > 0) {
                        setAllSelected(!allSelected);
                        toggleCheckboxes(!allSelected);
                      }
                    }}
                  >
                    {!allSelected ? "Select All" : "Deselect All"}
                  </Button>
                  {appContext.user["custom:role"] === "Admins" && (
                    <Button
                      className="headerButton"
                      disabled={checkboxes.length ? false : true}
                      onClick={handleSendEmail}
                    >
                      Send Email
                    </Button>
                  )}

                  {filteredTests.length > 0 && (
                    <>
                      {Object.keys(filter).length > 0 && (
                        <>
                          <ExportToExcelData
                            items={filteredTests}
                            selectedItem={selectedItem}
                            appContext={appContext}
                            filter={{ ...filter, status: "Processed" }}
                            timeFilter={timeFilter}
                            title="Completed Tests"
                            sortBy={sortBy}
                            setLoading={setLoading}
                          />
                          <TestPDFLayout
                            tests={filteredTests}
                            selectedItem={selectedItem}
                            filter={filter}
                            timeFilter={timeFilter}
                            title="Completed Tests"
                            appContext={appContext}
                          />
                        </>
                      )}
                    </>
                  )}
                  {/* <Button
                      className="headerButton"
                      onClick={() => {
                        const testList = filteredTests.filter((test) => checkboxes.includes(test.id));
                        if (!testList.length) {
                          return;
                        }
                        appContext.deleteTests(testList);
                      }}
                    >
                      Delete Test
                    </Button> */}

                  {appContext.user.phone_number == "+12222222222" && (
                    <Button
                      className="headerButton"
                      onClick={() => setOpenConfirmation(true)}
                      disabled={checkboxes.length ? false : true}
                    >
                      UnResult Test
                    </Button>
                  )}
                </div>
                {showFilter && (
                  <Filter
                    filterTerms={searchTerms}
                    setFilter={setFilter}
                    filter={filter}
                    triggerFilter={triggerFilter}
                    setTriggerFilter={setTriggerFilter}
                  />
                )}
                <PGPagination
                  timeFilter={timeFilter}
                  currentPage={currentPage}
                  handleChangePage={handleChangePage}
                  totalPages={totalPages}
                  totalRecord={totalRecord}
                  currentRecord={filteredTests.length}
                  showSelectedRecord
                  totalSelected={checkboxes.length}
                />
              </Card.Header>

              <Card.Body className="table-full-width px-0 desktop-noScroll">
                <div className="table-responsive pendingReleaseTable">
                  <Table className="table-hover table-striped" ref={mainRef}>
                    <Header
                      type={TEST_HEADER_TYPE.completed}
                      flipSort={flipSort}
                      sortBy={sortBy}
                      sortDescending={sortDescending}
                    />

                    <tbody>
                      {testsToMap &&
                        testsToMap.map((test) => {
                          return <TableRow key={test.id} test={test} />;
                        })}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <ContactInfo show={modalShow} moreInfo={moreInfo} handleClose={handleCloseContactInfo} />
      {showLog && (
        <TestLogsModal
          data={testLogs}
          handleClose={() => {
            setShowLog(false);
            setTestLogs([]);
          }}
        ></TestLogsModal>
      )}
      {openDeleteConfirmation && (
        <ConfirmationModal
          show={openDeleteConfirmation}
          title="Delete User"
          message={`Are you sure, you want to remove Test of ${itemToDelete.name}?`}
          handleConfirm={handleDeleteConfirm}
        />
      )}
      {openConfirmation && (
        <ConfirmationModal
          show={openConfirmation}
          title="UnResult Tests"
          message={`Are you sure, you want to UnResult the Tests`}
          handleConfirm={handleConfirmation}
        />
      )}
      {loading && <Loader />}
    </>
  );
};

export default TestListAdminApi;
