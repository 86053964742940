import React from "react";
import { Link } from "react-router-dom";

const ClientDashboardItem = (props) => {
  const {
    site,
    showStartDate,
    positiveTests,
    lastPositiveTest,
    getShowRunningDays,
    negativeTests,
    lastPositiveTestDate,
  } = props;
  return (
    <>
      <tr>
        <td className="ellipsis" title={site.name}>{site.name}</td>
        <td className="ellipsis" title={site.startDate}>{showStartDate(site.startDate)}</td>

        <td className="ellipsis" style={{ textAlign: "center" }}>
          {positiveTests(site.tests).length === 0 ? (
            "No Positive Tests"
          ) : (
            <Link
              style={{ color: "#A82632" }}
              to={{
                pathname: "/admin/positivetests",
                state: { name: lastPositiveTest(positiveTests(site.tests)).barcode, term: "barcode" },
              }}
            >
              {lastPositiveTestDate(lastPositiveTest(positiveTests(site.tests)).batch)}
            </Link>
          )}
        </td>
        <td className="ellipsis" style={{ textAlign: "center", color: "#A82632" }}>
          {site.tests.length !== 0 ? (
            <Link
              style={{ color: "#A82632" }}
              to={{
                pathname: "/admin/totaltests",
                state: {  showId: site.id },
              }}
            >
              {site.tests.length}
            </Link>
          ) : (
            site.tests.length
          )}
        </td>
        <td className="ellipsis" style={{ textAlign: "center", color: "#A82632" }}>{getShowRunningDays(site.startDate)}</td>
        <td className="ellipsis" style={{ textAlign: "center", color: "#A82632" }}>
          {positiveTests(site.tests).length !== 0 ? (
            <Link
              style={{ color: "#A82632" }}
              to={{
                pathname: "/admin/positivetests",
                state: { showId: site.id },
              }}
            >
              {positiveTests(site.tests).length}
            </Link>
          ) : (
            positiveTests(site.tests).length
          )}
        </td>
        <td className="ellipsis" style={{ textAlign: "center", color: "#A82632" }}>
          {negativeTests(site.tests) !== 0 ? (
            <Link
              style={{ color: "#A82632" }}
              to={{
                pathname: "/admin/totaltests",
                state: { name: "negative", term: "result", showId: site.id , negativeTests: true},
              }}
            >
              {negativeTests(site.tests)}
            </Link>
          ) : (
            negativeTests(site.tests)
          )}
        </td>
      </tr>
    </>
  );
};

export default ClientDashboardItem;
