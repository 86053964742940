import React, { useRef } from "react";
import Select from "react-select";
import {MESSAGE_SEND_TYPE} from "constant";

const TEXT_FILED_OPTIONS = [
  { label: "First Name", value: "{firstName}" },
  { label: "Last Name", value: "{lastName}" },
  { label: "Test Type", value: "{testType}" },
];

const TextAreaEditor = (props) => {
  const {message, name, handleChange, label, type } = props;
  const textAreaRef = useRef();
  

  const insertAtCursor = (dropDownValue) => {
    textAreaRef.current.focus;
    console.log("textAreaREf",textAreaRef, dropDownValue);  
    let startPos = textAreaRef.current.selectionStart;
      let endPos = textAreaRef.current.selectionEnd;
      textAreaRef.current.value =
        textAreaRef.current.value.substring(0, startPos) +
        dropDownValue +
        textAreaRef.current.value.substring(endPos, textAreaRef.current.value.length);
        const newPos = endPos + dropDownValue.length;
        textAreaRef.current.setSelectionRange(newPos,newPos);
      textAreaRef.current.focus();
      handleChange(textAreaRef.current.value, name, type);
    // if (textAreaRef.current.selectionStart || textAreaRef.current.selectionStart === "0") {
      
    // } else {
    //   handleChange(textAreaRef.current.value, name, type);
    // }
  };
  return (
    <div className="form-group-wrapper">
        <label className="modalLineHeaders">
          {/* <input
              className="position-relative"
              style={{top: '3px'}}
            checked={isSelected}
            type="checkBox"
            onChange={(e) => handleChange(e.target.checked, message, name)}
          ></input> */}
        {`${label}:`}
        </label>
      <div className="w-100">
        <div className="d-flex align-items-center justify-content-between">
        <Select
          options={MESSAGE_SEND_TYPE}
          blurInputOnSelect={true}
          className="w-75"
          menuPlacement="auto"
          placeholder="Select message Type"
          value={MESSAGE_SEND_TYPE.find((f)=> f.value === type)}
          onChange={(e)=>handleChange(message, name, e.value)}
          />
        <Select
          value={{ label: "Insert field", value: "none" }}
          options={TEXT_FILED_OPTIONS}
          isDisabled={!type}
          blurInputOnSelect={true}
          menuPlacement="auto"
          className="w-30 ml-auto inputFieldMessage"
          placeholder="Insert Field"
          onChange={(e) => insertAtCursor(e.value)}
        />
        </div>
        <textarea
          placeholder={label}
          className="textAreaWrapper"
          readOnly={!type}
          ref={textAreaRef}
          value={message}
          onChange={(e) => handleChange(e.target.value, name, type)}
        />
      </div>
    </div>
  );
};
export default TextAreaEditor;
