import React, { useState } from "react";
import API from "api";
import TestLogsModal from "components/Modal/TestLogsModal";

const SequenceNo = (props) => {
  const [showLog, setShowLog] = useState(false);
  const [testLogs, setTestLogs] = useState([]);

  const { num } = props;
  const showTestLogs = async () => {
    setShowLog(true);
    const logs = await API.getTestLogs(num);
    setTestLogs([...logs].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
  };
  return (
    <>
      <td className="ellipsis" style={{ textAlign: "center", cursor:"Pointer" }} title={num} onClick={() => showTestLogs()}>
        {num}
      </td>
      {showLog && (
        <TestLogsModal
          data={testLogs}
          handleClose={() => {
            setShowLog(false);
            setTestLogs([]);
          }}
        ></TestLogsModal>
      )}
    </>
  );
};

export default SequenceNo;
