import React from "react";
import { Button } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import { toDate } from "date-fns";
const FilterSelect = (props) => {
  const { setTimeFilter } = props;
  const handleFilterChange = (event, picker) => {
    if (picker.chosenLabel === "All") {
      setTimeFilter({});
    } else {
      setTimeFilter({ start: picker.startDate, end: picker.endDate });
    }
  };

  const handleFilterCancel = () => setTimeFilter({});

  return (
    <DateRangePicker
      initialSettings={{
        // startDate:moment().subtract(1, "days").startOf("day"),
        // endDate: moment().endOf("day"),
        // showCustomRangeLabel: false,
        // alwaysShowCalendars: true,
        ranges: {
          All: [moment().subtract(1, "days").startOf("day"), moment().endOf("day")],
          Today: [moment().startOf("day") , moment().endOf("day")],
          Yesterday: [moment().subtract(1, "days").startOf("day"), moment().subtract(1, "days").endOf("day")],
          "Last 7 Days": [moment().subtract(6, "days").startOf("day"), moment()],
          "Last 30 Days": [moment().subtract(29, "days").startOf("day"), moment()],
          "Last Month": [
            moment().subtract(1, "month").startOf("month").startOf("day"),
            moment().subtract(1, "month").endOf("month").endOf("day"),
          ],
          "This Year": [moment().startOf("year").utc(), moment().endOf("year")],
          "Last Year": [moment().subtract(1, "year").startOf("year"), moment().subtract(1, "year").endOf("year")],
        },
      }}
      onApply={handleFilterChange}
      onCancel={handleFilterCancel}
      
    >
      <Button className="headerButton">Date Range</Button>
    </DateRangePicker>
  );
};

export default FilterSelect;
