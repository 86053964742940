import React, { useContext, useState, useMemo, useRef } from "react";
import { Button, Card, Table, Row, Col, Modal, Form } from "react-bootstrap";
import { useLocation, Link } from "react-router-dom";
import SortIcon from "components/SortIcon";
import { useEffect } from "react";
import API from "api";
import { Auth } from "aws-amplify";
import { Bounce } from "react-activity";
import "react-activity/dist/Bounce.css";
import ErrorMessage from "components/Message/ErrorMessage";
import Select from "react-select";
import Filter from "components/Filter";
import TimeFilter from "components/TimeFilter";
import MFPagination from "components/Pagination/MFPagination";
import TextAreaEditor from "components/TextAreaEditor";
import {
  PAGE_LIMIT,
  weekDaysOptions,
  WEEK_DAYS,
  TestTypeOptions,
  getPreRegistrationLink,
  TEST_ORDER_QTY,
  PRE_REGISTRATION_TEST_TYPE,
  TEST_TYPE_VALUE,
} from "constant";
import ContactInfo from "components/Modal/ContactInfo";
import TestTypeDurationModal from "components/Modal/TestTypeDurationModal";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import {
  changeSort,
  formatPhoneNumber,
  isValidPhone,
  phoneFormatter,
  getDropDownValue,
  parseBooleanValue,
  parseIntValue,
  calculateTdWidth,
  sortTestList,
  sortingFilterInLC,
  isValidEmail,
  formatDateMDY,
  siteStatus,
} from "utils";
import HeaderItem from "components/Table/HeaderItem";
import ChangeStatusModal from "components/Modal/ChangeStatusModal";
import { QRCode } from "react-qrcode-logo";
import logo from "assets/img/logo.jpg";
import { AppContext } from "context/app-context";
import ViewOnlyCheckBox from "components/CheckBox/ViewOnlyCheckBox";
import TextEditor from "views/TextEditor";
import moment from "moment";
import PhoneNoInput from "components/Phone/PhoneNoInput";
import Loader from "../../components/Loader/Loader";

const SitesView = () => {
  const mainRef = useRef();
  const [mainWidth, setMainWidth] = useState(0);
  const currentURL = window.location.href;
  const handleResize = () => {
    if (mainRef.current) setMainWidth(mainRef.current.offsetWidth);
  };

  useEffect(() => handleResize(), [mainRef]);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  let defaultSafeAdmin = { name: "", phone: "", email: "" };
  const tdWidth = calculateTdWidth(mainWidth - 110, 21);
  const [siteTests, setSiteTests] = useState([]);
  const [sites, setSites] = useState([]);
  const [filteredSites, setFilteredSites] = useState([]);
  const [clients, setClients] = useState([]);
  const [labs, setLabs] = useState([]);
  const [openCreator, setOpenCreator] = useState(false);
  const [newSite, setNewSite] = useState({});
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [triggerFilter, setTriggerFilter] = useState(false);
  const [warningModal, setWarningModal] = useState(false);
  const [showToDelete, setShowToDelete] = useState("");
  const [timeFilter, setTimeFilter] = useState({});
  const [showModalTestType, setshowModalTestType] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [moreInfo, setMoreInfo] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [showPatternEditor, setShowPattrenEditor] = useState(false);
  const [showCopyText, setShowCopyText] = useState(false);
  const [error, setError] = useState("");
  const [checkboxes, setCheckboxes] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [showArchive, setShowArchive] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState({});
  const [safeCampAdmin, setSafeCampAdmin] = useState(defaultSafeAdmin);
  const { filterDates } = TimeFilter;
  const [adminList, setAdminList] = useState([]);

  const searchTerms = [
    "Name",
    "Client",
    "Contact Name",
    "Contact Email",
    "Contact Phone",
    "Automate Rapid Test",
    "Insurance Info",
    "Insurance Card",
    "Vaccination Card",
    "Vaccination Admit",
    "External Test",
    "External Test Admit",
    "Pattern Testing",
    "Pre-Registration",
    "Shipment",
    "HR Registration",
    "Show Status",
  ];

  const appContext = useContext(AppContext);

  const handleClose = () => {
    setOpenCreator(false);
    setSafeCampAdmin(defaultSafeAdmin);
  };

  const showWarningModal = () => {
    setWarningModal(true);
  };

  const exitModal = () => {
    setWarningModal(false);
  };

  const isValidToSave = () => {
    if (!newSite.name) {
      setError("Show name is required");
      return false;
    }

    if (!newSite.contact) {
      setError("Primary contact info is required");
      return false;
    }

    if (!newSite.phone) {
      setError("Phone is required");
      return false;
    }

    if (!newSite.email) {
      setError("Email is required");
      return false;
    }
    if (!isValidEmail(newSite.email)) {
      setError("Invalid Email");
      return false;
    }
    if (newSite.isNew && !newSite.clientID) {
      setError("Please select the client");
      return false;
    }

    if (!newSite.startDate) {
      setError("Show start date is required");
      return false;
    }

    if (!isValidPhone(newSite.phone)) {
      setError("Invalid Phone Number");
      return false;
    }
    if (newSite.startDate && !moment(newSite.startDate, "YYYY-MM-DD", true).isValid()) {
      setError("Invalid Start Date");
      return false;
    }

    // // Check Start date less than current date
    // if (
    //   moment(newSite.startDate).isBefore(moment()) &&
    //   !moment(newSite.startDate).isSame(moment().format("YYYY-MM-DD"))
    // ) {
    //   setError("Start Date Should Be Greater than Current Date");
    //   return false;
    // }

    if (newSite.endDate) {
      if (!moment(newSite.endDate, "YYYY-MM-DD", true).isValid()) {
        setError("Invalid End Date");
        return false;
      }

      if (
        moment(newSite.endDate).isBefore(moment(newSite.startDate)) &&
        !moment(newSite.endDate).isSame(moment(newSite.startDate).format("YYYY-MM-DD"))
      ) {
        setError("End Date Should Be Greater Than Start Date");
        return false;
      }
    }

    if (newSite.preRegistration && !newSite.orderKitDate) {
      setError("Order Cutoff Date required");
      return false;
    }

    if (
      newSite.preRegistration &&
      newSite.orderKitDate &&
      !moment(newSite.orderKitDate, "YYYY-MM-DDTHH:mm", true).isValid()
    ) {
      setError("Invalid Order Cutoff Date");
      return false;
    }

    if (newSite.preRegistration && newSite.orderKitDate) {
      // if (
      //   moment(newSite.orderKitDate).isBefore(moment(newSite.startDate)) &&
      //   !moment(newSite.orderKitDate).isSame(moment(newSite.startDate).format("YYYY-MM-DD"))
      // ) {
      //   setError("Order Cutoff Date Should Be Greater than Start Date");
      //   return false;
      // }

      if (
        moment(newSite.orderKitDate).isAfter(moment(newSite.endDate)) &&
        !moment(newSite.orderKitDate).isSame(moment(newSite.endDate).format("YYYY-MM-DD"))
      ) {
        setError("Order Cutoff Date Should Not Be Greater Than End Date");
        return false;
      }
    }
    return true;
  };
  const createSite = async () => {
    if (!isValidToSave()) {
      return;
    }
    handleClose();
    try {
      setLoading(true);
      /* const signUp = await Auth.signUp({
        username: `+${newSite.phone.replace(/\D/g, "")}`,
        password: newSite.password,
        attributes: {
          email: newSite.email,
          "custom:role": "Employers",
          "custom:autoConfirm": "true",
          "custom:firstName": newSite.contact.split(" ")[0],
          "custom:lastName": newSite.contact.split(" ")[1],
        },
      });
      const newest = await API.createSite(newSite, signUp.userSub); */

      Object.assign(newSite, {
        safeCampAdmin: JSON.stringify(safeCampAdmin),
      });
      const newest = await API.createSite(newSite);
      setNewSite({});
      appContext.resetSites();
      appContext.showSuccessMessage("Show created successfully");
    } catch (error) {
      console.log(error);
      appContext.showErrorMessage(error.message);
      setLoading(false);
      setOpenCreator(true);
    }
  };

  const deleteSite = async (id) => {
    try {
      await API.deleteSite(id);
      appContext.resetSites();
      appContext.showSuccessMessage("Site removed successfully");
    } catch (error) {
      appContext.showErrorMessage(error.message);
    }
  };

  const updateSite = async () => {
    if (!isValidToSave()) {
      return;
    }
    handleClose();
    try {
      setLoading(true);
      Object.assign(newSite, {
        safeCampAdmin: JSON.stringify(safeCampAdmin),
      });
      const update = await API.updateSite(newSite);
      setNewSite({});
      appContext.resetSites();
      appContext.showSuccessMessage("Site update successfully");
    } catch (error) {
      console.log("UPDATE SITE ERROR:", error);
      appContext.showErrorMessage(error.message);
    }
  };

  const getName = (id) => {
    if (!clients) return "";
    const clientObj = clients.find((client) => client.id === id);
    return clientObj ? clientObj.name : "N/A";
  };

  const getLabName = (id) => {
    const lab = labs.find((lab) => lab.id === id);
    console.log(lab);
    return lab ? lab.name : "";
  };

  const filterSites = (term, input) => {
    switch (term) {
      case "client":
        setFilteredSites(sites.filter((site) => getName(site["clientID"]).toLowerCase().includes(input.toLowerCase())));
        break;
      case "lab":
        setFilteredSites(sites.filter((site) => getLabName(site["labID"]).toLowerCase().includes(input.toLowerCase())));
        break;
      default:
        setFilteredSites(
          sites.filter((site) => {
            return site[term].toLowerCase().includes(input.toLowerCase());
          })
        );
    }
    // term === "client"
    //   ? setFilteredSites(
    //       sites.filter((site) =>
    //         getName(site["clientID"])
    //           .toLowerCase()
    //           .includes(input.toLowerCase())
    //       )
    //     )
    //   : setFilteredSites(
    //       sites.filter((site) => {
    //         console.log(site[term], "filtering", sites)
    //         return site[term].toLowerCase().includes(input.toLowerCase())
    //       })
    //     );
  };

  const sitesToMap = useMemo(() => {
    const list = filterDates(
      filteredSites.filter((s) => s.isArchive === showArchive),
      timeFilter
    );
    const ttlPages = list.length > PAGE_LIMIT ? Math.ceil(list.length / PAGE_LIMIT) : 1;
    setTotalPages(ttlPages);
    if (list.length < PAGE_LIMIT || currentPage > ttlPages) {
      setCurrentPage(1);
    }
    if (ttlPages > 1) {
      const indexOfLastUser = currentPage * PAGE_LIMIT;
      const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
      return list.slice(indexOfFirstUser, indexOfLastUser);
    }
    return list;
  }, [filteredSites, currentPage, timeFilter, showArchive]);

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filters).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);

    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length) {
          return true;
        }
        if (key === "phoneNumber") {
          return obj[key] && obj[key].replace(/\D/g, "").includes(filters[key].replace(/\D/g, ""));
        }
        if (key === "Lab") {
          return obj.labID && getLabName(obj.labID).toLowerCase().includes(filters[key]?.toLowerCase());
        }

        if (key === "Client") {
          return obj.clientID && getName(obj.clientID).toLowerCase().includes(filters[key]?.toLowerCase());
        }
        if (key === "antigen") {
          return parseBooleanValue(obj[key]).toLowerCase().includes(filters[key]?.toLowerCase());
        }
        if (key === "insurance_required") {
          return parseBooleanValue(obj[key]).toLowerCase().includes(filters[key]?.toLowerCase());
        }
        if (key === "sendInsuranceCard") {
          return parseBooleanValue(obj[key]).toLowerCase().includes(filters[key]?.toLowerCase());
        }
        if (key === "vaccinationCard") {
          return parseBooleanValue(obj[key]).toLowerCase().includes(filters[key]?.toLowerCase());
        }
        if (key === "admit") {
          return parseBooleanValue(obj[key]).toLowerCase().includes(filters[key]?.toLowerCase());
        }
        if (key === "externalTest") {
          return parseBooleanValue(obj[key]).toLowerCase().includes(filters[key]?.toLowerCase());
        }
        if (key === "externalTestAdmit") {
          return parseBooleanValue(obj[key]).toLowerCase().includes(filters[key]?.toLowerCase());
        }
        if (key === "patternTesting") {
          return parseBooleanValue(obj[key]).toLowerCase().includes(filters[key]?.toLowerCase());
        }
        if (key === "preRegistration") {
          return parseBooleanValue(obj[key]).toLowerCase().includes(filters[key]?.toLowerCase());
        }
        if (key === "enableShipment") {
          return parseBooleanValue(obj[key]).toLowerCase().includes(filters[key]?.toLowerCase());
        }
        if (key === "isHRPR") {
          return parseBooleanValue(obj[key]).toLowerCase().includes(filters[key]?.toLowerCase());
        }

        if (key === "status") {
          return parseInt(obj[key]) === parseInt(filters[key]);
        }

        return obj[key] && obj[key].toLowerCase().includes(filters[key].toLowerCase());
      });
    });
    return models;
  };

  useEffect(() => {
    const sortingFilters = sortingFilterInLC.get();
    if (sortingFilters.sites.sortBy) {
      setSortDescending(sortingFilters.sites.sortDescending);
      setSortBy(sortingFilters.sites.sortBy);
    }
    loadSiteData();
    loadSummary();
  }, []);

  useEffect(() => {
    if (appContext?.admins) {
      setAdminList(appContext.admins);
    }
  }, [appContext?.admins]);

  useEffect(() => {
    refreshSummary(sites, siteTests);
  }, [siteTests]);

  useEffect(() => {
    loadSiteData();
    setLoading(false);
    refreshSummary(appContext.sites, siteTests);
  }, [appContext.clients, appContext.sites, appContext.labs]);

  useEffect(() => {
    const prevSortingState = sortingFilterInLC.get();
    prevSortingState["sites"] = { filter, timeFilter, sortBy, sortDescending };
    sortingFilterInLC.save(prevSortingState);
    if (filteredSites.length === 0) return;
    changeSort(
      sortBy,
      sortDescending,
      filteredSites,
      setFilteredSites,
      appContext.getSiteName,
      appContext.getLabName,
      appContext.getClientName,
      appContext.getSiteTests
    );
  }, [sortBy, sortDescending]);

  useEffect(() => {
    if (location.state) {
      setShowFilter(true);
      setFilter({ ...filter, [location.state.term]: location.state.name });
      setTriggerFilter(true);
    }
    setTimeout(() => setShowFilter(false), 1);
  }, [location.state]);

  useEffect(() => {
    refreshSites();
  }, [filter, triggerFilter]);

  const loadSiteData = () => {
    if (appContext.clients.length > 0) {
      const whiteLableIds = []; // appContext.clients.filter((c) => c.whiteLabel).map((w) => w.id);
      const medFlowSites = appContext.sites.filter((s) => !whiteLableIds.includes(s.clientID));
      setClients(appContext.clients);
      setSites(medFlowSites);
      refreshSites();
      setLabs(appContext.labs);
    } else {
      setSites(appContext.sites);
      refreshSites();
      setLabs(appContext.labs);
    }
  };

  const loadSummary = async () => {
    const clientSummary = await API.getSummaryFromPG({ summary: "site" });
    setSiteTests(clientSummary.rows);
  };

  const refreshSummary = (data, summary) => {
    if (data.length === 0) return;
    if (summary.length === 0) {
      setFilteredSites(data);
      return;
    }
    const newList = data.map((c) => {
      const obj = summary.find((x) => x.id === c.id);
      return { ...c, totalTest: obj ? obj.totalTest : 0 };
    });
    const list = nestedFilter([...newList], filter);
    setSites([...newList]);
    setFilteredSites(list);
  };

  const refreshSites = () => {
    const siteList = nestedFilter(sites, filter);

    if (sortBy) {
      setFilteredSites(
        sortTestList(
          sortBy,
          sortDescending,
          siteList,
          appContext.getSiteName,
          appContext.getLabName,
          appContext.getClientName,
          appContext.parseResult
        )
      );
    } else {
      setFilteredSites(siteList);
    }
  };

  const handleFilterChange = (event, picker) => setTimeFilter({ start: picker.startDate, end: picker.endDate });
  const handleFilterCancel = (event, picker) => setTimeFilter({});
  const handleChangePage = (page) => setCurrentPage(page);

  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(sortBy === by ? !sortDescending : true);
  };

  const getClientObj = (id) => {
    if (!id) return null;
    const obj = clients.find((c) => c.id === id);
    if (obj) {
      return { value: obj.id, label: obj.name };
    }
    return null;
  };

  const setLabAntigenAutomate = (site) => {
    if (site.antigen) {
      return `${parseBooleanValue(site.antigen)} ${site.automateTestType ? "-" : ""} ${site.automateTestType || ""}`;
    }
    return parseBooleanValue(site.antigen);
  };

  const createMoreInfo = (type, id) => {
    let infoObj = null;
    if (type === "site") infoObj = appContext.sites.find((site) => site.id === id);
    else if (type === "client") infoObj = appContext.clients.find((client) => client.id === id);
    else infoObj = labs.find((lab) => lab.id === id);

    if (infoObj) {
      setMoreInfo({
        type,
        name: infoObj.name,
        contact: infoObj.contact_name,
        phone: infoObj.contact_phone,
        email: infoObj.contact_email,
      });
      setModalShow(true);
    }
  };

  const handleCloseContactInfo = () => {
    setMoreInfo({});
    setModalShow(false);
  };

  const onHandleMessageChange = (msg, key, type) => {
    console.log(msg, key, type);
    const obj = { ...newSite };
    Object.assign(obj, {
      messageReTest: {
        message: msg,
        type,
      },
    });
    setNewSite(obj);
  };

  const downloadQRCode = () => {
    const canvas = document.getElementById("qr-gen");
    const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${newSite.name}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  const handleCopyPRLink = (id) => {
    navigator.clipboard.writeText(getPreRegistrationLink(id));
    setShowCopyText(true);
    setTimeout(() => setShowCopyText(false), 2000);
  };

  const getTotalTest = (site) => {
    if (!site.totalTest || site.totalTest === 0) {
      return <>0</>;
    }
    return (
      <Link
        style={{ color: "#42cef5" }}
        to={{
          pathname: `/admin/totaltests`,
          state: { name: site.name, term: "Show", models: sites },
        }}
      >
        {site.totalTest}
      </Link>
    );
  };

  const getOrderKitDate = (site) => {
    if (site.orderKitDate) {
      return formatDateMDY(site.orderKitDate);
    }
    return "";
  };

  const handleChangeArchive = async () => {
    const updateSelectedSites = sitesToMap.filter((site) => checkboxes.includes(site.id));
    if (!updateSelectedSites.length) {
      return;
    }
    try {
      setLoading(true);
      const ttl = updateSelectedSites.length;
      for (let i = 0; i < ttl; i++) {
        const data = await API.updateSiteArchive(updateSelectedSites[i].id, !updateSelectedSites[i].isArchive);
      }

      appContext.resetSites();
      setCheckboxes([]);
      setAllSelected(false);
      appContext.showSuccessMessage(`Sites ${showArchive ? "Unarchive" : "Archive"} successfully`);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("UPDATE SITE ARCHIVE ERROR:", error);
      appContext.showErrorMessage(error.message);
    }
  };

  const handleCheckboxChange = (e, id) => {
    const filteredList = checkboxes.filter((c) => c !== id);
    if (e.target.checked) {
      filteredList.push(id);
    }
    setCheckboxes(filteredList);
  };

  const handleStatusClose = () => {
    setOpenStatus(false);
  };

  const handleUpdateStatus = async (status) => {
    try {
      setLoading(true);
      if (Object.keys(selectedStatus).length === 0) return;
      await API.updateSiteStatus(selectedStatus.id, status);
      setSelectedStatus({});
      // refreshSites;
      // appContext.resetSites();
      appContext.showSuccessMessage("Show updated successfully");
      loadSiteData();
      setLoading(false);
    } catch (error) {
      console.log(error);
      appContext.showErrorMessage(error.message);
      setLoading(false);
    }
  };

  const handleChangePendingStatus = (status) => {
    if (status) {
      setFilteredSites(pendingSites);
      setShowPendingStatus(true);
    } else {
      setFilteredSites(originalSites);
      setShowPendingStatus(false);
    }
  };

  const openSite = (siteID) => {
    // console.log("siiteID::", siteID);
    let url = `${window.location.origin}/site/tests/${siteID}`;
    window.open(url);
  };

  const TableRow = ({ site }) => {
    return (
      <tr className={`${site.status === 0 && "appleEventRow"}`}>
        <td className="icon" style={{ width: "45px", minWidth: "45px", maxWidth: "45px" }}>
          <i
            className="fas fa-edit"
            onMouseLeave={(e) => (e.target.style.color = "black")}
            onMouseOver={(e) => {
              e.target.style.color = "blue";
              e.target.style.cursor = "pointer";
            }}
            aria-hidden="true"
            id={site.id}
            onClick={async (e) => {
              setNewSite({
                isNew: false,
                id: site.id,
                name: site.name,
                contact: site.contact_name,
                phone: site.contact_phone || site.phone_number,
                email: site.contact_email,
                antigen: site.antigen,
                automateTestType: site.automateTestType,
                insurance_required: site.insurance_required,
                startDate: site.startDate,
                endDate: site.endDate,
                sendInsuranceCard: site.sendInsuranceCard,
                resetTestDay: site.resetTestDay,
                clientID: site.clientID,
                showOnSummaryScreen: site.showOnSummaryScreen || false,
                messageTested: site.messageTested,
                messageResulted: site.messageResulted,
                messagePositive: site.messagePositive,
                isArchive: site.isArchive,
                vaccinationCard: site.vaccinationCard,
                externalTest: site.externalTest,
                externalTestType: site.externalTestType,
                externalTestAdmit: site.externalTestAdmit,
                patternTesting: site.patternTesting,
                patternHippa: site.patternHippa,
                preRegistration: site.preRegistration,
                admit: site.admit,
                orderKitType: site.orderKitType,
                orderKitDate: moment(site.orderKitDate).format("YYYY-MM-DDTHH:mm"),
                enableShipment: site.enableShipment,
                orderQuantity: site.orderQuantity,
                messageReTest: site.messageReTest,
                isPCRCustody: site.isPCRCustody,
                isHRPR: site.isHRPR,
              });
              if (site.safeCampAdmin) {
                let adminData = site.safeCampAdmin;
                if (typeof adminData === "string") {
                  JSON.parse(adminData);
                } else {
                  setSafeCampAdmin(adminData);
                }
              }
              setOpenCreator(true);
            }}
          />
        </td>
        {/* <td className="ellipsis" style={{ textAlign: "left" }}>
          <Form.Check>
            <Form.Check.Input
              type="checkbox"
              value={site.id}
              checked={checkboxes.indexOf(site.id) !== -1}
              onChange={(e) => handleCheckboxChange(e, site.id)}
            ></Form.Check.Input>
          </Form.Check>
        </td> */}
        <td className="ellipsis" title={site.name}>
          {site.name}
        </td>
        <td className="ellipsis" title={site.clientID && getName(site.clientID)}>
          <a
            style={{ color: "#42cef5" }}
            onClick={() => createMoreInfo("client", site.clientID)}
            onMouseOver={(e) => (e.target.style.cursor = "pointer")}
          >
            {site.clientID && getName(site.clientID)}
          </a>
        </td>
        <td className="ellipsis" title={site.contact_name}>
          {site.contact_name}
        </td>
        <td
          className="ellipsis"
          style={{
            textDecoration: "underline",
            color: "#A82632",
          }}
          onMouseOver={(e) => {
            e.target.style.cursor = "pointer";
            e.target.style.textDecoration = "underline";
          }}
          onClick={() => site.contact_email && window.open(`mailto:${site.contact_email}`)}
          title={site.contact_email}
        >
          {site.contact_email}
        </td>
        <td
          className="ellipsis"
          style={{ textDecoration: "underline", color: "#A82632" }}
          onMouseOver={(e) => {
            e.target.style.cursor = "pointer";
            e.target.style.textDecoration = "none";
          }}
          onMouseLeave={(e) => {
            e.target.style.textDecoration = "underline";
          }}
          onClick={() => {
            (site.contact_phone || site.phone_number) &&
              window.open(`tel:${formatPhoneNumber(site.contact_phone || site.phone_number)}`);
          }}
          title={formatPhoneNumber(site.contact_phone || site.phone_number)}
        >
          {formatPhoneNumber(site.contact_phone || site.phone_number)}
        </td>
        <td className="ellipsis" title={getOrderKitDate(site)} style={{ textAlign: "center" }}>
          {getOrderKitDate(site)}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          {getTotalTest(site)}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          {setLabAntigenAutomate(site)}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          {parseBooleanValue(site.insurance_required)}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          {parseBooleanValue(site.sendInsuranceCard)}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          {parseBooleanValue(site.vaccinationCard)}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          {parseBooleanValue(site.admit)}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          {parseBooleanValue(site.externalTest)}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          {parseBooleanValue(site.externalTestAdmit)}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          {parseBooleanValue(site.patternTesting)}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          {parseBooleanValue(site.preRegistration)}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          {parseBooleanValue(site.enableShipment)}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          {parseBooleanValue(site.isHRPR)}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          <a
            style={{ color: "#42cef5" }}
            onClick={() => {
              if (siteStatus("E".status) === "Active") return;
              setSelectedStatus(site);
              setOpenStatus(true);
            }}
            onMouseOver={(e) => (e.target.style.cursor = "pointer")}
          >
            {siteStatus("E".status)}
          </a>
        </td>
        <td>
          <Link
            style={{ color: "#42cef5" }}
            className="ellipsis"
            onClick={() => {
              openSite(site?.id);
            }}
          >
            Launch Portal
          </Link>
        </td>
        <td className="icon" style={{ width: "45px", minWidth: "45px", maxWidth: "45px" }}>
          <i
            className="fa fa-trash"
            onMouseLeave={(e) => {
              e.target.style.color = "black";
            }}
            onMouseOver={(e) => {
              e.target.style.color = "#8B0000";
              e.target.style.cursor = "pointer";
            }}
            aria-hidden="true"
            id={site.id}
            onClick={(e) => (showWarningModal(), setShowToDelete(e.target.id))}
          />
        </td>
      </tr>
    );
  };

  const getWeekDaySelectedValue = (val) => {
    return { value: val, label: WEEK_DAYS[val] };
  };

  const handleSave = () => {
    if (newSite.isNew) createSite();
    else updateSite();
  };

  const toggleCheckboxes = (val) => {
    setCheckboxes(val ? sitesToMap.filter((s) => s.isArchive === showArchive).map((t) => t.id) : []);
  };

  
  return (
    <div style={{ flex: 1 }}>
      {!loading ? (
        <Row>
          <Col md="12">
            <Card className="striped-tabled-with-hover">
              <Card.Header>
                <Row>
                  <Card.Title
                    as="h4"
                    style={{
                      marginBottom: 10,
                      fontWeight: "bold",
                      color: "#565656",
                    }}
                  >
                    Shows ({filteredSites.filter((s) => s.isArchive === showArchive).length})
                  </Card.Title>
                  <div className="buttonHeader clientsButtonHeader justify-content-between mb-1">
                    <div className="buttonHeader clientsButtonHeader">
                      <Button className="headerButton mb-0" onClick={() => setShowFilter(!showFilter)}>
                        Filter
                      </Button>
                      <Button
                        className="headerButton"
                        onClick={() => {
                          const length = sitesToMap.filter((s) => s.isArchive === showArchive).length;
                          if (sitesToMap && length > 0) {
                            setAllSelected(!allSelected);
                            toggleCheckboxes(!allSelected);
                          }
                        }}
                      >
                        {!allSelected ? "Select All" : "Deselect All"}
                      </Button>
                      {/* <Button className="headerButton" disabled={checkboxes.length === 0} onClick={handleChangeArchive}>
                        {showArchive ? "Set Unarchive" : "Set Archive"}
                      </Button> */}
                      <Button
                        className="headerButton"
                        disabled={filteredSites.filter((s) => s.isArchive === showArchive).length === 0}
                        onClick={() => setShowArchive(!showArchive)}
                      >
                        {showArchive ? "Hide Archive" : "Show Archive"}
                      </Button>
                    </div>
                    <Button
                      className="createButtons"
                      onClick={() => (
                        setOpenCreator(true),
                        setNewSite({
                          isNew: true,
                          sendInsuranceCard: false,
                          showOnSummaryScreen: false,
                          isArchive: false,
                          vaccinationCard: false,
                          admit: false,
                          externalTest: false,
                          externalTestAdmit: false,
                          patternTesting: false,
                          preRegistration: false,
                          isPCRCustody:  false,
                          orderQuantity: 2,
                          isHRPR: false,
                        })
                      )}
                    >
                      Create a Show
                    </Button>
                  </div>
                  <div>
                    {showFilter && (
                      <Filter
                        filterTerms={searchTerms}
                        setFilter={setFilter}
                        filter={filter}
                        triggerFilter={triggerFilter}
                        setTriggerFilter={setTriggerFilter}
                      />
                    )}
                  </div>
                  <Col>
                    <MFPagination
                      currentPage={currentPage}
                      handleChangePage={handleChangePage}
                      totalPages={totalPages}
                    />
                  </Col>
                </Row>
              </Card.Header>

              <Card.Body className="table-full-width px-0 desktop-noScroll">
                <div className="table-responsive pendingReleaseTable" ref={mainRef}>
                  <Table
                    className="table-hover table-striped"
                    style={{ width: mainWidth, maxWidth: mainWidth, minWidth: mainWidth }}
                  >
                    <thead>
                      <tr>
                        <th></th>
                        {/* <th style={{ width: tdWidth, maxWidth: tdWidth }}>{showArchive ? "Unarchive" : "Archive"}</th> */}
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="name"
                          title="Show Name"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="clientID"
                          title="Client"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="contact_name"
                          title="Contact Name"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="contact_email"
                          title="Contact's Email"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="contact_phone"
                          title="Contact's Phone"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="orderKitDate"
                          title="Order Cutoff DateTime"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="totalTests"
                          title="Total Tests (YTD)"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="antigen"
                          title="Automate Rapid Test"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="insurance_required"
                          title="Insurance Info"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="sendInsuranceCard"
                          title="Insurance Card"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="vaccinationCard"
                          title="Vaccination Card"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="admit"
                          title="Vaccination Admit"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="externalTest"
                          title="External Test"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="externalTestAdmit"
                          title="External Test Admit"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="patternTesting"
                          title="Pattern Testing"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="preRegistration"
                          title="Pre-Registration"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="enableShipment"
                          title="Shipment"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />

                        <HeaderItem
                          width={tdWidth}
                          ItemKey="isHRPR"
                          title="HR Registration"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />

                        <HeaderItem
                          width={tdWidth}
                          ItemKey="status"
                          title="Status"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <th
                          className="border-0 centered text-grey"
                          style={{ fontWeight: "bold", width: tdWidth, minWidth: tdWidth, maxWidth: tdWidth }}
                        >
                          View
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {sitesToMap &&
                        sitesToMap
                          .filter((s) => s.isArchive === showArchive)
                          .map((site) => {
                            return <TableRow key={site.id} site={site} />;
                          })}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <ContactInfo show={modalShow} moreInfo={moreInfo} handleClose={handleCloseContactInfo} />
          {showModalTestType && (
            <TestTypeDurationModal
              data={new "E".externalTestType() ? JSON.parse(newSite.externalTestType) : []}
              handleSave={(val) => setNewSite({ ...newSite, externalTestType: val })}
              handleClose={() => setshowModalTestType(false)}
            />
          )}
        </Row>
      ) : (
        <Loader />
      )}
      <Modal
        show={openCreator}
        // style={{ transform: "translate(0, -30%)" }}
        animation={true}
        onHide={() => {
          setNewSite({});
          handleClose();
        }}
        // centered
        size={"xl"}
      >
        <Modal.Header closeButton className="mb-0">
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            {newSite.isNew ? "Create Show" : "Update Show"}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ paddingTop: 0, paddingBottom: 0 }}>
          <div className="createShowWrapper">
            <div className="createShowInner">
              <div className="createClientsWrapper">
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Show Name: </label>
                  <input
                    className="modalInput"
                    value={newSite.name}
                    placeholder="Show Name"
                    style={{ width: "100%" }}
                    onChange={(e) => setNewSite({ ...newSite, name: e.target.value })}
                  />
                </div>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Primary Contact: </label>
                  <input
                    className="modalInput"
                    value={newSite.contact}
                    placeholder="Primary Contact"
                    style={{ width: "100%" }}
                    onChange={(e) => setNewSite({ ...newSite, contact: e.target.value })}
                  />
                </div>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Phone #: </label>

                  <PhoneNoInput
                    value={newSite.phone}
                    handleChange={(e) => setNewSite({ ...newSite, phone: e })}
                    cssCode={"w-100"}
                  />
                </div>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Email: </label>
                  <input
                    className="modalInput"
                    value={newSite.email}
                    placeholder="Email"
                    style={{ width: "100%" }}
                    onChange={(e) => setNewSite({ ...newSite, email: e.target.value })}
                  />
                </div>
                {/* <div className="form-group-wrapper">
              <label className="modalLineHeaders">Temporary Password: </label>
              <input
                className="modalInput"
                type="password"
                value={newSite.password}
                placeholder="Temp Password"
                style={{ width: "100%", marginBottom: "10px" }}
                onChange={(e) => setNewSite({ ...newSite, password: e.target.value })}
              />
            </div> */}
                <div className="form-group-wrapper dateWrapper">
                  <label className="modalLineHeaders">Start Date: </label>
                  <input
                    className="modalInput"
                    type="date"
                    value={newSite.startDate}
                    placeholder="Start Date"
                    style={{ width: "100%", marginBottom: "10px" }}
                    onChange={(e) => setNewSite({ ...newSite, startDate: e.target.value })}
                  />
                </div>
                <div className="form-group-wrapper dateWrapper">
                  <label className="modalLineHeaders">End Date: </label>
                  <input
                    className="modalInput"
                    type="date"
                    value={newSite.endDate}
                    placeholder="End Date"
                    style={{ width: "100%", marginBottom: "10px" }}
                    onChange={(e) => setNewSite({ ...newSite, endDate: e.target.value })}
                  />
                </div>

                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Client: </label>
                  <Select
                    options={clients.map((client) => {
                      return { value: client.id, label: client.name };
                    })}
                    blurInputOnSelect={true}
                    defaultValue={null}
                    menuPlacement="auto"
                    placeholder="Select a Client"
                    value={getClientObj(newSite.clientID)}
                    onChange={(e) => setNewSite({ ...newSite, clientID: e.value })}
                    className="w-100 siteSelector"
                  />
                </div>
                <div className="form-group-wrapper dateWrapper">
                  <label className="modalLineHeaders">Order Cutoff DateTime: </label>
                  <input
                    className="modalInput"
                    type="datetime-local"
                    value={newSite.orderKitDate}
                    style={{ width: "100%", marginBottom: "10px" }}
                    onChange={(e) => setNewSite({ ...newSite, orderKitDate: e.target.value })}
                  />
                </div>
              </div>
              <div className="messages-area-show">
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders"> Message Tested:</label>
                  <textarea
                    className="textAreaWrapper"
                    onChange={(e) => setNewSite({ ...newSite, messageTested: e.target.value })}
                  >
                    {newSite.messageTested}
                  </textarea>
                </div>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders"> Message Resulted:</label>
                  <textarea
                    className="textAreaWrapper"
                    onChange={(e) => setNewSite({ ...newSite, messageResulted: e.target.value })}
                  >
                    {newSite.messageResulted}
                  </textarea>
                </div>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders"> Message Positive:</label>
                  <textarea
                    className="textAreaWrapper"
                    onChange={(e) => setNewSite({ ...newSite, messagePositive: e.target.value })}
                  >
                    {newSite.messagePositive}
                  </textarea>
                </div>
                <TextAreaEditor
                  message={newSite.messageReTest?.message}
                  name="reTestVerbiage"
                  label="Re-Test Verbiage"
                  type={newSite.messageReTest?.type}
                  handleChange={onHandleMessageChange}
                />
              </div>
            </div>
            <div className="createSettingsWrapper">
              <div className="">
                <div className="form-group-wrapper showsModal">
                  <div className="w-100">
                    <p style={{ fontSize: "18px", fontWeight: "600" }}> Show Runner </p>

                    <div className="form-group-wrapper safecamp-admin-wrapper">
                      <label className="modalLineHeaders"> Select Admin: </label>
                      <Select
                        className="siteSelector w-100"
                        options={adminList}
                        blurInputOnSelect={true}
                        defaultValue={null}
                        menuPlacement="auto"
                        isSearchable={false}
                        placeholder="Select Admin"
                        value={safeCampAdmin.name ? { label: safeCampAdmin.name, value: safeCampAdmin.userName } : null}
                        onChange={(e) => setSafeCampAdmin({ ...safeCampAdmin, name: e.label, userName: e.value, phone: e.phone, email: e.email })}
                      />
                    </div>
                   
                    <div className="form-group-wrapper safecamp-admin-wrapper">
                      <label className="modalLineHeaders">Phone: </label>
                      <PhoneNoInput
                        value={safeCampAdmin?.phone}
                        handleChange={(e) => setSafeCampAdmin({ ...safeCampAdmin, phone: e })}
                      />
                    </div>
                    <div className="form-group-wrapper safecamp-admin-wrapper">
                      <label className="modalLineHeaders"> Email: </label>
                      <input
                        className="modalInput"
                        value={safeCampAdmin?.email}
                        placeholder="Email"
                        style={{ width: "100%" }}
                        onChange={(e) => setSafeCampAdmin({ ...safeCampAdmin, email: e.target.value })}
                      />
                    </div>
                    <hr />
                    <div className="checkbox checkBoxWrapper">
                      <span>Automate Rapid Test </span>
                      <div className="d-flex justify-content-start align-items-center">
                        <span className="labelYesNo">{parseBooleanValue(newSite.antigen)}</span>
                        <ViewOnlyCheckBox
                          id="1"
                          bgColor="blue"
                          handleChange={(e) => setNewSite({ ...newSite, antigen: !newSite.antigen })}
                        />
                      </div>
                    </div>
                    {newSite.antigen && (
                      <div className="form-group-wrapper d-block selectTestWrapper">
                        <div className="form-group-wrapper">
                          <label className="modalLineHeaders">Select Test: </label>
                          <Select
                            className="siteSelector w-100"
                            options={TestTypeOptions}
                            blurInputOnSelect={true}
                            defaultValue={null}
                            menuPlacement="auto"
                            isSearchable={false}
                            placeholder="Test Type"
                            value={
                              newSite.automateTestType
                                ? { label: newSite.automateTestType, value: newSite.automateTestType }
                                : ""
                            }
                            onChange={(e) => setNewSite({ ...newSite, automateTestType: e.value })}
                          />
                        </div>
                        <div className="form-group-wrapper">
                          <label className="modalLineHeaders">Reset on: </label>
                          <Select
                            className="siteSelector w-100"
                            options={weekDaysOptions}
                            blurInputOnSelect={true}
                            defaultValue={null}
                            menuPlacement="auto"
                            isSearchable={false}
                            placeholder="Reset on"
                            value={getWeekDaySelectedValue(newSite.resetTestDay ?? "0")}
                            onChange={(e) => setNewSite({ ...newSite, resetTestDay: e.value })}
                          />
                        </div>
                      </div>
                    )}
                    <div className="checkbox checkBoxWrapper">
                      <span>Scan Insurance Card</span>
                      <div className="d-flex justify-content-start align-items-center">
                        <span className="labelYesNo">{parseBooleanValue(newSite.sendInsuranceCard)}</span>
                        <ViewOnlyCheckBox
                          checked={newSite.sendInsuranceCard}
                          id="2"
                          bgColor="blue"
                          handleChange={(e) =>
                            setNewSite({
                              ...newSite,
                              sendInsuranceCard: !newSite.sendInsuranceCard,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="checkbox checkBoxWrapper">
                      <span>Capture Insurance Information:</span>
                      <div className="d-flex justify-content-start align-items-center">
                        <span className="labelYesNo">{parseBooleanValue(newSite.insurance_required)}</span>
                        <ViewOnlyCheckBox
                          checked={newSite.insurance_required}
                          id="3"
                          bgColor="blue"
                          handleChange={(e) =>
                            setNewSite({
                              ...newSite,
                              insurance_required: !newSite.insurance_required,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="checkbox checkBoxWrapper">
                      <span>Exclude from Summary</span>
                      <div className="d-flex justify-content-start align-items-center">
                        <span className="labelYesNo">{parseBooleanValue(newSite.showOnSummaryScreen || false)}</span>
                        <ViewOnlyCheckBox
                          id="4"
                          checked={newSite.showOnSummaryScreen}
                          bgColor="blue"
                          handleChange={(e) =>
                            setNewSite({
                              ...newSite,
                              showOnSummaryScreen: !newSite.showOnSummaryScreen,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div>
                      <div className="checkbox checkBoxWrapper">
                        <span>Vaccination Card</span>
                        <div className="d-flex justify-content-start align-items-center">
                          <span className="labelYesNo">{parseBooleanValue(newSite.vaccinationCard || false)}</span>
                          <ViewOnlyCheckBox
                            id="5"
                            bgColor="blue"
                            checked={newSite.vaccinationCard}
                            handleChange={(e) =>
                              setNewSite({
                                ...newSite,
                                vaccinationCard: !newSite.vaccinationCard,
                              })
                            }
                          />
                        </div>
                      </div>

                      {newSite.vaccinationCard && (
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="fontFourteen" style={{ paddingLeft: "8px" }}>
                            Admit
                          </div>
                          <div className="d-flex align-items-center justify-content-start">
                            <span className="labelYesNo fontFourteen">{parseBooleanValue(newSite.admit || false)}</span>
                            <ViewOnlyCheckBox
                              id="6"
                              bgColor="blue"
                              checked={newSite.admit}
                              handleChange={(e) =>
                                setNewSite({
                                  ...newSite,
                                  admit: !newSite.admit,
                                })
                              }
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div>
                      <div className="checkbox checkBoxWrapper">
                        <span>External Test</span>
                        <div className="d-flex align-items-center justify-content-start">
                          <span className="labelYesNo fontFourteen">
                            {parseBooleanValue(newSite.externalTest || false)}
                          </span>
                          <ViewOnlyCheckBox
                            id="7"
                            bgColor="blue"
                            checked={newSite.externalTest}
                            handleChange={(e) =>
                              setNewSite({
                                ...newSite,
                                externalTest: !newSite.externalTest,
                              })
                            }
                          />
                        </div>
                      </div>

                      {newSite.externalTest && (
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="fontFourteen" style={{ paddingLeft: "8px" }}>
                            Admit{" "}
                            {/* 
                              // Temporarily comomment this requirement
                              {newSite.externalTestAdmit && (
                              <a href="#" style={{ color: "blue" }} onClick={() => setshowModalTestType(true)}>
                                Settings
                              </a>
                            )} */}
                          </div>
                          <div className="d-flex align-items-center justify-content-start">
                            <span className="labelYesNo">{parseBooleanValue(newSite.externalTestAdmit || false)}</span>
                            <ViewOnlyCheckBox
                              id="8"
                              checked={newSite.externalTestAdmit}
                              bgColor="blue"
                              handleChange={(e) =>
                                setNewSite({
                                  ...newSite,
                                  externalTestAdmit: !newSite.externalTestAdmit,
                                })
                              }
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div>
                      <div className="checkbox checkBoxWrapper">
                        <span>Pattern Testing</span>
                        <div className="d-flex justify-content-start align-items-center">
                          <span className="labelYesNo">{parseBooleanValue(newSite.patternTesting || false)}</span>
                          <ViewOnlyCheckBox
                            id="9"
                            checked={newSite.patternTesting}
                            bgColor="blue"
                            handleChange={(e) =>
                              setNewSite({
                                ...newSite,
                                patternTesting: !newSite.patternTesting,
                              })
                            }
                          />
                        </div>
                      </div>

                      {newSite.patternTesting && (
                        <div className="d-flex justify-content-between align-items-center">
                          <div
                            className="fontFourteen"
                            style={{ paddingLeft: "8px" }}
                            onClick={() => setShowPattrenEditor(true)}
                          >
                            Disclaimer
                          </div>
                          <div className="linkedText" onClick={() => setShowPattrenEditor(true)}>
                            {newSite.isNew ? "Edit Text" : "Update Text"}
                          </div>
                          {showPatternEditor && (
                            <TextEditor
                              text={newSite.patternHippa ?? ""}
                              handleSave={(val) => {
                                setShowPattrenEditor(false);
                                setNewSite({ ...newSite, patternHippa: val });
                              }}
                              handleClose={() => setShowPattrenEditor(false)}
                            />
                          )}
                        </div>
                      )}
                    </div>
                    <div>
                      <div className="checkbox checkBoxWrapper">
                        <span>Pre-Registration</span>
                        <div className="d-flex justify-content-start align-items-center">
                          <span className="labelYesNo">{parseBooleanValue(newSite.preRegistration || false)}</span>
                          <ViewOnlyCheckBox
                            id="10"
                            checked={newSite.preRegistration}
                            bgColor="blue"
                            handleChange={(e) =>
                              setNewSite({
                                ...newSite,
                                preRegistration: !newSite.preRegistration,
                              })
                            }
                          />
                        </div>
                      </div>
                      {newSite.isNew === false && newSite.preRegistration && (
                        <>
                          <div className="d-flex justify-content-between align-items-center position-relative">
                            {showCopyText && <div className="copy-link-text">Copy to clipboard!</div>}
                            <div className="fontFourteen" style={{ paddingLeft: "8px" }}>
                              QR Code
                            </div>
                            <div className="d-flex align-items-center justify-content-between qr-code-download">
                              <div onClick={() => handleCopyPRLink(newSite.id)}>
                                <i className="fas fa-copy" />
                              </div>
                              <div onClick={() => downloadQRCode()}>
                                <i className="fas fa-download"></i>
                              </div>
                              <span style={{ display: "none" }}>
                                <QRCode
                                  id="qr-gen"
                                  ecLevel={"H"}
                                  value={getPreRegistrationLink(newSite.id, currentURL)}
                                  enableCORS={true}
                                  logoImage={logo}
                                  size="250"
                                  logoHeight={"70"}
                                  logoWidth={"70"}
                                  fgColor={"#000"}
                                  bgColor={"#fff"}
                                />
                              </span>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center position-relative">
                            <div className="fontFourteen" style={{ paddingLeft: "8px" }}>
                              Test Quantity
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                              <Select
                                options={TEST_ORDER_QTY}
                                blurInputOnSelect={true}
                                value={
                                  newSite.orderQuantity
                                    ? { value: newSite.orderQuantity, label: newSite.orderQuantity }
                                    : { value: 2, label: 2 }
                                }
                                menuPlacement="auto"
                                placeholder="Select Quantity"
                                className="w-100"
                                onChange={(e) =>
                                  setNewSite({
                                    ...newSite,
                                    orderQuantity: e.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center position-relative">
                            <div className="fontFourteen" style={{ paddingLeft: "8px" }}>
                              Test Type
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                              <Select
                                options={PRE_REGISTRATION_TEST_TYPE}
                                blurInputOnSelect={true}
                                value={
                                  newSite.orderKitType
                                    ? { label: TEST_TYPE_VALUE[newSite.orderKitType], value: newSite.orderKitType }
                                    : null
                                }
                                menuPlacement="auto"
                                placeholder="Select Test"
                                className="w-100"
                                onChange={(e) =>
                                  setNewSite({
                                    ...newSite,
                                    orderKitType: e.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          {(newSite.orderKitType === "Antigen" || newSite.orderKitType === "Lucira") && (
                            <div className="d-flex justify-content-between align-items-center position-relative">
                              <div className="fontFourteen" style={{ paddingLeft: "8px" }}>
                                Enable Shipment
                              </div>
                              <div className="d-flex align-items-center justify-content-between">
                                <span className="labelYesNo">{parseBooleanValue(newSite.enableShipment || false)}</span>
                                <ViewOnlyCheckBox
                                  id="11"
                                  checked={newSite.enableShipment || false}
                                  bgColor="blue"
                                  handleChange={(e) =>
                                    setNewSite({
                                      ...newSite,
                                      enableShipment: e.target.checked,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          )}
                        </>
                      )}
                      {newSite.isNew === true && newSite.preRegistration && (
                        <div className="d-flex justify-content-between align-items-center position-relative">
                          <div className="fontFourteen" style={{ paddingLeft: "8px" }}>
                            Enable Shipment
                          </div>
                          <div className="d-flex align-items-center justify-content-between">
                            <span className="labelYesNo">{parseBooleanValue(newSite.enableShipment || false)}</span>
                            <ViewOnlyCheckBox
                              id="12"
                              checked={newSite.enableShipment || false}
                              bgColor="blue"
                              handleChange={(e) =>
                                setNewSite({
                                  ...newSite,
                                  enableShipment: e.target.checked,
                                })
                              }
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="checkbox checkBoxWrapper">
                      <span>HR Registration</span>
                      <div className="d-flex align-items-center justify-content-start">
                        <span className="labelYesNo">{parseBooleanValue(newSite.isHRPR || false)}</span>
                        <ViewOnlyCheckBox
                          id="13"
                          bgColor="blue"
                          checked={newSite.isHRPR}
                          handleChange={(e) =>
                            setNewSite({
                              ...newSite,
                              isHRPR: !newSite.isHRPR,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="checkbox checkBoxWrapper">
                      <span>Chain of Custody</span>
                      <div className="d-flex align-items-center justify-content-start">
                        <span className="labelYesNo">{parseBooleanValue(newSite.isPCRCustody || false)}</span>
                        <ViewOnlyCheckBox
                          id="isPCRCustody"
                          bgColor="blue"
                          checked={newSite.isPCRCustody}
                          handleChange={(e) =>
                            setNewSite({
                              ...newSite,
                              isPCRCustody: !newSite.isPCRCustody,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="checkbox checkBoxWrapper">
                      <span>Archive</span>
                      <div className="d-flex align-items-center justify-content-start">
                        <span className="labelYesNo">{parseBooleanValue(newSite.isArchive || false)}</span>
                        <ViewOnlyCheckBox
                          id="14"
                          bgColor="blue"
                          checked={newSite.isArchive}
                          handleChange={(e) =>
                            setNewSite({
                              ...newSite,
                              isArchive: !newSite.isArchive,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
        </Modal.Body>

        <Modal.Footer style={{ borderTop: "1px solid lightgrey", paddingTop: "15px" }}>
          <Button
            variant="secondary"
            className="modalButtons"
            onClick={() => {
              setNewSite({});
              handleClose();
            }}
          >
            Close
          </Button>
          <Button variant="primary" className="modalButtons" onClick={handleSave}>
            {newSite.isNew ? "Create" : "Update"}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        centered
        show={warningModal}
        // style={{ transform: "translate(0, -30%)" }}
        onHide={exitModal}
        backdrop="static"
      >
        <Modal.Header closeButton={exitModal}>
          <Modal.Title className="my-0">Delete Show</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: 0, marginRight: "auto", marginLeft: "auto" }}>
          <Modal.Title id="contained-modal-title-vcenter">Are you sure you want to delete this show?</Modal.Title>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={exitModal}>
            Go back
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              deleteSite(showToDelete);
              exitModal();
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      {openStatus && (
        <ChangeStatusModal
          handleSave={handleUpdateStatus}
          handleClose={handleStatusClose}
          selectedStatus={selectedStatus?.status}
        />
      )}
    </div>
  );
};

export default SitesView;
