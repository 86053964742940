import React, { useContext, useEffect, useState } from "react";
import API from "api";

import { SUMMARY_TABLES_KEY_LABELS } from "constant";
import { parseIntVal,formateDateLL } from "utils";
import { SUMMARY_TABLES_STATS_TYPE } from "constant";
import Loader from "components/Loader/Loader";

const DashboardChartsTableData = (props) => {

    const { statsType  , totalCounts} = props;

    const [testsSummaryData, setTestsSummaryData] = useState([]);
    const [loading, setLoading] = useState();
    const [positiveTest, setPositiveTest] = useState([]);
    const [filter, setFilter] = useState('');

    const lastPositiveTest = (id) => {
        let key = SUMMARY_TABLES_KEY_LABELS[statsType];
        let positiveTestsData = positiveTest.find((c) => c[key] === id)
        return positiveTestsData;
    };

    const tableSummaryData = async () => {

        let summaryData = ""

        if (statsType === 'clientstats') {
            summaryData = await API.getSummaryFromPG({ summary: "client" });
            loadTotalTestCounts(summaryData.rows);
        } else if (statsType === 'sitestats') {
            summaryData = await API.getSummaryFromPG({ summary: "site" });
        } else if (statsType === 'labstats') {
            summaryData = await API.getSummaryFromPG({ summary: "lab" });
        }

        setTestsSummaryData(summaryData?.rows);
        setFilter(summaryData.rows);

        return true;
    }


    const loadDashboardData = async () => {
        try {
            setLoading(true);
            const ptData = await API.getSummaryFromPG({ summary: "positiveTest" });
            setPositiveTest(ptData.rows);

            let flag = tableSummaryData();

            if (flag) setLoading(false);
        } catch (err) {
            console.log("Error ", err);
            setLoading(false);
        }
    };

    const loadTotalTestCounts = async (data) => {
        let sum = { totalTests: 0, positiveTests: 0, negativeTests: 0 };
        data.reduce((obj, client) => {
            obj["totalTests"] = (obj["totalTests"] || 0) + client.totalTest;
            obj["positiveTests"] = (obj["positiveTests"] || 0) + parseIntVal(client.positiveCount);
            obj["negativeTests"] = (obj["negativeTests"] || 0) + parseIntVal(client.negativeCount);
            return obj;
          }, sum)
        
        totalCounts(sum);
    }

    useEffect(async () => {
        loadDashboardData();
    }, []);


    const handleChange = (e) => {
        if (e.target.value === "") {
            setFilter(testsSummaryData);
            return;
        }

        const results = testsSummaryData?.filter(test => {
            return test?.name.toLowerCase().includes(e.target.value.toLowerCase());
        })
        setFilter(results);

    }

    return (
        <>
            <div className=" search-card">
                <div
                    className="card-body d-flex align-items-center justify-content-between">
                    <p className="card-title custom-title">{SUMMARY_TABLES_STATS_TYPE[statsType]}</p>
                    <div>
                        <form className="form position-relative">
                            <input type="search" id="filter" name="filter" onChange={handleChange} placeholder="Search..." className="search-field" />
                            <div><i className="fa fa-search search-icon" aria-hidden="true"></i></div>
                        </form>
                    </div>
                </div>
            </div>
            <table className="dashboard-tables-data">
                <tbody>
                <tr>
                    <td>
                        {filter && (
                            filter?.map((test, index) => {
                                const positiveData = lastPositiveTest(test?.id);
                                return (
                                    <table key={index} className="table">
                                        <tbody>
                                        <tr className="border-bottom-transparent">
                                            <td colSpan="4">
                                                <p className="custom-title">Name: <span className="fw-bold text-black">{test.name}</span></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p className="custom-title">Total Tests</p>
                                                <span className="fw-bold">{test.totalTest}</span>
                                            </td>
                                            <td>
                                                <p className="positive-number">Positive</p>
                                                <span className="fw-bold">{test.positiveCount}</span>
                                            </td>
                                            <td>
                                                <p className="negative-number">Negative</p>
                                                <span className="fw-bold">{test.negativeCount}</span>
                                            </td>
                                            <td>
                                                <p className="total-number">Last</p>
                                                <span className="fw-bold">
                                            {!positiveData ? (
                                                "No Positive Tests"
                                            ) : (
                                                formateDateLL(positiveData.resultDate)

                                            )}
                                        </span>

                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                );
                            })
                        )
                        }

                        {loading && <Loader />}
                    </td>
                </tr>
                </tbody>
            </table>

        </>
    );
}

export default DashboardChartsTableData;
