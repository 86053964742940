import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { DELIVERY_COMPANY } from "constant";
import { DeliveryTrackUrl, isValidDigits } from "./../../utils";

// Delivery Object Inialize

const deliveryObj = {
  order_id: "",
  eventType: "Shipped",
  eventData: "",
  tracking_number: "",
  tracking_url: "",
  shipmentDate: "",
};

const CreateTestDeliveryModal = (props) => {
  const { handleSave, handleClose } = props;
  const [error, setError] = useState("");
  const [deliveryCreate, setDeliveryCreate] = useState(deliveryObj);

  useEffect(() => {
    setTimeout(() => {
      setError("");
    }, 4000);
  }, [error]);

  const onHandleSave = () => {
    // Validation check

    if (!deliveryCreate.order_id) {
      setError("Order No. is required");
      return;
    }
    if (!isValidDigits(deliveryCreate.order_id)) {
      setError("Order No is invalid");
      return;
    }
    if (!deliveryCreate.eventData) {
      setError("Delviery Company is required");
      return;
    }

    if (!deliveryCreate.tracking_number) {
      setError("Tracking No. is required");
      return;
    }
    if (!deliveryCreate.tracking_url) {
      setError("Tracking URL is required");
      return;
    }

    if (!deliveryCreate.shipmentDate) {
      setError("Shipment Date is required");
      return;
    }

    // Creating delivery call to parent

    handleSave(deliveryCreate);
    handleClose();
  };

  const handleDelivery = (value) => {
    let trackUrl = DeliveryTrackUrl(deliveryCreate.eventData, value);
    setDeliveryCreate({
      ...deliveryCreate,
      tracking_number: value,
      tracking_url: trackUrl ? trackUrl : "",
    });
  };

  console.log("deliveryCreatedeliveryCreate", deliveryCreate);

  return (
    <>
      <Modal
        show
        animation={true}
        onHide={() => {
          handleClose();
        }}
        style={{ paddingLeft: "0" }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            Courier Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: 0,
          }}
        >
          <div className="form-group-wrapper">
            <label className="modalLineHeaders"> Delivery Comapany</label>
            <Select
              className="w-100 siteSelector"
              options={DELIVERY_COMPANY}
              blurInputOnSelect={true}
              defaultValue={null}
              menuPlacement="auto"
              placeholder="Select Delivery Company"
              onChange={(e) => setDeliveryCreate({ ...deliveryCreate, eventData: e.value })}
            />
          </div>
          <div className="form-group-wrapper">
            <label className="modalLineHeaders"> Order #: </label>
            <input
              className="modalInput"
              type="number"
              placeholder="Order No"
              style={{
                width: "100%",
              }}
              value={deliveryCreate?.order_id}
              onChange={(e) => setDeliveryCreate({ ...deliveryCreate, order_id: parseInt(e.target.value) })}
            />
          </div>
          <div className="form-group-wrapper">
            <label className="modalLineHeaders">Tracking #: </label>
            <input
              className="modalInput"
              placeholder="Tracking No"
              style={{
                width: "100%",
              }}
              value={deliveryCreate?.tracking_number}
              onChange={(e) => handleDelivery(e.target.value.replace(".", ""))}
            />
          </div>
          <div className="form-group-wrapper">
            <label className="modalLineHeaders">Tracking URL: </label>
            <input
              className="modalInput"
              placeholder="Tracking URL"
              style={{
                width: "100%",
              }}
              value={deliveryCreate?.tracking_url}
              onChange={(e) => setDeliveryCreate({ ...deliveryCreate, tracking_url: e.target.value })}
            />
          </div>
          <div className="form-group-wrapper dateWrapper">
            <label className="modalLineHeaders">Date: </label>
            <input
              className="modalInput"
              type="datetime-local"
              value={deliveryCreate?.shipmentDate}
              style={{ width: "100%", marginBottom: "10px" }}
              onChange={(e) => setDeliveryCreate({ ...deliveryCreate, shipmentDate: e.target.value })}
            />
          </div>
          {error.length > 0 && <strong style={{ color: "Red" }}>{error}</strong>}
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            className="modalButtons"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
          <Button style={{ marginBottom: 10 }} variant="primary" className="modalButtons" onClick={onHandleSave}>
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateTestDeliveryModal;
