import React, { useState, useContext, useEffect } from "react";
import { Card, Button, Form, Modal, Table } from "react-bootstrap";
import { AppContext } from "context/app-context";
import api from "api";
import Loader from "components/Loader/Loader";
import { formatPhoneNumber, parseBooleanValue, formatDOB } from "utils";
import { GENDER_TEXT } from "constant";
import moment from "moment";
import ProfileTableHeader from "components/CrewProfile/ProfileTableHeader";
import ProfileTableItem from "components/CrewProfile/ProfileTableItem";

const EmployeeCrewProfileLogsModal = (props) => {
  const { data, handleClose, totalTestsCount, handleShowMergedProfileLogs } = props;
  const [employees, setEmployees] = useState(data);
  const [userNotToDelete, setUserNotToDelete] = useState("");
  const [loading, setLoading] = useState(false);
  const appContext = useContext(AppContext);

  
  const handleConfirm = async (isConfirm) => {
    if (!isConfirm) {
      setUserNotToDelete(null);
      return;
    }
    try {
      setLoading(true);

      const duplicateEmployee = employees.filter((u) => u.id !== userNotToDelete.id);
      const primary_id = userNotToDelete?.mdID;
      const deleted_id = duplicateEmployee?.map((e) => {
        return e.mdID;
      });

      if (deleted_id.length === 0) {
        appContext.showSuccessMessage("No Record to Delete");
        setLoading(false);
        handleClose(true);
        return;
      }

      const models = await api.deleteDuplicateEmployee(userNotToDelete, primary_id, deleted_id, appContext.user);
      let length = models.deletedIds.length;
      let message = ` Success:  You have deleted ${length}  Duplicate Profiles and combined ${totalTestsCount} tests`;

      if (totalTestsCount === 0) {
        message = "Success: You have deleted " + length + " Duplicate Profiles.";
      }
      appContext.showSuccessMessage(message);

      setLoading(false);
      handleClose(true);
    } catch (error) {
      appContext.showErrorMessage(error.message);
      setLoading(false);
    }
  };

  const handleUpdataPoint = (key, value) => {
    if (userNotToDelete) {
      let obj = { ...userNotToDelete };
      if (key === "street") {
        obj = {
          ...obj,
          street: value.street,
          state: value.state,
          ...(value.city && { city: value.city }),
          ...(value.country && { country: value.country }),
          ...(value.street2 && { street2: value.street2 }),
        };
      } else {
        obj[`${key}`] = value;
      }

      setUserNotToDelete({ ...obj });
    }
  };

  return (
    <>
      {!loading ? (
        <Modal show animation={true} onHide={() => handleClose()} size="2xl">
          <Modal.Header closeButton>
            <Modal.Title className="my-0" id="contained-modal-title-vcenter">
              Employee Data - Total Tests ({totalTestsCount})
            </Modal.Title>
          </Modal.Header>

          <Modal.Body style={{ paddingTop: 0 }}>
            <h5 className="mb-1 mt-0 fw-bolder">Valid Profile</h5>
            <Card>
              <Card.Body className="px-2">
                <div className="table-responsive">
                  <Table className="table table-hover table-striped">
                    <ProfileTableHeader />
                    <tbody>
                    {userNotToDelete ? (
                        <ProfileTableItem user={userNotToDelete} userNotToDelete={userNotToDelete}></ProfileTableItem>
                    ) : (
                         <td colSpan='11'>
                           <div className="w-100 text-center">Select Valid Profile from below options</div>
                         </td>
                    )}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
            <h5 className="mb-1 mt-0 fw-bolder">Profile Options</h5>
            <Card>
              <Card.Body className="px-2">
                  <Form>
                    <div className="table-responsive">
                      <Table className="table table-hover table-striped table-fixed">
                        <ProfileTableHeader />
                        <tbody>
                        {employees?.map((user, index) => {
                          return (
                              <ProfileTableItem
                                  key={index}
                                  user={user}
                                  setUserNotToDelete={setUserNotToDelete}
                                  showSelection
                                  updateDatePoint={handleUpdataPoint}
                              ></ProfileTableItem>
                          );
                        })}
                        </tbody>
                      </Table>
                    </div>
                  </Form>
              </Card.Body>
            </Card>
          </Modal.Body>

          <Modal.Footer className="flex-row-reverse">
            {userNotToDelete && (
              <Button
                className="modalButtons"
                variant="danger"
                onClick={() => {
                  handleConfirm(true);
                }}
              >
                Combine Profiles
              </Button>
            )}
            <Button variant="primary" onClick={() => handleClose(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default EmployeeCrewProfileLogsModal;
