import React, { useEffect, useState, useContext } from "react";
import { Button, Modal, Form, Container, Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import API from "api";
import SingleDatePicker from "Date/SingleDatePicker";
import Default_Profile from "assets/img/default_profile.png";
import ImageInput from "./Input/ImageInput";
import { Storage } from "aws-amplify";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import Loader from "./Loader/Loader";
import ImageCropModal from "./Input/ImageCropModal";
import { async } from "@firebase/util";
import TestView from "./TestView";
import PhoneNoInput from "components/Phone/PhoneNoInput";
import { formatPhoneNumber, phoneFormatter, formatDateOfBirthDOB, formatZipCode, setPhoneNo } from "utils";
import moment from "moment";
import ImageView from "./Image/ImageView";
import ErrorMessage from "./Message/ErrorMessage";
import AutoCompleteAddress from "./AutoCompleteAddress";

const CrewProfile = (props) => {
  const { employee, handleSave, handleClose } = props;
  const [newEmp, setNewEmp] = useState(employee);
  const [tests, setTests] = useState([]);
  const [profileImage, setProfileImage] = useState(Default_Profile);
  const [copyImage, setCopyImage] = useState(Default_Profile);
  const [showImageCroper, setShowImageCropper] = useState(false);
  const [vaccinationImage, setVaccinationImage] = useState("");
  const [externalTestImage, setExternalTestImage] = useState("");
  const [editButton, setEditButton] = useState(true);
  const [phoneError, setPhoneError] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [loadingTestView, setLoadingTestView] = useState(false)
  useEffect(() => {
    handleViewTest(employee.id);
  }, []);
  useEffect(async () => {
    if (employee) {
      await getFileFromStorage(employee.profileImage, setProfileImage);
      await getFileFromStorage(employee.vaccinationCardImage, setVaccinationImage);
      await getFileFromStorage(employee.externalTestImage, setExternalTestImage);
    }
  }, [employee]);
  const getFileFromStorage = async (file, setImage) => {
    if (file) {
      try {
        const path = await Storage.get(file);
        setImage(path);
        console.log("path", path);
        return true;
      } catch {
        console.log("Image loading fail");
      }
    } else {
      return false;
    }
  };
  const handleViewTest = async (id) => {
    // const tests = await API.getTestGraphQL({ employeeID: { eq: id } });
    setLoadingTestView(true)
    const tests = await API.getAdminTestList(id);
    console.log("Tests Count", tests, id);
    setTests(tests);
    setLoadingTestView(false)
  };

  console.log("newEmployee", newEmp);
  const handleCropDone = (isConfirm, fileData) => {
    setShowImageCropper(false);
    if (isConfirm && fileData) {
      setProfileImage(new File([fileData], "profileImage.jpeg"));
    }else{
      setProfileImage(profileImage)
    }
  };
  
  const ImageUpload = async (fileName, image) => {
    if (image instanceof Object) {
      await Storage.put(fileName, image);
      return fileName;
    } else {
      return "";
    }
  };
  const handleSaveChange = async () => {
    if (!newEmp.first) {
      setError("First name is required");
      return;
    }
    if (!newEmp.last) {
      setError("Last name is required");
      return;
    }

    if (!newEmp.email) {
      setError("Email name is required");
      return;
    }

    if (!newEmp.phone_number) {
      setError("Phone name is required");
      return;
    }
    if (newEmp.dob && !moment(newEmp.dob, "YYYY-MM-DD", true).isValid()) {
      setError("Invalid Date of Birth");
      return;
    }
    if (phoneError) {
      setError("Invalid Phone Number");
      return;
    }
    if (!new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(newEmp.email)) {
      setError("Invalid Email");
      return;
    }
    setLoading(true);
    const models = await API.getEmployeeByIdGraphQl(newEmp.id);
    const versionNo = models[0]._version;
    console.log("versionNo", versionNo);
    const fileName = `${newEmp.mdID ? newEmp.mdID : newEmp.id}/${newEmp.id}-${
      newEmp.phone_number
    }-${moment().toISOString()}`;

    const ProfileFileName = await ImageUpload(`${fileName}-profile-image.png`, profileImage);

    const vaccinationFileName = await ImageUpload(`${fileName}-vaccination-card.png`, vaccinationImage);

    const externalImageFileName = await ImageUpload(`${fileName}-external-test.png`, externalTestImage);
    console.log("ProfileFileName", vaccinationFileName, externalImageFileName);
    const phone = formatPhoneNumberIntl(newEmp.phone_number);
    const phoneArr = phone.split(" ");
    const countryCode = phoneArr[0];
    const Emp = {
      ...newEmp,
      profileImage: ProfileFileName ? ProfileFileName : employee.profileImage,
      vaccinationCardImage: vaccinationFileName ? vaccinationFileName : employee.vaccinationCardImage,
      externalTestImage: externalImageFileName ? externalImageFileName : employee.externalTestImage,
      countryCode: countryCode,
      _version: versionNo,
    };
    handleSave(Emp)
  };

  const handleEdit = async (isEdit) => {
    if (isEdit) {
      setNewEmp(employee);
      const profile = await getFileFromStorage(employee.profileImage, setProfileImage);
      if (!profile) setProfileImage(Default_Profile);
      const vacc = await getFileFromStorage(employee.vaccinationCardImage, setVaccinationImage);
      console.log("vacc", vacc);
      if (!vacc) setVaccinationImage("");
      const externalImg = await getFileFromStorage(employee.externalTestImage, setExternalTestImage);
      if (!externalImg) setExternalTestImage("");
    }
    setEditButton(isEdit);
  };


  const handleUpdateAddress = (val, flag) => {
    if (!flag) {
      setNewEmp({ ...newEmp, street: val });
    } else {
      const addressObj = {
        street: val.street,
        country: val.country,
        city: val.city,
        zipcode: val.zipcode,
        zip: val.zipcode,
        state: val.state,
        isoCode: val.isoCode,
      };
      setNewEmp({ ...newEmp, ...addressObj });
    }
  };

  return (
    <>
      {!loading && newEmp && employee ? (
          <Modal
            show
            animation={true}
            size="xl"
            onHide={() => {
              handleClose();
            }}
            style={{ paddingLeft: "0" }}
            centered
            scrollable
          >
              <Modal.Header closeButton>
                <Modal.Title
                  className="my-0" 
                  id="contained-modal-title-vcenter"
                >
                  {`${employee.first.toUpperCase()} ${employee.last.toUpperCase()} PROFILE`}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form className="employeeProfile">
                  <Row>
                    <Col md="7">
                      <Row className="my-2">
                        <Col className="pr-1" md="6">
                          <Form.Group>
                            <label>First Name</label>
                            <Form.Control
                              readOnly={editButton}
                              plaintext
                              className={`${!editButton ? "modalInput" : ""}`}
                              onChange={(e) => setNewEmp({ ...newEmp, first: e.target.value })}
                              value={newEmp.first}
                              placeholder={`${!editButton ? "Enter First Name " : ""}`}
                              type="text"
                            />
                          </Form.Group>
                        </Col>
                        <Col className="pl-1" md="6">
                          <Form.Group>
                            <label>Last Name</label>
                            <Form.Control
                              readOnly={editButton}
                              plaintext
                              className={`${!editButton ? "modalInput" : ""}`}
                              onChange={(e) => setNewEmp({ ...newEmp, last: e.target.value })}
                              value={newEmp.last}
                              placeholder={`${!editButton ? "Enter Last Name " : ""}`}
                              type="text"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="my-2">
                        <Col md="6">
                          <Form.Group>
                            <label>Phone Number</label>
                            {editButton ? (
                              <Form.Control
                                style={{
                                  textDecoration: "underline",
                                  color: "#A82632",
                                }}
                                className={`${!editButton ? "modalInput" : ""}`}
                                onMouseOver={(e) => {
                                  editButton ? (e.target.style.cursor = "pointer") : (e.target.style.cursor = "auto");
                                  e.target.style.textDecoration = "underline";
                                }}
                                onClick={() =>
                                  editButton
                                    ? newEmp.phone_number && window.open(`tel:+1${newEmp.phone_number}`)
                                    : false
                                }
                                readOnly={editButton}
                                maxLength={15}
                                plaintext
                                value={
                                  editButton
                                    ? formatPhoneNumber(newEmp.phone_number)
                                    : phoneFormatter(newEmp.phone_number)
                                }
                                placeholder={`${!editButton ? "Enter Phone Number " : ""}`}
                                type="text"
                              />
                            ) : (
                              <PhoneNoInput
                                error={setPhoneError}
                                handleChange={(e) => {
                                  setNewEmp({ ...newEmp, phone_number: e });
                                }}
                                value={newEmp.phone_number}
                              />
                            )}
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group>
                            <label>Date of Birth</label>
                              <Form.Control
                                readOnly={editButton}
                                plaintext
                                className={`${!editButton ? "modalInput" : ""}`}
                                onChange={(e) => setNewEmp({ ...newEmp, dob: e.target.value })}
                                value={newEmp.dob}
                                placeholder="Date of Birth"
                                type="date"
                              />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="my-2">
                        <Col md="6">
                          <Form.Group>
                            <label>Email Address</label>
                            <Form.Control
                              style={{
                                textDecoration: "underline",
                                color: "#A82632",
                              }}
                              className={`${!editButton ? "modalInput" : ""}`}
                              onMouseOver={(e) => {
                                editButton ? (e.target.style.cursor = "pointer") : (e.target.style.cursor = "auto");
                                e.target.style.textDecoration = "underline";
                              }}
                              onClick={() =>
                                editButton ? newEmp.email && window.open(`mailto:${newEmp.email}`) : false
                              }
                              plaintext
                              onChange={(e) => setNewEmp({ ...newEmp, email: e.target.value })}
                              value={newEmp.email}
                              readOnly={editButton}
                              placeholder={`${!editButton ? "Enter Email " : ""}`}
                              type="text"
                            />
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group>
                            <label>Gender</label>
                            <div className="d-flex align-items-center">
                              <Form.Check
                                className="radioBox"
                                type="radio"
                                disabled={editButton}
                                label="Male"
                                checked={newEmp.sex === "M"}
                                name="gender"
                                onChange={(e) => setNewEmp({ ...newEmp, sex: e.target.checked && "M" })}
                              />
                              <Form.Check
                                className="radioBox"
                                type="radio"
                                disabled={editButton}
                                checked={newEmp.sex === "F"}
                                label="Female"
                                name="gender"
                                onChange={(e) => setNewEmp({ ...newEmp, sex: e.target.checked && "F" })}
                              />
                              <Form.Check
                                className="radioBox"
                                type="radio"
                                checked={newEmp.sex === "X"}
                                disabled={editButton}
                                label="Not Specified"
                                name="gender"
                                onChange={(e) => setNewEmp({ ...newEmp, sex: e.target.checked && "X" })}
                              />
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="my-2">
                        <Col md="6">
                          <Form.Group>
                            <label>Address</label>
                            {/* <Form.Control
                              plaintext
                              className={`${!editButton ? "modalInput" : ""}`}
                              onChange={(e) => setNewEmp({ ...newEmp, street: e.target.value })}
                              readOnly={editButton}
                              value={newEmp.street}
                              placeholder={`${!editButton ? "Enter Address  " : ""}`}
                              type="text"
                            /> */}
                             <AutoCompleteAddress readOnly={editButton} value={newEmp.street} handleChange={handleUpdateAddress} />
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group>
                            <label>Address 2</label>
                            <Form.Control
                              plaintext
                              className={`${!editButton ? "modalInput" : ""}`}
                              readOnly={editButton}
                              onChange={(e) => setNewEmp({ ...newEmp, street2: e.target.value })}
                              value={newEmp.street2}
                              placeholder={`${!editButton ? "Enter Address 2 " : ""}`}
                              type="text"
                            />
                               
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="my-2">
                        <Col className="pr-1" md="6">
                          <Form.Group>
                            <label>City</label>
                            <Form.Control
                              plaintext
                              readOnly={editButton}
                              className={`${!editButton ? "modalInput" : ""}`}
                              onChange={(e) => setNewEmp({ ...newEmp, city: e.target.value })}
                              value={newEmp.city}
                              placeholder={`${!editButton ? "Enter City" : ""}`}
                              type="text"
                            />
                          </Form.Group>
                        </Col>
                        <Col className="pl-1" md="6">
                          <Form.Group>
                            <label>State</label>
                            <Form.Control
                              plaintext
                              className={`${!editButton ? "modalInput" : ""}`}
                              readOnly={editButton}
                              onChange={(e) => setNewEmp({ ...newEmp, state: e.target.value })}
                              value={newEmp.state}
                              placeholder={`${!editButton ? "Enter Country" : ""}`}
                              type="text"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="my-2">
                        <Col className="pl-1" md="6">
                          <Form.Group>
                            <label>Postal Code</label>
                            <Form.Control
                              plaintext
                              className={`${!editButton ? "modalInput" : ""}`}
                              onChange={(e) => setNewEmp({ ...newEmp, zip: e.target.value })}
                              readOnly={editButton}
                              value={formatZipCode(newEmp.zip)}
                              placeholder={`${!editButton ? "Enter Postal Code " : ""}`}
                              type="text"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                    <Col md="5" className="align-items-center">
                      <Col md="12" className="colFirstWrapper">
                        <div className="form-group-wrapper">
                          <label className="modalLineHeaders">Profile Image:</label>
                          <ImageInput
                            type="profile"
                            setSelectedImage={setProfileImage}
                            selectedImage={profileImage}
                            showCropDialog={setShowImageCropper}
                            setCopyImage={setCopyImage}
                          />
                        </div>
                      </Col>
                      <Col md="12" className="my-5">
                        {!editButton ? (
                          <ImageInput
                            className="fileDesign"
                            cssClass="vaccinationField"
                            title="Vaccination Certificate"
                            setSelectedImage={setVaccinationImage}
                            selectedImage={vaccinationImage}
                          />
                        ) : (
                          <div className="form-group-wrapper employeeImageView">
                            <label>Vaccination Certificate:</label>
                            {vaccinationImage &&  <ImageView imgSrc={vaccinationImage} />}
                          </div>
                        )}
                      </Col>
                      <Col md="12" className="my-5">
                        {!editButton ? (
                          <ImageInput
                            cssClass="vaccinationField"
                            title="External Test Image"
                            setSelectedImage={setExternalTestImage}
                            selectedImage={externalTestImage}
                          />
                        ) : (
                          <div className="form-group-wrapper employeeImageView">
                            <label>External Test Image:</label>
                           {externalTestImage && <ImageView imgSrc={externalTestImage} />}
                          </div>
                        )}
                      </Col>
                    </Col>
                  </Row>
                </Form>
                {tests && <TestView testData={tests} loader={loadingTestView}/>}
                
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={handleClose}>Close</Button>
                <div style={{ height: "3vh" }}>
                  {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
                </div>
              <div>
                <Button
                  className="mx-2"
                  variant={editButton ? "primary" : "secondary"}
                  onClick={() => handleEdit(!editButton)}
                >
                  {editButton ? "Edit" : "Cancel"}
                </Button>
                <Button className="mx-2" onClick={handleSaveChange} disabled={editButton}>
                  Save
                </Button>
                </div>
              </Modal.Footer>
              {showImageCroper && (
                <ImageCropModal selectedImage={copyImage} handleConfirm={handleCropDone}></ImageCropModal>
              )}
          </Modal>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default CrewProfile;
