import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import API from "api";
import { Bar, Line, Pie } from "react-chartjs-2";
import { SUMMARY_STATS_TYPE } from "constant";
import moment from "moment";
import { SUMMARY_TABLES_KEY_LABELS } from "constant";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, ArcElement, Title, Tooltip, Legend);

const DashboardLineChart = (props) => {

  const { statsType, connectedID, statTitle } = props;

  const [summaryResultData, setSummaryResultData] = useState([]);
  const [dateType, setDateType] = useState('month');
  const [labels, setGraphLabel] = useState([]);
  const [colors , setColors] = useState([  'rgb(72, 139, 194)' , 'rgb(64, 67, 153)' , 'rgb(120, 28, 129)' , 'rgb(107, 178, 140)', 'rgb(159, 190, 87)', 'rgb(210, 179, 63)', 'rgb(231, 126, 49)', 'rgb(217, 33, 23)' ,'rgb(210, 33, 12)' ,'rgb(117, 13, 12)' ,'rgb(227, 18, 23)' , 'rgb(217, 33, 62)' , 'rgb(717, 63, 52)' ]);
  const [legendcolor, setLegend] = useState([]);


  const loadGraphStats = async (statsSummary) => {
    try {

      let startDate = moment().startOf(dateType).format('YYYY-MM-DD');
      let endDate = "";
      let dates = [];
      let formatedDates = [];
      let graphlabels = [];
      if (dateType == "isoWeek") {
        endDate = moment().endOf(dateType).format('YYYY-MM-DD');
        for (var i = 0; i <= 6; i++) {
          dates.push(moment(startDate).add(i, 'days').format("DD/MM/YYYY"));
        };
        graphlabels = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun",];
      } else {
        endDate = moment().endOf(dateType).format('YYYY-MM-DD');
        var day = moment().format('D');
        for (var i = 0; i < Number(day); i++) {
          dates.push(moment(startDate).add(i, 'days').format("DD/MM/YYYY"));
          formatedDates.push(moment(startDate).add(i, 'days').format('DD'));
        };
        graphlabels = formatedDates;


      }

      const summaryStatsData = await API.getSummaryFromPG({ summary: statsSummary, startDate, endDate, id: connectedID });
      //console.log("summaryStatsData:", summaryStatsData.rows);


      //get Clients-wise Data
      const nameArray = [];
      summaryStatsData?.rows?.map(test => {
        if (!(nameArray.includes(test.name))) {
          nameArray.push(test.name);
        }

      });

      // console.log('nameArray:::' , nameArray);
      // console.log("dates::::" , dates);
      // console.log("formatedDates:" ,  formatedDates);

      let seriesDataArr = [];
      nameArray.forEach((nameElement) => {
        let tempSeriesArray = [];
        dates.forEach((dateElement, index) => {
          tempSeriesArray.push(parseInt(summaryStatsData?.rows?.find((test) => test.date === dateElement && (test.name === nameElement))?.count) || 0);

        });
        seriesDataArr.push({ data: tempSeriesArray, datalabel: nameElement || '' });

      });
      // console.log("seriesDataArr: " , seriesDataArr);



      let colorsArr = [];
      seriesDataArr?.map((data, index) => {
        colorsArr.push({ color: colors[index], label: data.datalabel });
      });

     // console.log("colorsArr::::", colorsArr);
      setLegend(colorsArr);
      setGraphLabel(graphlabels);
      setSummaryResultData(seriesDataArr);




    } catch (err) {
      console.log("Error ", err);
    }

  };

  useEffect(async () => {
    loadGraphStats(statsType);
  }, []);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: SUMMARY_STATS_TYPE[statsType],

      },
      legend: {
        display: false,
        labels: {
          usePointStyle: true,
          boxWidth: 8,

        }
      },



    },
  };



  const data = {
    labels,
    datasets: summaryResultData.map((data, index) => {
      return {
        label: data.datalabel || '',
        data: data.data,
        borderColor: colors[index],
        backgroundColor: colors[index],
        borderWidth: 1,
      }

    }),
  };


  return (
    <>
      <p className="fw-bold mb-1 text-center w-100">{statTitle}</p>
      <div className="d-flex align-items-center justify-content-center flex-wrap">
        {
          legendcolor.map((element) => {
            let color = element.color;
            return <span className="row heelo" title={element.label} style={{
              margin: "0.6px",
              height: "15px",
              width: "15px",
              backgroundColor: color,
              borderRadius: "3px",
              display: "inline-block",
              alignContent: 'center',

            }} value={element.label} >

          </span>
          })
        }
      </div>
      <Line options={options} data={data} />

    </>
  );
};

export default DashboardLineChart;

