import React, { useState, useEffect, useRef } from "react";
import {
  phoneFormatter,
  setPhoneNo,
  isValidPhone,
  medFlowInLocalStorage,
  formatZip,
  checkValidity,
  getValidName,
  isValidName,
  isValidIdNum,
  isValidPhoneWithCode,
  getPhoneNo,
} from "utils";
import { useHistory, Link } from "react-router-dom";
import logoImage from "assets/img/safecamp_BRAND.1-nobg.png";
import { newRegisterObj } from "constant";
import { QRCode } from "react-qrcode-logo";
import logo from "assets/img/logo.jpg";
import { Button } from "react-bootstrap";
import API from "api";
import html2canvas from "html2canvas";
import countriesOption from "countries";
import Select from "react-select";
import moment from "moment";
import { countryListAllIsoData } from "constant";
import Loader from "components/Loader/Loader";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { toTitleCase, convertToLower, isValidZipCode, validSpace } from "utils";
import SweetAlert from "react-bootstrap-sweetalert";
import SingleDatePicker from "Date/SingleDatePicker";
import PhoneNoInput from "components/Phone/PhoneNoInput";
import AutoCompleteAddress from "components/AutoCompleteAddress";
import { isValidEmail } from "utils";

const PreRegister = () => {
  const [newUser, setNewUser] = useState(newRegisterObj);
  const [registrationDone, setRegistrationDone] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [barcode, setBarcode] = useState("");
  const [updateClass, setUpdateClass] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [messageError, setMessageError] = useState("");
  const history = useHistory();
  const downloadRef = useRef();
  const redirectToAdmin = () => {
    if (history) history.push("/admin");
  };

  const isLoggedIn = medFlowInLocalStorage.get();
  if (isLoggedIn) redirectToAdmin();

  const showErrorMessage = (message) => {
    setError(message);
    setTimeout(() => setError(""), 2000);
  };
  console.log("newUSer", newUser);
  const handleUpdateAddress = (val, flag) => {
    if (!flag) {
      setNewUser({ ...newUser, street: val });
    } else {
      const addressObj = {
        street: val.street,
        country: val.country,
        city: val.city,
        zipcode: val.zipcode,
        state: val.state,
        isoCode: val.isoCode,
      };
      setNewUser({ ...newUser, ...addressObj });
    }
  };
  const handleSubmit = async () => {
    if (!newUser.first) {
      showErrorMessage("First name is required");
      return;
    }

    if (!isValidName(newUser.first)) {
      showErrorMessage("First name is invalid must contains at least 2 letters and Name should be realistic");
      return;
    }

    if (!newUser.last) {
      showErrorMessage("Last name is required");
      return;
    }
    if (!isValidName(newUser.last)) {
      showErrorMessage("Last name is invalid must contains at least 2 letters and Name should be realistic");
      return;
    }

    if (!newUser.email) {
      showErrorMessage("Email is required");
      return;
    }
    if (!isValidEmail(newUser.email)) {
      showErrorMessage("Invalid Email");
      return;
    }

    if (!newUser.phone_number) {
      showErrorMessage("Phone Number is required");
      return;
    }

    if (!isValidPhoneWithCode(newUser.phone_number)) {
      showErrorMessage("Invalid Phone Number");
      return;
    }
    if (!newUser.dob) {
      showErrorMessage("Date of Birth is required");
      return;
    }

    if (!newUser.street.trim()) {
      showErrorMessage("Address is required");
      return;
    }
    if (!newUser.city.trim()) {
      showErrorMessage("City is required");
      return;
    }

    if (!newUser.state.trim()) {
      showErrorMessage("State is required");
      return;
    }
    if (!newUser.country) {
      showErrorMessage("Country is Required");
      return;
    }
    if (newUser.zipcode === null || !newUser.zipcode) {
      showErrorMessage("Zipcode is required");
      return;
    }

    if (!isValidZipCode(newUser.isoCode || "US", newUser.zipcode)) {
      showErrorMessage("Invalid zipcode");
      return;
    }

    if (!newUser.id_number) {
      showErrorMessage("DL/Passport Number is required");
      return;
    }
    if (!isValidIdNum(newUser.id_number)) {
      showErrorMessage("Invalid DL/Passport Number must contains at least 5 characters");
      return;
    }
    if (!newUser.sex) {
      showErrorMessage("Gender is required");
      return;
    }
    if (newUser.isVaccinated === null) {
      showErrorMessage("Vaccinated is required");
      return;
    }
    try {
      setLoading(true);
      const obj = { ...newUser };
      const [countryCode, phoneNumber] = getPhoneNo(newUser.phone_number);
      obj.phone_number = phoneNumber;
      obj.countryCode = countryCode;
      newUser.id_number = checkValidity(newUser.id_number);
      setBarcode(JSON.parse(JSON.stringify(obj)));
      const response = await API.preRegisterEmployee(
        obj,
        window.location.href.includes("portal.safecamp") ||
          window.location.href.includes("staging.safecamp") ||
          window.location.href.includes("medflow")
          ? "LIVE"
          : "DEV"
      );
      setLoading(false);
      setRegistrationDone(true);
    } catch (err) {
      setLoading(false);
      setMessageError(err.message);
    }
  };

  console.log("newUSer", newUser);
  const downloadQRCode = async () => {
    const [countryCode, phoneNumber] = getPhoneNo(newUser.phone_number);
    const element = downloadRef.current;
    const canvas = await html2canvas(element);
    const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${phoneNumber}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  if (registrationDone) {
    return (
      <div className="codeScannerWrapper">
        <div className="registered-successful">
          <h3>Thank you for Registering</h3>
          <p>Please present this QR code at Event Check In</p>
          <div className="justify-content-center align-items-center pt-4">
            <div className="qrCodeWrapper" ref={downloadRef}>
              <QRCode
                ecLevel={"H"}
                value={barcode.phone_number}
                enableCORS={true}
                logoImage={logo}
                size="250"
                logoHeight={"70"}
                logoWidth={"70"}
                fgColor={"#000"}
                bgColor={"#fff"}
              />
              <div className="text-center fs-4 fw-bold qrSubdiv">{`${toTitleCase(barcode.first)} ${toTitleCase(
                barcode.last
              )}`}</div>
            </div>
            <div className={`qrCodeWrapper printQrCode`} id={"printableArea"}>
              <QRCode
                ecLevel={"H"}
                value={barcode.phone_number}
                enableCORS={true}
                logoImage={logo}
                size="250"
                logoHeight={"70"}
                logoWidth={"70"}
                fgColor={"#000"}
                bgColor={"#fff"}
              />
              <div className="text-center fs-4 fw-bold qrSubdiv">{`${toTitleCase(newUser.first)} ${toTitleCase(
                newUser.last
              )}`}</div>
            </div>
            <div className="downloadQrBtn">
              <div className="pt-3 d-flex align-items-center justify-content-evenly">
                <Button className="w-50" variant="secondary" onClick={downloadQRCode}>
                  Download QR Code
                </Button>
                <Button
                  href={`safecampmd://app/${barcode.first || ""}/${barcode.last || ""}/${barcode.email || ""}/${
                    barcode.phone_number || ""
                  }`}
                  variant="secondary"
                >
                  Add To Wallet
                </Button>
                <Button variant="secondary" onClick={() => window.print()}>
                  <i className="fas fa-print fs-4" style={{ color: "black" }} />
                </Button>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="registerFormWrapper">
          <div className="registerWrapper">
            <div className="loginInner">
              <div className="text-center">
                <img src={logoImage} alt="logo" className="logo-width" />
              </div>
              <h4 className="w-100 text-center mt-4 fs-4 fw-bold">Register your account</h4>
              <div className="form-wrapper">
                <div className="form-group-wrapper mt-2">
                  <label className="mb-1">First Name:</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First Name"
                    value={newUser.first}
                    onChange={(e) => setNewUser({ ...newUser, first: getValidName(e.target.value) })}
                  />
                </div>
                <div className="form-group-wrapper ">
                  <label className="mb-1">Last Name:</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last Name"
                    value={newUser.last}
                    onChange={(e) => setNewUser({ ...newUser, last: getValidName(e.target.value) })}
                  />
                </div>
                <div className="form-group-wrapper ">
                  <label className="mb-1">Email:</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Email"
                    value={newUser.email}
                    onChange={(e) => setNewUser({ ...newUser, email: convertToLower(e.target.value) })}
                  />
                </div>
                <div className="form-group-wrapper ">
                  <label className="mb-1">Phone Number:</label>
                  <PhoneNoInput
                    error={setPhoneError}
                    handleChange={(e) => {
                      setNewUser({
                        ...newUser,
                        phone_number: e,
                      });
                    }}
                    value={newUser.phone_number}
                  />
                  {/* <input
                    value={phoneFormatter(newUser.phone_number)}
                    type="text"
                    maxLength={15}
                    className="form-control"
                    placeholder="Phone Number"
                    onChange={(e) =>
                      setNewUser({
                        ...newUser,
                        phone_number: setPhoneNo(phoneFormatter(e.target.value)),
                      })
                    }
                  /> */}
                </div>
                <div className="form-group-wrapper ">
                  <label className="modalLineHeaders">DOB: </label>
                  <SingleDatePicker
                    onApply={(event, picker) => {
                      setNewUser({ ...newUser, dob: moment(picker.startDate).format("YYYY-MM-DD") });
                    }}
                    value={newUser.dob}
                    currentDate={true}
                  />
                </div>
                <div className="form-group-wrapper">
                  <label className="mb-1">Address:</label>
                  <AutoCompleteAddress value={newUser.street} handleChange={handleUpdateAddress} />
                </div>
                <div className="form-group-wrapper">
                  <label className="mb-1">City:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={newUser.city}
                    placeholder="City"
                    onChange={(e) => setNewUser({ ...newUser, city: e.target.value })}
                  />
                </div>
                <div className="form-group-wrapper">
                  <label className="mb-1">State:</label>
                  <input
                    type="text"
                    className="form-control stateField"
                    placeholder="State Abbr: XX"
                    value={newUser.state}
                    maxLength={35}
                    onChange={(e) => {
                      let val = e.target.value;
                      if (val) val = val.toUpperCase();
                      setNewUser({ ...newUser, state: validSpace(val) });
                    }}
                  />
                </div>
                <div className="form-group-wrapper ">
                  <label className="mb-1">Country:</label>
                  <Select
                    options={countriesOption}
                    blurInputOnSelect={true}
                    value={{ label: newUser.country, value: newUser.country }}
                    menuPlacement="auto"
                    placeholder="Select Country"
                    className="w-100"
                    onChange={(e) => {
                      const country = countryListAllIsoData.find((f) => f.name === e.value) || { code: "US" };
                      setNewUser({
                        ...newUser,
                        country: e.value,
                        isoCode: country.code,
                      });
                    }}
                  />
                </div>
                <div className="form-group-wrapper">
                  <label className="mb-1">Zip Code:</label>
                  <input
                    value={newUser.isoCode === "US" ? formatZip(newUser.zipcode) : newUser.zipcode}
                    type="text"
                    className="form-control"
                    placeholder="Enter Zip Code"
                    maxLength={10}
                    onChange={(e) => {
                      let val = e.target.value;
                      if (val) val = val.toUpperCase();
                      setNewUser({
                        ...newUser,
                        zipcode: newUser.isoCode === "US" ? formatZip(val) : validSpace(val),
                      });
                    }}
                  />
                </div>
                <div className="form-group-wrapper ">
                  <label className="mb-1">DL/Passport Number:</label>
                  <input
                    maxLength={16}
                    type="text"
                    className="form-control"
                    value={newUser.id_number}
                    placeholder="DL/Passport Number"
                    onChange={(e) => setNewUser({ ...newUser, id_number: e.target.value })}
                  />
                </div>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Gender: </label>
                  <div>
                    <span className="radioBox-wrapper">
                      <input
                        type="radio"
                        className="radioButton"
                        name="sex"
                        onChange={(e) => setNewUser({ ...newUser, sex: e.target.checked ? "M" : "" })}
                      />
                      <span className="radioButton">Male</span>
                    </span>
                    <span className="radioBox-wrapper">
                      <input
                        type="radio"
                        className="radioButton"
                        name="sex"
                        onChange={(e) => setNewUser({ ...newUser, sex: e.target.checked ? "F" : "" })}
                      />
                      <span className="radioButton">Female</span>
                    </span>
                    <span className="radioBox-wrapper">
                      <input
                        type="radio"
                        className="radioButton"
                        name="sex"
                        onChange={(e) => setNewUser({ ...newUser, sex: e.target.checked ? "X" : "" })}
                      />
                      <span className="radioButton">Not Specified</span>
                    </span>
                  </div>
                </div>

                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Vaccinated: </label>
                  <div>
                    <span className="radioBox-wrapper">
                      <input
                        type="radio"
                        className="radioButton"
                        name="isVaccinated"
                        onChange={(e) => setNewUser({ ...newUser, isVaccinated: e.target.checked && true })}
                      />
                      <span className="radioButton">Yes</span>
                    </span>
                    <span className="radioBox-wrapper">
                      <input
                        type="radio"
                        className="radioButton"
                        name="isVaccinated"
                        onChange={(e) => setNewUser({ ...newUser, isVaccinated: e.target.checked && false })}
                      />
                      <span className="radioButton">No</span>
                    </span>
                  </div>
                </div>
              </div>
              {error && (
                <div className="error-message">
                  <h6>{error}</h6>
                </div>
              )}
              {messageError && (
                <SweetAlert
                  show={messageError ? true : false}
                  error
                  title="Error"
                  onConfirm={() => setMessageError("")}
                >
                  {messageError}
                </SweetAlert>
              )}

              <div className="mt-2">
                <button type="submit" className="text-uppercase w-100 btn signup-btn" onClick={handleSubmit}>
                  Pre-register
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PreRegister;
