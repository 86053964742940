import React from "react";
import { formatPhoneNumber, parseBooleanValue, formatDOB } from "utils";
import moment from "moment";
import { Button, Form, Modal, Table } from "react-bootstrap";
import ViewCheckBox from "../CheckBox/ViewCheckBox";
import ViewOnlyCheckBox from "../CheckBox/ViewOnlyCheckBox";

const ProfileTableItem = (props) => {
  const { user, userNotToDelete, setUserNotToDelete, showSelection, updateDatePoint } = props;
  const replaceData = (key, value) => {
    if (updateDatePoint) updateDatePoint(key, value);
  };
  return (
    <tr key={user.id}>
      <td className="ellipsis" title={user.firstName} onClick={() => replaceData("firstName", user.firstName)}>
        {user.firstName}
      </td>
      <td className="ellipsis" title={user.lastName} onClick={() => replaceData("lastName", user.lastName)}>
        {user.lastName}
      </td>
      <td
        className="ellipsis"
        style={{
          textDecoration: "underline",
          color: "#A82632",
        }}
        onMouseOver={(e) => {
          e.target.style.cursor = "pointer";
          e.target.style.textDecoration = "none";
        }}
        onMouseLeave={(e) => {
          e.target.style.textDecoration = "underline";
        }}
        title={user.email}
        onClick={() => replaceData("email", user.email)}
      >
        {user.email}
      </td>
      <td
        className="ellipsis"
        style={{ textDecoration: "underline", color: "#A82632" }}
        onMouseOver={(e) => {
          e.target.style.cursor = "pointer";
          e.target.style.textDecoration = "none";
        }}
        onMouseLeave={(e) => {
          e.target.style.textDecoration = "underline";
        }}
        title={formatPhoneNumber(user.phoneNumber)}
        onClick={() => replaceData("phoneNumber", user.phoneNumber)}
      >
        {formatPhoneNumber(user.phoneNumber)}
      </td>

      <td
        className="ellipsis"
        title={formatDOB(user.dob)}
        style={{ textAlign: "center" }}
        onClick={() => replaceData("dob", user.dob)}
      >
        {formatDOB(user.dob)}
      </td>
      <td
        className="ellipsis"
        title={user?.street}
        style={{ textAlign: "center" }}
        onClick={() => replaceData("street", user)}
      >
        {user?.street}
      </td>
      <td
        className="ellipsis"
        title={user?.id_number}
        style={{ textAlign: "center" }}
        onClick={() => replaceData("id_number", user.id_number)}
      >
        {user?.id_number}
      </td>
      <td className="ellipsis" title={user?.testCount} style={{ textAlign: "center" }}>
        {user?.totalTest}
      </td>
      <td className="ellipsis" title={user?.site_name} style={{ textAlign: "center" }}>
        {user?.site_name}
      </td>
      <td className="ellipsis" style={{ textAlign: "center" }}>
        {user?.lastUsed && user?.lastUsed !== "null" ? moment(user?.lastUsed).format("MM-DD-YYYY HH:mm") : "-"}
      </td>
      <td style={{ textAlign: "center" }} className="ellipsis">
        {showSelection && (
            <a style={{color: 'rgb(66, 206, 245)'}} className="cursor-pointer" onClick={(e) => setUserNotToDelete({...user})}>Select</a>
        )}
      </td>
    </tr>
  );
};

export default ProfileTableItem;
