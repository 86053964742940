import React, { useState, useContext, useMemo, useRef } from "react";
import { Button, Card, Table, Row, Col, Modal } from "react-bootstrap";
import { useEffect } from "react";
import API from "api";
import { Link } from "react-router-dom";
import { Bounce } from "react-activity";
import ErrorMessage from "components/Message/ErrorMessage";
import "react-activity/dist/Bounce.css";
import Hipaa from "views/Hipaa";
import Filter from "components/Filter";
import TimeFilter from "components/TimeFilter";
import { AppContext } from "context/app-context";
import { PAGE_LIMIT } from "constant";
import Select from "react-select";
import MFPagination from "components/Pagination/MFPagination";
import HeaderItem from "components/Table/HeaderItem";
import {
  changeSort,
  isValidPhone,
  formatPhoneNumber,
  phoneFormatter,
  parseBooleanValue,
  calculateTdWidth,
  sortingFilterInLC,
} from "utils";
import PhoneNoInput from "components/Phone/PhoneNoInput";
import Loader from "../../components/Loader/Loader";

const ClientsView = () => {
  const componentRef = useRef();
  const mainWidth = useRef();
  const [width, setWidth] = useState(0);
  const [error, setError] = useState("");
  useEffect(() => {
    console.log("width", componentRef.current.offsetWidth);
    setWidth(componentRef.current.offsetWidth);
  }, [componentRef]);

  const handleResize = () => {
    console.log("width handle Resize", componentRef.current.offsetWidth);
    if (componentRef.current) setWidth(componentRef.current.offsetWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => handleResize(), [mainWidth]);

  const tdWidth = calculateTdWidth(width - 100, 8);
  const appContext = useContext(AppContext);

  const [clientTests, setClientTests] = useState([]);
  const [clients, setClients] = useState(appContext.clients);
  const [filteredClients, setFilteredClients] = useState(appContext.clients);
  const [openCreator, setOpenCreator] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [newClient, setNewClient] = useState({
    isNew: true,
    showOnSummaryScreen: false,
    whiteLabel: false,
    isArchive: false,
  });
  const [clientToDelete, setClientToDelete] = useState("");
  const [openHipaa, setOpenHipaa] = useState(false);
  const [selectedClient, setSelectedClient] = useState({});
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [loading, setLoading] = useState();
  const [timeFilter, setTimeFilter] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { filterDates } = TimeFilter;

  const searchTerms = ["Name", "Contact Name", "Contact Email", "Contact Phone", "Result Type", "White Label"];
  const resultTypeOptions = [
    { value: "P/N", label: "P/N" },
    { value: "P/F", label: "P/F" },
  ];
  const handleClose = () => {
    setOpenCreator(false);
    setOpenDeleteConfirmation(false);
    setOpenHipaa(false);
    setSelectedClient({});
  };

  const createClient = async () => {
    try {
      setLoading(true);
      const newest = await API.createClient(newClient);
      setNewClient({ isNew: true, showOnSummaryScreen: false, whiteLabel: false, isArchive: false });
      setLoading(true);
      setTimeout(() => appContext.resetClients(), 3000);
      appContext.showSuccessMessage("Client created successfully");
    } catch (error) {
      console.log("this is the error", error);
      appContext.showErrorMessage(error.message);
    }
  };

  const deleteClient = async (id) => {
    try {
      await API.deleteClient(id);
      appContext.resetClients();
      appContext.showSuccessMessage("Client removed successfully");
    } catch (error) {
      appContext.showErrorMessage(error.message);
    }
  };

  const updateClient = async () => {
    try {
      setLoading(true);
      const update = await API.updateClient(newClient);
      setNewClient({ isNew: true, showOnSummaryScreen: false, whiteLabel: false, isArchive: false });
      appContext.resetClients();
      appContext.showSuccessMessage("Client Updated successfully");
    } catch (error) {
      appContext.showErrorMessage(error.message);
    }
    setLoading(false);
  };

  const clientsToMap = useMemo(() => {
    const list = filterDates(filteredClients, timeFilter);
    const ttlPages = list.length > PAGE_LIMIT ? Math.ceil(list.length / PAGE_LIMIT) : 1;
    setTotalPages(ttlPages);
    if (list.length < PAGE_LIMIT || currentPage > ttlPages) {
      setCurrentPage(1);
    }
    if (ttlPages > 1) {
      const indexOfLastUser = currentPage * PAGE_LIMIT;
      const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
      return list.slice(indexOfFirstUser, indexOfLastUser);
    }
    return list;
  }, [filteredClients, currentPage, timeFilter]);

  const parseSelectedOption = (id) => {
    if (!id) return null;
    const data = resultTypeOptions.find((f) => f.value === id);
    if (data) return data.label;
    return id;
  };

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filter).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length) {
          return true;
        }
        if (key === "resultType") {
          return obj[key] && parseSelectedOption(obj[key]).toLowerCase().includes(filters[key]);
        }
        if (key === "whiteLabel") {
          return parseBooleanValue(obj[key]).toLowerCase().includes(filters[key]?.toLowerCase());
        }
        return obj[key] && obj[key].toLowerCase().includes(filters[key]);
      });
    });
    // console.log("FROM NESTED FILTER", models);
    return models;
  };

  useEffect(() => {
    const sortingFilters = sortingFilterInLC.get();
    if (sortingFilters.clients.sortBy) {
      setSortDescending(sortingFilters.clients.sortDescending);
      setSortBy(sortingFilters.clients.sortBy);
    }
    loadClientSummary();
  }, []);

  useEffect(() => {
    setLoading(true);
    setClients(appContext.clients);
    refreshSummary(appContext.clients, clientTests);
    setLoading(false);
  }, [appContext.clients]);

  useEffect(() => {
    const prevSortingState = sortingFilterInLC.get();
    prevSortingState["clients"] = { filter, timeFilter, sortBy, sortDescending };
    sortingFilterInLC.save(prevSortingState);
    changeSort(
      sortBy,
      sortDescending,
      filteredClients,
      setFilteredClients,
      appContext.getSiteName,
      appContext.getLabName,
      appContext.getClientName,
      appContext.getClientTests
    );
  }, [sortBy, sortDescending]);

  useEffect(() => {
    setFilteredClients(filterDates(nestedFilter(clients, filter), timeFilter));
  }, [filter]);

  useEffect(() => {
    refreshSummary(clients, clientTests);
  }, [clientTests]);

  const loadClientSummary = async () => {
    const clientSummary = await API.getSummaryFromPG({ summary: "client" });
    setClientTests(clientSummary.rows);
  };

  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(!sortDescending);
  };

  const refreshSummary = (data, summary) => {
    
    if (data.length === 0) return;
    if (summary.length === 0) {
      setFilteredClients(data);
      return;
    }
    const newList = data.map((c) => {
      const obj = summary.find((x) => x.id === c.id);
      return { ...c, totalTest: obj ? obj.totalTest : 0 };
    });
    setFilteredClients(newList);
  };

  const handleFilterChange = (event, picker) => setTimeFilter({ start: picker.startDate, end: picker.endDate });
  const handleFilterCancel = (event, picker) => setTimeFilter({});
  const handleChangePage = (page) => setCurrentPage(page);
  const handleSave = () => {
    if (!newClient.name) {
      setError("Client name is required");
      return false;
    }

    if (!newClient.contact) {
      setError("Client contact is required");
      return false;
    }

    if (!newClient.phone) {
      setError("Client Phone is required");
      return false;
    }

    if (!newClient.email) {
      setError("Client email is required");
      return false;
    }

    if (!newClient.resultType) {
      setError("Please select the result type");
      return false;
    }

    if (!isValidPhone(newClient.phone)) {
      setError("Invalid Phone Number");
      return false;
    }

    if (newClient.isNew) createClient();
    else updateClient();

    handleClose();
  };
  const getSelectedOption = (id) => {
    if (!id) return null;
    const data = resultTypeOptions.find((f) => f.value === id);
    if (data) return data;
    return { value: id, label: id };
  };

  const getTotalTest = (client) => {
    if (!client.totalTest || client.totalTest === 0) {
      return <>0</>;
    }
    return (
      <Link
        style={{ color: "#42cef5" }}
        to={{
          pathname: `/admin/shows`,
          state: { name: client.name, term: "Client" },
        }}
      >
        {client.totalTest}
      </Link>
    );
  };

  const TableRow = ({ client }) => {
    return (
      <tr>
        <td className="icon" style={{ width: "50px", minWidth: "50px", maxWidth: "50px" }}>
          <i
            className="fas fa-edit"
            onMouseLeave={(e) => (e.target.style.color = "black")}
            onMouseOver={(e) => {
              e.target.style.color = "blue";
              e.target.style.cursor = "pointer";
            }}
            aria-hidden="true"
            id={client.id}
            onClick={(e) => {
              setNewClient({
                isNew: false,
                id: client.id,
                name: client.name,
                contact: client.contact_name,
                phone: client.contact_phone || client.phone_number,
                email: client.contact_email,
                resultType: client.resultType,
                showOnSummaryScreen: client.showOnSummaryScreen || false,
                whiteLabel: client.whiteLabel,
                whiteLabelPackage: client.whiteLabelPackage,
                isArchive: client.isArchive,
              });
              setOpenCreator(true);
            }}
          />
        </td>
        <td className="ellipsis" title={client.name}>
          {client.name}
        </td>
        <td className="ellipsis" title={client.contact_name}>
          {client.contact_name}
        </td>
        <td
          className="ellipsis"
          style={{
            textDecoration: "underline",
            color: "#A82632",
          }}
          onMouseOver={(e) => {
            e.target.style.cursor = "pointer";
            e.target.style.textDecoration = "none";
          }}
          onMouseLeave={(e) => {
            e.target.style.textDecoration = "underline";
          }}
          onClick={() => client.contact_email && window.open(`mailto:${client.contact_email}`)}
          title={client.contact_email}
        >
          {client.contact_email}
        </td>
        <td
          className="ellipsis"
          style={{
            textDecoration: "underline",
            color: "#A82632",
          }}
          onMouseOver={(e) => {
            e.target.style.cursor = "pointer";
            e.target.style.textDecoration = "underline";
          }}
          onClick={() => client.contact_phone && window.open(`tel:+${client.contact_phone.replace(/\D/g, "")}`)}
          title={client.contact_phone && formatPhoneNumber(client.contact_phone)}
        >
          {(client.contact_phone || client.phone_number) &&
            formatPhoneNumber(client.contact_phone || client.phone_number)}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          {getTotalTest(client)}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          <span title={parseSelectedOption(client.resultType)}>{parseSelectedOption(client.resultType)}</span>
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          <span title={parseBooleanValue(client.whiteLabel)}>{parseBooleanValue(client.whiteLabel)}</span>
        </td>
        <td>
          <a
            style={{ textDecoration: "underline", color: "#42cef5", cursor: "pointer" }}
            onMouseOver={(e) => (e.target.style.cursor = "pointer")}
            onClick={() => {
              setSelectedClient(client);
              setOpenHipaa(true);
            }}
          >
            View/Update
          </a>
        </td>
        <td className="icon">
          <i
            className="fa fa-trash"
            onMouseLeave={(e) => (e.target.style.color = "black")}
            onMouseOver={(e) => {
              e.target.style.color = "#8B0000";
              e.target.style.cursor = "pointer";
            }}
            aria-hidden="true"
            id={client.id}
            onClick={(e) => (setOpenDeleteConfirmation(true), setClientToDelete(e.target.id))}
          />
        </td>
      </tr>
    );
  };

  console.log("Client", newClient);

  return (
    <div style={{ flex: 1 }}>
      {!loading ? (
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Row>
                  <Card.Title
                    as="h4"
                    style={{
                      marginBottom: 10,
                      fontWeight: "bold",
                      color: "#565656",
                    }}
                  >
                    Clients ({clientsToMap.length})
                  </Card.Title>
                  <div className="buttonHeader clientsButtonHeader justify-content-between mb-1">
                    <div className="clientsBtnWrapper">
                      <div className="buttonHeader clientsButtonHeader">
                        <Button className="headerButton mb-0" onClick={() => setShowFilter(!showFilter)}>
                          Filter
                        </Button>
                      </div>
                    </div>
                    <Button className="createButtons" onClick={() => setOpenCreator(true)}>
                      Create New Client
                    </Button>
                  </div>
                  <div>{showFilter && <Filter filterTerms={searchTerms} setFilter={setFilter} filter={filter} />}</div>
                  <Col>
                    <MFPagination
                      currentPage={currentPage}
                      handleChangePage={handleChangePage}
                      totalPages={totalPages}
                    />
                  </Col>
                </Row>
              </Card.Header>

              <Card.Body className="table-full-width px-0 desktop-noScroll">
                <div className="table-responsive pendingReleaseTable">
                  <Table className="table table-hover table-striped">
                    <thead ref={componentRef}>
                      <tr>
                        <th></th>
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="name"
                          title="Name"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          cssClass="tdWidth12"
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="contact_name"
                          title="Contact Name"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          cssClass="tdWidth12"
                        />

                        <HeaderItem
                          width={tdWidth}
                          ItemKey="contact_email"
                          title="Contact's Email"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          cssClass="tdWidth18"
                        />

                        <HeaderItem
                          width={tdWidth}
                          ItemKey="contact_phone"
                          title="Contact's Phone"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />

                        <HeaderItem
                          width={tdWidth}
                          ItemKey="totalTests"
                          title="Total Tests (YTD)"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />

                        <HeaderItem
                          width={tdWidth}
                          ItemKey="resultType"
                          title="Result Type"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="whiteLabel"
                          title="White Label"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />

                        <th
                          className="text-grey"
                          style={{ fontWeight: "bold", width: tdWidth, minWidth: tdWidth, maxWidth: tdWidth }}
                        >
                          <div>
                            <span tile="Hippa">Hippa</span>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {clientsToMap.map((client, i) => {
                        return <TableRow key={i} client={client} />;
                      })}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <Loader />
      )}

      <Modal
        show={openCreator}
        // style={{ transform: "translate(0, -30%)" }}
        animation={true}
        onHide={() => {
          setNewClient({ isNew: true, showOnSummaryScreen: false, whiteLabel: false, isArchive: false });
          handleClose();
        }}
        centered
        size={"md"}
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            {newClient.isNew ? "Create Client" : "Update Client"}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ paddingTop: 0 }}>
          <div className="form-group-wrapper">
            <label className="modalLineHeaders">Client Name: </label>
            <input
              value={newClient.name}
              className="modalInput"
              placeholder="Client Name"
              style={{ width: "100%" }}
              onChange={(e) => setNewClient({ ...newClient, name: e.target.value })}
            />
          </div>
          <div className="form-group-wrapper">
            <label className="modalLineHeaders">Contact Name: </label>
            <input
              value={newClient.contact}
              className="modalInput"
              placeholder="Primary Contact Name"
              style={{ width: "100%" }}
              onChange={(e) => setNewClient({ ...newClient, contact: e.target.value })}
            />
          </div>
          <div className="form-group-wrapper">
            <label className="modalLineHeaders">Phone #: </label>

            <PhoneNoInput
              value={newClient.phone}
              handleChange={(e) => setNewClient({ ...newClient, phone: e })}
              cssCode={"w-100"}
            />
          </div>

          <div className="form-group-wrapper">
            <label className="modalLineHeaders">Email: </label>
            <input
              value={newClient.email}
              className="modalInput"
              placeholder="Email"
              style={{ width: "100%" }}
              onChange={(e) => setNewClient({ ...newClient, email: e.target.value })}
            />
          </div>
          <div className="form-group-wrapper">
            <label className="modalLineHeaders">Result View Type: </label>
            <Select
              options={resultTypeOptions}
              blurInputOnSelect={true}
              defaultValue={null}
              menuPlacement="auto"
              placeholder="Select"
              value={getSelectedOption(newClient.resultType)}
              onChange={(e) => setNewClient({ ...newClient, resultType: e.value })}
              className="w-100 siteSelector"
            />
          </div>
          <div className="form-group-wrapper">
            <label className="modalLineHeaders">Exclude from Summary: </label>
            <div>
              <span className="labelYesNo">{parseBooleanValue(newClient.showOnSummaryScreen || false)}</span>
              <input
                checked={newClient.showOnSummaryScreen}
                onChange={(e) => setNewClient({ ...newClient, showOnSummaryScreen: !newClient.showOnSummaryScreen })}
                type="checkbox"
              />
            </div>
          </div>
          <div className="form-group-wrapper">
            <label className="modalLineHeaders">Archive: </label>
            <div>
              <span className="labelYesNo">{parseBooleanValue(newClient.isArchive || false)}</span>
              <input
                checked={newClient.isArchive}
                onChange={(e) => setNewClient({ ...newClient, isArchive: !newClient.isArchive })}
                type="checkbox"
              />
            </div>
          </div>
          <div className="form-group-wrapper">
            <label className="modalLineHeaders">White Label: </label>
            <div>
              <span className="labelYesNo">{parseBooleanValue(newClient.whiteLabel || false)}</span>
              <input
                checked={newClient.whiteLabel}
                onChange={(e) => setNewClient({ ...newClient, whiteLabel: !newClient.whiteLabel })}
                type="checkbox"
              />
            </div>
          </div>
          {newClient.whiteLabel && (
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Package Name: </label>
              <input
                value={newClient.whiteLabelPackage}
                className="modalInput"
                placeholder="Package Name"
                style={{ width: "100%" }}
                onChange={(e) => setNewClient({ ...newClient, whiteLabelPackage: e.target.value })}
              />
            </div>
          )}
          {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="modalButtons"
            variant="secondary"
            onClick={() => {
              setNewClient("");
              handleClose();
            }}
          >
            Close
          </Button>
          <Button className="modalButtons" variant="primary" onClick={() => handleSave()}>
            {newClient.isNew ? "Create" : "Update"}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={openDeleteConfirmation}
        // style={{ transform: "translate(0, -30%)" }}
        animation={true}
        onHide={() => {
          handleClose();
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            Delete Client
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ paddingTop: 0, marginRight: "auto", marginLeft: "auto" }}>
          <Modal.Title id="contained-modal-title-vcenter">Are you sure you want to delete this client?</Modal.Title>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="primary"
            className="modalButtons"
            onClick={() => {
              handleClose();
            }}
          >
            Go back
          </Button>
          <Button
            variant="danger"
            className="modalButtons"
            onClick={() => {
              deleteClient(clientToDelete);
              handleClose();
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      {selectedClient && (
        <Hipaa
          openHipaa={openHipaa}
          handleClose={handleClose}
          client={selectedClient}
          resetClients={appContext.resetClients}
        />
      )}
    </div>
  );
};

export default ClientsView;
