import React from "react";

const ClientDashboardCard = (props) => {
  const { title, value, imgSrc } = props;

  return (
    <div className="card-dashboard-wrapper">
      <div className="card-dashboard-inner">
        <div className="content-wrapper">
          <h6>{title}</h6>
          <h4>{value}</h4>
        </div>
        <div className="image-wrapper">
          <img src={imgSrc} alt="card image" />
        </div>
      </div>
    </div>
  );
};

export default ClientDashboardCard;
