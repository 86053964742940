import React, { useState, useEffect, useContext, useMemo, useRef } from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  DropdownButton,
  Dropdown,
  Modal,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { Bounce } from "react-activity";
import "react-activity/dist/Bounce.css";
import HeaderItem from "components/Table/HeaderItem";
import MFPagination from "components/Pagination/MFPagination";
import { AppContext } from "context/app-context";
import { PAGE_LIMIT, RESULT_COLOR, TOTAL_TEST_HEADER } from "../constant";
import {
  changeSort,
  formatDateOfBirthDOB,
  formatPhoneNumber,
  calculateTdWidth,
  formatUTCDate,
  formatTest,
} from "../utils";
import ExportToExcel from "components/ExportToExcel";
import Loader from "./Loader/Loader";

const TestView = (props) => {
  const { testData, loader, handleResultPDF } = props;
  const componentRef = useRef();
  const [width, setWidth] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  const appContext = useContext(AppContext);
  useEffect(() => {
    setWidth(componentRef.current?.offsetWidth);
  }, [componentRef]);

  const handleResize = () => {
    if (componentRef.current) setWidth(componentRef.current?.offsetWidth);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  useEffect(() => {
    setTimeout(() => {
      setErrorMessage("");
    }, 4000);
  }, [errorMessage]);

  const tdWidth = calculateTdWidth(width, 11);

  const importResult = [];
  const [tests, setTests] = useState(testData);
  const [filteredTests, setFilteredTests] = useState(testData);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    setFilteredTests(testData);
  }, [testData]);

  const selectedItem = [
    "createdAt",
    "firstName",
    "lastName",
    "dob",
    "phoneNumber",
    "email",
    "siteID",
    "sequenceNo",
    "status",
    "result",
    "test_type",
  ];

  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(sortBy === by ? !sortDescending : true);
  };

  const testsToMap = useMemo(() => {
    const list = filteredTests ? [...filteredTests] : [];
    const ttlPages = list.length > PAGE_LIMIT ? Math.ceil(list.length / PAGE_LIMIT) : 1;
    setTotalPages(ttlPages);
    if (list.length < PAGE_LIMIT || currentPage > ttlPages) {
      setCurrentPage(1);
    }
    if (ttlPages > 1) {
      const indexOfLastUser = currentPage * PAGE_LIMIT;
      const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
      return list.slice(indexOfFirstUser, indexOfLastUser);
    }
    return list;
  }, [filteredTests, currentPage]);

  useEffect(() => {
    changeSort(
      sortBy,
      sortDescending,
      filteredTests,
      setFilteredTests,
      appContext.getSiteName,
      appContext.getLabName,
      appContext.getClientName,
      appContext.parseResult
    );
  }, [sortBy, sortDescending]);

  const handleChangePage = (page) => setCurrentPage(page);

  const TableRow = ({ test }) => {
    const { employee_demographics } = test;
    return (
      <tr>
        <td className="ellipsis" title={formatUTCDate(test.createdAt)}>
          {formatUTCDate(test.createdAt)}
        </td>
        <td style={{ textAlign: "center" }} className="ellipsis">
          {appContext.getSiteName(test.siteID)}
        </td>
        <td className="table-column ellipsis" style={{ textAlign: "center" }}>
          {appContext.getLabName(test.labID)}
        </td>
        <td style={{ textAlign: "center" }} className="ellipsis">
          {test.status}
        </td>
        <td style={{ textAlign: "center" }}>
          <span
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
              e.target.style.textDecoration = "none";
            }}
            onMouseLeave={(e) => {
              e.target.style.textDecoration = "underline";
            }}
            style={{
              color: RESULT_COLOR[test.result?.toLowerCase()],
              textDecoration: "underline",
            }}
            onClick={() => handleResultPDF(test)}
          >
            {appContext.parseResult(test)}
          </span>
        </td>
        <td style={{ textAlign: "center" }}>{test.sequenceNo}</td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          {test.test_type || "-"}
        </td>
      </tr>
    );
  };

  return (
    <>
      {loader ? (
        <Loader cssClass="horizontalLoader" />
      ) : (
        <Row>
          <Col md="12">
            <Card className="striped-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                    color: "#565656",
                  }}
                >
                  Total Tests ({filteredTests.length})
                </Card.Title>
                <MFPagination currentPage={currentPage} handleChangePage={handleChangePage} totalPages={totalPages} />
              </Card.Header>

              <Card.Body className="table-full-width px-0 desktop-noScroll">
                <div className="table-responsive pendingReleaseTable overFlow-y-hidden">
                  <Table className="table-hover table-striped">
                    <thead ref={componentRef}>
                      <tr>
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="createdAt"
                          title="Date Tested"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="siteID"
                          title="Show"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="labID"
                          title="Lab"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />

                        <HeaderItem
                          width={tdWidth}
                          ItemKey="status"
                          title="Status"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />

                        <HeaderItem
                          width={tdWidth}
                          ItemKey="result"
                          title="Result"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="sequenceNo"
                          title="Sequence"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="test_type"
                          title="Test Type"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                      </tr>
                    </thead>
                    <tbody>
                      {testsToMap &&
                        testsToMap.map((test) => {
                          return <TableRow key={test.id} test={test} />;
                        })}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};

export default TestView;
