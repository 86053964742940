import React, { useEffect, useState } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,

} from "chart.js";

import API from "api";
import { Bar , Line , Pie} from "react-chartjs-2";
import { SUMMARY_STATS_TYPE } from "constant";
import moment from "moment";


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
);

const DashboardBarChart = (props) => {


  const { statsType, connectedID } = props;
  
    const [ summaryResultData ,  setSummaryResultData] = useState([]);
    const [dateType , setDateType] = useState('month');
    const [labels , setGraphLabel] = useState([]);
    const [colors , setColors] = useState([  'rgb(72, 139, 194)' , 'rgb(64, 67, 153)' , 'rgb(120, 28, 129)' , 'rgb(107, 178, 140)', 'rgb(159, 190, 87)', 'rgb(210, 179, 63)', 'rgb(231, 126, 49)', 'rgb(217, 33, 23)' ,'rgb(210, 33, 12)' ,'rgb(117, 13, 12)' ,'rgb(227, 18, 23)' , 'rgb(217, 33, 62)' , 'rgb(717, 63, 52)' ]);
    const [legendcolor, setLegend] = useState([]);

    

    const loadGraphStats = async (statsSummary) => {
      try {
  
        let startDate = moment().startOf(dateType).format('YYYY-MM-DD');
        let endDate = "";
        let dates = [];
        let formatedDates = [];
        let graphlabels = [];
        if (dateType == "isoWeek") {
          endDate = moment().endOf(dateType).format('YYYY-MM-DD');
          for (var i = 0; i <= 6; i++) {
            dates.push(moment(startDate).add(i, 'days').format("DD/MM/YYYY"));
          };
          graphlabels = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun",];
        } else {
          endDate = moment().endOf(dateType).format('YYYY-MM-DD');
          var day = moment().format('D');
          for (var i = 0; i < Number(day); i++) {
            dates.push(moment(startDate).add(i, 'days').format("DD/MM/YYYY"));
            formatedDates.push(moment(startDate).add(i, 'days').format('DD-MMM'));
          };
          graphlabels = formatedDates;
  
  
        }
  
        const summaryStatsData = await API.getSummaryFromPG({ summary: statsSummary, startDate, endDate, id: connectedID });
        //console.log("summaryStatsData:", summaryStatsData.rows);
  
  
        //get Clients-wise Data
        const nameArray = [];
        summaryStatsData?.rows?.map(test => {
          if (!(nameArray.includes(test.name))) {
            nameArray.push(test.name);
          }
  
        });
  
        let seriesDataArr = [];

          let tempSeriesArray = [];
          let dateElementLabel= ""

             dates.forEach((dateElement, index) => {
             tempSeriesArray.push(summaryStatsData?.rows?.filter((test) => test.date == dateElement).reduce((ttl , obj) => ttl + Number(obj.count) , 0)  || 0) ;
             
          });
          
          //console.log("tempSeriesArray: " , tempSeriesArray);
          seriesDataArr.push({ data: tempSeriesArray || '' });
  
       // console.log("seriesDataArr: " , seriesDataArr);
  
  
  
        let colorsArr = [];
        seriesDataArr?.map((data, index) => {
          colorsArr.push({ color: colors[index], label: data.datalabel });
        });
  
       // console.log("colorsArr::::", colorsArr);
        setLegend(colorsArr);
        setGraphLabel(graphlabels);
        setSummaryResultData(seriesDataArr);
  
  
  
  
      } catch (err) {
        console.log("Error ", err);
      }
  
    };
  
    useEffect(async () => {
      loadGraphStats(statsType);
    }, []);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: SUMMARY_STATS_TYPE[statsType],

      },
      legend: {
        display: false,
        labels: {
          usePointStyle: true,
          boxWidth: 8,

        }
      },
    },
  };


  const data = {
    labels,
    datasets:  summaryResultData.map((data , index) =>{
        return {
              label: data.datalabel || '' ,
              data: data.data,
              borderColor:  colors[index],
              backgroundColor: colors[index],
              borderWidth: 10,
            }
        
    }),

   
  };

    return (
        <>


            <Bar width={100} height={100} options={options} data={data} />


        </>
    );
};



export default DashboardBarChart;
