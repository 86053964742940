import React, { useEffect, useState } from "react";
import { Modal, Button, Table } from "react-bootstrap";
import { formatOrderDate } from "../../utils";
import API from "api";
import Loader from "components/Loader/Loader";

const OrderDetailModal = (props) => {
  const { show, handleClose, order } = props;
  const [tracking, setTracking] = useState("");
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchTrackingLink();
  }, []);

  const fetchTrackingLink = async () => {
    if (order && order.user.orderId && order.user.orderId !== -1) {
      setLoading(false);
      const logData = await API.getPreRegistrationOrderLog(order.user.orderId);
      const items = logData.Items.filter((l) => l.eventType === "Shipped");

      if (items.length > 0) {
        if (items[0].tracking_url) {
          setTracking(items[0].tracking_url);
        } else {
          const logResult = logData.Items.filter((l) => l.eventType === "OrderPlaced");
          if (logResult.length > 0) {
            const link = await API.getShipBobOrderDetails(logResultx[0].shipbob_order_id);
            setTracking(link);
          }
        }
      }
      setLogs(
        logData.Items.sort((a, b) => {
          const testerB = b.createdAt;
          const testerA = a.createdAt;
          return testerB < testerA ? 1 : testerA < testerB ? -1 : 0;
        })
      );
    }
  };

  const parseEventData = (event, val) => {
    const EVENT_TYPES = "DeliveredShippedMovedDeclinedPickedPacked";
    if (EVENT_TYPES.includes(event)) {
      return formatOrderDate(val);
    }
    return val;
  };

  const TableRow = ({ item, sr }) => {
    return (
      <tr>
        <td className="icon" style={{ width: "45px", minWidth: "45px", maxWidth: "45px" }}>
          {sr}
        </td>
        <td className={`ellipsis `}>{formatOrderDate(item.createdAt)}</td>
        <td className={`ellipsis `}>{item.eventType}</td>
        <td className={`ellipsis`}>{parseEventData(item.eventType, item.eventData)}</td>
      </tr>
    );
  };

  return (
    <Modal show={show} animation={true} onHide={() => handleClose()} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          Order Detail
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="table-responsive">
          {loading && <Loader />}
          <Table className="table-hover table-striped">
            <thead>
              <tr>
                <th
                  className={`ellipsis border-0`}
                  style={{
                    color: "grey",
                    fontWeight: "bold",
                  }}
                >
                  Sr#
                </th>
                <th
                  className={`ellipsis border-0`}
                  style={{
                    color: "grey",
                    fontWeight: "bold",
                  }}
                >
                  <div>
                    <span title="Order Status">Date</span>
                  </div>
                </th>
                <th
                  className={`ellipsis border-0`}
                  style={{
                    color: "grey",
                    fontWeight: "bold",
                  }}
                >
                  <div>
                    <span title="Order Status">Event</span>
                  </div>
                </th>
                <th
                  className={`ellipsis border-0`}
                  style={{
                    color: "grey",
                    fontWeight: "bold",
                  }}
                >
                  <div>
                    <span title="Order Status">Details</span>
                  </div>
                </th>
                {/* <th></th> */}
              </tr>
            </thead>
            <tbody>
              {logs &&
                logs.map((o, index) => {
                  return <TableRow key={index} item={o} sr={index + 1} />;
                })}
            </tbody>
          </Table>
        </div>
        <div>
          {tracking && (
            <a href={tracking} target="_blank">
              Live Tracking <i className="fa fa-external-link" />
            </a>
          )}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={() => handleClose()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OrderDetailModal;
