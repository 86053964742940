import React, { useState, useContext, useRef } from "react";
import { Card, Table, Row, Col, Button } from "react-bootstrap";
import { useEffect } from "react";
import TableRow from "./TableRow";
import TestTypeModal from "views/TestTypeSetting/TestTypeModal";
import LabBarCodeAlertSetting from "views/TestTypeSetting/LabBarCodeAlertSetting";
import API from "api";
import { AppContext } from "context/app-context";
import { Bounce } from "react-activity";
import HeaderItem from "../../components/Table/HeaderItem";
import { calculateTdWidth } from "../../utils";
import NewEmployeeImportModal from "components/Modal/NewEmployeeImportModal";
import Loader from "../../components/Loader/Loader";

const TestTypeSetting = () => {
  const componentRef = useRef();
  const mainWidth = useRef();
  const [width, setWidth] = useState(0);
  const appContext = useContext(AppContext);
  const [openImportModal, setOpenImportModal] = useState(false);

  useEffect(() => {
    console.log("width", componentRef.current.offsetWidth);
    setWidth(componentRef.current.offsetWidth);
  }, [componentRef]);
  const handleResize = () => {
    console.log("width handle Resize", componentRef.current.offsetWidth);
    if (componentRef.current) setWidth(componentRef.current.offsetWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  useEffect(() => handleResize(), [mainWidth]);
  const tdWidth = calculateTdWidth(width - 50, 5);

  const [loading, setLoading] = useState();
  const [list, setList] = useState([]);
  const [editObj, setEditObj] = useState(null);

  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    setList(await API.getTestTypes());
  };

  const handleEdit = (item) => {
    console.log("Time", item);
    setEditObj(item);
  };

  const handleSave = async (item) => {
    console.log("handle save", item);
    const obj = { ...item };
    setEditObj(null);
    setLoading(true);
    try {
      await API.updateTestTypes(obj);
      setLoading(false);
      setList(await API.getTestTypes());
      appContext.showSuccessMessage("Test Type update successfully");
    } catch (error) {
      setEditObj(obj);
      appContext.showErrorMessage(error.message);
    }
    setLoading(false);
  };

  const handleClose = () => {
    setEditObj(null);
  };



  return (
    <div style={{ flex: 1 }}>
      {!loading ? (
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                    color: "#565656",
                  }}
                >
                  Test Settings
                </Card.Title>
                {/* <Button
                  className="headerButton"
                  onClick={() => {
                    setOpenImportModal(true);
                  }}
                >
                  Load Data
                </Button> */}
              </Card.Header>

              <Card.Body className="table-full-width px-0 desktop-noScroll">
                <div className="table-responsive pendingReleaseTable">
                  <Table className="table-hover table-striped">
                    <thead ref={componentRef}>
                      <tr>
                        <th></th>
                        <th
                          style={{
                            color: "grey",
                            fontWeight: "bold",
                            width: tdWidth,
                          }}
                          className="border-0"
                        >
                          <div>
                            <span title="name">Name</span>
                          </div>
                        </th>
                        <th
                          style={{
                            color: "grey",
                            fontWeight: "bold",
                            width: tdWidth,
                          }}
                          className="border-0"
                        >
                          <div>
                            <span title="Total Time">Total Time</span>
                          </div>
                        </th>
                        <th
                          style={{
                            color: "grey",
                            fontWeight: "bold",
                            width: tdWidth,
                          }}
                          className="border-0"
                        >
                          <div>
                            <span title="First Alert">First Alert</span>
                          </div>
                        </th>
                        <th
                          style={{
                            color: "grey",
                            fontWeight: "bold",
                            width: tdWidth,
                          }}
                          className="border-0"
                        >
                          <div>
                            <span title="Second Alert">Second Alert</span>
                          </div>
                        </th>
                        <th
                          style={{
                            color: "grey",
                            fontWeight: "bold",
                            width: tdWidth,
                          }}
                          className="border-0"
                        >
                          <div>
                            <span title="Second Alert">Valid duration</span>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {list.map((item, i) => {
                        return <TableRow key={i} item={item} handleEdit={handleEdit} />;
                      })}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <LabBarCodeAlertSetting />
        </Row>
      ) : (
        <Loader />
      )}
      {editObj && <TestTypeModal item={editObj} handleSave={handleSave} handleClose={handleClose} />}
      
    </div>
  );
};

export default TestTypeSetting;
