import React, { useState, useContext, useMemo, useRef, useCallback } from "react";

// react-bootstrap components
import { Button, Card, Table, Container, Row, Col, Form } from "react-bootstrap";
import { useEffect } from "react";
import API from "api";
import GQAPI from "views/GraphQL/gqApi";
import { Link } from "react-router-dom";
import parsePhoneNumber, { AsYouType } from "libphonenumber-js";
import { PDFViewer } from "@react-pdf/renderer";
import PDF from "components/PDF";
import Filter from "components/Filter";
import { useLocation } from "react-router-dom";
import Loader from "components/Loader/Loader";
import { AppContext } from "context/app-context";
import MFPagination from "components/Pagination/MFPagination";
import { PAGE_LIMIT, TEST_HEADER_TYPE, SUPER_ADMIN_ID, RESULT_VALUE } from "constant";
import TimeFilter from "components/TimeFilter";
import Header from "components/Table/Header";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import {
  changeSort,
  getCrewsName,
  formatTest,
  formatPhoneNumber,
  setPhoneNo,
  calculateTdWidth,
  sortTestList,
  sortingFilterInLC,
  formatUTCDate,
} from "utils";
import ContactInfo from "components/Modal/ContactInfo";
import FilterSelect from "components/FilterSelect";
import TestSubmitModal from "components/Modal/TestSubmitModal";
import ExportToExcel from "components/ExportToExcel";
import TestPDFLayout from "components/PDF/TestPDFLayout";
import TestLogsModal from "components/Modal/TestLogsModal";
import ChangeTestLabModal from "components/Modal/ChangeTestLabModal";
import moment from "moment";
import { useInitialRender } from "components/useInitialRender";
import ViewOnlyCheckBox from "components/CheckBox/ViewOnlyCheckBox";

const UnprocessedTestsApi = () => {
  const [tests, setTests] = useState([]);
  const [filteredTests, setFilteredTests] = useState([]);
  const [labs, setLabs] = useState([]);
  const [sites, setSites] = useState([]);
  const [moreInfo, setMoreInfo] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [testsLab, setTestsLab] = useState();
  const [testPdf, setTestPdf] = useState();
  const [showPdf, setShowPdf] = useState(false);
  const [timeFilter, setTimeFilter] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const [triggerFilter, setTriggerFilter] = useState();
  const [checkboxes, setCheckboxes] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [importStatus, setImportStatus] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [showLog, setShowLog] = useState(false);
  const [testLogs, setTestLogs] = useState([]);
  const [showLabChangeModal, setShowLabChangeModal] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const { filterDates } = TimeFilter;
  const initialRender = useInitialRender();
  const location = useLocation();
  const waitingResultCall = location.pathname.includes("senttest") ? true : false;
  const appContext = useContext(AppContext);

  const searchTerms = [
    "First Name",
    "Last Name",
    "Date of Birth",
    "Email",
    "Phone",
    "Client",
    "Show",
    "Lab",
    "Status",
    "Test Type",
    "Sequence",
    "Transition Status",
    "Tester",
    "Barcode",
    "isVaccinated",
    "White Glove",
    "Pattern",
    "Capacity",
  ];

  const selectedItem = [
    "createdAt",
    "firstName",
    "lastName",
    "dob",
    "email",
    "phoneNumber",
    "clientID",
    "siteID",
    "labID",
    "status",
    "test_type",
    "sequenceNo",
    "tester_name",
    "isVaccinated",
    "employeeType",
    "whiteGlove",
    "patternTestAnswer",
  ];

  const displayOwner = (test) => {
    let displayedId = test.owner.substr(-12);
    return displayedId;
  };

  const createMoreInfo = (type, id) => {
    let infoObj = null;
    if (type === "site") infoObj = appContext.sites.find((site) => site.id === id);
    else if (type === "client") infoObj = appContext.clients.find((client) => client.id === id);
    else infoObj = labs.find((lab) => lab.id === id);

    if (infoObj) {
      setMoreInfo({
        type,
        name: infoObj.name,
        contact: infoObj.contact_name,
        phone: infoObj.contact_phone,
        email: infoObj.contact_email,
      });
      setModalShow(true);
    }
  };

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filter).length === 0) return targetArray;

    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length) {
          return true;
        }
        if (key === "phoneNumber") {
          return obj[key] && obj[key].replace(/\D/g, "").includes(filters[key].replace(/\D/g, ""));
        }
        if (key === "Lab") {
          return obj.labID && appContext.getLabName(obj.labID).toLowerCase().includes(filters[key]);
        }
        if (key === "Client") {
          return (
            obj.clientID && appContext.getClientName(obj.clientID).toLowerCase().includes(filters[key].toLowerCase())
          );
        }
        if (key === "Tester") {
          return obj.tester_name && obj.tester_name.toLowerCase().includes(filters[key].toLowerCase());
        }

        // if ((key = "Test Type")) {
        //   if ("antigen".includes(filter[key].toLowerCase()))
        //     return obj.isAntigen;
        //   if ("pcr".includes(filter[key].toLowerCase())) return !obj.isAntigen;
        // }

        if (key === "Show") {
          const siteIds = appContext.sites
            .filter((s) => s.name.toLowerCase().includes(filters[key].toLowerCase()))
            .map((s) => s.id);
          return siteIds.includes(obj.siteID);
        }
        if (key === "sequenceNo") {
          return obj[key] && obj["sequenceNo"].toString().includes(filters[key]);
        }

        if (key === "dob") {
          return obj[key] && obj[key] === filters[key];
        }

        if (key === "test_type" && filters[key] && filters[key].length > 0) {
          return obj[key] && filters[key].indexOf(obj[key]) !== -1;
        }

        if (key === "vaccinated") {
          return (obj["isVaccinated"] ? 1 : 0).toString() === filters[key];
        }

        if (key === "whiteGlove") {
          return (obj["whiteGlove"] ? 1 : 0).toString() === filters[key];
        }

        if (key === "capacity") {
          return obj["employeeType"] && obj["employeeType"].toLowerCase().includes(filters[key].toLowerCase());
        }

        if (key === "pattern") {
          if (filters[key] === "1") return obj["patternTestAnswer"] && obj["patternTestAnswer"] !== "undefined";

          if (filters[key] === "0") return obj["patternTestAnswer"] == "undefined" || obj["patternTestAnswer"] == "";
        }

        return obj[key] && obj[key].toLowerCase().includes(filters[key].toLowerCase());
      });
    });

    return models;
  };

  const displayPdf = async (test) => {
    const empID = await appContext.getEmployeeID(test.employeeID);
    const t = test;
    t.empID = empID;
    appContext.getLab(test).then(async (resp) => {
      setTestsLab(resp);
      setShowPdf(true);
    });
    setTestPdf(formatTest(test));
  };

  const loadTestFromPG = async () => {
    console.log("Calling LoadTestFromPG");
    try {
      setLoading(true);
      const pgList = await API.getTestListFromPG({ waiting: waitingResultCall ? "w" : "p" });
      setLoading(false);
      let ttlTests = pgList.rows.map((t) => formatTest(t));
      if (waitingResultCall) {
        ttlTests = ttlTests.filter((t) => t.status !== "New");
      }
      setTests(ttlTests);
      refreshTestList(ttlTests);
    } catch (err) {
      setLoading(false);
    }
  };

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  const handleCheckboxChange = (e, id) => {
    const filteredList = checkboxes.filter((c) => c !== id);
    if (e.target.checked) {
      filteredList.push(id);
    }
    setCheckboxes(filteredList);
  };

  const toggleCheckboxes = (val) => setCheckboxes(val ? filteredTests.map((t) => t.id) : []);

  const submitTests = async () => {
    try {
      const testsToSubmit = tests.filter((test) => checkboxes.includes(test.id));
      if (!testsToSubmit.length) return;
      setLoading(true);
      const submission = await GQAPI.submitUnprocessedTests(
        testsToSubmit,
        appContext.user,
        window.location.href.includes("portal.safecamp") ||
          window.location.href.includes("staging.safecamp") ||
          window.location.href.includes("medflow")
          ? "LIVE"
          : "DEV"
      );
      const newTestList = tests.filter((test) => !checkboxes.includes(test.id));

      setFilteredTests(newTestList);
      setAllSelected(false);
      setCheckboxes([]);
      setImportStatus(submission);
      setShowAlert(true);
      setShowFilter(false);
      setLoading(false);
    } catch (err) {
      console.log("Error", err);
      setLoading(false);
      appContext.showErrorMessage("Fail to Submit the Record");
    }
  };

  const reSubmitTests = async () => {
    const testsToSubmit = tests.filter((test) => checkboxes.includes(test.id));
    if (!testsToSubmit.length) return;
    setLoading(true);
    const submission = await API.reSubmitUnprocessedTests(testsToSubmit, appContext.user);
    // addLogs(testsToSubmit, "ReSubmitToLab");
    setAllSelected(false);
    setCheckboxes([]);
    setImportStatus(submission);
    setShowAlert(true);
    setShowFilter(false);
    setLoading(false);
  };

  const addLogs = (items, slug) => {
    const ids = items.map((s) => {
      return { id: s.id, sequenceNo: s.sequenceNo };
    });
    const params = { ids: ids, userID: appContext.user?.sub, userName: appContext.user?.name, slug: slug };
    API.addTestLogs(params);
  };

  useEffect(() => {
    const savedFilters = appContext.testFilter;
    if (savedFilters && savedFilters.location === "unprocessedtest") {
      if (Object.keys(savedFilters.filter).length > 0) {
        setFilter(savedFilters.filter);
        setShowFilter(true);
      }
    }
    const sortingFilters = sortingFilterInLC.get();
    if (waitingResultCall && sortingFilters.senttest.sortBy) {
      setSortDescending(sortingFilters.senttest.sortDescending);
      setSortBy(sortingFilters.senttest.sortBy);
    } else if (sortingFilters.unprocessedtest.sortBy) {
      setSortDescending(sortingFilters.unprocessedtest.sortDescending);
      setSortBy(sortingFilters.unprocessedtest.sortBy);
    }
    loadTestFromPG();
  }, []);

  useEffect(() => {
    setLoading(true);
    setLabs(appContext.labs);
    setSites(appContext.sites);
  }, [appContext.labs, appContext.sites]);

  useEffect(() => {
    const prevSortingState = sortingFilterInLC.get();
    prevSortingState[waitingResultCall ? "senttest" : "unprocessedtest"] = {
      filter,
      timeFilter,
      sortBy,
      sortDescending,
    };
    sortingFilterInLC.save(prevSortingState);
    if (filteredTests.length === 0) return;
    changeSort(
      sortBy,
      sortDescending,
      filteredTests,
      setFilteredTests,
      appContext.getSiteName,
      appContext.getLabName,
      appContext.getClientName
    );
  }, [sortBy, sortDescending]);

  useEffect(() => {
    if (location.state) {
      setShowFilter(true);
      setFilter({ ...filter, [location.state.term]: location.state.name });
      setTriggerFilter(true);
    }
  }, [location.state]);

  useEffect(() => {
    refreshTestList(tests);
  }, [filter, triggerFilter, timeFilter]);

  useEffect(() => {
    if (initialRender) return;
    if (appContext.testObject && appContext.testObject !== 0) {
      const eventType = appContext.testObject.opType;
      const model = formatTest(appContext.testObject.element);
      const newList = [...tests];

      if (eventType === "DELETE" || model.result) {
        setTests(tests.filter((t) => t.id !== model.id));
        setFilteredTests(filteredTests.filter((t) => t.id !== model.id));
        return;
      }

      if (eventType === "INSERT") {
        if ((waitingResultCall && model.status !== "New") || (!waitingResultCall && model.status === "New")) {
          newList.unshift(model);
        }
      } else if (eventType === "UPDATE") {
        const index = newList.findIndex((t) => t.id === model.id);
        if ((waitingResultCall && model.status !== "New") || (!waitingResultCall && model.status === "New")) {
          if (index !== -1) {
            newList.splice(index, 1, model);
          } else {
            newList.unshift(model);
          }
        } else if (index !== -1) {
          newList.splice(index, 1);
        }
      }
      setTests(newList);
      refreshTestList(newList);
    }
  }, [appContext.testObject]);

  const refreshTestList = (list) => {
    const testList = filterDates(nestedFilter(list, filter), timeFilter);

    if (sortBy) {
      setFilteredTests(
        sortTestList(
          sortBy,
          sortDescending,
          testList,
          appContext.getSiteName,
          appContext.getLabName,
          appContext.getClientName
        )
      );
    } else {
      setFilteredTests(testList);
    }
  };

  const testsToMap = useMemo(() => {
    const list = filteredTests ? [...filteredTests] : [];
    const ttlPages = list.length > PAGE_LIMIT ? Math.ceil(list.length / PAGE_LIMIT) : 1;
    setTotalPages(ttlPages);
    if (list.length < PAGE_LIMIT || currentPage > ttlPages) {
      setCurrentPage(1);
    }
    if (ttlPages > 1) {
      const indexOfLastUser = currentPage * PAGE_LIMIT;
      const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
      return list.slice(indexOfFirstUser, indexOfLastUser);
    }
    return list;
  }, [filteredTests, currentPage]);

  console.log("filteredTests", filteredTests);

  const handleSaveFilter = () => {
    appContext.setTestFilter({ location: "unprocessedtest", filter, timeFilter });
  };

  const showTestLogs = async (num) => {
    setShowLog(true);
    const logs = await API.getTestLogs(num);
    setTestLogs([...logs].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
  };

  const handleDeleteConfirm = async (isConfirm) => {
    setOpenDeleteConfirmation(false);
    if (!isConfirm) {
      return;
    }
    setLoading(true);
    try {
      const testList = filteredTests.filter((test) => checkboxes.includes(test.id));
      if (!testList.length) {
        return;
      }
      await appContext.deleteTests(testList);
      const newTestlist = tests.filter((test) => !checkboxes.includes(test.id));
      setTests(newTestlist);
      refreshTestList(newTestlist);
      setLoading(false);
      setCheckboxes([]);
    } catch (err) {
      console.log("Error", err);
      setLoading(false);
    }
  };

  const updateLabChange = async (selectedType, isSubmitToLab) => {
    const updateSelectedTestResult = tests.filter((test) => checkboxes.includes(test.id) && test.test_type === "PCR");
    const ttlTest = updateSelectedTestResult.length;
    if (!ttlTest) {
      appContext.showErrorMessage("No PCR test was selected");
      return;
    }
    setLoading(true);
    try {
      let testList = [];
      if (!loading) setLoading(true);
      if (isSubmitToLab) {
        testList = updateSelectedTestResult.map((t) => {
          return {
            id: t.id,
            labID: selectedType.value,
            labName: selectedType.label,
            status: "Sent",
            submittedBy: appContext.user.sub,
            submittedByName: appContext.user.name,
            sequenceNo: t.sequenceNo,
          };
        });
        await GQAPI.updateTestLab(testList);
        const lab = appContext.labs.find((l) => l.id === selectedType.value);
        await API.submitToLab(lab, updateSelectedTestResult);
      } else {
        testList = updateSelectedTestResult.map((t) => {
          return {
            id: t.id,
            labID: selectedType.value,
            labName: selectedType.label,
            sequenceNo: t.sequenceNo,
          };
        });
        await GQAPI.updateTestLab(testList);
      }
      addLogs(updateSelectedTestResult, "LabChange", selectedType.label);
      appContext.showSuccessMessage(`${ttlTest} test${ttlTest === 1 ? "" : "s"} lab has been changed`);
    } catch (err) {
      console.log("Error", err);
      appContext.showErrorMessage(`Fail to update the lab`);
    }
    setLoading(false);
    setCheckboxes([]);
  };

  const TableRow = ({ test }) => {
    return (
      <tr>
        <td className="ellipsis" style={{ textAlign: "left" }}>
          <div className="d-flex align-items-center">
            <Form.Check className="me-2">
              <Form.Check.Input
                type="checkbox"
                value={test.id}
                checked={checkboxes.indexOf(test.id) !== -1}
                onChange={(e) => handleCheckboxChange(e, test.id)}
              ></Form.Check.Input>
            </Form.Check>
            {test.preRegTest && <i title="Created from PR" className="fa fa-star fa-2xs me-2" />}
            {test.labStatusTest === "1" ? (
              <i title={test.labOrderDetail?.message} className="fa fa-check-circle me-2" />
            ) : test.labStatusTest === "2" ? (
              <i title={test.labOrderDetail?.message} className="fa fa-exclamation-circle" />
            ) : (
              ""
            )}
          </div>
        </td>
        <td className="ellipsis" title={formatUTCDate(test.createdAt)}>
          {formatUTCDate(test.createdAt)}
        </td>
        <td className="table-column ellipsis" title={test.firstName}>
          {test.firstName}
        </td>
        <td className="table-column ellipsis" title={test.lastName}>
          {test.lastName}
        </td>
        <td className="ellipsis" title={test.dob}>
          {test.dob}
        </td>
        <td
          className="table-column ellipsis"
          style={{
            textDecoration: "underline",
            color: "#A82632",
          }}
          onMouseOver={(e) => {
            e.target.style.cursor = "pointer";
            e.target.style.textDecoration = "underline";
          }}
          onClick={() => test?.email && window.open(`mailto:${test.email}`)}
          title={test.email}
        >
          {test.email}
        </td>
        <td
          className="table-column ellipsis"
          style={{ textDecoration: "underline", color: "#A82632" }}
          onMouseOver={(e) => {
            e.target.style.cursor = "pointer";
            e.target.style.textDecoration = "none";
          }}
          onMouseLeave={(e) => {
            e.target.style.textDecoration = "underline";
          }}
          onClick={() => {
            test.phoneNumber && window.open(`tel:${test.phoneNumber}`);
          }}
          title={test.phoneNumber && formatPhoneNumber(test.phoneNumber)}
        >
          {test.phoneNumber && formatPhoneNumber(test.phoneNumber)}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          <a
            style={{ color: "#42cef5" }}
            onClick={() => createMoreInfo("client", test.clientID)}
            onMouseOver={(e) => (e.target.style.cursor = "pointer")}
            title={appContext.getClientName(test.clientID)}
          >
            {appContext.getClientName(test.clientID)}
          </a>
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          <a
            style={{ color: "#42cef5" }}
            onClick={() => createMoreInfo("site", test.siteID)}
            onMouseOver={(e) => (e.target.style.cursor = "pointer")}
            title={appContext.getSiteName(test.siteID)}
          >
            {appContext.getSiteName(test.siteID)}
          </a>
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          <a
            style={{ color: "#42cef5" }}
            onClick={() => createMoreInfo("lab", test.labID)}
            onMouseOver={(e) => (e.target.style.cursor = "pointer")}
            title={appContext.getLabName(test.labID)}
          >
            {appContext.getLabName(test.labID)}
          </a>
        </td>
        <td
          className="ellipsis"
          style={{
            textAlign: "center",
            color: test.status === "Pending" ? "orange" : "black",
          }}
          title={test.status}
        >
          {test.status}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }} title={test.test_type || "-"}>
          {test.test_type || "-"}
        </td>
        <td
          className="ellipsis"
          style={{ textAlign: "center", cursor: "Pointer" }}
          title={test.sequenceNo}
          onClick={() => showTestLogs(test.sequenceNo)}
        >
          {test.sequenceNo}
        </td>

        <td className="ellipsis" style={{ textAlign: "center" }} title={test.transitionStatus || "-"}>
          {test.transitionStatus || "-"}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }} title={test.tester_name || "NA"}>
          {test.tester_name || "NA"}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          <Link
            style={{ color: "#42cef5", textAlign: "center" }}
            to={{
              pathname: "/admin/test/" + test.id,
              state: { unprocessed: true, type: "unprocessed", test: test },
            }}
            onClick={handleSaveFilter}
          >
            View
          </Link>
        </td>
      </tr>
    );
  };

  const handleChangePage = (page) => setCurrentPage(page);
  const handleCloseContactInfo = () => {
    setMoreInfo({});
    setModalShow(false);
  };
  const updateTestResult = async (result) => {
    const updateSelectedTestResult = tests.filter((test) => checkboxes.includes(test.id));
    const ttlTest = updateSelectedTestResult.length;
    if (!ttlTest) {
      return;
    }
    setLoading(true);

    const params = updateSelectedTestResult.map((t) => {
      return {
        id: t.id,
        result: RESULT_VALUE[result],
        status: result === "positive" ? "Pending" : "Processed",
        timerStatus: result === "positive" ? "Pending" : "Processed",
        resultDateTime: moment().utc().toISOString(),
        resultDate: moment().utc().format("YYYY-MM-DD"),
        StampBy: appContext.user.sub,
        StampByName: appContext.user.name,
        sequenceNo: t.sequenceNo,
      };
    });
    try {
      if (!loading) setLoading(true);
      await GQAPI.updateTestResult(params);

      appContext.showSuccessMessage(`${ttlTest} test${ttlTest === 1 ? "" : "s"} has been resulted ${result}`);
      setLoading(false);
      const newList = tests.filter((test) => !checkboxes.includes(test.id));
      refreshTestList(newList);
    } catch (err) {
      setLoading(false);
    }

    setCheckboxes([]);
  };
  const handleConfirm = async (isConfirm) => {
    setOpenConfirmation(false);
    if (!isConfirm) {
      return;
    }
    updateTestResult("positive");
  };
  return showPdf ? (
    <Container fluid>
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <Row style={{ alignItems: "center" }}>
                <Card.Title as="h4">
                  Showing Test PDF for{" "}
                  {typeof testPdf.employee_demographics === "string"
                    ? JSON.parse(testPdf.employee_demographics).firstName
                    : testPdf.employee_demographics.firstName}
                </Card.Title>
                <Button
                  style={{
                    marginTop: 10,
                  }}
                  className="createButtons"
                  onClick={() => setShowPdf(false)}
                >
                  Close
                </Button>
              </Row>
            </Card.Header>
            <Card.Body className="pdfContainer table-full-width table-responsive px-0">
              <PDFViewer className="pdfView">
                <PDF test={testPdf} testsLab={testsLab} parseTestResultForPDF={appContext.parseTestResultForPDF} />
              </PDFViewer>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  ) : !loading ? (
    <>
      <Container fluid style={{ flex: 1 }}>
        {showAlert && <TestSubmitModal data={importStatus} handleClose={setShowAlert} />}
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                    color: "#565656",
                  }}
                >
                  {waitingResultCall ? "Waiting Results" : "Unprocessed Tests"} ({filteredTests.length})
                </Card.Title>
                <div className="buttonHeader">
                  <Button className="headerButton" onClick={() => setShowFilter(!showFilter)}>
                    Filter
                  </Button>
                  <FilterSelect setTimeFilter={setTimeFilter} />
                  <Button
                    className="headerButton"
                    onClick={() => {
                      if (filteredTests && filteredTests.length > 0) {
                        setAllSelected(!allSelected);
                        toggleCheckboxes(!allSelected);
                      }
                    }}
                  >
                    {!allSelected ? "Select All" : "Deselect All"}
                  </Button>

                  {
                    <>
                      <Button
                        className="headerButton"
                        disabled={checkboxes.length === 0}
                        onClick={() => setOpenDeleteConfirmation(true)}
                      >
                        Delete Test
                      </Button>
                      <Button
                        className="headerButton"
                        onClick={() => setShowLabChangeModal(true)}
                        disabled={checkboxes.length === 0}
                      >
                        Change Lab
                      </Button>
                    </>
                  }
                  {waitingResultCall && appContext.user["custom:role"] === "Admins" ? (
                    <>
                      <Button
                        className="headerButton"
                        disabled={checkboxes.length === 0}
                        onClick={() => setOpenConfirmation(true)}
                      >
                        Set Positive
                      </Button>
                      <Button
                        className="headerButton"
                        disabled={checkboxes.length === 0}
                        onClick={() => updateTestResult("negative")}
                      >
                        Set Negative
                      </Button>

                      <Button
                        className="headerButton"
                        disabled={checkboxes.length === 0}
                        onClick={() => updateTestResult("Inconclusive")}
                      >
                        Set Inconclusive
                      </Button>
                      <Button
                        className="headerButton"
                        onClick={() => reSubmitTests()}
                        disabled={checkboxes.length === 0}
                      >
                        ReSubmit Selected Tests
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button className="headerButton" onClick={() => submitTests()} disabled={checkboxes.length === 0}>
                        Submit Selected Tests
                      </Button>
                    </>
                  )}
                  {filteredTests.length > 0 && (
                    <>
                      {Object.keys(filter).length > 0 && (
                        <>
                          <ExportToExcel
                            items={filteredTests}
                            selectedItem={selectedItem}
                            appContext={appContext}
                            filter={filter}
                            title={waitingResultCall ? "Waiting Results" : "Unprocessed Tests"}
                            sortBy={sortBy}
                          />
                          <TestPDFLayout
                            tests={filteredTests}
                            selectedItem={selectedItem}
                            filter={filter}
                            timeFilter={timeFilter}
                            title={waitingResultCall ? "Waiting Results" : "Unprocessed Tests"}
                            appContext={appContext}
                            sortBy={sortBy}
                          />
                        </>
                      )}
                    </>
                  )}
                  {/*  <Button
                    className="headerButton"
                    onClick={() => {
                      const testList = filteredTests.filter((test) => checkboxes.includes(test.id));
                      if (!testList.length) {
                        return;
                      }
                      appContext.deleteTests(testList);
                    }}
                  >
                    Delete Test
                  </Button>  */}
                </div>

                {showFilter && (
                  <Filter
                    filterTerms={searchTerms}
                    setFilter={setFilter}
                    filter={filter}
                    triggerFilter={triggerFilter}
                    setTriggerFilter={setTriggerFilter}
                  />
                )}

                <MFPagination
                  currentPage={currentPage}
                  handleChangePage={handleChangePage}
                  totalPages={totalPages}
                  showSelectedRecord
                  totalSelected={checkboxes.length}
                />
              </Card.Header>

              <Card.Body className="table-full-width px-0 desktop-noScroll">
                <div className="table-responsive pendingReleaseTable">
                  <Table className="table-hover table-striped">
                    <Header
                      type={TEST_HEADER_TYPE.unprocessed}
                      flipSort={flipSort}
                      sortBy={sortBy}
                      sortDescending={sortDescending}
                      cssClass="pendingReleaseTable"
                      widthCol={55}
                    />
                    <tbody>
                      {testsToMap &&
                        testsToMap.map((test) => {
                          return <TableRow key={test.id} test={test} />;
                        })}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <ContactInfo show={modalShow} moreInfo={moreInfo} handleClose={handleCloseContactInfo} />
      {showLog && (
        <TestLogsModal
          data={testLogs}
          handleClose={() => {
            setShowLog(false);
            setTestLogs([]);
          }}
        ></TestLogsModal>
      )}
      {openConfirmation && (
        <ConfirmationModal
          show={openConfirmation}
          title="Result Positive"
          message="Are you sure, to result positive ?"
          handleConfirm={handleConfirm}
        />
      )}
      {showLabChangeModal && (
        <ChangeTestLabModal
          handleSave={updateLabChange}
          labs={appContext.labs}
          handleClose={() => setShowLabChangeModal(false)}
        />
      )}
      {openDeleteConfirmation && (
        <ConfirmationModal
          show={openDeleteConfirmation}
          title="Delete Test"
          message={`Are you sure, you want to remove Test`}
          handleConfirm={handleDeleteConfirm}
        />
      )}
    </>
  ) : (
    <Loader />
  );
};

export default UnprocessedTestsApi;
