import React, { useState, useEffect, useMemo } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { externalTypesOptions } from "constant";
import Select from "react-select";
import { parseBooleanValue } from "utils";
import ViewOnlyCheckBox from "components/CheckBox/ViewOnlyCheckBox";

const ChangeTestConfigModal = (props) => {
  const { handleSave, handleClose, clients, sites } = props;
  const [client, setClient] = useState("");
  const [site, setSite] = useState("");
  const [error, setError] = useState("");
  const [confirmText, setConfirmText] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setError("");
    }, 4000);
  }, [error]);

  const onHandleSave = () => {
    if (!client) {
      setError("Please select the Client");
      return;
    }
    if (!site) {
      setError("Please select the Site");
      return;
    }
    if (confirmText !== "confirm") {
      setError("Please enter confirm in input");
      return;
    }
    handleSave(client, site);
    handleClose();
  };

  const getSiteOptions = useMemo(()=>{
    if(!client) return [];
    return sites.filter((s)=> s.clientID === client.value).map((l) => {
      return { value: l.id, label: l.name };
    })
  },[client]);

  
  return (
    <>
      <Modal
        show
        animation={true}
        onHide={() => {
          handleClose();
        }}
        style={{ paddingLeft: "0" }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            Change Test Configuration
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: 0,
          }}
        >
          <div className="form-group-wrapper">
            <label className="modalLineHeaders">Select Client </label>
            <Select
              className="w-100 siteSelector"
              options={clients.map((l) => {
                return { value: l.id, label: l.name };
              })}
              blurInputOnSelect={true}
              defaultValue={null}
              menuPlacement="auto"
              placeholder="Select Client"
              onChange={(e) => setClient(e)}
            />
          </div>
          <div className="form-group-wrapper">
            <label className="modalLineHeaders">Select Site </label>
            <Select
              className="w-100 siteSelector"
              options={getSiteOptions}
              blurInputOnSelect={true}
              defaultValue={null}
              menuPlacement="auto"
              placeholder="Select Site"
              onChange={(e) => setSite(e)}
            />
          </div>

          <div className="form-group-wrapper">
            <label className="modalLineHeaders">Type confirm: </label>
            <input
              value={confirmText}
              className="modalInput"
              placeholder="type confirm"
              style={{
                width: "100%",
              }}
              onChange={(e) => setConfirmText(e.target.value)}
            />
          </div>
  

          {error.length > 0 && <strong style={{ color: "Red" }}>{error}</strong>}
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            className="modalButtons"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
          <Button style={{ marginBottom: 10 }} variant="primary" className="modalButtons" onClick={onHandleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ChangeTestConfigModal;
