import { DataStore, Predicates, SortDirection } from "@aws-amplify/datastore";
import {
  Client,
  Site,
  Lab,
  Employee,
  Test,
  TestTypes,
  TestConsent,
  LencoBarcodes,
  EmployeeTestCounter,
} from "./models";
import { Auth, API, Storage, graphqlOperation } from "aws-amplify";
import * as queries from "./graphql/queries";
import { setPhoneNo, formatTest, getPhoneNo } from "utils";
import { TEST_STATUS, RESULT_VALUE } from "constant";
import axios from "axios";
import AWS from "aws-sdk";
import awsconfig from "./aws-exports";
import { jsonToCSV } from "react-papaparse";
import moment from "moment";
import * as uuid from "uuid";
import * as mutations from "./graphql/mutations";

import { AWS_ENDPOINT } from "constants/endpoints";
import { ExternalTest } from "models";

const MAX_PAGE_LIMIT = 1000;
const PRE_REGISTER_URL = {
  LIVE: "https://g8bgbgfb45.execute-api.eu-west-1.amazonaws.com/dev/register",
  DEV: "https://qa3dzt4aoj.execute-api.eu-west-1.amazonaws.com/dev/register",
};

const getTestGQL = async (filterParam) => {
  let nextToken = null;
  let tests = [];
  const params = {
    filter: filterParam,
    sort: {
      field: "status",
      order: SortDirection.ASCENDING,
    },
    limit: 1000,
  };
  do {
    if (nextToken) {
      Object.assign(params, { nextToken: nextToken });
    }
    const graphqlData = await API.graphql(graphqlOperation(queries.listTests, params));
    console.log("getTestGraphQL ", graphqlData);
    tests = tests.concat(graphqlData.data.listTests.items);
    nextToken = graphqlData.data.listTests.nextToken;
  } while (nextToken);
  return tests.filter((c) => !c._deleted);
};

const getEmployeeGQL = async () => {
  let employees = [];
  let nextToken = null;
  const params = {
    limit: 1000,
  };
  do {
    if (nextToken) {
      Object.assign(params, { nextToken: nextToken });
    }
    try {
      const graphqlData = await API.graphql(graphqlOperation(queries.listEmployees, params));
      employees = employees.concat(graphqlData.data.listEmployees.items);
      nextToken = graphqlData.data.listEmployees.nextToken;
    } catch (err) {
      console.log("Error:- EmployeeGraphQl", err.message);
    }
  } while (nextToken);

  console.log("getEmployeeGraphQl ", employees);
  return employees;
};

const deleteEmployeeGQL = async (id) => {
  try {
    const employeeData = {
      id: id,
    };
    await API.graphql({ query: mutations.deleteEmployee, variables: { input: employeeData } });
    return true;
  } catch (err) {
    console.log("Error:- EmployeeGraphQl", err.message);
  }
  return false;
};
const updateEmployeeGQL = async (employeeData) => {
  console.log("EmployeeGraphQl", employeeData);
  try {
    const updateEmp = await API.graphql({ query: mutations.updateEmployee, variables: { input: employeeData } });
    const { data } = updateEmp;
    return data.updateEmployee;
  } catch (err) {
    console.log("Error:- UpdateEmployeeGraphQl", err.message);
  }
};
const getEmployeeByIdGQl = async (id) => {
  let employees = [];
  let nextToken = null;
  const params = {
    filter: {
      id: {
        eq: id,
      },
    },
    limit: 1000,
  };
  do {
    if (nextToken) {
      Object.assign(params, { nextToken: nextToken });
    }
    console.log("xcvbnm", params);
    try {
      const graphqlData = await API.graphql(graphqlOperation(queries.listEmployees, params));
      employees = employees.concat(graphqlData.data.listEmployees.items);
      nextToken = graphqlData.data.listEmployees.nextToken;
    } catch (err) {
      console.log("Error:- EmployeeGraphQl", err.message);
    }
  } while (nextToken);
  return employees;
};

const getTestByIdGQL = async (id) => {
  let nextToken = null;
  let tests = [];
  const params = {
    filter: {
      id: {
        eq: id,
      },
    },
    limit: 1000,
  };
  do {
    if (nextToken) {
      Object.assign(params, { nextToken: nextToken });
    }
    const graphqlData = await API.graphql(graphqlOperation(queries.listTests, params));
    console.log("getTestGraphQLINloop ", graphqlData);
    tests = tests.concat(graphqlData.data.listTests.items);
    nextToken = graphqlData.data.listTests.nextToken;
  } while (nextToken);
  console.log("getTestGraphQLAfterLoop ", tests);
  return tests;
};
const getTestBySequenceNoGQL = async (sequenceNo) => {
  let nextToken = null;
  let tests = [];
  const params = {
    filter: {
      sequenceNo: {
        eq: sequenceNo,
      },
    },
    limit: 1000,
  };
  do {
    if (nextToken) {
      Object.assign(params, { nextToken: nextToken });
    }
    const graphqlData = await API.graphql(graphqlOperation(queries.listTests, params));
    console.log("getTestGraphQLINloop ", graphqlData);
    tests = tests.concat(graphqlData.data.listTests.items);
    nextToken = graphqlData.data.listTests.nextToken;
  } while (nextToken);
  console.log("getTestGraphQLAfterLoop ", tests);
  return tests;
};

const deleteTestGQL = async (id) => {
  try {
    const filter = {
      id: id,
    };
    await API.graphql({ query: mutations.deleteTest, variables: { input: filter } });
    return true;
  } catch (err) {
    console.log("Error:- Delete GraphQl", err.message);
  }
  return false;
};

export default {
  getEmployeeGQL,
  getTestGQL,
  deleteEmployeeGQL,
  getEmployeeByIdGQl,
  updateEmployeeGQL,
  getTestByIdGQL,
  deleteTestGQL,
  getTestBySequenceNoGQL,
};
