import { transferKeyToLowerCase } from "@aws-amplify/core";
import React, { useState, useEffect, useContext } from "react";
import { Button, Card, Table, Row, Col, DropdownButton, Dropdown, Modal, Container } from "react-bootstrap";
import { PDFViewer } from "@react-pdf/renderer";
import { Link } from "react-router-dom";
import SitePDF from "components/SitePDF";
import API from "api";
import { AppContext } from "context/app-context";
import { Bounce } from "react-activity";
import "react-activity/dist/Bounce.css";
import { parseIntVal,formateDateLL } from "utils";
import Loader from "../../components/Loader/Loader";

const AdminHomeApi = ({ completedTests }) => {
  const [clients, setClients] = useState([]);
  const [sites, setSites] = useState([]);
  const [labs, setLabs] = useState([]);
  const [tests, setTests] = useState();
  const [loading, setLoading] = useState();
  const [positiveTest, setPositiveTest] = useState([]);

  const [pdf, setPdf] = useState();
  const [showPdf, setShowPdf] = useState(false);

  const appContext = useContext(AppContext);

  const negativeTests = (tests) => {
    const negativeTests = tests.filter((test) => {
      return test.result?.toLowerCase() === "negative";
    });
    return negativeTests;
  };

  const positiveTests = (tests) => 1;

  const lastPositiveTest = (key,id)=>positiveTest.find((c)=> c[key] === id);
  
  const displayPdf = (site) => {
    // const thisTestsLab = labs.find((lab) => lab.id === test.labID);
    // setTestsLab(thisTestsLab);
    setPdf(site);
    setShowPdf(true);
  };

  const loadDashboardData = async () => {
    try {
      setLoading(true);
      const site = await API.getSummaryFromPG({ summary: "site" });
      const client = await API.getSummaryFromPG({ summary: "client" });
      const lab = await API.getSummaryFromPG({ summary: "lab" });
      const ptData = await API.getSummaryFromPG({ summary: "positiveTest" });
      setLoading(false);
      setClients(client.rows);
      setLabs(lab.rows);
      setSites(site.rows);
      setPositiveTest(ptData.rows);
    } catch (err) {
      console.log("Error ", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    loadDashboardData();
  }, []);

  const getFilteredIds = (data, arr) => {
    const list = data.filter((f) => f.showOnSummaryScreen !== true && f.isArchive !== true).map((c) => c.id);
    return arr.filter((f) => list.includes(f.id));
  };

  const summary = (key, name, models) => {
    let sum = { totalTests: 0, positiveTests: 0, negativeTests: 0 };
    models.reduce((obj, site) => {
      obj["totalTests"] = (obj["totalTests"] || 0) + site.totalTest;
      obj["positiveTests"] = (obj["positiveTests"] || 0) + parseIntVal(site.positiveCount);
      obj["negativeTests"] = (obj["negativeTests"] || 0) + parseIntVal(site.negativeCount);
      return obj;
    }, sum);
    return (
      <Card>
        <Card.Header>
          <Card.Title
            as="h3"
            style={{
              marginBottom: 10,
              fontWeight: "bold",
              color: "#565656",
              textAlign: "left",
            }}
          >
            {name}s
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="notFirstChild table-responsive pendingReleaseTable" style={{ marginBottom: 5, padding: 5 }}>
            <Table className="adminHomeTable user-table table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th className="centered">Total Tests</th>
                  <th className="centered">Positive Tests</th>
                  <th className="centered">Negative Tests</th>
                  <th className="centered">Date of Last Positive Test</th>
                </tr>
              </thead>
              <tbody>
                {models?.map((model, index) => {
                  const positiveData = lastPositiveTest(key,model.id);

                  return (
                    <tr key={index}>
                      <td>{model.name}</td>
                      <td style={{ textAlign: "center" }}>
                        {model.totalTest !== 0 ? (
                          <Link
                            style={{ color: "#42cef5" }}
                            to={{
                              pathname: "/admin/totaltests",
                              state: { name: model.name, term: name, models: models },
                            }}
                          >
                            {model.totalTest}
                          </Link>
                        ) : (
                          model.totalTest || 0
                        )}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {(model.positiveCount || 0) !== 0 ? (
                          <Link
                            style={{ color: "#42cef5" }}
                            to={{
                              pathname: "/admin/alltests",
                              state: { name: model.name, term: name, models: models, positiveTests: true },
                            }}
                          >
                            {model.positiveCount}
                          </Link>
                        ) : (
                          model.positiveCount || 0
                        )}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {model.negativeCount !== 0 ? (
                          <Link
                            style={{ color: "#42cef5" }}
                            to={{
                              pathname: "/admin/alltests",
                              state: { name: model.name, term: name, models: models, negativeTests: true },
                            }}
                          >
                            {model.negativeCount}
                          </Link>
                        ) : (
                          model.negativeCount || 0
                        )}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {!positiveData ? (
                          "No Positive Tests"
                        ) : (
                          <Link
                            style={{ color: "#42cef5" }}
                            to={{
                              pathname: "/admin/alltests",
                              state: { name: positiveData.barcode, term: "barcode" },
                            }}
                          >
                            {formateDateLL(positiveData.resultDate)}
                          </Link>
                        )}
                      </td>
                    </tr>
                  );
                })}
                <tr style={{ borderBottomWidth: 2 }}></tr>
                {
                  <tr>
                    <td></td>
                    <td style={{ textAlign: "center" }}>
                      {sum.totalTests !== 0 ? (
                        <Link
                          style={{ color: "#42cef5" }}
                          to={{
                            pathname: "/admin/totaltests",
                          }}
                        >
                          {sum.totalTests}
                        </Link>
                      ) : (
                        sum.totalTests
                      )}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {sum.positiveTests !== 0 ? (
                        <Link
                          style={{ color: "#42cef5" }}
                          to={{
                            pathname: "/admin/alltests",
                            state: { name: "positive", term: "result" },
                          }}
                        >
                          {sum.positiveTests}
                        </Link>
                      ) : (
                        sum.positiveTests
                      )}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {sum.negativeTests !== 0 ? (
                        <Link
                          style={{ color: "#42cef5" }}
                          to={{
                            pathname: "/admin/alltests",
                            state: { name: "negative", term: "result" },
                          }}
                        >
                          {sum.negativeTests}
                        </Link>
                      ) : (
                        sum.negativeTests
                      )}
                    </td>
                    <td style={{ textAlign: "center" }}>{positiveTests(tests).length === 0 && "No Positive Tests"}</td>
                  </tr>
                }
              </tbody>
            </Table>
          </div>
        </Card.Body>
      </Card>
    );
  };

  return showPdf ? (
    <Container fluid>
      <Row>
        <Col md="12">
          <Card
            style={{ paddingLeft: 30, marginLeft: "auto", marginRight: "auto" }}
            className="strpied-tabled-with-hover"
          >
            <Card.Header>
              <Row style={{ alignItems: "left" }}>
                <Card.Title as="h4">Showing Summary for {pdf.name}</Card.Title>
                <Button style={{ marginLeft: 30 }} onClick={() => setShowPdf(false)}>
                  Close
                </Button>
              </Row>
            </Card.Header>
            <Card.Body className="table-full-width table-responsive px-0">
              <PDFViewer style={{ flex: 1, width: 500, height: 750 }}>
                <SitePDF site={pdf} client={client} />
              </PDFViewer>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  ) : !loading ? (
    <div style={{ flex: 1 }}>
      <Row>
        <Col md="12">
          {summary("clientID", "Client", clients && appContext.clients ? getFilteredIds(appContext.clients, clients) : [])}
          {summary("siteID", "Show", sites && appContext.sites ? getFilteredIds(appContext.sites, sites) : [])}
          {summary("labID","Lab", labs && appContext.labs ? getFilteredIds(appContext.labs, labs) : [])}
          {/* {summary(
            "White Label Lab",
            labs ? appContext.labs.filter((l) => l.showOnSummaryScreen !== true).filter((l) => l.whiteLabel === true) : []
          )} */}
        </Col>
      </Row>
    </div>
  ) : (
    <Loader />
  );
};

export default AdminHomeApi;
