import React from "react";
import SortIcon from "../../components/SortIcon";

const HeaderItem = (props) => {
  const { ItemKey, title, flipSort, aligned, sortBy, sortDescending, cssClass, width } = props;
  return (
    <th
      className={`ellipsis border-0 ${aligned} ${cssClass}`}
      style={{
        color: sortBy === ItemKey ? "#A72632" : "grey",
        fontWeight: "bold",
        maxWidth: width,
        // minWidth: width,
        width: width,
      }}
      onClick={() => flipSort(ItemKey)}
    >
      <div className={`${aligned}`}>
        <span title={title}>{title}</span>
        <SortIcon sortName={ItemKey} sortBy={sortBy} sortDescending={sortDescending} />
      </div>
    </th>
  );
};

export default HeaderItem;
