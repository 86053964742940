/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component, useState, useEffect, useLayoutEffect } from "react";
import { useLocation, Route, Switch } from "react-router-dom";
import AdminNavbar from "components/Navbars/AdminNavbar";
import localApi from "api";
import GQAPI from "views/GraphQL/gqApi";
import { AppContext } from "../context/app-context";
import siteRoutes from "routes/HSRoutes";
import sidebarImage from "assets/img/sidebar-3.jpg";
import { Hub, Auth, Storage, API, graphqlOperation } from "aws-amplify";
import * as subscriptions from "graphql/subscriptions";
import { Test, Employee, TestConsent, EmployeeBarCodes, ExternalTest, Site } from "../models";
import {
  formatTest,
  medFlowInLocalStorage,
  formatExternalTest,
  roleInLocalStorage,
} from "utils";
import TableListApi from "views/GraphQL/TableListApi";
import api from "api";
import { useIdleTimer } from "react-idle-timer";
import IdleModal from "components/IdleModal";
import { useHistory } from "react-router-dom";
import { debounce } from "debounce";
import { AMAZON_CLIENT_RESULT, RESULT_PDF, RESULT_CHECK, ROUTES, CONFIG } from "../constant";
import SweetAlert from "react-bootstrap-sweetalert";
import { DataStore, syncExpression } from "@aws-amplify/datastore";
import moment from "moment";
import Loader from "components/Loader/Loader";
import { showsInSS } from "utils";
import HSRoutesNew from "routes/HSRoutesNew";
import HealthAndSafetyDashBoard from "views/GraphQL/HealthAndSafetyDashboard";
import SiteSidebarNew from "components/Sidebar/SiteSideBarNew";
import { connectedIDByURL } from "utils";
import HSDashboard from "views/DashboardCharts/HSDashboard";

function SiteNew() {
    const timeout = 1800000;
    const [showIdleModal, setShowIdleModal] = useState(false);
    const [remaining, setRemaining] = useState(timeout);
    const [elapsed, setElapsed] = useState(0);
    const [lastActive, setLastActive] = useState(+new Date());
    const [lastEvent, setLastEvent] = useState("Events Emitted on Leader");
    const [leader, setLeader] = useState(true);
    const [timedOut, setTimedOut] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [successMessageText, setSuccessMessageText] = useState("");
    const [errorMessageText, setErrorMessageText] = useState("");
    const [pageState, setPageState] = useState("New");
    const [role, setRole] = useState(null);
  //const connectedID = window.location?.pathname?.split('/').pop();

  let getConnectedID = connectedIDByURL(window.location.pathname)
  // console.log("connectedID getConnectedID: " , getConnectedID);
  const connectedID = getConnectedID.connectedID; 
  // console.log("connectedID: " , connectedID);
  
  const [routingPathBasedMedFlow , setRoutingPathBasedMedFlow] = useState({
    userType: 'E',
    connectedId: connectedID,
    siteID: connectedID,
  })
  

  useLayoutEffect(() => {
    let userType = routingPathBasedMedFlow?.userType ||  medFlowInLocalStorage.get();
    if (!userType || userType === null) handleLogOut();
  }, []);

  useEffect(() => {
    let roleSelected = roleInLocalStorage.get();
    setRole(roleSelected);
  }, []);

  let history = useHistory();

  const handleCloseModal = () => {
    setShowIdleModal(false);
  };

  const handleLogOut = async () => {
    setShowIdleModal(false);
    try {
      // await DataStore.clear();
      await Auth.signOut();
      medFlowInLocalStorage.clear();
      roleInLocalStorage.clear();
      showsInSS.clear();
      // console.log("PAUSED");
      handlePause();
      history.push("/login");
    } catch (error) {
      console.log("Error signing out: ", error);
    }
  };

  const handleOnIdle = (event) => {
    setShowIdleModal(true);
  };

  const handleOnActive = (event) => {
    setTimedOut(false);
  };

  const handleOnAction = (event) => {
    setTimedOut(false);
  };

  const handleReset = () => reset();
  const handlePause = () => pause();
  const handleResume = () => resume();
  const handleStart = () => start();

  const {
   
    pause,
    resume,
    start,
  } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
    onAction: handleOnAction,
    debounce: 500,
    crossTab: {
      emitOnAllTabs: false,
    },
  });

  
  useEffect(() => {
    // window.addEventListener("beforeunload", removeUserFromLC);
    DataStore.stop();
    // return () => {
    //   window.removeEventListener("beforeunload",removeUserFromLC);
    // };
  }, []);

  useEffect(() => {
    if (showIdleModal) {
      const timeout = setTimeout(() => {
        if (showIdleModal) {
          handleLogOut();
        }
      }, 60000); // TODO: - This time is the extra time you want to see how long they're idle for until they get logged out 300000
      return () => clearTimeout(timeout);
    }
  }, [showIdleModal]);

  const [image, setImage] = React.useState(sidebarImage);
  const [color, setColor] = React.useState(process.env.REACT_APP_COLOR);
  const [hasImage, setHasImage] = React.useState(true);
  const [userRoutes, setUserRoutes] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [clients, setClients] = React.useState([]);
  const [sites, setSites] = React.useState([]);
  const [admins, setAdmins] = React.useState([]);
  const [pendingTests, setPendingTests] = React.useState([]);
  const [invalidTests, setInvalidTests] = React.useState([]);
  const [completedTests, setCompletedTests] = React.useState([]);
  const [unprocessedTests, setUnprocessedTests] = React.useState();
  const [unSubmittedTests, setUnsubmittedTests] = React.useState();
  const [externalTest, setExternalTest] = useState([]);
  const [newTests, setNewTests] = React.useState([]);
  const [totalTests, setTotalTests] = React.useState([]);
  const [adminTotalTests, setAdminTotalTests] = React.useState([]);
  const [userSite, setUserSite] = React.useState(null);
  const [userSiteName, setUserSiteName] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [testFilter, setTestFilter] = useState(null);
  const [eventObject, setEventObject] = useState(0);
  const [testObject, setTestObject] = useState(0);
  const [isShowUpdated, setIsShowUpdated] = useState(null);

  const location = useLocation();
  const mainPanel = React.useRef(null);
  

  const getRoutes = (routes) => {
    
    return routes.map((prop, key) => {
      if (prop.layout === "/site") {
        return <Route path={prop.layout + prop.path } render={(props) => <prop.component {...props} />} key={key} />;
      } else {
        return null;
      }
    });
  };

  const fetchUserGroups = async () => {
    const admins = await localApi.fetchAllUsersInGroup("Admins");
    const sites = await localApi.fetchAllUsersInGroup("Sites");
    const testers = await localApi.fetchAllUsersInGroup("Testers");
    const employers = await localApi.fetchAllUsersInGroup("Employers");
    const clients = await localApi.fetchAllUsersInGroup("Clients");
    return Promise.all([admins, sites, testers, employers, clients]).then((values) => [
      admins,
      sites,
      testers,
      employers,
      clients,
    ]);
  };

  const fetchAdmins = async () => {
    const admins = await localApi.fetchUsersInGroup("Admins");
    return admins;
  };

  const fetchClients = async () => {
    const models = await localApi.getClients();
    setClients(models);
  };

  const fetchSites = async () => {
    const models = await localApi.getSites();
    // setSites(models.filter((s) => s.isArchive === false));
    setSites(models);
  };

  const fetchSitesByUser = async (siteID) => {
    const models = await localApi.getUserSiteData(siteID);
    setUserSite(models[0])
    setUserSiteName(models[0]?.name);
    return models;
  };

  const fetchSitesByClient = async (clientID) => {
    const models = await localApi.getSitesByClient(clientID);
    return models;
  };

  const findTotalTestsBySites = async (sites) => {
    const passFailClients = await localApi.getResultClients();
    const tests = [];
    sites.forEach((site) => {
      return site?.tests.map((test) => {
        return tests.push(formatTest(test, passFailClients));
      });
    });
    setTotalTests(tests);

    return tests;
  };

  const fetchExternalTests = async () => {
    const models = await localApi.getExternalTest();

    setExternalTest(models.map((t) => formatExternalTest(t)));
  };

  const updatedShow = (obj) => {
    if (obj) {
      setIsShowUpdated(obj);
    } else {
      setIsShowUpdated(null);
    }
  };

  const getClientName = (id) => {
    if (!id) return "";
    if (clients) {
      const client = clients.find((c) => c.id === id);
      if (client) return client.name;
    }
    return "";
  };

  const parseResult = (test) => {
    if (!test) return "";
    if (!test.result) return "";
    if (clients) {
      const client = clients.find((c) => c.id === test.clientID);
      if (client && client.resultType === "P/F") {
        return AMAZON_CLIENT_RESULT[test.result];
      }
    }

    return test.result && test.result.charAt(0).toUpperCase() + test.result.slice(1);
  };

  const parseTestResultForPDF = (test) => {
    if (!test) return "";

    if (clients) {
      const client = clients.find((c) => c.id === test.clientID);
      if (client && client.resultType === "P/F") {
        return AMAZON_CLIENT_RESULT[test.result];
      }
    }

    return test.result && RESULT_PDF[test.result.toLowerCase()];
  };

  const getEmployeeByID = async (id) => {
    const emp = await localApi.getEmployeeByID(id);
    return emp;
  };


  const fetchPendingTests = async (ids) => {
    if (ids) {
      const newList = pendingTests.filter((t) => ids.indexOf(t.id) === -1 && (t.invalidResultStatus || 0) === 0);
      setPendingTests(newList);
      return;
    }
    const params = { pendingRelease: "Pending" };
   
      Object.assign(params, { siteID: routingPathBasedMedFlow?.siteID || "E" });
    
    const models = await localApi.getTestListFromPG(params);

    const tests = models.rows
      .map((test) => {
        return formatTest(test);
      })
      .filter((f) => (f.invalidResultStatus || 0) === 0);
    setPendingTests(tests);
  };

  const fetchAllAdminTests = async () => {
    const passFailClients = await localApi.getResultClients();
    const models = await localApi.getTotalAdminTests();
    const tests = models.map((test) => {
      return formatTest(test, passFailClients);
    });
    setAdminTotalTests(tests);
  };

  

  const onSubscriptionTrigger = debounce(() => refreshDataOnSubscribe(), 6000);

  const refreshDataOnSubscribe = () => {
    console.log("Refresh Data");
    const userType = routingPathBasedMedFlow?.userType || "E";
    if (userType === "E") {
      fetchNewTests();
    }
  };


  const fetchNewTests = async () => {
    const models = await localApi.getCurrentLabUserTests();
    setNewTests(models);
    return models;
  };
  
  const [user, setUser] = useState();

  useEffect(() => {
    if (!testObject) return;
    const eventType = testObject.opType;
    const model = formatTest(testObject.element);
    const result = model.result;

    if (result && result.toLowerCase() === RESULT_CHECK[result.toLowerCase()]) {
      const newList = [...pendingTests];
      const index = pendingTests.findIndex((t) => t.id === model.id);

      if (
        (eventType === "DELETE" || (model.status === "Processed" && result.toLowerCase() === "positive")) &&
        index !== -1
      ) {
        newList.splice(index, 1);
      } else if (
        (eventType === "INSERT" || eventType === "UPDATE") &&
        index === -1 &&
        (model.status === "Pending" || result.toLowerCase() === "inconclusive" || result.toLowerCase() === "invalid")
      ) {
        newList.unshift(model);
      } else if (eventType === "UPDATE" && index !== -1) {
        newList.splice(index, 1, model);
      }
      setPendingTests(newList.filter((f) => (f.invalidResultStatus || 0) === 0));
    }

  }, [testObject]);

  React.useEffect(() => {
    let subscription = null;
    let apiSubscription = null;
    let showSubscription = null;
    const userType = routingPathBasedMedFlow?.userType || "E";
    
    if (userType === "E") {
      setUserRoutes(HSRoutesNew);
    } 

    const getUSer = async () => {
      const days = 2;
      const date = moment().subtract(days, "days").format("YYYY-MM-DD");
      const user = await localApi.getCurrentUser();
      let syncExpressionsByUserType = [];
      if (user) {
        if (user.isAdmin() || user.isSite()) {
          syncExpressionsByUserType = [
            syncExpression(TestConsent, () => {
              return (test) => test.employeeID("eq", "123");
            }),
            syncExpression(EmployeeBarCodes, () => {
              return (emp) => emp.employeeID("eq", "123");
            }),
            syncExpression(Employee, () => {
              return (test) => test.id("eq", "123");
            }),
            syncExpression(ExternalTest, () => {
              return (test) => test.id("eq", "000");
            }),
          ];
          if (user.isAdmin()) {
            syncExpressionsByUserType.push(
              syncExpression(Test, () => {
                return (test) => test.status("ne", "Processed");
              })
            );
          }
       
          DataStore.configure({
            maxRecordsToSync: 90000,
            syncPageSize: 2000,
            syncExpressions: syncExpressionsByUserType,
          });
          DataStore.start();
          setUser(user);
          handleStart();
        } else {
          setLoading(false);
          setShowAlert(true);
        }
      } else {
        setLoading(false);
        console.log("push to login");
        history.push("/login");
      }
    };
    getUSer().then((res) => {

      subscription = DataStore.observe(Test).subscribe((msg) => {
        const noSubs = ROUTES.some((r) => window.location.href.includes(r));
        if (noSubs) return;
        const userType = routingPathBasedMedFlow?.userType || "E";
        const connectedID = routingPathBasedMedFlow?.siteID ||"E";
        if ((userType === "E" && msg.element.siteID === connectedID)) {
          setEventObject(msg);
        }
        onSubscriptionTrigger();
      });
      showSubscription = DataStore.observe(Site).subscribe((msg) => {
        updatedShow(msg.element);
        fetchSites();
      });
      apiSubscription = API.graphql(graphqlOperation(subscriptions.onUpdateTest)).subscribe({
        next: ({ value: { data }, value }) => {
          console.log("Here are in event");
          const userType = routingPathBasedMedFlow?.userType || "E";
          const connectedID = routingPathBasedMedFlow?.siteID || "E";
  

          let model = data.onUpdateTest;
          if ((userType === "E" && model.siteID === connectedID)) {
            setEventObject({ opType: "UPDATE", element: model });
          }
        },
        error: (error) => console.warn(error),
      });
    });
    return () => {
      if (subscription) subscription.unsubscribe();
      if (apiSubscription) apiSubscription.unsubscribe();
      if (showSubscription) showSubscription.unsubscribe();
    };
  }, []);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    // mainPanel.current.scrollTop = 0;
    if (window.innerWidth < 993 && document.documentElement.className.indexOf("nav-open") !== -1) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location]);

  const contextSwitch = (role) => {
    if (user?.isAdmin()) {
      return {
        testObject,
        user,
        users,
        resetUsers: () => setUsers(fetchUserGroups()),
        clients,
        resetClients: () => fetchClients(),
        sites,
        resetSites: () => fetchSites(),
        isShowUpdated,
        sites,
        resetSites: () => fetchSites(),
        pendingTests,
        invalidTests,
        resetPendingTests: (ids) => fetchPendingTests(ids),
        // resetInvalidTests: (ids) => fetchInvalidTests(ids),
        completedTests,
        unprocessedTests,
        unSubmittedTests,
        totalTests,
        adminTotalTests,
        externalTest,
        isSuperAdmin: () => isSuperAdmin(),
        sendEmailToCrew: (tests) => sendResultOnEmail(tests),
        resetUnprocessedTests: () => fetchUnprocessedTests(),
        resetUnSubmittedTests: () => fetchUnSubmittedTests(),
        resetAllTests: () => refreshDataOnSubscribe(),
        resetTotalTest: () => fetchAllAdminTests(),
        resetExternalTest: () => fetchExternalTests(),
        getSiteName: (id) => getSiteName(id),
        getSiteName: (id) => getSiteName(id),
        getSite: (test) => getSite(test),
        getSiteTests: (id) => getSiteTests('a1115b41-a58a-4c0e-8933-38f6d8a085c9'),
        getClientTests: (id) => getClientTests(id),
        admins,
        newTests,
        parseResult: (test) => parseResult(test),
        parseTestResultForPDF: (test) => parseTestResultForPDF(test),
        getClientName: (id) => getClientName(id),
        testFilter,
        setTestFilter: (filter) => setTestFilter(filter),
        deleteTests: (tests) => deleteTests(tests),
        showSuccessMessage: (message) => setSuccessMessageText(message),
        showErrorMessage: (message) => setErrorMessageText(message),
        getEmployeeID: (id) => getEmployeeByID(id),
      };
    }

  };

  const isSuperAdmin = () => {
    console.log("isSuperAdmin", user?.isAdmin());
    if (user?.isAdmin()) {
      return user?.phone_number === "+18888888888" || user?.phone_number === "+12222222222";
    }
    return false;
  };

  const getClientTests = (id) => {
    const clientObj = adminTotalTests.filter((t) => t.clientID === id);
    return clientObj.length;
  };
  
  const getSiteTests = (id) => {
    const siteObj = adminTotalTests.filter((t) => t.siteID === id);
    return siteObj.length;
  };

  const getSiteName = (id) => {
    const siteObj = sites.find((site) => site.id === id);
    return siteObj ? siteObj.name : "NA";
  };

  const getSite = async (test) => {
    let site = sites.find((site) => site.id === test.siteID);
    if (!site) {
      site = await localApi.getSiteByID(test?.siteID);
    }

    if (site && "logo" in site && site.logo) {
      const path = await Storage.get(site.logo);
      return { ...site, logoSrc: path };
    }
    return site;
  };

  const deleteTests = async (tests) => {
    if (tests.length === 0) return;

    await GQAPI.deleteWrongTest(tests.map((t) => t.id));

    setSuccessMessageText(`${tests.length} test${tests.length === 1 ? "" : "s"} were deleted!`);
  };

  const sendResultOnEmail = async (param, slug) => {
    if (param.length === 0) return;
    setLoading(true);
    let ttlEmails = param.length;
    try {
      await GQAPI.sendEmailToPendingRelease(param);
      const params = { ids: param, userID: user?.sub, userName: user?.name, slug };
      api.addTestLogs(params);

      setLoading(false);
      setSuccessMessageText(`Email has been successfully sent to ${ttlEmails} Crew Member`);
    } catch (error) {
      console.log("error:-", error.message);
      setLoading(false);
      setErrorMessageText("Fail to send the Email");
    }
  };


  const siteListener = () => {
    const hubListener = Hub.listen("datastore", async (hubData) => {
      const { event } = hubData.payload;
      if (event === "ready") {
        setUserRoutes(HSRoutesNew);
        setSites(await fetchSitesByUser(connectedID));
        fetchPendingTests(); 
        fetchSites();
        // setAdmins(fetchAdmins());
       // fetchNewTests();
        setLoading(false);
        Hub.remove("datastore", hubListener);
      }
    });
  };

  React.useEffect(() => {
    //user?.isAdmin() && adminListener();
    user?.isAdmin() && siteListener()
    user?.isSite() && siteListener();
  }, [user]);


  return (
    <>
      <div className="wrapper">
        {showAlert && (
          <SweetAlert
            show={showAlert}
            error
            title="Error"
            onConfirm={async () => {
              await Auth.signOut();
              window.location.reload();
            }}
          >
            Your account does not have access to the Med Flow portal
          </SweetAlert>
        )}
        {successMessageText && (
          <SweetAlert
            show={successMessageText ? true : false}
            success
            title="Success"
            onConfirm={() => setSuccessMessageText("")}
          >
            {successMessageText}
          </SweetAlert>
        )}
        {errorMessageText && (
          <SweetAlert
            show={errorMessageText ? true : false}
            error
            title="Error"
            onConfirm={() => setErrorMessageText("")}
          >
            {errorMessageText}
          </SweetAlert>
        )}
        <SiteSidebarNew
          color={color}
          image={""}
          routes={userRoutes}
          user={user}
          pendingTests={pendingTests}
          invalidTests={invalidTests}
          testFilter={testFilter}
          setTestFilter={setTestFilter}
          siteID={connectedID}
        />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar
            routes={userRoutes}
            completedTests={completedTests}
            pendingTests={pendingTests}
            role={role || user?.roles[0]}
            userSiteName={userSiteName}
            siteID={connectedID} 
            userType={'E'}
          />
          {!loading ? (
            <div className="content">
              <AppContext.Provider value={contextSwitch(user?.roles[0])}>
                <Switch>{getRoutes(userRoutes)}</Switch>
                { window.location.pathname === "/site/tests/" + connectedID && user?.isAdmin() && (
                 <HealthAndSafetyDashBoard site={userSite} siteID={connectedID} userType={'E'} />
                // <HSDashboard site={userSite} siteID={connectedID} userType={'E'}/>
                )}
              </AppContext.Provider>
            </div>
          ) : (
            <Loader />
          )}
          {/* <Footer /> */}
        </div>
      </div>

      <IdleModal
        showIdleModal={showIdleModal}
        animation={true}
        handleLogOut={handleLogOut}
        handleCloseModal={handleCloseModal}
      />
    </>
  );
}

export default SiteNew;
