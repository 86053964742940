import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import { Card, Table, Row, Col } from "react-bootstrap";
import "react-activity/dist/Bounce.css";
import MFPagination from "components/Pagination/MFPagination";
import EmployeeLogsEntries from "components/EmployeeLogsEntries";
import { PAGE_LIMIT } from "../constant";
import { changeSort, calculateTdWidth } from "../utils";

const EmployeeLogs = (props) => {
  const { employeeData } = props;
  const componentRef = useRef();
  const [width, setWidth] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    setWidth(componentRef.current?.offsetWidth);
  }, [componentRef]);

  const handleResize = () => {
    if (componentRef.current) setWidth(componentRef.current?.offsetWidth);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  useEffect(() => {
    setTimeout(() => {
      setErrorMessage("");
    }, 4000);
  }, [errorMessage]);

  const [filteredEmployeeLogs, setFilteredEmployeeLogs] = useState(employeeData);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    setFilteredEmployeeLogs(employeeData);
  }, [employeeData]);

  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(sortBy === by ? !sortDescending : true);
  };

  const employeeLogsToMap = useMemo(() => {
    const list = filteredEmployeeLogs ? [...filteredEmployeeLogs] : [];
    const ttlPages = list.length > PAGE_LIMIT ? Math.ceil(list.length / PAGE_LIMIT) : 1;
    setTotalPages(ttlPages);
    if (list.length < PAGE_LIMIT || currentPage > ttlPages) {
      setCurrentPage(1);
    }
    if (ttlPages > 1) {
      const indexOfLastUser = currentPage * PAGE_LIMIT;
      const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
      return list.slice(indexOfFirstUser, indexOfLastUser);
    }
    return list;
  }, [filteredEmployeeLogs, currentPage]);

  useEffect(() => {
    changeSort(sortBy, sortDescending, filteredEmployeeLogs, setFilteredEmployeeLogs);
  }, [sortBy, sortDescending]);

  const handleChangePage = (page) => setCurrentPage(page);
  return (
    <>
      {filteredEmployeeLogs.length > 0 && (
        <Row>
          <Col md="12">
            <Card className="striped-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                    color: "#565656",
                  }}
                >
                  Employees Logs ({filteredEmployeeLogs?.length})
                </Card.Title>
                <MFPagination currentPage={currentPage} handleChangePage={handleChangePage} totalPages={totalPages} />
              </Card.Header>
              <Card.Body className="table-full-width px-0 desktop-noScroll">
                <div className="table-responsive pendingReleaseTable overFlow-y-hidden">
                  <Table className="table-hover table-striped">
                    <tbody>
                      {employeeLogsToMap &&
                        employeeLogsToMap.map((employee, i) => {
                          let newDetails = JSON.parse(employee.newdetail);
                          if (Object.keys(newDetails).length > 0)
                            return (
                              <EmployeeLogsEntries key={i} index={i} employee={employee} newDetails={newDetails} />
                            );
                        })}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};

export default EmployeeLogs;
