import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";

const CreateTestLabModal = (props) => {
  const { handleSave, handleClose, labs } = props;
  const [testType, setTestType] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setError("");
    }, 4000);
  }, [error]);

  const onHandleSave = () => {
    if (!testType) {
      setError("Please select the Lab");
      return;
    }
    handleSave(testType);
    handleClose();
  };

  return (
    <>
      <Modal
        show
        animation={true}
        onHide={() => {
          handleClose();
        }}
        style={{ paddingLeft: "0" }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            Create Test
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: 0,
          }}
        >
          <div className="form-group-wrapper">
            <label className="modalLineHeaders"> Select Lab </label>
            <Select
              className="w-100 siteSelector"
              options={labs.map((l) => {
                return { value: l.id, label: l.name };
              })}
              blurInputOnSelect={true}
              defaultValue={null}
              menuPlacement="auto"
              placeholder="Select Lab"
              onChange={(e) => setTestType(e)}
            />
          </div>
          {error.length > 0 && <strong style={{ color: "Red" }}>{error}</strong>}
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            className="modalButtons"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
          <Button style={{ marginBottom: 10 }} variant="primary" className="modalButtons" onClick={onHandleSave}>
            Create Test
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateTestLabModal;
