/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import { Navbar, Container, Nav, Dropdown, Button } from "react-bootstrap";
import { Auth } from "aws-amplify";
import { DataStore } from "@aws-amplify/datastore";
import { medFlowInLocalStorage, medFlowPortalTitle } from "utils";

import { showsInSS } from "utils";

function Header(props) {
  const { userLabName, emailSending, userSiteName, labID, siteID, userType } = props;
  const location = useLocation();
  let history = useHistory();
  const mobileSidebarToggle = (e) => {
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    node.onclick = function () {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle("nav-open");
    };
    document.body.appendChild(node);
  };

  const roleSwitch = (role) => {
    const value = userType || medFlowInLocalStorage.get() || "";
    let title = medFlowPortalTitle.get() || "";

    if (title === "undefined") {
      title = "";
    }
    switch (value) {
      case "A":
        return "Admin";
      case "L":
        if (userLabName) return `${userLabName}`;
        if (title) return title;
        return "Lab";
      case "E":
        if (userSiteName) return `${userSiteName}`;
        if (title) return title;
        return "Health & Safety";
      case "C":
        return "Clients";
      default:
        return;
    }
  };

  const getBrandText = (routes) => {
    console.log("getBrandText ", props);
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "";
  };

  const getHrefRouteName = (currentLocation) => {
    console.log("currentLocation:", currentLocation);
    if (currentLocation.includes("/site/") && userType === "E") {
      history.push("/site/tests/" + siteID);
    } else if (currentLocation.includes("/lab/") && userType === "L") {
      history.push("/lab/tests/" + labID);
    } else if (currentLocation.includes("/admin") ){
      history.push("/admin/dashboard");
    }
  };
  return (
    <Navbar bg="light" expand="lg">
      <Container fluid>
        <div className="d-flex justify-content-center align-items-center ml-0 ml-sm-2 ml-md-2 ml-lg-0 ml-xl-0">
          <Button
            variant="dark"
            className="d-lg-none btn-fill d-flex justify-content-center align-items-center rounded-circle p-2 mx-0"
            onClick={mobileSidebarToggle}
          >
            <i className="fas fa-ellipsis-v"></i>
          </Button>
          <Navbar.Brand
            // href={window.location.pathname === "/site/tests/" + siteID ? history.push("/site/tests/" + siteID) : history.push("/admin")}
            href=""
            onClick={(e) => {
              e.preventDefault();
              // history.push("/admin");
              // getBrandText(props.routes)
              getHrefRouteName(window.location.pathname);
            }}
            className="mr-2"
            style={{ fontSize: "20px", fontWeight: "bold" }}
          >
            {/* {getBrandText(props.routes)} */}
            <Link style={{ color: "rgba(0, 0, 0, 0.4)" }}>{roleSwitch(props.role)} Portal</Link>
          </Navbar.Brand>
          {/* <Navbar.Brand
            href="#home"
            onClick={(e) => e.preventDefault()}
            className="mr-2"
            style={{ fontSize: '30px'}}
          >{getBrandText(props.routes) === "Completed Tests" && `(${props.completedTests.length})`}</Navbar.Brand>
          <Navbar.Brand
            href="#home"
            onClick={(e) => e.preventDefault()}
            className="mr-2"
            style={{ fontSize: '30px'}}
          >{getBrandText(props.routes) === "Pending Release" && `(${props.pendingTests.length})`}</Navbar.Brand> */}
        </div>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="mr-2">
          <span className="navbar-toggler-bar burger-lines"></span>
          <span className="navbar-toggler-bar burger-lines"></span>
          <span className="navbar-toggler-bar burger-lines"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="nav mr-auto" navbar>
            {/* <Nav.Item>
              <Nav.Link
                data-toggle="dropdown"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
                className="m-0"
              >
                <i className="nc-icon nc-palette"></i>
                <span className="d-lg-none ml-1">Dashboard</span>
              </Nav.Link>
            </Nav.Item> */}
            {/* <Dropdown as={Nav.Item}>
              <Dropdown.Toggle
                as={Nav.Link}
                data-toggle="dropdown"
                id="dropdown-67443507"
                variant="default"
                className="m-0"
              >
                <i className="nc-icon nc-planet"></i>
                <span className="notification">5</span>
                <span className="d-lg-none ml-1">Notification</span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  Notification 1
                </Dropdown.Item>
                <Dropdown.Item
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  Notification 2
                </Dropdown.Item>
                <Dropdown.Item
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  Notification 3
                </Dropdown.Item>
                <Dropdown.Item
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  Notification 4
                </Dropdown.Item>
                <Dropdown.Item
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  Another notification
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}
            {/* <Nav.Item>
              <Nav.Link
                className="m-0"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <i className="nc-icon nc-zoom-split"></i>
                <span className="d-lg-block"> Search</span>
              </Nav.Link>
            </Nav.Item> */}
          </Nav>
          <Nav className="ml-auto" navbar>
            {/* <Nav.Item>
              <Nav.Link
                className="m-0"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <span className="no-icon">Account</span>
              </Nav.Link>
            </Nav.Item> */}
            {/* <Dropdown as={Nav.Item}>
              <Dropdown.Toggle
                aria-expanded={false}
                aria-haspopup={true}
                as={Nav.Link}
                data-toggle="dropdown"
                id="navbarDropdownMenuLink"
                variant="default"
                className="m-0"
              >
                <span className="no-icon">Dropdown</span>
              </Dropdown.Toggle>
              <Dropdown.Menu aria-labelledby="navbarDropdownMenuLink">
                <Dropdown.Item
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  Action
                </Dropdown.Item>
                <Dropdown.Item
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  Another action
                </Dropdown.Item>
                <Dropdown.Item
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  Something
                </Dropdown.Item>
                <Dropdown.Item
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  Something else here
                </Dropdown.Item>
                <div className="divider"></div>
                <Dropdown.Item
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  Separated link
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}
            <Nav.Item>
              <Nav.Link
                className="m-0 "
                href="#pablo"
                onClick={async (e) => {
                  e.preventDefault();
                  // await DataStore.clear();
                  await Auth.signOut();
                  medFlowInLocalStorage.clear();
                  showsInSS.clear();
                  history.push("/login");
                }}
              >
                <span
                  className="no-icon"
                  style={{ textDecoration: "underline" }}
                  onMouseOver={(e) => (e.target.style.textDecoration = "none")}
                  onMouseLeave={(e) => (e.target.style.textDecoration = "underline")}
                >
                  Log out
                </span>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
