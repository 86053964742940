import React, {useState} from 'react'
import { Modal, Button, Card, Table, Row, Col } from "react-bootstrap";
import { externalTypesOptions , TEST_TYPE_VALUE} from "constant";
import Select from "react-select";
import ErrorMessage from "components/Message/ErrorMessage"


const TestTypeDurationModal = (props) => {
   const { handleClose, handleSave, data}= props;
   const [addHours, setAddHours] = useState(0 );
   const [error, setError] = useState("");
   const [addTestType, setAddtestType] = useState('');
   const [addGroup, setaddGroup] = useState(data);
   console.log(data,addGroup);
   const handleChange =()=>{
        if(!addTestType.length>0){
          setError("Select Test Type Please!");
          return;
        }
        if(addHours <= 0){
          setError("Select Hours Positive Value Please!");
          return;
        }
        const findVal = addGroup.findIndex((c)=> c.testType === addTestType);
        if(findVal !==-1){
          const groupData = addGroup;
            groupData.splice(findVal,1,{testType: addTestType, hours:addHours})
           setaddGroup(groupData);
        }else{
         setaddGroup([...addGroup, {testType: addTestType, hours:addHours}])
        }
          setAddtestType('');
          setAddHours(0);
        }  
        const handleSaveChange =()=>{
            handleSave(JSON.stringify(addGroup));
            handleClose();
        }
        const handleDelete=(index)=>{
          const groupdata =[...addGroup];
          groupdata.splice(index,1);
          setaddGroup(groupdata);
        }
    const TableRow = ({ data, index }) => {
        return (
          <tr>
            <td>{index + 1}</td>
            <td>{TEST_TYPE_VALUE[data.testType]}</td>
            <td>{`${data.hours}h`}</td>
            <td><i className='fas fa-trash' style={{cursor: "pointer"}} onClick={()=>handleDelete(index)}/></td>
          </tr>
        );
      };

  return (
      <> <Modal
        show={true}
        backdrop="static"
        className='externalTestModal'
      onHide={() =>handleClose()}
      centered
      scrollable
      size={"lg"}
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
         Test Type Setting
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ paddingTop: 0 }}>
          <div className='formWrapper mt-2 mb-3 align-items-center'>
            <label>Select Type: </label>
            <Select
              options={externalTypesOptions}
              className="w-25 siteSelector"
              value={addTestType.length > 0 ? {value: addTestType, label: TEST_TYPE_VALUE[addTestType]}:null}
              blurInputOnSelect={true}
              defaultValue={null}
              menuPlacement="bottom"
              placeholder="Select Type"
              onChange={(e)=>setAddtestType(e.value)}
            />
             <label>Duration:</label>
             <input
                    type="number"
                    className="w-25 modalInput"
                    value={addHours}
                    placeholder="only hours"
                    style={{
                      width: "100%",
                    }}
                    onChange={(e)=>setAddHours(e.target.value)}
                  />
            <Button onClick={() => handleChange()}>
          Add
        </Button>
        </div>
        <div style={{minHeight:"3vh"}}>
        {error && (
          <ErrorMessage error={error} handleChange={()=>setError('')}/>
        )}
        </div>
        <Row>
              <Col md="12">
                <Card className="strpied-tabled-with-hover modalHeight">
                  <Card.Body>
                      <div className="form-group-wrapper d-block">
                        <div className="table-responsive" style={{ overflow: "hidden" }}>
                          <Table className="table-hover table-striped">
                            <thead>
                              <tr>
                                <th style={{ width: "10%" }}>SR#</th>
                                <th>TEST TYPE</th>
                                <th>DURATION</th>
                              </tr>
                            </thead>
                            <tbody>
                              {addGroup.length > 0 &&
                                addGroup.map((data, index) => <TableRow data={data} key={index} index={index} />)}
                            </tbody>
                          </Table>
                        </div>
                      

                      </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" className="mt-2" onClick={() =>handleClose()}>
          Close
        </Button>
        <Button className="mt-2" onClick={handleSaveChange}>Save</Button>
      </Modal.Footer>
    </Modal>
</>
  )
}

export default TestTypeDurationModal