import React, { useState, useEffect, useMemo, useContext } from "react";
import { Button, Card, Table, Row, Col, DropdownButton, Dropdown, Modal, Container } from "react-bootstrap";
import { PDFViewer } from "@react-pdf/renderer";
import SitePDF from "components/SitePDF";
import API from "api";
import { Link } from "react-router-dom";

import { AppContext } from "context/app-context";
import { connectedIDInLocalStorage, formateDateLL } from "utils";

const HealthAndSafetyDashBoard = ({ site }) => {
  const [pdf, setPdf] = useState();
  const [showPdf, setShowPdf] = useState(false);
  const [summaryData, setSummaryData] = useState({ positive: 0, negative: 0, total: 0, positiveTest: null });
  const appContext = useContext(AppContext);
  const connectedIDFromRoute = window.location?.pathname?.split("/").pop();
  const [routingPathBasedMedFlow, setRoutingPathBasedMedFlow] = useState({
    userType: "E",
    siteID:
      connectedIDFromRoute.toLowerCase() !== "admin" ? connectedIDFromRoute : connectedIDInLocalStorage.get() || "A",
  });

  useEffect(() => {
    loadSummaryData();
  }, []);

  const loadSummaryData = async () => {
    const connectedID = routingPathBasedMedFlow.siteID || connectedIDInLocalStorage.get() || "A";

    const data = await API.getSummaryFromPG({ siteID: connectedID });
    let newObj = { ...summaryData };
    if (data.rows.length > 0) {
      newObj = data.rows.reduce((obj, item) => {
        if (item["result"] === "Negative") {
          obj.negative = parseInt(item["count"]);
        }
        obj.total = obj.total + parseInt(item["count"]);
        return obj;
      }, summaryData);
    }
    if (data.positiveTest.length > 0) {
      const positiveTests = data.positiveTest;
      const realPositive = positiveTests.filter((t) => t.status !== "Pending");
      if (realPositive.length > 0) {
        newObj.positiveTest = realPositive[0];
      }
      newObj.positive = realPositive.length;
      newObj.total = newObj.total + positiveTests.length;
    }
    setSummaryData({ ...newObj });
  };

  console.log("Site loadSummaryData", summaryData);

  const showStartDate = (startDate) => {
    if (startDate) {
      return new Date(startDate).toDateString();
    }
    return "N/A";
  };

  const getShowRunningDays = (startDate) => {
    if (startDate) {
      return Math.ceil((new Date() - new Date(startDate).getTime()) / (1000 * 3600 * 24));
    }
    return "N/A";
  };

  const lastPositiveTestDate = (testDate) => {
    if (!testDate) return "N/A";
    const date = new Date(testDate);
    const currentDate = new Date();
    const dayLastPositive = Math.floor((currentDate.getTime() - date.getTime()) / (1000 * 3600 * 24));
    return `${date.toDateString()} (${dayLastPositive} days ago)`;
  };

  const totalTestOfShow = (tests) => {
    const ids = tests
      .filter((test) => test.result?.toLowerCase() === "positive" && test.status.toLowerCase() === "pending")
      .map((t) => t.id);
    return tests.filter((t) => !ids.includes(t.id)).length;
  };

  return showPdf ? (
    <Container fluid>
      <Row>
        <Col md="12">
          <Card
            style={{ paddingLeft: 30, marginLeft: "auto", marginRight: "auto" }}
            className="strpied-tabled-with-hover"
          >
            <Card.Header>
              <Row style={{ alignItems: "center" }}>
                <Card.Title as="h4">Showing Summary for {pdf.name}</Card.Title>
                <Button style={{ marginLeft: 30 }} onClick={() => setShowPdf(false)}>
                  Close
                </Button>
              </Row>
            </Card.Header>
            <Card.Body className="table-full-width table-responsive px-0">
              <PDFViewer style={{ flex: 1, width: 500, height: 750 }}>
                <SitePDF site={pdf} client={client} />
              </PDFViewer>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  ) : (
    <>
      <Container fluid style={{ flex: 1 }}>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title
                  as="h3"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                    color: "#565656",
                    textAlign: "center",
                    // textDecoration: "underline",
                  }}
                >
                  {site?.name} Summary
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <div style={{ marginTop: 20, marginBottom: 50, padding: 20 }}>
                  <div className="dashboard-page">
                    <div className="dashboard-tab">
                      <h4>Show Start Date</h4>
                      <div className="box">
                        <p>{showStartDate(site?.startDate)}</p>
                      </div>
                    </div>
                    <div className="dashboard-tab">
                      <h4>Total Tests</h4>
                      <div className="box">
                        <p>
                          {summaryData.total !== 0 ? (
                            <Link
                              style={{ color: "#A82632" }}
                              to={{
                                pathname: connectedIDFromRoute.toLowerCase() !== "admin" ? ("/site/totaltests/" + connectedIDFromRoute) : "/admin/totaltests",
                              }}
                            >
                              {summaryData.total}
                            </Link>
                          ) : (
                            0
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="dashboard-tab">
                      <h4>Last Positive Test</h4>
                      <div className="box">
                        <p>
                          {!summaryData.positiveTest ? (
                            "No Positive Tests"
                          ) : (
                            <Link
                              style={{ color: "#A82632" }}
                              to={{
                                pathname: connectedIDFromRoute.toLowerCase() !== "admin" ? ("/site/positivetests/" + connectedIDFromRoute) : "/admin/positivetests",
                                state: { name: summaryData.positiveTest.barcode, term: "barcode" },
                              }}
                            >
                              {formateDateLL(summaryData.positiveTest.createdAt)}
                            </Link>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="dashboard-tab">
                      <h4>Show Running Days</h4>
                      <div className="box">
                        <p>{getShowRunningDays(site?.startDate)}</p>
                      </div>
                    </div>
                    <div className="dashboard-tab">
                      <h4>Positive Tests</h4>
                      <div className="box">
                        <p>
                          {summaryData.positive !== 0 ? (
                            <Link
                              style={{ color: "#A82632" }}
                              to={{
                                pathname: connectedIDFromRoute.toLowerCase() !== "admin" ? ("/site/positivetests/" + connectedIDFromRoute) : "/admin/positivetests",
                              }}
                            >
                              {summaryData.positive}
                            </Link>
                          ) : (
                            0
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="dashboard-tab">
                      <h4>Negative Tests</h4>
                      <div className="box">
                        <p>
                          {summaryData.negative !== 0 ? (
                            <Link
                              style={{ color: "#A82632" }}
                              to={{
                                
                                pathname: connectedIDFromRoute.toLowerCase() !== "admin" ? ("/site/totaltests/" + connectedIDFromRoute) : "/admin/totaltests",

                                state: { negativeTests: true },
                              }}
                            >
                              {summaryData.negative}
                            </Link>
                          ) : (
                            0
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default HealthAndSafetyDashBoard;
