import React, { useEffect, useState } from "react";
import API from "api";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import moment from "moment";
import DashboardLineChart from "./DashboardLineChart";
import DashboardPieChart from "./DashboardPieChart";
import DashboardBarChart from "./DashboardBarChart";
import DashboardDougnutChart from "./DashboardDougnutChart";
import DashboardChartTotalTestsDataCard from "./DashboardChartTotalTestsData";
import DashboardChartsTableData from "./DashboardChartsTableData";

  const AdminDashboard = (props) => {

    const [totalTestCounts , setTotalCounts] = useState('');

    const totalCounts = (testCountsObj) => {
        setTotalCounts(testCountsObj);
    };

return (
    <div className="dashboard-wrapper bg-transparent">
        <div className="container-fluid mb-3">
            <DashboardChartTotalTestsDataCard totalTestCounts={totalTestCounts} />
        </div>
        <div className="dashboard-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-lg-4 col-sm-12">
                        <div className="bg-white rounded-3 pt-3">
                            <DashboardLineChart statsType={'clientstats'} statTitle="Clients" />
                            <DashboardChartsTableData statsType={'clientstats'} totalCounts={totalCounts}/>
                        </div>
                    </div>

                    <div className="col-12 col-lg-4 col-sm-12">
                        <div className="bg-white rounded-3 pt-3">
                            <DashboardLineChart statsType={'labstats'} statTitle="Labs" />
                            <DashboardChartsTableData statsType={'labstats'} />
                        </div>
                    </div>

                    <div className="col-12 col-lg-4 col-sm-12">
                        <div className="bg-white rounded-3 pt-3">
                            <DashboardLineChart statsType={'sitestats'} statTitle="Sites" />
                            <DashboardChartsTableData statsType={'sitestats'} />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
  );
}

export default AdminDashboard;
