/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Test from "views/Test";
import CrewQRCode from "views/CrewQRCode";
import Typography from "views/Typography.js";
import Icons from "views/Icons.js";
import Maps from "views/Maps.js";
import Notifications from "views/Notifications.js";
import Settings from "views/Settings.js";
import TotalTestsApiNew from "views/GraphQL/TotalTestApiNew";
import PositiveTestsApiNew from "views/GraphQL/PositiveTestApiNew";
import QuarantineNew from "views/QuarantineNew";
import HSCrewNew from "views/GraphQL/HSCrewNew";

const HSRoutesNew = [
  {
    path: "/totaltests/:siteID",
    name: "Total Tests",
    icon: "nc-icon nc-notes",
    component: TotalTestsApiNew,
    layout: "/site",
  },
  {
    path: "/positivetests/:siteID",
    name: "Positive Tests",
    icon: "nc-icon nc-notes",
    component: PositiveTestsApiNew,
    layout: "/site",
  },
  {
    path: "/quarantine/:siteID",
    name: "Quarantine",
    icon: "nc-icon nc-notes",
    component: QuarantineNew,
    layout: "/site",
  },
  /* {
    path: "/settings",
    name: "Settings",
    icon: "nc-icon nc-notes",
    component: Settings,
    layout: "/site",
  }, */
  {
    path: "/crew/:siteID",
    name: "Crew",
    icon: "nc-icon nc-circle-09",
    component: HSCrewNew,
    layout: "/site",
  },
  {
    path: "/qrview/:siteID",
    name: "QRCode",
    icon: "nc-icon nc-circle-09",
    component: CrewQRCode,
    layout: "/site",
    redirect: true,
  },
  {
    path: "/test/:siteID",
    name: "Test",
    icon: "nc-icon nc-circle-09",
    component: Test,
    layout: "/site",
    redirect: true,
  }

  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "nc-icon nc-atom",
  //   component: Icons,
  //   layout: "/site",
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "nc-icon nc-pin-3",
  //   component: Maps,
  //   layout: "/site",
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   icon: "nc-icon nc-bell-55",
  //   component: Notifications,
  //   layout: "/site",
  // },
];

export default HSRoutesNew;
