import React, { useState, useContext } from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import { useEffect } from "react";
import API from "api";
import { AppContext } from "../../context/app-context";

const LabBarCodeAlertSetting = () => {
  const appContext = useContext(AppContext);

  const getAlertValue = () => {
    if (appContext.labs) {
      const barcodelabs = appContext.labs.filter((l) => l.barCodeProvided === true);
      console.log(barcodelabs);
      if (barcodelabs.length > 0) return barcodelabs[0].barCodeAlertLimit || 0;
    }
    return 0;
  };
  const [limit, setLimit] = useState(getAlertValue());
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    const alertVal = getAlertValue();
    if (alertVal > 0) {
      setLimit(alertVal);
    }
  }, [appContext.labs]);

  const onHandleSave = async () => {
    setIsEdit(false);
    await API.updateBarCodeAlert(limit);
    appContext.resetLabs();
  };

  return (
    <div style={{ flex: 1 }}>
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{
                  marginBottom: 10,
                  fontWeight: "bold",
                  color: "#565656",
                }}
              >
                Lab Settings
              </Card.Title>
            </Card.Header>

            <Card.Body className="table-full-width px-0">
              <div className="formWrapper">
                <div>Barcode Alert Limit:</div>
                <div>
                  <input
                    value={limit}
                    disabled={!isEdit}
                    className="modalInput"
                    placeholder="Alert Limit"
                    style={{
                      width: "100%",
                    }}
                    onChange={(e) => setLimit(e.target.value)}
                  />
                </div>
                <div>
                  {!isEdit && (
                    <Button variant="primary" className="modalButtons" onClick={() => setIsEdit(true)}>
                      Edit
                    </Button>
                  )}
                  {isEdit && (
                    <Button variant="primary" className="modalButtons" onClick={onHandleSave}>
                      Update
                    </Button>
                  )}
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default LabBarCodeAlertSetting;
