import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { SITE_STATUS_OPTIONS } from "../../constant";
import Select from "react-select";

const ChangeStatusModal = (props) => {
  const { handleSave, handleClose, selectedStatus } = props;
  const [result, setResult] = useState();
  const [error, setError] = useState("");
  console.log("selectedStatus", selectedStatus);

  useEffect(() => {
    let statusValue = selectedStatus === null ? 1 : selectedStatus;
    setResult(SITE_STATUS_OPTIONS.find((s) => s.value === statusValue));
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setError("");
    }, 4000);
  }, [error]);

  const onHandleSave = () => {
    if (!result) {
      setError("Please select the Status");
      return;
    }
    handleSave(result.value);
    handleClose();
  };

  return (
    <>
      <Modal
        show
        animation={true}
        onHide={() => {
          handleClose();
        }}
        style={{ paddingLeft: "0" }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            Change Site Status
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: 0,
          }}
        >
          <div className="form-group-wrapper">
            <label className="modalLineHeaders">Status:</label>
            <Select
              options={SITE_STATUS_OPTIONS}
              blurInputOnSelect={true}
              menuPlacement="auto"
              placeholder="Select a Status"
              className="w-100 roleSelector"
              value={result}
              onChange={(e) => setResult({ label: e.label, value: e.value })}
            />
          </div>

          {error.length > 0 && <strong style={{ color: "Red" }}>{error}</strong>}
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            className="modalButtons"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
          <Button style={{ marginBottom: 10 }} variant="primary" className="modalButtons" onClick={onHandleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ChangeStatusModal;
