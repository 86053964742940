import React from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { formatLogDate ,capitalizeLetter} from "utils";

const TestLogsModal = (props) => {
  const { data, handleClose } = props;
  const getSequenceNo = ()=>{
    if(data && data.length > 0) return data[0].sequenceNo;
    return '';
  }
  return (
    <Modal show animation={true} onHide={() => handleClose()} centered size="xl">
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          Test Logs {getSequenceNo()}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="form-group-wrapper d-block">
          <div className="table-responsive">
            <Table className="table-hover table-striped">
              <thead>
                <tr>
                  <th
                    className={`border-0`}
                    style={{
                      color: "grey",
                      fontWeight: "bold",
                    }}
                  >
                    <div className="d-flex justify-content-start align-items-center">
                      <span>Date</span>
                    </div>
                  </th>
                  <th
                    className={`border-0 centered`}
                    style={{
                      color: "grey",
                      fontWeight: "bold",
                    }}
                  >
                    <div className="d-flex justify-content-start align-items-center">
                      <span>Action</span>
                    </div>
                  </th>
                  <th
                    className={`border-0 centered`}
                    style={{
                      color: "grey",
                      fontWeight: "bold",
                    }}
                  >
                    <div>
                      <span>User</span>
                    </div>
                  </th>
                  <th
                    className={`border-0 centered`}
                    style={{
                      color: "grey",
                      fontWeight: "bold",
                    }}
                  >
                    <div>
                      <span>oldValue</span>
                    </div>
                  </th>
                  <th
                    className={`border-0 centered`}
                    style={{
                      color: "grey",
                      fontWeight: "bold",
                    }}
                  >
                    <div>
                      <span>newValue</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((msg, index) => {
                  return (
                    <tr key={index}>
                      <td>{formatLogDate(msg.createdAt)}</td>
                      <td  style={{ textAlign: "center" }}>{msg.slug}</td>
                      <td style={{ textAlign: "center" }}>{msg.userName || "N/A"}</td>
                      <td style={{ textAlign: "center" }}>{capitalizeLetter(msg.prevValue || msg.oldValue || "-")}</td>
                      <td style={{ textAlign: "center" }}>{ capitalizeLetter(msg.newValue || msg.result || "-")}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer className="flex-row-reverse">
        <Button variant="primary" onClick={() => handleClose()}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TestLogsModal;
