import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";

const TestTypeModal = ({ item, handleSave, handleClose }) => {
  const [newItem, setNewItem] = useState(item);
  const validateInput = () => {
    if (!validateTimeInput(newItem.totalTime)) {
      alert("Enter valid total Time");
      return;
    }
    if (!validateTimeInput(newItem.firstAlert)) {
      alert("Enter valid First Alert");
      return;
    }
    if (!validateTimeInput(newItem.secondAlert)) {
      alert("Enter valid Second Alert");
      return;
    }
    handleSave(newItem);
  };

  const validateTimeInput = (val) => /^[0-9][0-9]:[0-5][0-9]$/.test(val);

  return (
    <Modal
      show
      // style={{ transform: "translate(0, -30%)" }}
      animation={true}
      onHide={() => handleClose()}
      centered
      size={"md"}
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0 h4" id="contained-modal-title-vcenter">
          Edit Times
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ paddingTop: 0 }}>
        <p className="modalLineHeaders">Test Name</p>
        <h6>{newItem.name}</h6>
        <p className="modalLineHeaders">Total Time</p>
        <input
          className="modalInput"
          value={newItem.totalTime}
          placeholder="Primary Contact"
          style={{ width: "100%" }}
          onChange={(e) => setNewItem({ ...newItem, totalTime: e.target.value })}
        />
        <p className="modalLineHeaders">First Alert</p>
        <input
          className="modalInput"
          value={newItem.firstAlert}
          style={{ width: "100%" }}
          onChange={(e) => setNewItem({ ...newItem, firstAlert: e.target.value })}
        />
        <p className="modalLineHeaders">Second Alert</p>
        <input
          className="modalInput"
          value={newItem.secondAlert}
          style={{ width: "100%" }}
          onChange={(e) => setNewItem({ ...newItem, secondAlert: e.target.value })}
        />
        <p className="modalLineHeaders">Test Valid Duration in Hours</p>
        <input
          type="number"
          className="modalInput"
          value={newItem.duration}
          style={{ width: "100%" }}
          placeholder="Duration in hours"
          onChange={(e) => setNewItem({ ...newItem, duration: e.target.value })}
        />
      </Modal.Body>

      <Modal.Footer>
      <Button variant="secondary" className="modalButtons" onClick={() => handleClose()}>
          Close
        </Button>
        <Button variant="primary" className="modalButtons" onClick={validateInput}>
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TestTypeModal;
