import React, { useContext, useState, useMemo, useRef } from "react";
import { Button, Card, Table, Row, Col, Modal, OverlayTrigger, Alert } from "react-bootstrap";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import API from "api";
import SearchBar from "components/SearchBar";
import { Auth, Storage } from "aws-amplify";
import { Bounce } from "react-activity";
import "react-activity/dist/Bounce.css";
import Filter from "components/Filter";
import TimeFilter from "components/TimeFilter";
import ErrorMessage from "components/Message/ErrorMessage";
import {
  changeSort,
  formatPhoneNumber,
  isValidPhone,
  phoneFormatter,
  parseBooleanValue,
  isValidFile,
  calculateTdWidth,
  sortingFilterInLC,
} from "utils";
import HeaderItem from "components/Table/HeaderItem";
import MFPagination from "components/Pagination/MFPagination";
import { PAGE_LIMIT } from "constant";
import LabBarCode from "components/Modal/LabBarCode";
import BarCodeAlert from "components/Labs/BarCodeAlert";
import TextEditor from "views/TextEditor";

import { AppContext } from "context/app-context";
import ViewOnlyCheckBox from "components/CheckBox/ViewOnlyCheckBox";
import { DISCLAIMER_TYPE } from "constant";
import { DISCLAIMER_KEYS } from "constant";
import PhoneNoInput from "components/Phone/PhoneNoInput";
import Loader from "../../components/Loader/Loader";

const LabsView = () => {
  const mainRef = useRef();
  const [width, setWidth] = useState(mainRef.current ? mainRef.current.offsetWidth : 0);

  const handleResize = () => {
    if (mainRef.current) setWidth(mainRef.current.offsetWidth);
  };
  useEffect(() => handleResize(), [mainRef, mainRef.current]);
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  const tdWidth = calculateTdWidth(width - 110, 14);

  const appContext = useContext(AppContext);
  const [labTests, setLabTests] = useState([]);
  const [labs, setLabs] = useState(appContext.labs ?? []);
  const [filteredLabs, setFilteredLabs] = useState([]);
  const [openCreator, setOpenCreator] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [labToDelete, setLabToDelete] = useState("");
  const [newLab, setNewLab] = useState({});
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [loading, setLoading] = useState(false);
  const [timeFilter, setTimeFilter] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedImage, setSelectedImage] = useState("");
  const [logo, setLogo] = useState(null);
  const [showEditor, setShowEditor] = useState(null);
  const inputFile = useRef();
  const [showCSVImporter, setShowCSVImporter] = useState(false);
  const [error, setError] = useState("");

  const { filterDates } = TimeFilter;

  const searchTerms = [
    "Name",
    "Contact Name",
    "Contact Email",
    "Contact Phone",
    "Print Labels",
    "Antigen",
    "Molecular",
    "Accula",
    "Send Insurance",
    "BarCodes",
    "White Label",
  ];

  const handleClose = () => {
    setOpenCreator(false);
    setOpenDeleteConfirmation(false);
  };

  useEffect(() => {
    const sortingFilters = sortingFilterInLC.get();
    if (sortingFilters.labs.sortBy) {
      setSortDescending(sortingFilters.labs.sortDescending);
      setSortBy(sortingFilters.labs.sortBy);
    }
    loadSummary();
  }, []);

  useEffect(() => {
    setLabs(appContext.labs);
    setFilteredLabs(appContext.labs);
  }, [appContext.labs]);

  useEffect(() => {
    const prevSortingState = sortingFilterInLC.get();
    prevSortingState["labs"] = { filter, timeFilter, sortBy, sortDescending };
    sortingFilterInLC.save(prevSortingState);
    changeSort(
      sortBy,
      sortDescending,
      filteredLabs,
      setFilteredLabs,
      appContext.getSiteName,
      appContext.getLabName,
      appContext.getClientName,
      appContext.getLabTests
    );
  }, [sortBy, sortDescending]);

  useEffect(() => {
    refreshSummary(labs, labTests);
  }, [labTests]);

  const loadSummary = async () => {
    const summary = await API.getSummaryFromPG({ summary: "lab" });
    setLabTests(summary.rows);
  };

  const openLab = (labID) => {
    let url = `${window.location.origin}/lab/tests/${labID}`;
    window.open(url);
  };
  const refreshSummary = (data, summary) => {
    if (data.length === 0) return;
    if (summary.length === 0) {
      setFilteredLabs(data);
      return;
    }
    const newList = data.map((c) => {
      const obj = summary.find((x) => x.id === c.id);
      return { ...c, totalTest: obj ? obj.totalTest : 0 };
    });
    setFilteredLabs(newList);
  };

  const isValidToSave = async (obj, isNew) => {

    if (!obj.name) {
      setError("Lab name is required");
      return false;
    }

    if (!obj.contact) {
      setError("Lab primary contact is required");
      return false;
    }

    if (!obj.email) {
      setError("Lab email is required");
      return false;
    }

    if (!obj.phone) {
      setError("Lab phone is required");
      return false;
    }

    if (!obj.street) {
      setError("Lab address is required");
      return false;
    }

    if (!obj.city_state_zip) {
      setError("Lab city/state/zip is required");
      return false;
    }

    if (!obj.lab_director) {
      setError("Lab director name is required");
      return false;
    }

    if (!obj.clia_number) {
      setError("Lab CLIA# is required");
      return false;
    }

    if (!obj.testing_notes) {
      setError("Lab Disclaimer is required");
      return false;
    }

    if (!isValidPhone(obj.phone)) {
      setError("Invalid Phone Number");
      return false;
    }

    if (obj.default_antigen) {
      await API.resetDefaultLab(obj.id, "default_antigen");
    }

    if (obj.default_molecular) {
      await API.resetDefaultLab(obj.id, "default_molecular");
    }

    if (obj.default_rapidflu) {
      await API.resetDefaultLab(obj.id, "default_rapidflu");
    }

    if (obj.default_lucira) {
      await API.resetDefaultLab(obj.id, "default_lucira");
    }

    if (obj.default_other) {
      await API.resetDefaultLab(obj.id, "default_other");
    }

    return true;
  };

  const createLab = async () => {
    const validInput = await isValidToSave(newLab, true);
    if (!validInput) {
      return;
    }
    handleClose();

    try {
      setLoading(true);
      const newest = await API.createLab(newLab);
      if (logo) {
        const isLogoSave = await uploadLogo(newest.id);
        if (isLogoSave) {
          await API.updateLabLogo(newest.id, `labs/${newest.id}-logo.png`);
        }
      }
      setLogo(null);
      setNewLab({});
      appContext.resetLabs();
      appContext.showSuccessMessage("Lab created successfully");
    } catch (error) {
      console.log(error);
      appContext.showErrorMessage(error.message);
    }
    setLoading(false);
  };

  const deleteLab = async (id) => {
    try {
      await API.deleteLab(id);
      appContext.resetLabs();
      appContext.showSuccessMessage("Lab delete successfully");
    } catch (error) {
      appContext.showErrorMessage(error.message);
    }
  };

  const updateLab = async () => {
    const validInput = await isValidToSave(newLab, false);
    if (!validInput) {
      return;
    }

    handleClose();
    const obj = { ...newLab };
    try {
      setLoading(true);
      if (logo) {
        const isLogoSave = await uploadLogo(obj.id);
        if (isLogoSave) Object.assign(obj, { logo: `labs/${obj.id}-logo.png` });
      }
      await API.updateLab(obj);
      setLogo(null);
      setNewLab({});
      appContext.resetLabs();
      appContext.showSuccessMessage("Lab update successfully");
    } catch (error) {
      console.log("Error", error);
      appContext.showErrorMessage(error.message);
    }
    setLoading(false);
  };

  const uploadLogo = async (id) => {
    if (logo) {
      try {
        // const response = await Storage.put(`${id}-logo.png`, logo);
        let fileName = `labs/${id}-logo.png`;
        await Storage.put(fileName, logo, { bucket: "medflow-images" });
        return true;
      } catch (error) {
        return false;
      }
    }
    return false;
  };

  const filterLabs = (term, input) => {
    setFilteredLabs(labs.filter((lab) => lab[term].toLowerCase().includes(input.toLowerCase())));
  };

  const labsToMap = useMemo(() => {
    const list = filterDates(filteredLabs, timeFilter);
    const ttlPages = list.length > PAGE_LIMIT ? Math.ceil(list.length / PAGE_LIMIT) : 1;
    setTotalPages(ttlPages);
    if (list.length < PAGE_LIMIT || currentPage > ttlPages) {
      setCurrentPage(1);
    }
    if (ttlPages > 1) {
      const indexOfLastUser = currentPage * PAGE_LIMIT;
      const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
      return list.slice(indexOfFirstUser, indexOfLastUser);
    }
    return list;
  }, [filteredLabs, currentPage, timeFilter]);

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filter).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length) {
          return true;
        }
        if (key === "tubes_provided") {
          return parseBooleanValue(obj[key]).toLowerCase().includes(filters[key]?.toLowerCase());
        }
        if (key === "default_antigen") {
          return parseBooleanValue(obj[key]).toLowerCase().includes(filters[key]?.toLowerCase());
        }
        if (key === "default_molecular") {
          return parseBooleanValue(obj[key]).toLowerCase().includes(filters[key]?.toLowerCase());
        }
        if (key === "default_other") {
          return parseBooleanValue(obj[key]).toLowerCase().includes(filters[key]?.toLowerCase());
        }
        if (key === "default_rapidflu") {
          return parseBooleanValue(obj[key]).toLowerCase().includes(filters[key]?.toLowerCase());
        }
        if (key === "default_lucira") {
          return parseBooleanValue(obj[key]).toLowerCase().includes(filters[key]?.toLowerCase());
        }
        if (key === "sendInsurance") {
          return parseBooleanValue(obj[key]).toLowerCase().includes(filters[key]?.toLowerCase());
        }
        if (key === "barCodeProvided") {
          return parseBooleanValue(obj[key]).toLowerCase().includes(filters[key]?.toLowerCase());
        }
        if (key === "whiteLabel") {
          return parseBooleanValue(obj[key]).toLowerCase().includes(filters[key]?.toLowerCase());
        }

        return obj[key] && obj[key].toLowerCase().includes(filters[key]);
      });
    });
    // console.log("FROM NESTED FILTER", models);
    return models;
  };

  useEffect(() => {
    setFilteredLabs(nestedFilter(labs, filter));
  }, [filter]);

  const handleFilterChange = (event, picker) => setTimeFilter({ start: picker.startDate, end: picker.endDate });
  const handleFilterCancel = (event, picker) => setTimeFilter({});
  const handleChangePage = (page) => setCurrentPage(page);

  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(sortBy === by ? !sortDescending : true);
  };

  const handleSave = () => {
    if (newLab.isNew) createLab();
    else updateLab();
  };

  const onChangeImage = async (event) => {
    if (event.currentTarget.files && event.currentTarget.files.length > 0) {
      const file = event.currentTarget.files[0];
      const isValid = await isValidImageHeightWidth(file);
      if (!isValid) {
        // setError("File resolution should be upto 1500px*500px and less then 1600px*600px");
        setError("File resolution should be min 1200px*400px");
        setLogo("");
        setSelectedImage("");
        return;
      }
      const message = isValidFile(file);
      if (message.length > 0) {
        setLogo();
        setSelectedImage();
        setError(message);
        return;
      }

      setLogo(file);
      setSelectedImage(URL.createObjectURL(file));
    }
  };

  const isValidImageHeightWidth = (file) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = window.URL.createObjectURL(file);
      img.onload = () => {
        console.log("Image ", img.width, img.height);
        if (img.width >= 1200 && img.width <= 1600 && img.height >= 400 && img.height <= 600) {
          resolve(true);
        }
        resolve(false);
      };
      img.onerror = () => resolve(false);
    });
  };

  const handleImageClick = () => {
    if (inputFile.current) {
      inputFile.current.click();
    }
  };

  const handleEditLab = async (lab) => {
    setNewLab({
      isNew: false,
      id: lab.id,
      name: lab.name,
      contact: lab.contact_name,
      phone: lab.contact_phone || lab.phone_number,
      email: lab.contact_email,
      street: lab.street,
      city_state_zip: lab.city_state_zip,
      lab_director: lab.lab_director,
      clia_number: lab.clia_number,
      testing_notes: lab.testing_notes,
      tubes_provided: lab.tubes_provided,
      default_antigen: lab.default_antigen,
      default_molecular: lab.default_molecular,
      default_rapidflu: lab.default_rapidflu,
      default_lucira: lab.default_lucira,
      sendInsurance: lab.sendInsurance,
      logo: lab.logo,
      barCodeProvided: lab.barCodeProvided,
      barCodeAlertLimit: lab.barCodeAlertLimit,
      antigen_notes: lab.antigen_notes,
      molecular_notes: lab.molecular_notes,
      showOnSummaryScreen: lab.showOnSummaryScreen || false,
      whiteLabel: lab.whiteLabel,
      whiteLabelPackage: lab.whiteLabelPackage,
      default_other: lab.default_other,
      other_notes: lab.other_notes,
      isArchive: lab.isArchive,
      lucira_notes: lab.lucira_notes,
      rapidflu_notes: lab.rapidflu_notes,
      pendingRelease: parseInt(lab.pendingRelease) || 0,
    });
    if (lab.logo) {
      try {
        let imagePath = `${lab.logo}`;
        const path = await Storage.get(imagePath, { bucket: "medflow-images" });
        setSelectedImage(path);
      } catch (error) {
        console.log("Fail to load the logo");
      }
    } else {
      setSelectedImage(null);
    }
    setOpenCreator(true);
  };

  const getTotalTest = (lab) => {
    if (!lab.totalTest || lab.totalTest === 0) {
      return <>0</>;
    }
    return (
      <Link
        style={{ color: "#42cef5" }}
        to={{
          pathname: `/admin/totaltests`,
          state: { name: lab.name, term: "Lab", models: labs },
        }}
      >
        {lab.totalTest}
      </Link>
    );
  };

  const TableRow = ({ lab }) => {
    return (
      <tr key={lab.id}>
        <td className="icon" style={{ width: "50px", minWidth: "50px", maxWidth: "50px" }}>
          <i
            className="fas fa-edit"
            onMouseLeave={(e) => (e.target.style.color = "black")}
            onMouseOver={(e) => {
              e.target.style.color = "blue";
              e.target.style.cursor = "pointer";
            }}
            aria-hidden="true"
            id={lab.id}
            onClick={(e) => handleEditLab(lab)}
          />
        </td>
        <td className="ellipsis" title={lab.name}>
          {lab.name}
        </td>
        <td className="ellipsis" title={lab.contact_name}>
          {lab.contact_name}
        </td>
        <td
          className="ellipsis"
          title={lab.contact_email}
          style={{
            textDecoration: "underline",
            color: "#A82632",
          }}
          onMouseOver={(e) => {
            e.target.style.cursor = "pointer";
            e.target.style.textDecoration = "underline";
          }}
          onClick={() => lab.contact_email && window.open(`mailto:${lab.contact_email}`)}
        >
          {lab.contact_email}
        </td>
        <td
          className="ellipsis"
          style={{ textDecoration: "underline", color: "#A82632" }}
          onMouseOver={(e) => {
            e.target.style.cursor = "pointer";
            e.target.style.textDecoration = "none";
          }}
          onMouseLeave={(e) => {
            e.target.style.textDecoration = "underline";
          }}
          onClick={() => {
            lab.contact_phone && window.open(`tel:+${lab.contact_phone.replace(/\D/g, "")}`);
          }}
          title={formatPhoneNumber(lab.contact_phone || lab.phone_number)}
        >
          {formatPhoneNumber(lab.contact_phone || lab.phone_number)}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          {getTotalTest(lab)}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          {parseBooleanValue(lab.tubes_provided)}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          <span title={parseBooleanValue(lab.default_antigen)}>{parseBooleanValue(lab.default_antigen)}</span>
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          <span title={parseBooleanValue(lab.default_molecular)}>{parseBooleanValue(lab.default_molecular)}</span>
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          <span title={parseBooleanValue(lab.default_other)}>{parseBooleanValue(lab.default_other)}</span>
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          <span title={parseBooleanValue(lab.default_rapidflu)}>{parseBooleanValue(lab.default_rapidflu)}</span>
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          <span title={parseBooleanValue(lab.default_lucira)}>{parseBooleanValue(lab.default_lucira)}</span>
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          <span title={parseBooleanValue(lab.sendInsurance)}>{parseBooleanValue(lab.sendInsurance)}</span>
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          <span>
            {parseBooleanValue(lab.barCodeProvided) === "Yes" ? (
              <div
                onClick={() => {
                  setShowCSVImporter(lab.id);
                }}
              >
                <span style={{ textDecoration: "underline", color: "#42cef5", cursor: "pointer" }}>View</span> &nbsp;
                <BarCodeAlert id={lab.id} labData={lab} title="Available barcode is below the limit" />
              </div>
            ) : (
              "No"
            )}
          </span>
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          <span title={parseBooleanValue(lab.whiteLabel)}>{parseBooleanValue(lab.whiteLabel)}</span>
        </td>
        <td className="ellipsis">
          <Link
            style={{ color: "#42cef5" }}
            className="ellipsis"
            //to={{ pathname: "/labs/" + lab.id }}
            onClick={() => {
              openLab(lab.id);
            }}
          >
            Launch Portal
          </Link>
        </td>
        <td className="icon" style={{ width: "50px", minWidth: "50px", maxWidth: "50px" }}>
          <i
            className="fa fa-trash"
            onMouseLeave={(e) => (e.target.style.color = "black")}
            onMouseOver={(e) => {
              e.target.style.color = "#8B0000";
              e.target.style.cursor = "pointer";
            }}
            aria-hidden="true"
            id={lab.id}
            onClick={(e) => {
              // deleteLab(e.target.id)
              setOpenDeleteConfirmation(true);
              setLabToDelete(e.target.id);
            }}
          />
        </td>
      </tr>
    );
  };

  const handleDisclaimerSave = (val, type) => {
    setShowEditor(null);
    setNewLab({ ...newLab, [DISCLAIMER_KEYS[type]]: val });
  };

  return (
    <div style={{ flex: 1 }}>
      {!loading ? (
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Row>
                  <Card.Title
                    as="h4"
                    style={{
                      marginBottom: 10,
                      fontWeight: "bold",
                      color: "#565656",
                    }}
                  >
                    Labs ({filteredLabs.length})
                  </Card.Title>
                  <div className="clientsButtonHeader buttonHeader justify-content-between mb-1">
                    <div className="clientsButtonHeader buttonHeader">
                      <Button className="headerButton mb-0" onClick={() => setShowFilter(!showFilter)}>
                        Filter
                      </Button>
                    </div>
                    <Button
                      className="createButtons"
                      onClick={() => (
                        setOpenCreator(true),
                        setNewLab({
                          isNew: true,
                          default_antigen: false,
                          default_molecular: false,
                          default_other: false,
                          default_rapidflu: false,
                          default_lucira: false,
                          sendInsurance: false,
                          barCodeProvided: false,
                          whiteLabel: false,
                          isArchive: false,
                          pendingRelease: 0,
                        }),
                        setSelectedImage(null),
                        setLogo(null)
                      )}
                    >
                      Create a Lab
                    </Button>
                  </div>
                  <div>{showFilter && <Filter filterTerms={searchTerms} setFilter={setFilter} filter={filter} />}</div>
                </Row>
                <Col>
                  <MFPagination currentPage={currentPage} handleChangePage={handleChangePage} totalPages={totalPages} />
                </Col>
              </Card.Header>

              <Card.Body className="table-full-width px-0 desktop-noScroll">
                <div className="table-responsive pendingReleaseTable" ref={mainRef}>
                  <Table className="table-hover table-striped ">
                    <thead>
                      <tr>
                        <th></th>

                        <HeaderItem
                          width={tdWidth}
                          ItemKey="name"
                          title="Name"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="contact_name"
                          title="Contacts Name"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="contact_email"
                          title="Contacts's Email"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="contact_phone"
                          title="Contacts's Phone"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />

                        <HeaderItem
                          width={tdWidth}
                          ItemKey="totalTests"
                          title="Total Tests (YTD)"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="tubes_provided"
                          title="Print Labels"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="default_antigen"
                          title="Antigen"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="default_molecular"
                          title="Molecular"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="default_other"
                          title="Accula"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="default_rapidflu"
                          title="Radif Flu"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="default_lucira"
                          title="Lucira"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="sendInsurance"
                          title="Send Insurance"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="barCodeProvided"
                          title="BarCodes"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="whiteLabel"
                          title="White Label"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <th
                          className="border-0 centered text-grey"
                          style={{ fontWeight: "bold", width: tdWidth, minWidth: tdWidth, maxWidth: tdWidth }}
                        >
                          View
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {labsToMap.map((lab) => {
                        return <TableRow key={lab.id} lab={lab} />;
                      })}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <Loader />
      )}
      <Modal className="createLabModal" show={openCreator} animation={true} onHide={handleClose} centered size="xl">
        <Modal.Header className="mb-0" closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            {newLab.isNew ? "Create Lab" : "Update Lab"}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ paddingTop: "0", paddingBottom: "0" }}>
          <div className="createShowWrapper">
            <div className="createShowInner">
              <div className="createClientsWrapper">
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Lab Name: </label>
                  <input
                    id="labname"
                    className="labModalInput"
                    placeholder="Lab Name"
                    disabled={!newLab.isNew}
                    value={newLab.name}
                    onChange={(e) => setNewLab({ ...newLab, name: e.target.value })}
                  />
                </div>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Contact Name: </label>
                  <input
                    id="contactname"
                    className="labModalInput"
                    placeholder="Primary Contact"
                    value={newLab.contact}
                    onChange={(e) => setNewLab({ ...newLab, contact: e.target.value })}
                  />
                </div>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Email: </label>
                  <input
                    id="contactemail"
                    className="labModalInput"
                    placeholder="Email"
                    value={newLab.email}
                    onChange={(e) => setNewLab({ ...newLab, email: e.target.value })}
                  />
                </div>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Phone Number: </label>

                  <PhoneNoInput
                    value={newLab.phone}
                    handleChange={(e) => setNewLab({ ...newLab, phone: e })}
                    cssCode={"w-100"}
                  />
                </div>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Address: </label>
                  <input
                    className="modalInput"
                    id="street"
                    placeholder="Address"
                    value={newLab.street}
                    onChange={(e) => setNewLab({ ...newLab, street: e.target.value })}
                  />
                </div>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">City/State/Zip: </label>
                  <input
                    id="city_state_zip"
                    style={{
                      marginTop: 5,
                    }}
                    className="modalInput"
                    placeholder="City, State Zip"
                    value={newLab.city_state_zip}
                    onChange={(e) => setNewLab({ ...newLab, city_state_zip: e.target.value })}
                  />
                </div>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Lab Director: </label>
                  <input
                    id="lab_director"
                    className="labModalInput"
                    placeholder="Lab Director"
                    value={newLab.lab_director}
                    onChange={(e) => setNewLab({ ...newLab, lab_director: e.target.value })}
                  />
                </div>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">CLIA #: </label>
                  <input
                    id="clia_number"
                    className="labModalInput"
                    placeholder="CLIA Number"
                    value={newLab.clia_number}
                    onChange={(e) => setNewLab({ ...newLab, clia_number: e.target.value })}
                  />
                </div>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Disclaimer: </label>
                  <div
                    className="linkedText"
                    onClick={() => setShowEditor({ type: DISCLAIMER_TYPE.DISCLAIMER, message: newLab.testing_notes })}
                  >
                    {newLab.isNew ? "Edit Text" : "Update Text"}
                  </div>
                  {showEditor && (
                    <TextEditor
                      text={showEditor.message ?? ""}
                      handleSave={handleDisclaimerSave}
                      handleClose={() => setShowEditor(null)}
                      type={showEditor.type}
                    />
                  )}
                </div>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Logo: </label>
                  <div className="inputFileWrapper" onClick={handleImageClick}>
                    {selectedImage && <img src={selectedImage} />}
                    <input
                      type="file"
                      ref={inputFile}
                      accept="image/*"
                      onChange={(e) => onChangeImage(e)}
                      style={{ display: "none" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="createSettingsWrapper">
              <div>
                <div className="form-group-wrapper showsModal">
                  <div className="w-100">
                    <div className="checkbox checkBoxWrapper">
                      <span style={{ marginRight: "8px" }}>Print Labels</span>
                      <div className="d-flex justify-content-start align-items-center">
                        <span className="labelYesNo">{parseBooleanValue(newLab.tubes_provided)}</span>
                        <ViewOnlyCheckBox
                          id="tubes_provided"
                          bgColor="blue"
                          checked={newLab.tubes_provided}
                          handleChange={(e) => {
                            const val = !newLab.tubes_provided;
                            if (val) {
                              setNewLab({ ...newLab, tubes_provided: val });
                            } else {
                              if (!newLab.barCodeProvided) setNewLab({ ...newLab, tubes_provided: val });
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="checkbox checkBoxWrapper">
                      <span style={{ marginRight: "8px" }}>Default Antigen</span>
                      <div className="d-flex justify-content-start align-items-center">
                        <span className="labelYesNo">{parseBooleanValue(newLab.default_antigen)}</span>
                        <ViewOnlyCheckBox
                          id="default_antigen"
                          bgColor="blue"
                          checked={newLab.default_antigen}
                          handleChange={(e) => setNewLab({ ...newLab, default_antigen: !newLab.default_antigen })}
                        />
                      </div>
                    </div>

                    <div className="checkbox checkBoxWrapper">
                      <span style={{ marginRight: "8px" }}>Default Molecular</span>
                      <div className="d-flex justify-content-start align-items-center">
                        <span className="labelYesNo">{parseBooleanValue(newLab.default_molecular)}</span>
                        <ViewOnlyCheckBox
                          id="default_molecular"
                          bgColor="blue"
                          checked={newLab.default_molecular}
                          handleChange={(e) => setNewLab({ ...newLab, default_molecular: !newLab.default_molecular })}
                        />
                      </div>
                    </div>

                    <div className="checkbox checkBoxWrapper">
                      <span style={{ marginRight: "8px" }}>Default Accula</span>
                      <div className="d-flex justify-content-start align-items-center">
                        <span className="labelYesNo">{parseBooleanValue(newLab.default_other)}</span>
                        <ViewOnlyCheckBox
                          id="default_other"
                          bgColor="blue"
                          checked={newLab.default_other}
                          handleChange={(e) => setNewLab({ ...newLab, default_other: !newLab.default_other })}
                        />
                      </div>
                    </div>

                    <div className="checkbox checkBoxWrapper">
                      <span style={{ marginRight: "8px" }}>Default Rapid Flu</span>
                      <div className="d-flex justify-content-start align-items-center">
                        <span className="labelYesNo">{parseBooleanValue(newLab.default_rapidflu)}</span>
                        <ViewOnlyCheckBox
                          id="default_rapidflu"
                          bgColor="blue"
                          checked={newLab.default_rapidflu}
                          handleChange={(e) => setNewLab({ ...newLab, default_rapidflu: !newLab.default_rapidflu })}
                        />
                      </div>
                    </div>
                    <div className="checkbox checkBoxWrapper">
                      <span style={{ marginRight: "8px" }}>Default Lucira</span>
                      <div className="d-flex justify-content-start align-items-center">
                        <span className="labelYesNo">{parseBooleanValue(newLab.default_lucira)}</span>
                        <ViewOnlyCheckBox
                          id="default_lucira"
                          bgColor="blue"
                          checked={newLab.default_lucira}
                          handleChange={(e) => setNewLab({ ...newLab, default_lucira: !newLab.default_lucira })}
                        />
                      </div>
                    </div>

                    <div className="checkbox checkBoxWrapper">
                      <span style={{ marginRight: "8px" }}>Send Insurance Info</span>
                      <div className="d-flex justify-content-start align-items-center">
                        <span className="labelYesNo">{parseBooleanValue(newLab.sendInsurance)}</span>
                        <ViewOnlyCheckBox
                          id="sendInsurance"
                          bgColor="blue"
                          checked={newLab.sendInsurance}
                          handleChange={(e) => setNewLab({ ...newLab, sendInsurance: !newLab.sendInsurance })}
                        />
                      </div>
                    </div>
                    <div className="checkbox checkBoxWrapper">
                      <span style={{ marginRight: "8px" }}>Use Lab BC Numbers</span>
                      <div className="d-flex justify-content-start align-items-center">
                        <span className="labelYesNo">{parseBooleanValue(newLab.barCodeProvided)}</span>
                        <ViewOnlyCheckBox
                          id="barCodeProvided"
                          bgColor="blue"
                          checked={newLab.barCodeProvided}
                          handleChange={(e) => {
                            const val = !newLab.barCodeProvided;
                            let limit = 0;
                            if (val) {
                              const barcodelabs = appContext.labs.filter((l) => l.barCodeProvided === true);
                              if (barcodelabs.length > 0) limit = barcodelabs[0].barCodeAlertLimit || 0;
                            }
                            setNewLab({
                              ...newLab,
                              barCodeProvided: val,
                              tubes_provided: val ? val : newLab.tubes_provided,
                              barCodeAlertLimit: limit,
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className="checkbox checkBoxWrapper">
                      <span style={{ marginRight: "8px" }}>Exclude from Summary</span>
                      <div className="d-flex justify-content-start align-items-center">
                        <span className="labelYesNo">{parseBooleanValue(newLab.showOnSummaryScreen || false)}</span>
                        <ViewOnlyCheckBox
                          id="showOnSummaryScreen"
                          bgColor="blue"
                          checked={newLab.showOnSummaryScreen}
                          handleChange={(e) =>
                            setNewLab({ ...newLab, showOnSummaryScreen: !newLab.showOnSummaryScreen })
                          }
                        />
                      </div>
                    </div>
                    <div className="checkbox checkBoxWrapper">
                      <span style={{ marginRight: "8px" }}>Archive</span>
                      <div className="d-flex justify-content-start align-items-center">
                        <span className="labelYesNo">{parseBooleanValue(newLab.isArchive)}</span>
                        <ViewOnlyCheckBox
                          id="isArchive"
                          bgColor="blue"
                          checked={newLab.isArchive}
                          handleChange={(e) => setNewLab({ ...newLab, isArchive: !newLab.isArchive })}
                        />
                      </div>
                    </div>
                    <div className="checkbox checkBoxWrapper">
                      <span style={{ marginRight: "8px" }}>White Label</span>
                      <div className="d-flex justify-content-start align-items-center">
                        <span className="labelYesNo">{parseBooleanValue(newLab.whiteLabel)}</span>
                        <ViewOnlyCheckBox
                          id="whiteLabel"
                          bgColor="blue"
                          checked={newLab.whiteLabel}
                          handleChange={(e) => setNewLab({ ...newLab, whiteLabel: !newLab.whiteLabel })}
                        />
                      </div>
                    </div>

                    {newLab.whiteLabel && appContext.isSuperAdmin() && (
                      <>
                        <div className="checkbox checkBoxWrapper">
                          <span style={{ marginRight: "8px" }}>App Package Name</span>
                          <div className="d-flex justify-content-start align-items-center">
                            <input
                              id="whitelabelpackage"
                              className="labModalInput"
                              placeholder="Package Name"
                              value={newLab.whiteLabelPackage}
                              onChange={(e) => setNewLab({ ...newLab, whiteLabelPackage: e.target.value })}
                            />
                          </div>
                        </div>
                        <div className="checkbox checkBoxWrapper">
                          <span style={{ marginRight: "8px" }}>Postive Release Tab</span>
                          <div className="d-flex justify-content-start align-items-center">
                            <span className="labelYesNo">{parseInt(newLab.pendingRelease) === 1 ? "Yes" : "No"}</span>
                            <ViewOnlyCheckBox
                              id="pendingRelease"
                              bgColor="blue"
                              checked={parseInt(newLab.pendingRelease) === 1 ? true : false}
                              handleChange={(e) => {
                                setNewLab({ ...newLab, pendingRelease: 1 - newLab.pendingRelease });
                              }}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    <div className="checkbox checkBoxWrapper">
                      <span style={{ marginRight: "8px" }}>Antigen</span>
                      <div className="d-flex justify-content-start align-items-center">
                        <div
                          className="showDisclaimerText"
                          onClick={() =>
                            setShowEditor({ type: DISCLAIMER_TYPE.ANTIGEN, message: newLab.antigen_notes })
                          }
                        >
                          <span>Disclaimer</span>
                        </div>
                      </div>
                    </div>
                    <div className="checkbox checkBoxWrapper">
                      <span style={{ marginRight: "8px" }}>Molecular</span>
                      <div className="d-flex justify-content-start align-items-center">
                        <div
                          className="showDisclaimerText"
                          onClick={() =>
                            setShowEditor({ type: DISCLAIMER_TYPE.MOLECULAR, message: newLab.molecular_notes })
                          }
                        >
                          <span>Disclaimer</span>
                        </div>
                      </div>
                    </div>
                    <div className="checkbox checkBoxWrapper">
                      <span style={{ marginRight: "8px" }}>Accula</span>
                      <div className="d-flex justify-content-start align-items-center">
                        <div
                          className="showDisclaimerText"
                          onClick={() => setShowEditor({ type: DISCLAIMER_TYPE.ACCULA, message: newLab.other_notes })}
                        >
                          <span>Disclaimer</span>
                        </div>
                      </div>
                    </div>
                    <div className="checkbox checkBoxWrapper">
                      <span style={{ marginRight: "8px" }}>Lucira</span>
                      <div className="d-flex justify-content-start align-items-center">
                        <div
                          className="showDisclaimerText"
                          onClick={() => setShowEditor({ type: DISCLAIMER_TYPE.LUCIRA, message: newLab.lucira_notes })}
                        >
                          <span>Disclaimer</span>
                        </div>
                      </div>
                    </div>
                    <div className="checkbox checkBoxWrapper">
                      <span style={{ marginRight: "8px" }}>Rapid Flu</span>
                      <div className="d-flex justify-content-start align-items-center">
                        <div
                          className="showDisclaimerText"
                          onClick={() =>
                            setShowEditor({ type: DISCLAIMER_TYPE.RAPIDFLU, message: newLab.rapidflu_notes })
                          }
                        >
                          <span>Disclaimer</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
        </Modal.Body>

        <Modal.Footer style={{ borderTop: "1px solid lightgrey", paddingTop: "15px" }}>
          <Button className="modalButtons" style={{ marginRight: "25px" }} variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            style={{ marginLeft: "25px" }}
            className="modalButtons"
            variant="primary"
            onClick={() => handleSave()}
          >
            {newLab.isNew ? "Create" : "Update"}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={openDeleteConfirmation}
        // style={{ transform: "translate(0, -30%)" }}
        animation={true}
        onHide={() => {
          handleClose();
        }}
        centered
        size={"md"}
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            Delete LAB
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ paddingTop: 0, marginRight: "auto", marginLeft: "auto" }}>
          <Modal.Title id="contained-modal-title-vcenter">Are you sure you want to delete this LAB?</Modal.Title>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="modalButtons"
            variant="primary"
            onClick={() => {
              handleClose();
            }}
          >
            Go back
          </Button>
          <Button
            className="modalButtons"
            variant="danger"
            onClick={() => {
              deleteLab(labToDelete);
              handleClose();
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      {showCSVImporter && <LabBarCode id={showCSVImporter} handleClose={() => setShowCSVImporter(null)} />}
    </div>
  );
};

export default LabsView;
