import React, { useState, useEffect, useMemo } from "react";
import {
  medFlowInLocalStorage,
  formatZip,
  toTitleCase,
  convertToLower,
  formateDateLL,
  checkValidity,
  getValidName,
  isValidName,
  isValidIdNum,
  isValidPhoneWithCode,
  isValidZipCode,
} from "utils";
import { useHistory } from "react-router-dom";
import { newRegisterObj, externalTypesOptions, countryListAllIsoData, IMAGE_UPLOAD_TYPE } from "constant";
import { Button } from "react-bootstrap";
import API from "api";
import moment from "moment";
import Loader from "components/Loader/Loader";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import ImageInput from "components/Input/ImageInput";
import Select from "react-select";
import { Storage } from "aws-amplify";
import userIcon from "assets/img/default-avatar.png";
import PhoneNoInput from "components/Phone/PhoneNoInput";
import Default_Profile from "../assets/img/default_profile.png";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import countriesOption from "countries";
import logoImage from "../assets/img/safecamp_BRAND.1-nobg.png";
import ImageCropModal from "components/Input/ImageCropModal";
import RegisterStepOne from "components/PreRegister/RegisterStepOne";
import QRCodeView from "components/PreRegister/QRCodeView";
import SweetAlert from "react-bootstrap-sweetalert";
import ShowSelection from "components/PreRegister/ShowSelection";
import SingleDatePicker from "Date/SingleDatePicker";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import { validSpace } from "utils";
import AutoCompleteAddress from "components/AutoCompleteAddress";
import { isValidEmail } from "utils";
import { formatPhoneNumber } from "utils";

const RegisterCrew = (props) => {
  // const {
  //   match: { params },
  // } = props;
  const isMatch = props.hasOwnProperty("match");
  const isId = props.hasOwnProperty("id");

  const [newUser, setNewUser] = useState(newRegisterObj);
  const [registrationDone, setRegistrationDone] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [barcode, setBarcode] = useState("");
  const [profileImage, setProfileImage] = useState(Default_Profile);
  const [copyImage, setCopyImage] = useState(Default_Profile);
  const [vaccinationImage, setVaccinationImage] = useState("");
  const [externalTestImage, setExternalTestImage] = useState("");
  const [showImageCroper, setShowImageCropper] = useState(false);
  const [client, setClient] = useState("");
  const [showName, setShowName] = useState("");
  const [pageExpire, setPageExpire] = useState(false);
  const [step, setStep] = useState(1);
  const [errorMessageText, setErrorMessageText] = useState("");
  const [showListing, setShowListing] = useState([]);
  const [orderKitClose, setOrderKitClose] = useState(1);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [message, setMessage] = useState("");
  const [maxQty, setMaxQty] = useState(2);

  const currentEnv =
    window.location.href.includes("portal.safecamp") ||
    window.location.href.includes("staging.safecamp") ||
    window.location.href.includes("medflow")
      ? "LIVE"
      : "DEV";

  const getFileFromStorage = async (file) => {
    try {
      const path = await Storage.get(file);
      setProfileImage(path);
      console.log("path", path);
    } catch {
      console.log("Image loading fail");
    }
  };

  useEffect(() => {
    if (isMatch) {
      const {
        match: { params },
      } = props;
      params && params.id ? loadShowListing(params.id) : "";
    }
    if (isId) {
      loadShowListing(props.id);
    } else {
      setLoading(false);
    }
  }, []);

  const loadShowListing = async (id) => {
    const showData = await API.preRegisterShowData(id, currentEnv);
    console.log("showData", showData);
    setLoading(false);
    if (showData && showData.preRegistration) {
      setMaxQty(showData.orderQuantity || 2);
      setNewUser({ ...newUser, showId: showData });
      setShowName(showData.name);
      setClient(showData.clientID);
      return showData;
    } else {
      setPageExpire(true);
    }
    return null;
  };

  const loadShowData = async (id) => {
    const showData = await API.preRegisterShowData(id, currentEnv);
    setLoading(false);
    if (showData) {
      return showData;
    }
    return null;
  };

  const vaccinationTypes = [
    {
      label: "Bharat Biotech (Covaxin)",
      value: "Bharat Biotech (Covaxin)",
      noOfDose: 2,
    },
    {
      label: "Johnson & Johnson (Ad26.COV2.S)",
      value: "Johnson & Johnson (Ad26.COV2.S)",
      noOfDose: 1,
    },
    { label: "Novavax (Nuvaxovid)", value: "Novavax (Nuvaxovid)", noOfDose: 2 },
    { label: "Moderna (Spikevax)", value: "Moderna (Spikevax)", noOfDose: 2 },
    {
      label: "Oxford/AstraZeneca (Vaxzevria)",
      value: "Oxford/AstraZeneca (Vaxzevria)",
      noOfDose: 2,
    },
    {
      label: "Pfizer/BioNTech (Comirnaty)",
      value: "Pfizer/BioNTech (Comirnaty)",
      noOfDose: 2,
    },
    {
      label: "Serum Institute of India (Covishield)",
      value: "Serum Institute of India (Covishield)",
      noOfDose: 2,
    },
    {
      label: "Serum Institute of India (Novavax)",
      value: "Serum Institute of India Novavax(Novavax)",
      noOfDose: 2,
    },
    { label: "Sinopharm (Covilo)", value: "Sinopharm (Covilo)", noOfDose: 2 },
    { label: "Sinovac (CoronaVac)", value: "Sinovac (CoronaVac)", noOfDose: 2 },
  ];

  const history = useHistory();

  const redirectToAdmin = () => {
    if (history) history.push("/admin");
  };

  const isLoggedIn = medFlowInLocalStorage.get();
  if (isLoggedIn && props.hasOwnProperty("match")) redirectToAdmin();

  const showErrorMessage = (message) => {
    setError(message);
    setTimeout(() => setError(""), 2000);
  };
  const isVaccinationInfoEnable = useMemo(() => {
    return newUser.isVaccinated && newUser.showId && newUser.showId.vaccinationCard;
  }, [newUser.isVaccinated, newUser.showId]);

  const isExternalTestAllow = useMemo(() => {
    return newUser.showId && newUser.showId.externalTest;
  }, [newUser.showId]);

  const handleUpdateAddress = (val, flag) => {
    if (!flag) {
      setNewUser({ ...newUser, street: val });
    } else {
      const addressObj = {
        street: val.street,
        country: val.country,
        city: val.city,
        zipcode: val.zipcode,
        zip: val.zipcode,
        state: val.state,
        isoCode: val.isoCode,
      };
      setNewUser({ ...newUser, ...addressObj });
    }
  };

  const isPCRShow = () => newUser.showId && newUser.showId.orderKitType && newUser.showId.orderKitType === "PCR";

  const handleSubmit = async () => {
    if (!newUser.first) {
      showErrorMessage("First name is required");
      return;
    }

    if (!isValidName(newUser.first)) {
      showErrorMessage("First name is invalid must contains at least 2 letters and Name should be realistic");
      return;
    }
    if (!newUser.last) {
      showErrorMessage("Last name is required");
      return;
    }
    if (!isValidName(newUser.last)) {
      showErrorMessage("Last name is invalid must contains at least 2 letters and Name should be realistic");
      return;
    }
    if (!newUser.email?.trim()) {
      showErrorMessage("Email name is required");
      return;
    }
    if (!isValidEmail(newUser.email)) {
      showErrorMessage("Invalid Email");
      return;
    }

    if (!newUser.phone_number.trim()) {
      showErrorMessage("Phone number is required");
      return;
    }

    if (!newUser.street.trim()) {
      showErrorMessage("Address is required");
      return;
    }
    if (!newUser.city.trim()) {
      showErrorMessage("City is required");
      return;
    }
    if (!newUser.state.trim()) {
      showErrorMessage("State is required");
      return;
    }
    if (!newUser.country) {
      showErrorMessage("Select the country");
      return;
    }
    if (newUser.zip === null || !newUser.zip) {
      showErrorMessage("Zipcode is required");
      return;
    }

    if (!isValidZipCode(newUser.isoCode || "US", newUser.zip)) {
      showErrorMessage("Invalid zipcode");
      return;
    }
    if (isPCRShow()) {
      if (!newUser.sex) {
        showErrorMessage("Gender is required");
        return;
      }

      if (!newUser.id_number) {
        showErrorMessage("DL/Passport Number is required");
        return;
      }

      if (!isValidIdNum(newUser.id_number)) {
        showErrorMessage("Invalid DL/Passport Number must contains at least 5 characters");
        return;
      }
      if (!newUser.dob) {
        showErrorMessage("Date of Birth is required");
        return;
      }
    }
    try {
      setLoading(true);
      const obj = newUser;
      const phone = formatPhoneNumberIntl(newUser.phone_number);
      const phoneArr = phone.split(" ");
      obj.countryCode = phoneArr[0];
      phoneArr.splice(0, 1);
      obj.phone_number = phoneArr.join("");
      obj.order_phone = phone;
      let imageFormatWithUtcTime = `_#datePattern#_${moment().utc().format("YYYY-MM-DD HH:mm")}`;
      const fileName = `${newUser.phone_number}-profile-image${imageFormatWithUtcTime}.jpeg`;
      if (profileImage && profileImage !== Default_Profile && profileImage instanceof Object) {
        await Storage.put(`profile/${fileName}`, profileImage, { bucket: "medflow-images" });
      }
      if (profileImage && profileImage !== Default_Profile) {
        obj.profileImage = fileName;
      }
      const response = await API.requestTestKit(obj, client, currentEnv);

      setLoading(false);
      setStep(3);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  const handleCompleteRegistration = async () => {
    if (!newUser.first) {
      showErrorMessage("First name is required");
      return;
    }

    if (!isValidName(newUser.first)) {
      showErrorMessage("First name is invalid must contains at least 2 letters and Name should be realistic");
      return;
    }
    if (!newUser.last) {
      showErrorMessage("Last name is required");
      return;
    }
    if (!isValidName(newUser.last)) {
      showErrorMessage("Last name is invalid must contains at least 2 letters and Name should be realistic");
      return;
    }
    if (!newUser.email?.trim()) {
      showErrorMessage("Email name is required");
      return;
    }
    if (!isValidEmail(newUser.email)) {
      showErrorMessage("Invalid Email");
      return;
    }

    if (!newUser.phone_number) {
      showErrorMessage("Phone number is required");
      return;
    }
    if (!newUser.street.trim()) {
      showErrorMessage("Address is required");
      return;
    }
    if (!newUser.city.trim()) {
      showErrorMessage("City is required");
      return;
    }
    if (newUser.zip === null || !newUser.zip) {
      showErrorMessage("Zipcode is required");
      return;
    }

    if (!isValidZipCode(newUser.isoCode || "US", newUser.zip)) {
      showErrorMessage("Invalid zipcode");
      return;
    }

    if (!newUser.sex) {
      showErrorMessage("Gender is required");
      return;
    }
    if (!newUser.dob) {
      showErrorMessage("Date of Birth is required");
      return;
    }
    if (!newUser.state.trim()) {
      showErrorMessage("State is required");
      return;
    }
    if (!newUser.id_number) {
      showErrorMessage("DL/Passport Number is required");
      return;
    }

    if (!isValidIdNum(newUser.id_number)) {
      showErrorMessage("Invalid DL/Passport Number must contains at least 5 characters");
      return;
    }
    if (newUser.showId === null) {
      showErrorMessage("Fail to register, show info not found");
      return;
    }

    if (newUser.isVaccinated === null || newUser.isVaccinated.length === 0) {
      showErrorMessage("Please select the vaccination status");
      return;
    }
    if (newUser.isVaccinated === false && newUser.showId && newUser.showId.vaccinationCard) {
      setErrorMessageText(
        "Can not proceed without vaccination. Please contact the event organizer for further details"
      );
      return;
    }
    if (isVaccinationInfoEnable) {
      if (newUser.isVaccinated && !vaccinationImage) {
        showErrorMessage("Vaccination Image is required");
        return;
      }
      if (newUser.isVaccinated && !newUser.vaccinationType) {
        showErrorMessage("Vaccination Type is required");
        return;
      }
      if (newUser.isVaccinated && !newUser.vaccinationFirstDate) {
        showErrorMessage("Vaccination Date is required");
        return;
      }
      if (newUser.isVaccinated && !newUser.vaccinationFirstLocation) {
        showErrorMessage("Vaccination Location is required");
        return;
      }
      if (newUser.isVaccinated && newUser.vaccinationDose > 1 && !newUser.vaccinationSecondDate) {
        showErrorMessage("Vaccination Second Date is required");
        return;
      }
      if (newUser.isVaccinated && newUser.vaccinationSecondDate && !newUser.vaccinationSecondLocation) {
        showErrorMessage("Vaccination Second Location is required");
        return;
      }
      if (newUser.isVaccinated) {
        if (newUser.isBooster === null) {
          showErrorMessage("Please select the booster");
          return;
        }
        if (newUser.isBooster && !newUser.boosterType) {
          showErrorMessage("Booster Type is required");
          return;
        }
        if (newUser.isBooster && !newUser.boosterDate) {
          showErrorMessage("Booster Date is required");
          return;
        }
        if (newUser.isBooster && !newUser.boosterLocation) {
          showErrorMessage("Booster Location is required");
          return;
        }
      }
    }

    if (isExternalTestAllow) {
      console.log("isExternalTest", newUser.isExternalTest);
      if (newUser.isExternalTest === null || newUser.isExternalTest.length === 0) {
        showErrorMessage("Please select external test");
        return;
      }

      if (newUser.isExternalTest && !externalTestImage) {
        showErrorMessage("External Test Image is required");
        return;
      }
      if (newUser.isExternalTest && !newUser.externalTestType) {
        showErrorMessage("External Test Type is required");
        return;
      }
      if (newUser.isExternalTest && !newUser.externalTestDate) {
        showErrorMessage("External Test Date is required");
        return;
      }
      if (newUser.isExternalTest && !newUser.externalTestResult) {
        showErrorMessage("External Test Result is required");
        return;
      }
    }
    try {
      setLoading(true);
      const obj = newUser;
      const phone = formatPhoneNumberIntl(newUser.phone_number);
      const phoneArr = phone.split(" ");
      newUser.id_number = checkValidity(newUser.id_number);
      obj.countryCode = phoneArr[0];
      phoneArr.splice(0, 1);
      obj.phone_number = phoneArr.join("");

      if (newUser.isVaccinated && isVaccinationInfoEnable) {
        let vaccinationFileName = "";
        if (typeof vaccinationImage === "object") {
          vaccinationFileName = await API.saveFileExternalStorage(
            obj.phone_number,
            "PR",
            IMAGE_UPLOAD_TYPE.VacinationCard,
            vaccinationImage
          );
        }

        // let vaccineCardFile = `${newUser.phone_number}-vaccination-card.png`;
        // await Storage.put(vaccineCardFile, vaccinationImage);

        Object.assign(newUser, { vaccineCardFile: vaccinationFileName });
      }
      if (newUser.isExternalTest && isExternalTestAllow) {
        let externalImageFileName = "";
        if (typeof externalTestImage === "object") {
          externalImageFileName = await API.saveFileExternalStorage(
            obj.phone_number,
            "PR",
            IMAGE_UPLOAD_TYPE.ExternalTest,
            externalTestImage
          );
        }
        // let externalFile = `${newUser.phone_number}-external-test.png`;
        // await Storage.put(externalFile, externalTestImage);
        Object.assign(newUser, { externalFile: externalImageFileName });
      }

      if (profileImage && profileImage !== Default_Profile && profileImage instanceof Object) {
        let imageFormatWithUtcTime = `_#datePattern#_${moment().utc().format("YYYY-MM-DD HH:mm")}`;
        const fileName = `${newUser.phone_number}-profile-image${imageFormatWithUtcTime}.jpeg`;
        await Storage.put(`profile/${fileName}`, profileImage, { bucket: "medflow-images" });
        Object.assign(newUser, { profileImage: fileName });
      }

      const response = await API.preRegisterNewEmployee(
        newUser,
        client,
        isVaccinationInfoEnable,
        orderKitClose,
        currentEnv
      );

      setNewUser({ ...obj });
      setStep(5);
      if (isId) {
        props.handleAddedPreReg();
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  const handleCropDone = (isConfirm, fileData) => {
    console.log("registerCrew", isConfirm, fileData);
    setShowImageCropper(false);
    if (isConfirm && fileData) {
      setProfileImage(new File([fileData], "profileImage.jpeg"));
    } else {
      setProfileImage(profileImage);
    }
  };

  const setSpecificUI = () => {
    if (newUser.showId && newUser.showId.orderKitDate) {
      const startDate = moment(newUser.showId.orderKitDate);
      if (!moment().isSameOrBefore(startDate, "date")) {
        setStep(4);
        setOrderKitClose(5);
        return;
      } else {
        setMessage("Would you like to order a test for this event?");
        setOpenConfirmationModal(true);
      }
    }
    setStep(4);
    setOrderKitClose(5);
  };

  const handleProcced = async (obj) => {
    try {
      setLoading(true);
      const phone = formatPhoneNumberIntl(obj.phone);
      let formattedPhone = `+${phone?.replace(/\D/g, "")}`;
      const phoneArr = phone?.split(" ");
      phoneArr?.splice(0, 1);
      const response = await API.getPreRegisterByPhone(
        phoneArr.join(""),
        newUser.showId ? newUser.showId.id : null,
        currentEnv
      );
      const data = response.body;
      console.log("data for profile", data);
      const statusCode = response.statusCode;
      console.log("response", response);

      if (!newUser.showId) {
        setLoading(false);
        if (statusCode === 404 || data.length === 0) {
          setErrorMessageText("No active record found");
          return;
        }
        if (data.length === 1) {
          handleShowSelection(data[0]);
          return;
        }
        if (statusCode === 202) {
          setShowListing(data);
        }
        return;
      }

      if (statusCode === 404) {
        setSpecificUI();
        setNewUser({ ...newUser, phone_number: phone });
      } else if (statusCode === 201) {
        // when user has some basic information in pre register table against any show
        fillUserData(data, formattedPhone);
        if (data.profileImage && data.profileImage !== "undefined") {
          let path = `${data.phone_number}-profile-image.png`;
          if (data.profileImage.includes("_#datePattern#_")) {
            path = data.profileImage;
          }
          // await API.getFileProfileStorage(path)
          // await getFileFromStorage(path);

          let getProfileImage = await API.getFileProfileStorage(path);
          // setProfileImage(path);
          setProfileImage(getProfileImage);
        }
        setSpecificUI();
      } else {
        if (data.profileImage && data.profileImage !== "undefined") {
          let path = `${data.phone_number}-profile-image.png`;

          if (data.profileImage.includes("_#datePattern#_")) {
            path = data.profileImage;
          }

          // await getFileFromStorage(path);
          let getProfileImage = await API.getFileProfileStorage(path);
          // setProfileImage(path);
          setProfileImage(getProfileImage);
        }
        if (data.step && parseInt(data.step) === 5) {
          setStep(parseInt(data.step));
          formattedPhone = data.phone_number;
        } else {
          setStep(4);
          setOrderKitClose(5);
        }
        fillUserData(data, formattedPhone);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setStep(1);
      setErrorMessageText(err);
    }
  };

  const fillUserData = (data, formattedPhone) => {
    const country = countryListAllIsoData.find((f) => f.name === data.country) || { code: "US" };
    const obj = {
      ...newUser,
      first: data.first,
      last: data.last,
      email: data.email,
      phone_number: formattedPhone,
      state: data.state,
      zipcode: data.zipcode,
      country: data.country,
      isoCode: country.code,
      street: data.street,
      street2: data.street2 === "undefined" ? "" : data.street2,
      city: data.city,
      designation: "",
    };
    try {
      Object.assign(obj, {
        sex: data.sex,
        dob: data.dob,
        id_number: data.id_number || "",
        isVaccinated: data.isVaccinated && data.isVaccinated === "true" ? true : false,
      });
    } catch (err) {
      console.log("Error", err);
    }
    setNewUser(obj);
  };
  console.log("bew", newUser);
  const handleShowSelection = async (obj) => {
    setLoading(true);
    if (obj.profileImage && obj.profileImage !== "undefined") {
      let path = `${data.phone_number}-profile-image.png`;
      if (data.profileImage.includes("_#datePattern#_")) {
        path = data.profileImage;
      }
      let getProfileImage = await API.getFileProfileStorage(path);
      // setProfileImage(path);
      setProfileImage(getProfileImage);
      // await getFileFromStorage(path);
    }
    const showData = await loadShowData(obj.showId);
    if (showData) {
      obj.phone_number =
        obj.countryCode && obj.countryCode.substring(0, 1) === "+"
          ? `${obj.countryCode}${obj.phone_number}`
          : `+${obj.countryCode}${obj.phone_number}`;
      setShowListing([]);
      setNewUser({ ...obj, showId: showData });
      setShowName(showData.name);
      setClient(showData.clientID);
      setStep(4);
      setOrderKitClose(5);
    }
  };

  const handleConfirmationRes = (value) => {
    setOpenConfirmationModal(false);
    setMessage("");
    if (value) {
      setStep(2);
    } else {
      setStep(4);
      setOrderKitClose(5);
    }
  };

  const getTestOrderQuantity = useMemo(() => {
    const options = [];
    for (let i = 1; i <= maxQty; i++) {
      options.push({ value: i, label: i });
    }
    return options;
  }, [maxQty]);

  if (pageExpire) return <p>Page Expired</p>;

  if (loading) return <Loader />;

  if (showListing.length > 0) {
    return <ShowSelection shows={showListing} handleShowSelection={handleShowSelection} />;
  }

  if (step === 1) {
    return (
      <>
        <RegisterStepOne
          show={newUser.showId}
          handleProcced={handleProcced}
          errorMessageText={errorMessageText}
          linkCrew
        />
        {openConfirmationModal && (
          <ConfirmationModal
            isPreRegister
            title="Pre Registration"
            message={message}
            handleConfirm={handleConfirmationRes}
          />
        )}
      </>
    );
  }

  if (step === 3)
    return (
      <div className="registerFormWrapper align-items-start">
        <div className="registerWrapper">
          <div className="loginInner">
            <div className="text-center">
              {" "}
              <img src={logoImage} alt="logo" className="logo-width" />{" "}
            </div>
            <h4 className="w-100 text-center mt-4">
              <b>Test Order Successful for {showName && `${showName}`}</b>
            </h4>
            <h4 className="w-100 text-center mt-4">
              <b>Event Date: {`${formateDateLL(newUser.showId.startDate)}`}</b>
            </h4>

            <p>
              Thank you for submitting your request for a SafeCamp at home test. You should be receiving your test(s)
              within 3-5 business days.
            </p>
            <p>
              As a reminder, a negative test result is required within 3 calendar days of the event date. Additional
              instructions will be included within the test kit to upload your result or bring it to the event.
            </p>
            <p>Please refer to your invitation for additional event details and FAQ's.</p>
          </div>
        </div>
      </div>
    );

  if (step === 5) {
    return <QRCodeView newUser={newUser} profileImage={profileImage} />;
  }

  return (
    <>
      <div className="registerFormWrapper">
        <div className="registerWrapper">
          <div className="loginInner">
            <div className="text-center">
              {" "}
              <img src={logoImage} alt="logo" className="logo-width" />{" "}
            </div>
            <h4 className="w-100 text-center mt-4">
              <b>
                {" "}
                {step === 2 ? "Order Tests for" : "Register for "} {showName && `${showName}`}
              </b>
            </h4>
            <h4 className="w-100 text-center mt-4">
              <b>Event Date: {`${formateDateLL(newUser.showId.startDate)}`}</b>
            </h4>
            <div className="form-wrapper">
              <ImageInput
                type="profile"
                setSelectedImage={setProfileImage}
                selectedImage={profileImage}
                showCropDialog={setShowImageCropper}
                setCopyImage={setCopyImage}
              />
              <div className="form-group-wrapper mt-2">
                <label className="mb-1">First Name:</label>
                <input
                  value={toTitleCase(newUser.first)}
                  type="text"
                  className="form-control"
                  placeholder="First Name"
                  onChange={(e) => setNewUser({ ...newUser, first: getValidName(e.target.value) })}
                />
              </div>
              <div className="form-group-wrapper ">
                <label className="mb-1">Last Name:</label>
                <input
                  value={toTitleCase(newUser.last)}
                  type="text"
                  className="form-control"
                  placeholder="Last Name"
                  onChange={(e) => setNewUser({ ...newUser, last: getValidName(e.target.value) })}
                />
              </div>
              <div className="form-group-wrapper ">
                <label className="mb-1">Email:</label>
                <input
                  value={newUser.email}
                  type="text"
                  className="form-control"
                  placeholder="Email"
                  onChange={(e) => setNewUser({ ...newUser, email: convertToLower(e.target.value) })}
                />
              </div>
              <div className="form-group-wrapper">
                <label className="mb-1">Phone Number:</label>
                <input
                  value={formatPhoneNumber(newUser.phone_number)}
                  type="text"
                  maxLength={15}
                  className="form-control"
                  placeholder="Phone Number"
                  disabled={true}
                />
              </div>

              <div className="form-group-wrapper">
                <label className="mb-1">Address:</label>
                <AutoCompleteAddress value={newUser.street} handleChange={handleUpdateAddress} />
              </div>
              <div className="form-group-wrapper">
                <label className="mb-1">Address 2:</label>
                <input
                  value={toTitleCase(newUser.street2)}
                  type="text"
                  className="form-control"
                  placeholder="Address 2"
                  onChange={(e) => setNewUser({ ...newUser, street2: getValidName(e.target.value) })}
                />
              </div>
              <div className="form-group-wrapper">
                <label className="mb-1">City:</label>
                <input
                  value={toTitleCase(newUser.city)}
                  type="text"
                  className="form-control"
                  placeholder="City"
                  onChange={(e) => setNewUser({ ...newUser, city: getValidName(e.target.value) })}
                />
              </div>
              <div className="form-group-wrapper">
                <label className="mb-1">State:</label>
                <input
                  value={newUser.state}
                  type="text"
                  className="form-control stateField"
                  placeholder="State Abbr: XX"
                  maxLength={35}
                  onChange={(e) => {
                    let val = e.target.value;
                    if (val) val = val.toUpperCase();
                    setNewUser({ ...newUser, state: validSpace(val) });
                  }}
                />
              </div>
              <div className="form-group-wrapper ">
                <label className="mb-1">Country:</label>
                <Select
                  options={[{ label: "United States", value: "United States" }]}
                  blurInputOnSelect={true}
                  value={{ label: newUser.country, value: newUser.country }}
                  menuPlacement="auto"
                  placeholder="Select Country"
                  className="w-100"
                  onChange={(e) => {
                    const country = countryListAllIsoData.find((f) => f.name === e.value) || { code: "US" };
                    setNewUser({
                      ...newUser,
                      country: e.value,
                      isoCode: country.code,
                    });
                  }}
                />
              </div>
              <div className="form-group-wrapper">
                <label className="mb-1">Zip Code:</label>
                <input
                  value={newUser.isoCode === "US" ? formatZip(newUser.zip) : newUser.zip}
                  type="text"
                  className="form-control"
                  placeholder="Enter Zip Code"
                  maxLength={10}
                  onChange={(e) => {
                    let val = e.target.value;
                    if (val) val = val.toUpperCase();
                    setNewUser({
                      ...newUser,
                      zip: newUser.isoCode === "US" ? formatZip(val) : validSpace(val),
                    });
                  }}
                />
              </div>
              {step === 2 && (
                <>
                  {newUser.showId && newUser.showId.orderKitType && newUser.showId.orderKitType === "PCR" && (
                    <>
                      <div className="form-group-wrapper">
                        <label className="modalLineHeaders">Gender: </label>
                        <div>
                          <span className="radioBox-wrapper">
                            <input
                              type="radio"
                              className="radioButton"
                              name="sex"
                              checked={newUser.sex === "M"}
                              onChange={(e) =>
                                setNewUser({
                                  ...newUser,
                                  sex: e.target.checked ? "M" : "",
                                })
                              }
                            />
                            <span className="radioButton">Male</span>
                          </span>
                          <span className="radioBox-wrapper">
                            <input
                              type="radio"
                              className="radioButton"
                              name="sex"
                              checked={newUser.sex === "F"}
                              onChange={(e) =>
                                setNewUser({
                                  ...newUser,
                                  sex: e.target.checked ? "F" : "",
                                })
                              }
                            />
                            <span className="radioButton">Female</span>
                          </span>
                          <span className="radioBox-wrapper">
                            <input
                              type="radio"
                              className="radioButton"
                              name="sex"
                              checked={newUser.sex === "X"}
                              onChange={(e) =>
                                setNewUser({
                                  ...newUser,
                                  sex: e.target.checked ? "X" : "",
                                })
                              }
                            />
                            <span className="radioButton">Not Specified</span>
                          </span>
                        </div>
                      </div>
                      <div className="form-group-wrapper ">
                        <label className="mb-1">DL/Passport Number:</label>
                        <input
                          value={newUser.id_number}
                          maxLength={20}
                          type="text"
                          className="form-control"
                          placeholder="DL/Passport Number"
                          onChange={(e) => setNewUser({ ...newUser, id_number: e.target.value })}
                        />
                      </div>
                      <div className="form-group-wrapper ">
                        <label className="modalLineHeaders">DOB: </label>
                        <SingleDatePicker
                          onApply={(event, picker) => {
                            setNewUser({
                              ...newUser,
                              dob: moment(picker.startDate).format("YYYY-MM-DD"),
                            });
                          }}
                          value={newUser.dob}
                          currentDate
                        />
                      </div>
                    </>
                  )}
                  <div className="form-group-wrapper">
                    <label className="modalLineHeaders">Capacity: </label>
                    <div>
                      <span className="radioBox-wrapper">
                        <input
                          type="radio"
                          className="radioButton"
                          name="capacity"
                          checked={newUser.capacity === "Guest"}
                          onChange={(e) =>
                            setNewUser({
                              ...newUser,
                              capacity: e.target.checked ? "Guest" : "",
                            })
                          }
                        />
                        <span className="radioButton">Guest</span>
                      </span>
                      <span className="radioBox-wrapper">
                        <input
                          type="radio"
                          className="radioButton"
                          name="capacity"
                          checked={newUser.capacity === "Staff"}
                          onChange={(e) =>
                            setNewUser({
                              ...newUser,
                              capacity: e.target.checked ? "Staff" : "",
                            })
                          }
                        />
                        <span className="radioButton">Staff</span>
                      </span>
                    </div>
                  </div>
                  {newUser.capacity === "Staff" && (
                    <div className="form-group-wrapper">
                      <label className="mb-1">Role:</label>
                      <input
                        value={newUser.designation}
                        type="text"
                        className="form-control"
                        placeholder="Role"
                        onChange={(e) =>
                          setNewUser({
                            ...newUser,
                            designation: e.target.value,
                          })
                        }
                      />
                    </div>
                  )}

                  <div className="form-group-wrapper">
                    <label className="modalLineHeaders">Select Test Quantity:</label>
                    <Select
                      options={getTestOrderQuantity}
                      blurInputOnSelect={true}
                      defaultValue={{ value: 1, label: 1 }}
                      menuPlacement="auto"
                      placeholder="Select Quantity"
                      className="w-100"
                      onChange={(e) =>
                        setNewUser({
                          ...newUser,
                          testSendQty: e.value,
                        })
                      }
                    />
                  </div>
                </>
              )}
              {step === 4 && (
                <>
                  <div className="form-group-wrapper">
                    <label className="modalLineHeaders">Gender: </label>
                    <div>
                      <span className="radioBox-wrapper">
                        <input
                          type="radio"
                          className="radioButton"
                          name="sex"
                          checked={newUser.sex === "M"}
                          onChange={(e) =>
                            setNewUser({
                              ...newUser,
                              sex: e.target.checked ? "M" : "",
                            })
                          }
                        />
                        <span className="radioButton">Male</span>
                      </span>
                      <span className="radioBox-wrapper">
                        <input
                          type="radio"
                          className="radioButton"
                          name="sex"
                          checked={newUser.sex === "F"}
                          onChange={(e) =>
                            setNewUser({
                              ...newUser,
                              sex: e.target.checked ? "F" : "",
                            })
                          }
                        />
                        <span className="radioButton">Female</span>
                      </span>
                      <span className="radioBox-wrapper">
                        <input
                          type="radio"
                          className="radioButton"
                          name="sex"
                          checked={newUser.sex === "X"}
                          onChange={(e) =>
                            setNewUser({
                              ...newUser,
                              sex: e.target.checked ? "X" : "",
                            })
                          }
                        />
                        <span className="radioButton">Not Specified</span>
                      </span>
                    </div>
                  </div>

                  <div className="form-group-wrapper ">
                    <label className="modalLineHeaders">DOB: </label>

                    <SingleDatePicker
                      onApply={(event, picker) => {
                        setNewUser({
                          ...newUser,
                          dob: moment(picker.startDate).format("YYYY-MM-DD"),
                        });
                      }}
                      value={newUser.dob}
                      currentDate
                    />
                  </div>
                  <div className="form-group-wrapper ">
                    <label className="mb-1">DL/Passport Number:</label>
                    <input
                      value={newUser.id_number}
                      maxLength={16}
                      type="text"
                      className="form-control"
                      placeholder="DL/Passport Number"
                      onChange={(e) => setNewUser({ ...newUser, id_number: e.target.value })}
                    />
                  </div>

                  <div className="form-group-wrapper">
                    <label className="modalLineHeaders">Are you vaccinated:</label>
                    <div>
                      <span className="radioBox-wrapper">
                        <input
                          type="radio"
                          className="radioButton"
                          name="isVaccinated"
                          checked={newUser.isVaccinated === true}
                          onChange={(e) =>
                            setNewUser({
                              ...newUser,
                              isVaccinated: e.target.checked && true,
                              vaccinationFirstLocation: "United States",
                            })
                          }
                        />
                        <span className="radioButton">Yes</span>
                      </span>
                      <span className="radioBox-wrapper">
                        <input
                          type="radio"
                          className="radioButton"
                          name="isVaccinated"
                          checked={newUser.isVaccinated === false}
                          onChange={(e) =>
                            setNewUser({
                              ...newUser,
                              isVaccinated: e.target.checked && false,
                              vaccinationFirstDate: "",
                              vaccinationFirstLocation: "",
                              vaccinationSecondDate: "",
                              vaccinationSecondLocation: "",
                              vaccinationType: "",
                            })
                          }
                        />
                        <span className="radioButton">No</span>
                      </span>
                    </div>
                  </div>

                  {isVaccinationInfoEnable && (
                    <>
                      <ImageInput
                        className="fileDesign"
                        cssClass="vaccinationField"
                        title=" Vaccination Certificate"
                        setSelectedImage={setVaccinationImage}
                        selectedImage={vaccinationImage}
                      />
                      <div className="form-group-wrapper ">
                        <label className="mb-1">Vaccination Type:</label>
                        <Select
                          options={vaccinationTypes}
                          blurInputOnSelect={true}
                          defaultValue={null}
                          menuPlacement="auto"
                          placeholder="Select Vaccination type"
                          className="w-100"
                          onChange={(e) =>
                            setNewUser({
                              ...newUser,
                              vaccinationType: e.value,
                              vaccinationDose: e.noOfDose,
                              vaccinationSecondLocation: e.noOfDose === 2 ? "United States" : "",
                            })
                          }
                        />
                      </div>
                      <div className="form-group-wrapper ">
                        <label className="modalLineHeaders">
                          {newUser.vaccinationDose && newUser.vaccinationDose === 1
                            ? "Vaccination Date"
                            : "Vaccination 1st Date:"}
                        </label>
                        <SingleDatePicker
                          onApply={(event, picker) =>
                            setNewUser({
                              ...newUser,
                              vaccinationFirstDate: moment(picker.startDate).format("YYYY-MM-DD"),
                            })
                          }
                          value={newUser.vaccinationFirstDate}
                          currentDate
                        />
                      </div>
                      <div className="form-group-wrapper ">
                        <label className="mb-1">Vaccination Location:</label>
                        <Select
                          options={[{ label: "United States", value: "United States" }]}
                          blurInputOnSelect={true}
                          defaultValue={{ label: "United States", value: "United States" }}
                          menuPlacement="auto"
                          placeholder="Select Location"
                          className="w-100"
                          onChange={(e) =>
                            setNewUser({
                              ...newUser,
                              vaccinationFirstLocation: e.value,
                            })
                          }
                        />
                      </div>
                      {newUser.vaccinationDose && newUser.vaccinationDose > 1 && (
                        <>
                          <div className="form-group-wrapper ">
                            <label className="modalLineHeaders">Vaccination 2nd Date</label>
                            <SingleDatePicker
                              onApply={(event, picker) =>
                                setNewUser({
                                  ...newUser,
                                  vaccinationSecondDate: moment(picker.startDate).format("YYYY-MM-DD"),
                                })
                              }
                              value={newUser.vaccinationSecondDate}
                              currentDate
                            />
                          </div>
                          <div className="form-group-wrapper ">
                            <label className="mb-1">Vaccination 2nd Location:</label>

                            <Select
                              options={countriesOption}
                              blurInputOnSelect={true}
                              defaultValue={{ label: "United States", value: "United States" }}
                              menuPlacement="auto"
                              placeholder="Select Location"
                              className="w-100"
                              onChange={(e) =>
                                setNewUser({
                                  ...newUser,
                                  vaccinationSecondLocation: e.value,
                                })
                              }
                            />
                          </div>
                        </>
                      )}
                      {isVaccinationInfoEnable && (
                        <div className="form-group-wrapper">
                          <label className="modalLineHeaders">Booster?:</label>
                          <div>
                            <span className="radioBox-wrapper">
                              <input
                                type="radio"
                                className="radioButton"
                                name="isBooster"
                                onChange={(e) =>
                                  setNewUser({
                                    ...newUser,
                                    isBooster: e.target.checked && true,
                                    boosterLocation: "United States",
                                  })
                                }
                              />
                              <span className="radioButton">Yes</span>
                            </span>
                            <span className="radioBox-wrapper">
                              <input
                                type="radio"
                                className="radioButton"
                                name="isBooster"
                                onChange={(e) =>
                                  setNewUser({
                                    ...newUser,
                                    isBooster: e.target.checked && false,
                                    boosterLocation: "",
                                  })
                                }
                              />
                              <span className="radioButton">No</span>
                            </span>
                          </div>
                        </div>
                      )}
                      {newUser.isBooster && (
                        <>
                          <div className="form-group-wrapper ">
                            <label className="mb-1">Booster Type:</label>
                            <Select
                              options={vaccinationTypes}
                              blurInputOnSelect={true}
                              defaultValue={null}
                              menuPlacement="auto"
                              placeholder="Select Booster type"
                              className="w-100"
                              onChange={(e) => {
                                setNewUser({ ...newUser, boosterType: e.value });
                              }}
                            />
                          </div>
                          <div className="form-group-wrapper ">
                            <label className="modalLineHeaders">Booster Date:</label>
                            <SingleDatePicker
                              onApply={(event, picker) =>
                                setNewUser({
                                  ...newUser,
                                  boosterDate: moment(picker.startDate).format("YYYY-MM-DD"),
                                })
                              }
                              value={newUser.boosterDate}
                              currentDate
                            />
                          </div>
                          <div className="form-group-wrapper ">
                            <label className="mb-1">Booster Location</label>

                            <Select
                              options={[{ label: "United States", value: "United States" }]}
                              blurInputOnSelect={true}
                              defaultValue={{ label: "United States", value: "United States" }}
                              menuPlacement="auto"
                              placeholder="Select Location"
                              className="w-100"
                              onChange={(e) =>
                                setNewUser({
                                  ...newUser,
                                  boosterLocation: e.value,
                                })
                              }
                            />
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {isExternalTestAllow && (
                    <div className="form-group-wrapper">
                      <label className="modalLineHeaders">Do you Have an External Test:</label>
                      <div>
                        <span className="radioBox-wrapper">
                          <input
                            type="radio"
                            className="radioButton"
                            name="isExternalTest"
                            onChange={(e) =>
                              setNewUser({
                                ...newUser,
                                isExternalTest: e.target.checked && true,
                              })
                            }
                          />
                          <span className="radioButton">Yes</span>
                        </span>
                        <span className="radioBox-wrapper">
                          <input
                            type="radio"
                            className="radioButton"
                            name="isExternalTest"
                            onChange={(e) =>
                              setNewUser({
                                ...newUser,
                                isExternalTest: e.target.checked && false,
                              })
                            }
                          />
                          <span className="radioButton">No</span>
                        </span>
                      </div>
                    </div>
                  )}
                  {newUser.isExternalTest && isExternalTestAllow && (
                    <>
                      <ImageInput
                        cssClass="vaccinationField"
                        title="External Test Image"
                        setSelectedImage={setExternalTestImage}
                        selectedImage={externalTestImage}
                      />
                      <div className="form-group-wrapper ">
                        <label className="mb-1">External Test Type:</label>
                        <Select
                          options={externalTypesOptions}
                          blurInputOnSelect={true}
                          defaultValue={null}
                          menuPlacement="auto"
                          placeholder="Select External test type"
                          className="w-100"
                          onChange={(e) => {
                            setNewUser({ ...newUser, externalTestType: e.value });
                          }}
                        />
                      </div>
                      <div className="form-group-wrapper ">
                        <label className="modalLineHeaders">External Test Date:</label>
                        <SingleDatePicker
                          onApply={(event, picker) =>
                            setNewUser({
                              ...newUser,
                              externalTestDate: moment(picker.startDate).format("YYYY-MM-DD"),
                            })
                          }
                          value={newUser.externalTestDate}
                          currentDate
                        />
                      </div>
                      <div className="form-group-wrapper">
                        <label className="modalLineHeaders">External Test Result:</label>
                        <div>
                          <span className="radioBox-wrapper">
                            <input
                              type="radio"
                              className="radioButton"
                              name="externalTestResult"
                              onChange={(e) =>
                                setNewUser({
                                  ...newUser,
                                  externalTestResult: e.target.value ? "Positive" : "",
                                })
                              }
                            />
                            <span className="radioButton">Positive</span>
                          </span>
                          <span className="radioBox-wrapper">
                            <input
                              type="radio"
                              className="radioButton"
                              name="externalTestResult"
                              onChange={(e) =>
                                setNewUser({
                                  ...newUser,
                                  externalTestResult: e.target.checked ? "Negative" : "",
                                })
                              }
                            />
                            <span className="radioButton">Negative</span>
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
            <p>
              I have read and agree to the
              <a
                className="text-decoration-none"
                target="_blank"
                href="https://medflow-images.s3.eu-west-1.amazonaws.com/TermsofUse-MedFlow.pdf"
              >
                &nbsp; Privacy Policy
              </a>
            </p>
            <p>Please refer to your invitation for additional event details and FAQ's</p>
            {error && (
              <div className="error-message">
                <h6>{error}</h6>
              </div>
            )}
            {step === 2 && (
              <div className="mt-2">
                <button type="submit" className="text-uppercase w-100 btn signup-btn" onClick={handleSubmit}>
                  Process Request
                </button>
              </div>
            )}
            {step === 4 && (
              <div className="mt-2">
                <button
                  type="submit"
                  className="text-uppercase w-100 btn signup-btn"
                  onClick={handleCompleteRegistration}
                >
                  Complete Registration
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {errorMessageText && (
        <SweetAlert
          show={errorMessageText ? true : false}
          info
          title="Information"
          onConfirm={() => setErrorMessageText("")}
        >
          {errorMessageText}
        </SweetAlert>
      )}
      {showImageCroper && <ImageCropModal selectedImage={copyImage} handleConfirm={handleCropDone}></ImageCropModal>}
      {openConfirmationModal && (
        <ConfirmationModal
          isPreRegister
          title="Order the Test"
          message={message}
          handleConfirm={handleConfirmationRes}
        />
      )}
    </>
  );
};

export default RegisterCrew;
