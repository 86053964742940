import React, { useState } from "react";
import { Button, Modal, Card, Table, Row, Col, Collapse } from "react-bootstrap";
import API from "api";
import { formatLogDate } from "utils";
import { async } from "@firebase/util";
// import profileImageDefault from "./../assets/img/default_profile.png";
import profileImageDefault from "../assets/img/default_profile.png";
const EmployeeLogsEntries = (props) => {
  const { employee, newDetails } = props;
  const [show, setShow] = useState(false);
  const handleExpand = () => {
    setShow(!show);
  };

  // Add space before the Captital letter and then make first letter capital
  function insertSpaces(string) {
    string = string.replace(/([a-z])([A-Z])/g, "$1 $2");
    string = string.replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");
    const capitalized = string.charAt(0).toUpperCase() + string.slice(1);
    return capitalized;
  }

  function handleImageCheck(key, data) {
    if (key === "profileImage" && data) {
      let imagePAth = `https://medflow-images.s3.eu-west-1.amazonaws.com/public/profile/${encodeURIComponent(data)}`;
      return (
        <img
          src={imagePAth}
          width="100px"
          height="100px"
          onError={(e) => {
            e.currentTarget.src = profileImageDefault;
          }}
        />
      );
    } else {
      if (data === true) return "True";
      if (data === false) return "False";
      return data;
    }
  }

  const TableRow = () => {
    let oldDetails = JSON.parse(employee?.olddetail);
    let objectKeys = Object.keys(newDetails);
    const [image, setImage] = useState();
    const [oldImage, setOldImage] = useState();
    const [imageExt, setImageExt] = useState();
    const [oldImageExt, setOldImageExt] = useState();
    let notRequiredKeys = ["createdAt", "updatedAt", "updatedBy", "updatedByName"];
    objectKeys = objectKeys.filter((obj) => !notRequiredKeys.includes(obj));
    const handleAPiImage = async (data, type, imageType) => {
      let testData = await API.getFileExternalStorage(data);
      if (imageType === "vac") {
        if (type === "old") {
          setOldImage(testData);
        } else {
          setImage(testData);
        }
      } else if (imageType === "ext") {
        if (type === "old") {
          setOldImageExt(testData);
        } else {
          setImageExt(testData);
        }
      }
    };

    // for vaccinationCardImage
    if (objectKeys.includes("vaccinationCardImage")) {
      if (newDetails["vaccinationCardImage"]) {
        handleAPiImage(newDetails["vaccinationCardImage"], "new", "vac");
      }

      if (oldDetails["vaccinationCardImage"]) {
        handleAPiImage(oldDetails["vaccinationCardImage"], "old", "vac");
      }
    }

    // for external images
    if (objectKeys.includes("externalTestImage")) {
      if (newDetails["externalTestImage"]) {
        handleAPiImage(newDetails["externalTestImage"], "new", "ext");
      }

      if (oldDetails["externalTestImage"]) {
        handleAPiImage(oldDetails["externalTestImage"], "old", "ext");
      }
    }

    return (
      <>
        {objectKeys.map((keyName, i) => {
          return (
            <>
              <tr key={i}>
                <td>
                  <b>{insertSpaces(keyName)} :</b>
                </td>
                {keyName === "vaccinationCardImage" || keyName === "externalTestImage" ? (
                  <>
                    {keyName === "vaccinationCardImage" && (
                      <>
                        <td>{image ? <img src={image} width="100px" height="100px" /> : "-"}</td>
                        <td>{oldImage ? <img src={oldImage} width="100px" height="100px" /> : "-"}</td>
                      </>
                    )}

                    {keyName === "externalTestImage" && (
                      <>
                        <td>
                          {imageExt ? (
                            <img
                              src={imageExt}
                              width="100px"
                              height="100px"
                              onError="this.onerror=null;this.src='./../assets/img/default_profile.png';"
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                        <td>{oldImageExt ? <img src={oldImageExt} width="100px" height="100px" /> : "-"}</td>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <td>{handleImageCheck(keyName, newDetails[keyName]) || "-"}</td>
                    <td>{handleImageCheck(keyName, oldDetails[keyName]) || "-"}</td>
                  </>
                )}
              </tr>
            </>
          );
        })}
      </>
    );
  };

  return (
    <>
      <tr
        onClick={() => handleExpand()}
        onMouseOver={(e) => {
          e.target.style.cursor = "pointer";
        }}
      >
        <td>
          <i
            onMouseLeave={(e) => (e.target.style.color = "black")}
            onMouseOver={(e) => {
              e.target.style.color = "#8B0000";
              e.target.style.cursor = "pointer";
            }}
            aria-hidden="true"
            className={show ? "fas fa-minus" : "fas fa-plus"}
          />
        </td>
        <td>
          <b style={{ fontSize: "large" }}>Updated By: </b>
          <span className="emp-updated-text">{newDetails.updatedByName}</span>
          <span style={{ float: "right" }}>
            <b style={{ fontSize: "large" }}>Created At: </b>
            <span className="emp-updated-text">{formatLogDate(employee.createdAt)}</span>
          </span>
        </td>
      </tr>
      {show && (
        <tr className="subTableRow">
          <td colSpan={"12"} className="expended-table-wrapper">
            <Card className="striped-table-card">
              <Card.Body>
                <div className="form-group-wrapper d-block">
                  <div className="table-responsive overFlow-y-hidden">
                    <Table className="table expandedTable">
                      <thead>
                        <tr>
                          <th style={{ width: "20%" }}></th>
                          <th>New Details</th>
                          <th>Old Details</th>
                        </tr>
                      </thead>
                      <tbody>{<TableRow />}</tbody>
                    </Table>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </td>
        </tr>
      )}
    </>
  );
};

export default EmployeeLogsEntries;
