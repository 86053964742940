import React, { useReducer, createContext } from "react";

const initialState = {

};

const reducer = (state, action) => {
  switch (action.type) {
    // case "ADD_CONTACT":
    //   return {
    //     contacts: [...state.contacts, action.payload]
    //   };
    // case "DEL_CONTACT":
    //   return {
    //     contacts: state.contacts.filter(
    //       contact => contact.id !== action.payload
    //     )
    //   };
    // case "START":
    //   return {
    //     loading: true
    //   };
    // case "COMPLETE":
    //   return {
    //     loading: false
    //   };
    default:
      return state;
  }
};

export const AppContextProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AppContext.Provider value={[state, dispatch]}>
      {props.children}
    </AppContext.Provider>
  );
};

export const AppContext = createContext();

// export const UserContextProvider = props => {
//   const [state, dispatch] = useReducer(reducer, initialState);

//   return (
//     <UserContext.Provider value={[state, dispatch]}>
//       {props.children}
//     </UserContext.Provider>
//   );
// };