/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createLab = /* GraphQL */ `
  mutation CreateLab(
    $input: CreateLabInput!
    $condition: ModelLabConditionInput
  ) {
    createLab(input: $input, condition: $condition) {
      id
      name
      logo
      contact_name
      contact_phone
      phone_number
      user_id
      contact_email
      tubes_provided
      lab_director
      clia_number
      testing_notes
      street
      city_state_zip
      lab_contacts
      default_antigen
      antigen_notes
      default_molecular
      default_other
      default_rapidflu
      default_lucira
      molecular_notes
      other_notes
      lucira_notes
      rapidflu_notes
      sendInsurance
      barCodeProvided
      barCodeAlertLimit
      whiteLabel
      whiteLabelPackage
      showOnSummaryScreen
      orderCodes
      isArchive
      positiveCount
      negativeCount
      postitiveDate
      totalTest
      pendingRelease
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          baseSiteID
          batch
          barcode
          owner
          result
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateLab = /* GraphQL */ `
  mutation UpdateLab(
    $input: UpdateLabInput!
    $condition: ModelLabConditionInput
  ) {
    updateLab(input: $input, condition: $condition) {
      id
      name
      logo
      contact_name
      contact_phone
      phone_number
      user_id
      contact_email
      tubes_provided
      lab_director
      clia_number
      testing_notes
      street
      city_state_zip
      lab_contacts
      default_antigen
      antigen_notes
      default_molecular
      default_other
      default_rapidflu
      default_lucira
      molecular_notes
      other_notes
      lucira_notes
      rapidflu_notes
      sendInsurance
      barCodeProvided
      barCodeAlertLimit
      whiteLabel
      whiteLabelPackage
      showOnSummaryScreen
      orderCodes
      isArchive
      positiveCount
      negativeCount
      postitiveDate
      totalTest
      pendingRelease
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          baseSiteID
          batch
          barcode
          owner
          result
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteLab = /* GraphQL */ `
  mutation DeleteLab(
    $input: DeleteLabInput!
    $condition: ModelLabConditionInput
  ) {
    deleteLab(input: $input, condition: $condition) {
      id
      name
      logo
      contact_name
      contact_phone
      phone_number
      user_id
      contact_email
      tubes_provided
      lab_director
      clia_number
      testing_notes
      street
      city_state_zip
      lab_contacts
      default_antigen
      antigen_notes
      default_molecular
      default_other
      default_rapidflu
      default_lucira
      molecular_notes
      other_notes
      lucira_notes
      rapidflu_notes
      sendInsurance
      barCodeProvided
      barCodeAlertLimit
      whiteLabel
      whiteLabelPackage
      showOnSummaryScreen
      orderCodes
      isArchive
      positiveCount
      negativeCount
      postitiveDate
      totalTest
      pendingRelease
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          baseSiteID
          batch
          barcode
          owner
          result
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createSite = /* GraphQL */ `
  mutation CreateSite(
    $input: CreateSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    createSite(input: $input, condition: $condition) {
      id
      name
      clientID
      contact_name
      contact_phone
      phone_number
      contact_email
      safeCampAdmin
      antigen
      automateTestType
      user_id
      show_contacts
      insurance_required
      sendInsuranceCard
      resetTestDay
      startDate
      endDate
      orderKitDate
      orderKitType
      orderQuantity
      enableShipment
      showOnSummaryScreen
      messageTested
      messageResulted
      messagePositive
      messageReTest
      printLabel
      isArchive
      isLucira
      isPCRCustody
      vaccinationCard
      externalTest
      externalTestType
      externalTestDuration
      externalTestAdmit
      patternTesting
      isHRPR
      patternHippa
      preRegistration
      admit
      positiveCount
      negativeCount
      postitiveDate
      totalTest
      totalPR
      totalOrdered
      totalExtTest
      totalCheckIn
      totalGuest
      totalStaff
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          baseSiteID
          batch
          barcode
          owner
          result
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateSite = /* GraphQL */ `
  mutation UpdateSite(
    $input: UpdateSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    updateSite(input: $input, condition: $condition) {
      id
      name
      clientID
      contact_name
      contact_phone
      phone_number
      contact_email
      safeCampAdmin
      antigen
      automateTestType
      user_id
      show_contacts
      insurance_required
      sendInsuranceCard
      resetTestDay
      startDate
      endDate
      orderKitDate
      orderKitType
      orderQuantity
      enableShipment
      showOnSummaryScreen
      messageTested
      messageResulted
      messagePositive
      messageReTest
      printLabel
      isArchive
      isLucira
      isPCRCustody
      vaccinationCard
      externalTest
      externalTestType
      externalTestDuration
      externalTestAdmit
      patternTesting
      isHRPR
      patternHippa
      preRegistration
      admit
      positiveCount
      negativeCount
      postitiveDate
      totalTest
      totalPR
      totalOrdered
      totalExtTest
      totalCheckIn
      totalGuest
      totalStaff
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          baseSiteID
          batch
          barcode
          owner
          result
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteSite = /* GraphQL */ `
  mutation DeleteSite(
    $input: DeleteSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    deleteSite(input: $input, condition: $condition) {
      id
      name
      clientID
      contact_name
      contact_phone
      phone_number
      contact_email
      safeCampAdmin
      antigen
      automateTestType
      user_id
      show_contacts
      insurance_required
      sendInsuranceCard
      resetTestDay
      startDate
      endDate
      orderKitDate
      orderKitType
      orderQuantity
      enableShipment
      showOnSummaryScreen
      messageTested
      messageResulted
      messagePositive
      messageReTest
      printLabel
      isArchive
      isLucira
      isPCRCustody
      vaccinationCard
      externalTest
      externalTestType
      externalTestDuration
      externalTestAdmit
      patternTesting
      isHRPR
      patternHippa
      preRegistration
      admit
      positiveCount
      negativeCount
      postitiveDate
      totalTest
      totalPR
      totalOrdered
      totalExtTest
      totalCheckIn
      totalGuest
      totalStaff
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          baseSiteID
          batch
          barcode
          owner
          result
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createClient = /* GraphQL */ `
  mutation CreateClient(
    $input: CreateClientInput!
    $condition: ModelClientConditionInput
  ) {
    createClient(input: $input, condition: $condition) {
      id
      name
      contact_name
      contact_phone
      phone_number
      contact_email
      hippa
      hippaFile
      resultType
      showOnSummaryScreen
      whiteLabel
      whiteLabelPackage
      isArchive
      positiveCount
      negativeCount
      postitiveDate
      totalTest
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Sites {
        items {
          id
          name
          clientID
          contact_name
          contact_phone
          phone_number
          contact_email
          safeCampAdmin
          antigen
          automateTestType
          user_id
          show_contacts
          insurance_required
          sendInsuranceCard
          resetTestDay
          startDate
          endDate
          orderKitDate
          orderKitType
          orderQuantity
          enableShipment
          showOnSummaryScreen
          messageTested
          messageResulted
          messagePositive
          messageReTest
          printLabel
          isArchive
          isLucira
          isPCRCustody
          vaccinationCard
          externalTest
          externalTestType
          externalTestDuration
          externalTestAdmit
          patternTesting
          isHRPR
          patternHippa
          preRegistration
          admit
          positiveCount
          negativeCount
          postitiveDate
          totalTest
          totalPR
          totalOrdered
          totalExtTest
          totalCheckIn
          totalGuest
          totalStaff
          status
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      Employees {
        items {
          id
          first
          last
          dob
          id_number
          clientID
          street
          street2
          city
          state
          zip
          country
          countryCode
          phone_number
          sex
          email
          isVaccinated
          insurance_name
          insurance_number
          insurance_img_front
          insurance_img_back
          subID
          site_name
          isNew
          isPreRegistered
          medflowDomain
          mdID
          schrID
          whiteGlove
          employeeType
          profileImage
          vaccinationCardUpload
          vaccinationCardImage
          vaccinationDetails
          vaccinationType
          vaccinationDate
          vaccinationLocation
          vaccinationDateS
          vaccinationLocationS
          isBooster
          BoosterShotCardImage
          isExternalTest
          externalTestImage
          externalTestDate
          externalTestType
          externalTestResult
          preRegisterShowId
          patternConsent
          hippaConsent
          insuranceScan
          createdAt
          updatedBy
          updatedByName
          lastUpdatedTest
          autoRelease
          lastUsed
          isPrimaryProfile
          parentRelation
          note
          totalTest
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          baseSiteID
          batch
          barcode
          owner
          result
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateClient = /* GraphQL */ `
  mutation UpdateClient(
    $input: UpdateClientInput!
    $condition: ModelClientConditionInput
  ) {
    updateClient(input: $input, condition: $condition) {
      id
      name
      contact_name
      contact_phone
      phone_number
      contact_email
      hippa
      hippaFile
      resultType
      showOnSummaryScreen
      whiteLabel
      whiteLabelPackage
      isArchive
      positiveCount
      negativeCount
      postitiveDate
      totalTest
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Sites {
        items {
          id
          name
          clientID
          contact_name
          contact_phone
          phone_number
          contact_email
          safeCampAdmin
          antigen
          automateTestType
          user_id
          show_contacts
          insurance_required
          sendInsuranceCard
          resetTestDay
          startDate
          endDate
          orderKitDate
          orderKitType
          orderQuantity
          enableShipment
          showOnSummaryScreen
          messageTested
          messageResulted
          messagePositive
          messageReTest
          printLabel
          isArchive
          isLucira
          isPCRCustody
          vaccinationCard
          externalTest
          externalTestType
          externalTestDuration
          externalTestAdmit
          patternTesting
          isHRPR
          patternHippa
          preRegistration
          admit
          positiveCount
          negativeCount
          postitiveDate
          totalTest
          totalPR
          totalOrdered
          totalExtTest
          totalCheckIn
          totalGuest
          totalStaff
          status
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      Employees {
        items {
          id
          first
          last
          dob
          id_number
          clientID
          street
          street2
          city
          state
          zip
          country
          countryCode
          phone_number
          sex
          email
          isVaccinated
          insurance_name
          insurance_number
          insurance_img_front
          insurance_img_back
          subID
          site_name
          isNew
          isPreRegistered
          medflowDomain
          mdID
          schrID
          whiteGlove
          employeeType
          profileImage
          vaccinationCardUpload
          vaccinationCardImage
          vaccinationDetails
          vaccinationType
          vaccinationDate
          vaccinationLocation
          vaccinationDateS
          vaccinationLocationS
          isBooster
          BoosterShotCardImage
          isExternalTest
          externalTestImage
          externalTestDate
          externalTestType
          externalTestResult
          preRegisterShowId
          patternConsent
          hippaConsent
          insuranceScan
          createdAt
          updatedBy
          updatedByName
          lastUpdatedTest
          autoRelease
          lastUsed
          isPrimaryProfile
          parentRelation
          note
          totalTest
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          baseSiteID
          batch
          barcode
          owner
          result
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteClient = /* GraphQL */ `
  mutation DeleteClient(
    $input: DeleteClientInput!
    $condition: ModelClientConditionInput
  ) {
    deleteClient(input: $input, condition: $condition) {
      id
      name
      contact_name
      contact_phone
      phone_number
      contact_email
      hippa
      hippaFile
      resultType
      showOnSummaryScreen
      whiteLabel
      whiteLabelPackage
      isArchive
      positiveCount
      negativeCount
      postitiveDate
      totalTest
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Sites {
        items {
          id
          name
          clientID
          contact_name
          contact_phone
          phone_number
          contact_email
          safeCampAdmin
          antigen
          automateTestType
          user_id
          show_contacts
          insurance_required
          sendInsuranceCard
          resetTestDay
          startDate
          endDate
          orderKitDate
          orderKitType
          orderQuantity
          enableShipment
          showOnSummaryScreen
          messageTested
          messageResulted
          messagePositive
          messageReTest
          printLabel
          isArchive
          isLucira
          isPCRCustody
          vaccinationCard
          externalTest
          externalTestType
          externalTestDuration
          externalTestAdmit
          patternTesting
          isHRPR
          patternHippa
          preRegistration
          admit
          positiveCount
          negativeCount
          postitiveDate
          totalTest
          totalPR
          totalOrdered
          totalExtTest
          totalCheckIn
          totalGuest
          totalStaff
          status
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      Employees {
        items {
          id
          first
          last
          dob
          id_number
          clientID
          street
          street2
          city
          state
          zip
          country
          countryCode
          phone_number
          sex
          email
          isVaccinated
          insurance_name
          insurance_number
          insurance_img_front
          insurance_img_back
          subID
          site_name
          isNew
          isPreRegistered
          medflowDomain
          mdID
          schrID
          whiteGlove
          employeeType
          profileImage
          vaccinationCardUpload
          vaccinationCardImage
          vaccinationDetails
          vaccinationType
          vaccinationDate
          vaccinationLocation
          vaccinationDateS
          vaccinationLocationS
          isBooster
          BoosterShotCardImage
          isExternalTest
          externalTestImage
          externalTestDate
          externalTestType
          externalTestResult
          preRegisterShowId
          patternConsent
          hippaConsent
          insuranceScan
          createdAt
          updatedBy
          updatedByName
          lastUpdatedTest
          autoRelease
          lastUsed
          isPrimaryProfile
          parentRelation
          note
          totalTest
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          baseSiteID
          batch
          barcode
          owner
          result
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createEmployee = /* GraphQL */ `
  mutation CreateEmployee(
    $input: CreateEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    createEmployee(input: $input, condition: $condition) {
      id
      first
      last
      dob
      id_number
      clientID
      street
      street2
      city
      state
      zip
      country
      countryCode
      phone_number
      sex
      email
      isVaccinated
      insurance_name
      insurance_number
      insurance_img_front
      insurance_img_back
      subID
      site_name
      isNew
      isPreRegistered
      medflowDomain
      mdID
      schrID
      whiteGlove
      employeeType
      profileImage
      vaccinationCardUpload
      vaccinationCardImage
      vaccinationDetails
      vaccinationType
      vaccinationDate
      vaccinationLocation
      vaccinationDateS
      vaccinationLocationS
      isBooster
      BoosterShotCardImage
      isExternalTest
      externalTestImage
      externalTestDate
      externalTestType
      externalTestResult
      preRegisterShowId
      patternConsent
      hippaConsent
      insuranceScan
      createdAt
      updatedBy
      updatedByName
      lastUpdatedTest
      autoRelease
      lastUsed
      isPrimaryProfile
      parentRelation
      note
      totalTest
      _version
      _deleted
      _lastChangedAt
      updatedAt
      Client {
        id
        name
        contact_name
        contact_phone
        phone_number
        contact_email
        hippa
        hippaFile
        resultType
        showOnSummaryScreen
        whiteLabel
        whiteLabelPackage
        isArchive
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Sites {
          nextToken
          startedAt
        }
        Employees {
          nextToken
          startedAt
        }
        Tests {
          nextToken
          startedAt
        }
      }
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          baseSiteID
          batch
          barcode
          owner
          result
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateEmployee = /* GraphQL */ `
  mutation UpdateEmployee(
    $input: UpdateEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    updateEmployee(input: $input, condition: $condition) {
      id
      first
      last
      dob
      id_number
      clientID
      street
      street2
      city
      state
      zip
      country
      countryCode
      phone_number
      sex
      email
      isVaccinated
      insurance_name
      insurance_number
      insurance_img_front
      insurance_img_back
      subID
      site_name
      isNew
      isPreRegistered
      medflowDomain
      mdID
      schrID
      whiteGlove
      employeeType
      profileImage
      vaccinationCardUpload
      vaccinationCardImage
      vaccinationDetails
      vaccinationType
      vaccinationDate
      vaccinationLocation
      vaccinationDateS
      vaccinationLocationS
      isBooster
      BoosterShotCardImage
      isExternalTest
      externalTestImage
      externalTestDate
      externalTestType
      externalTestResult
      preRegisterShowId
      patternConsent
      hippaConsent
      insuranceScan
      createdAt
      updatedBy
      updatedByName
      lastUpdatedTest
      autoRelease
      lastUsed
      isPrimaryProfile
      parentRelation
      note
      totalTest
      _version
      _deleted
      _lastChangedAt
      updatedAt
      Client {
        id
        name
        contact_name
        contact_phone
        phone_number
        contact_email
        hippa
        hippaFile
        resultType
        showOnSummaryScreen
        whiteLabel
        whiteLabelPackage
        isArchive
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Sites {
          nextToken
          startedAt
        }
        Employees {
          nextToken
          startedAt
        }
        Tests {
          nextToken
          startedAt
        }
      }
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          baseSiteID
          batch
          barcode
          owner
          result
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteEmployee = /* GraphQL */ `
  mutation DeleteEmployee(
    $input: DeleteEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    deleteEmployee(input: $input, condition: $condition) {
      id
      first
      last
      dob
      id_number
      clientID
      street
      street2
      city
      state
      zip
      country
      countryCode
      phone_number
      sex
      email
      isVaccinated
      insurance_name
      insurance_number
      insurance_img_front
      insurance_img_back
      subID
      site_name
      isNew
      isPreRegistered
      medflowDomain
      mdID
      schrID
      whiteGlove
      employeeType
      profileImage
      vaccinationCardUpload
      vaccinationCardImage
      vaccinationDetails
      vaccinationType
      vaccinationDate
      vaccinationLocation
      vaccinationDateS
      vaccinationLocationS
      isBooster
      BoosterShotCardImage
      isExternalTest
      externalTestImage
      externalTestDate
      externalTestType
      externalTestResult
      preRegisterShowId
      patternConsent
      hippaConsent
      insuranceScan
      createdAt
      updatedBy
      updatedByName
      lastUpdatedTest
      autoRelease
      lastUsed
      isPrimaryProfile
      parentRelation
      note
      totalTest
      _version
      _deleted
      _lastChangedAt
      updatedAt
      Client {
        id
        name
        contact_name
        contact_phone
        phone_number
        contact_email
        hippa
        hippaFile
        resultType
        showOnSummaryScreen
        whiteLabel
        whiteLabelPackage
        isArchive
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Sites {
          nextToken
          startedAt
        }
        Employees {
          nextToken
          startedAt
        }
        Tests {
          nextToken
          startedAt
        }
      }
      Tests {
        items {
          id
          status
          clientID
          labID
          employeeID
          siteID
          baseSiteID
          batch
          barcode
          owner
          result
          resultDate
          resultDateTime
          employee_demographics
          labOrderDetail
          site_name
          clientName
          labName
          phoneNumber
          test_number
          email
          tester_name
          isAntigen
          referenceID
          test_type
          createdBy
          testerPhone
          submittedBy
          submittedByName
          StampBy
          StampByName
          emailSend
          quarantinedStart
          quarantinedEnd
          startTimeStamp
          timerStatus
          startDate
          expired
          beenTimed
          done
          sr_no
          appVer
          sequenceNo
          createSource
          isFalsePositive
          invalidResultStatus
          isLucira
          patternTestAnswer
          schrID
          schrTestID
          createdAt
          timezone
          pcrStatus
          scanByTesterID
          scanByTesterName
          scanByLabUserID
          scanByLabUserName
          labelPrinted
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createTest = /* GraphQL */ `
  mutation CreateTest(
    $input: CreateTestInput!
    $condition: ModelTestConditionInput
  ) {
    createTest(input: $input, condition: $condition) {
      id
      status
      clientID
      labID
      employeeID
      siteID
      baseSiteID
      batch
      barcode
      owner
      result
      resultDate
      resultDateTime
      employee_demographics
      labOrderDetail
      site_name
      clientName
      labName
      phoneNumber
      test_number
      email
      tester_name
      isAntigen
      referenceID
      test_type
      createdBy
      testerPhone
      submittedBy
      submittedByName
      StampBy
      StampByName
      emailSend
      quarantinedStart
      quarantinedEnd
      startTimeStamp
      timerStatus
      startDate
      expired
      beenTimed
      done
      sr_no
      appVer
      sequenceNo
      createSource
      isFalsePositive
      invalidResultStatus
      isLucira
      patternTestAnswer
      schrID
      schrTestID
      createdAt
      timezone
      pcrStatus
      scanByTesterID
      scanByTesterName
      scanByLabUserID
      scanByLabUserName
      labelPrinted
      _version
      _deleted
      _lastChangedAt
      updatedAt
      lab {
        id
        name
        logo
        contact_name
        contact_phone
        phone_number
        user_id
        contact_email
        tubes_provided
        lab_director
        clia_number
        testing_notes
        street
        city_state_zip
        lab_contacts
        default_antigen
        antigen_notes
        default_molecular
        default_other
        default_rapidflu
        default_lucira
        molecular_notes
        other_notes
        lucira_notes
        rapidflu_notes
        sendInsurance
        barCodeProvided
        barCodeAlertLimit
        whiteLabel
        whiteLabelPackage
        showOnSummaryScreen
        orderCodes
        isArchive
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        pendingRelease
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Tests {
          nextToken
          startedAt
        }
      }
      site {
        id
        name
        clientID
        contact_name
        contact_phone
        phone_number
        contact_email
        safeCampAdmin
        antigen
        automateTestType
        user_id
        show_contacts
        insurance_required
        sendInsuranceCard
        resetTestDay
        startDate
        endDate
        orderKitDate
        orderKitType
        orderQuantity
        enableShipment
        showOnSummaryScreen
        messageTested
        messageResulted
        messagePositive
        messageReTest
        printLabel
        isArchive
        isLucira
        isPCRCustody
        vaccinationCard
        externalTest
        externalTestType
        externalTestDuration
        externalTestAdmit
        patternTesting
        isHRPR
        patternHippa
        preRegistration
        admit
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        totalPR
        totalOrdered
        totalExtTest
        totalCheckIn
        totalGuest
        totalStaff
        status
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Tests {
          nextToken
          startedAt
        }
      }
      client {
        id
        name
        contact_name
        contact_phone
        phone_number
        contact_email
        hippa
        hippaFile
        resultType
        showOnSummaryScreen
        whiteLabel
        whiteLabelPackage
        isArchive
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Sites {
          nextToken
          startedAt
        }
        Employees {
          nextToken
          startedAt
        }
        Tests {
          nextToken
          startedAt
        }
      }
      employee {
        id
        first
        last
        dob
        id_number
        clientID
        street
        street2
        city
        state
        zip
        country
        countryCode
        phone_number
        sex
        email
        isVaccinated
        insurance_name
        insurance_number
        insurance_img_front
        insurance_img_back
        subID
        site_name
        isNew
        isPreRegistered
        medflowDomain
        mdID
        schrID
        whiteGlove
        employeeType
        profileImage
        vaccinationCardUpload
        vaccinationCardImage
        vaccinationDetails
        vaccinationType
        vaccinationDate
        vaccinationLocation
        vaccinationDateS
        vaccinationLocationS
        isBooster
        BoosterShotCardImage
        isExternalTest
        externalTestImage
        externalTestDate
        externalTestType
        externalTestResult
        preRegisterShowId
        patternConsent
        hippaConsent
        insuranceScan
        createdAt
        updatedBy
        updatedByName
        lastUpdatedTest
        autoRelease
        lastUsed
        isPrimaryProfile
        parentRelation
        note
        totalTest
        _version
        _deleted
        _lastChangedAt
        updatedAt
        Client {
          id
          name
          contact_name
          contact_phone
          phone_number
          contact_email
          hippa
          hippaFile
          resultType
          showOnSummaryScreen
          whiteLabel
          whiteLabelPackage
          isArchive
          positiveCount
          negativeCount
          postitiveDate
          totalTest
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        Tests {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateTest = /* GraphQL */ `
  mutation UpdateTest(
    $input: UpdateTestInput!
    $condition: ModelTestConditionInput
  ) {
    updateTest(input: $input, condition: $condition) {
      id
      status
      clientID
      labID
      employeeID
      siteID
      baseSiteID
      batch
      barcode
      owner
      result
      resultDate
      resultDateTime
      employee_demographics
      labOrderDetail
      site_name
      clientName
      labName
      phoneNumber
      test_number
      email
      tester_name
      isAntigen
      referenceID
      test_type
      createdBy
      testerPhone
      submittedBy
      submittedByName
      StampBy
      StampByName
      emailSend
      quarantinedStart
      quarantinedEnd
      startTimeStamp
      timerStatus
      startDate
      expired
      beenTimed
      done
      sr_no
      appVer
      sequenceNo
      createSource
      isFalsePositive
      invalidResultStatus
      isLucira
      patternTestAnswer
      schrID
      schrTestID
      createdAt
      timezone
      pcrStatus
      scanByTesterID
      scanByTesterName
      scanByLabUserID
      scanByLabUserName
      labelPrinted
      _version
      _deleted
      _lastChangedAt
      updatedAt
      lab {
        id
        name
        logo
        contact_name
        contact_phone
        phone_number
        user_id
        contact_email
        tubes_provided
        lab_director
        clia_number
        testing_notes
        street
        city_state_zip
        lab_contacts
        default_antigen
        antigen_notes
        default_molecular
        default_other
        default_rapidflu
        default_lucira
        molecular_notes
        other_notes
        lucira_notes
        rapidflu_notes
        sendInsurance
        barCodeProvided
        barCodeAlertLimit
        whiteLabel
        whiteLabelPackage
        showOnSummaryScreen
        orderCodes
        isArchive
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        pendingRelease
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Tests {
          nextToken
          startedAt
        }
      }
      site {
        id
        name
        clientID
        contact_name
        contact_phone
        phone_number
        contact_email
        safeCampAdmin
        antigen
        automateTestType
        user_id
        show_contacts
        insurance_required
        sendInsuranceCard
        resetTestDay
        startDate
        endDate
        orderKitDate
        orderKitType
        orderQuantity
        enableShipment
        showOnSummaryScreen
        messageTested
        messageResulted
        messagePositive
        messageReTest
        printLabel
        isArchive
        isLucira
        isPCRCustody
        vaccinationCard
        externalTest
        externalTestType
        externalTestDuration
        externalTestAdmit
        patternTesting
        isHRPR
        patternHippa
        preRegistration
        admit
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        totalPR
        totalOrdered
        totalExtTest
        totalCheckIn
        totalGuest
        totalStaff
        status
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Tests {
          nextToken
          startedAt
        }
      }
      client {
        id
        name
        contact_name
        contact_phone
        phone_number
        contact_email
        hippa
        hippaFile
        resultType
        showOnSummaryScreen
        whiteLabel
        whiteLabelPackage
        isArchive
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Sites {
          nextToken
          startedAt
        }
        Employees {
          nextToken
          startedAt
        }
        Tests {
          nextToken
          startedAt
        }
      }
      employee {
        id
        first
        last
        dob
        id_number
        clientID
        street
        street2
        city
        state
        zip
        country
        countryCode
        phone_number
        sex
        email
        isVaccinated
        insurance_name
        insurance_number
        insurance_img_front
        insurance_img_back
        subID
        site_name
        isNew
        isPreRegistered
        medflowDomain
        mdID
        schrID
        whiteGlove
        employeeType
        profileImage
        vaccinationCardUpload
        vaccinationCardImage
        vaccinationDetails
        vaccinationType
        vaccinationDate
        vaccinationLocation
        vaccinationDateS
        vaccinationLocationS
        isBooster
        BoosterShotCardImage
        isExternalTest
        externalTestImage
        externalTestDate
        externalTestType
        externalTestResult
        preRegisterShowId
        patternConsent
        hippaConsent
        insuranceScan
        createdAt
        updatedBy
        updatedByName
        lastUpdatedTest
        autoRelease
        lastUsed
        isPrimaryProfile
        parentRelation
        note
        totalTest
        _version
        _deleted
        _lastChangedAt
        updatedAt
        Client {
          id
          name
          contact_name
          contact_phone
          phone_number
          contact_email
          hippa
          hippaFile
          resultType
          showOnSummaryScreen
          whiteLabel
          whiteLabelPackage
          isArchive
          positiveCount
          negativeCount
          postitiveDate
          totalTest
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        Tests {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteTest = /* GraphQL */ `
  mutation DeleteTest(
    $input: DeleteTestInput!
    $condition: ModelTestConditionInput
  ) {
    deleteTest(input: $input, condition: $condition) {
      id
      status
      clientID
      labID
      employeeID
      siteID
      baseSiteID
      batch
      barcode
      owner
      result
      resultDate
      resultDateTime
      employee_demographics
      labOrderDetail
      site_name
      clientName
      labName
      phoneNumber
      test_number
      email
      tester_name
      isAntigen
      referenceID
      test_type
      createdBy
      testerPhone
      submittedBy
      submittedByName
      StampBy
      StampByName
      emailSend
      quarantinedStart
      quarantinedEnd
      startTimeStamp
      timerStatus
      startDate
      expired
      beenTimed
      done
      sr_no
      appVer
      sequenceNo
      createSource
      isFalsePositive
      invalidResultStatus
      isLucira
      patternTestAnswer
      schrID
      schrTestID
      createdAt
      timezone
      pcrStatus
      scanByTesterID
      scanByTesterName
      scanByLabUserID
      scanByLabUserName
      labelPrinted
      _version
      _deleted
      _lastChangedAt
      updatedAt
      lab {
        id
        name
        logo
        contact_name
        contact_phone
        phone_number
        user_id
        contact_email
        tubes_provided
        lab_director
        clia_number
        testing_notes
        street
        city_state_zip
        lab_contacts
        default_antigen
        antigen_notes
        default_molecular
        default_other
        default_rapidflu
        default_lucira
        molecular_notes
        other_notes
        lucira_notes
        rapidflu_notes
        sendInsurance
        barCodeProvided
        barCodeAlertLimit
        whiteLabel
        whiteLabelPackage
        showOnSummaryScreen
        orderCodes
        isArchive
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        pendingRelease
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Tests {
          nextToken
          startedAt
        }
      }
      site {
        id
        name
        clientID
        contact_name
        contact_phone
        phone_number
        contact_email
        safeCampAdmin
        antigen
        automateTestType
        user_id
        show_contacts
        insurance_required
        sendInsuranceCard
        resetTestDay
        startDate
        endDate
        orderKitDate
        orderKitType
        orderQuantity
        enableShipment
        showOnSummaryScreen
        messageTested
        messageResulted
        messagePositive
        messageReTest
        printLabel
        isArchive
        isLucira
        isPCRCustody
        vaccinationCard
        externalTest
        externalTestType
        externalTestDuration
        externalTestAdmit
        patternTesting
        isHRPR
        patternHippa
        preRegistration
        admit
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        totalPR
        totalOrdered
        totalExtTest
        totalCheckIn
        totalGuest
        totalStaff
        status
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Tests {
          nextToken
          startedAt
        }
      }
      client {
        id
        name
        contact_name
        contact_phone
        phone_number
        contact_email
        hippa
        hippaFile
        resultType
        showOnSummaryScreen
        whiteLabel
        whiteLabelPackage
        isArchive
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Sites {
          nextToken
          startedAt
        }
        Employees {
          nextToken
          startedAt
        }
        Tests {
          nextToken
          startedAt
        }
      }
      employee {
        id
        first
        last
        dob
        id_number
        clientID
        street
        street2
        city
        state
        zip
        country
        countryCode
        phone_number
        sex
        email
        isVaccinated
        insurance_name
        insurance_number
        insurance_img_front
        insurance_img_back
        subID
        site_name
        isNew
        isPreRegistered
        medflowDomain
        mdID
        schrID
        whiteGlove
        employeeType
        profileImage
        vaccinationCardUpload
        vaccinationCardImage
        vaccinationDetails
        vaccinationType
        vaccinationDate
        vaccinationLocation
        vaccinationDateS
        vaccinationLocationS
        isBooster
        BoosterShotCardImage
        isExternalTest
        externalTestImage
        externalTestDate
        externalTestType
        externalTestResult
        preRegisterShowId
        patternConsent
        hippaConsent
        insuranceScan
        createdAt
        updatedBy
        updatedByName
        lastUpdatedTest
        autoRelease
        lastUsed
        isPrimaryProfile
        parentRelation
        note
        totalTest
        _version
        _deleted
        _lastChangedAt
        updatedAt
        Client {
          id
          name
          contact_name
          contact_phone
          phone_number
          contact_email
          hippa
          hippaFile
          resultType
          showOnSummaryScreen
          whiteLabel
          whiteLabelPackage
          isArchive
          positiveCount
          negativeCount
          postitiveDate
          totalTest
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        Tests {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createLencoBarcodes = /* GraphQL */ `
  mutation CreateLencoBarcodes(
    $input: CreateLencoBarcodesInput!
    $condition: ModelLencoBarcodesConditionInput
  ) {
    createLencoBarcodes(input: $input, condition: $condition) {
      id
      labID
      barcode
      available
      isActive
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateLencoBarcodes = /* GraphQL */ `
  mutation UpdateLencoBarcodes(
    $input: UpdateLencoBarcodesInput!
    $condition: ModelLencoBarcodesConditionInput
  ) {
    updateLencoBarcodes(input: $input, condition: $condition) {
      id
      labID
      barcode
      available
      isActive
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteLencoBarcodes = /* GraphQL */ `
  mutation DeleteLencoBarcodes(
    $input: DeleteLencoBarcodesInput!
    $condition: ModelLencoBarcodesConditionInput
  ) {
    deleteLencoBarcodes(input: $input, condition: $condition) {
      id
      labID
      barcode
      available
      isActive
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createTestConsent = /* GraphQL */ `
  mutation CreateTestConsent(
    $input: CreateTestConsentInput!
    $condition: ModelTestConsentConditionInput
  ) {
    createTestConsent(input: $input, condition: $condition) {
      id
      employeeID
      siteID
      isConsent
      isInsuranceCardScan
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      site {
        id
        name
        clientID
        contact_name
        contact_phone
        phone_number
        contact_email
        safeCampAdmin
        antigen
        automateTestType
        user_id
        show_contacts
        insurance_required
        sendInsuranceCard
        resetTestDay
        startDate
        endDate
        orderKitDate
        orderKitType
        orderQuantity
        enableShipment
        showOnSummaryScreen
        messageTested
        messageResulted
        messagePositive
        messageReTest
        printLabel
        isArchive
        isLucira
        isPCRCustody
        vaccinationCard
        externalTest
        externalTestType
        externalTestDuration
        externalTestAdmit
        patternTesting
        isHRPR
        patternHippa
        preRegistration
        admit
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        totalPR
        totalOrdered
        totalExtTest
        totalCheckIn
        totalGuest
        totalStaff
        status
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Tests {
          nextToken
          startedAt
        }
      }
      employee {
        id
        first
        last
        dob
        id_number
        clientID
        street
        street2
        city
        state
        zip
        country
        countryCode
        phone_number
        sex
        email
        isVaccinated
        insurance_name
        insurance_number
        insurance_img_front
        insurance_img_back
        subID
        site_name
        isNew
        isPreRegistered
        medflowDomain
        mdID
        schrID
        whiteGlove
        employeeType
        profileImage
        vaccinationCardUpload
        vaccinationCardImage
        vaccinationDetails
        vaccinationType
        vaccinationDate
        vaccinationLocation
        vaccinationDateS
        vaccinationLocationS
        isBooster
        BoosterShotCardImage
        isExternalTest
        externalTestImage
        externalTestDate
        externalTestType
        externalTestResult
        preRegisterShowId
        patternConsent
        hippaConsent
        insuranceScan
        createdAt
        updatedBy
        updatedByName
        lastUpdatedTest
        autoRelease
        lastUsed
        isPrimaryProfile
        parentRelation
        note
        totalTest
        _version
        _deleted
        _lastChangedAt
        updatedAt
        Client {
          id
          name
          contact_name
          contact_phone
          phone_number
          contact_email
          hippa
          hippaFile
          resultType
          showOnSummaryScreen
          whiteLabel
          whiteLabelPackage
          isArchive
          positiveCount
          negativeCount
          postitiveDate
          totalTest
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        Tests {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateTestConsent = /* GraphQL */ `
  mutation UpdateTestConsent(
    $input: UpdateTestConsentInput!
    $condition: ModelTestConsentConditionInput
  ) {
    updateTestConsent(input: $input, condition: $condition) {
      id
      employeeID
      siteID
      isConsent
      isInsuranceCardScan
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      site {
        id
        name
        clientID
        contact_name
        contact_phone
        phone_number
        contact_email
        safeCampAdmin
        antigen
        automateTestType
        user_id
        show_contacts
        insurance_required
        sendInsuranceCard
        resetTestDay
        startDate
        endDate
        orderKitDate
        orderKitType
        orderQuantity
        enableShipment
        showOnSummaryScreen
        messageTested
        messageResulted
        messagePositive
        messageReTest
        printLabel
        isArchive
        isLucira
        isPCRCustody
        vaccinationCard
        externalTest
        externalTestType
        externalTestDuration
        externalTestAdmit
        patternTesting
        isHRPR
        patternHippa
        preRegistration
        admit
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        totalPR
        totalOrdered
        totalExtTest
        totalCheckIn
        totalGuest
        totalStaff
        status
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Tests {
          nextToken
          startedAt
        }
      }
      employee {
        id
        first
        last
        dob
        id_number
        clientID
        street
        street2
        city
        state
        zip
        country
        countryCode
        phone_number
        sex
        email
        isVaccinated
        insurance_name
        insurance_number
        insurance_img_front
        insurance_img_back
        subID
        site_name
        isNew
        isPreRegistered
        medflowDomain
        mdID
        schrID
        whiteGlove
        employeeType
        profileImage
        vaccinationCardUpload
        vaccinationCardImage
        vaccinationDetails
        vaccinationType
        vaccinationDate
        vaccinationLocation
        vaccinationDateS
        vaccinationLocationS
        isBooster
        BoosterShotCardImage
        isExternalTest
        externalTestImage
        externalTestDate
        externalTestType
        externalTestResult
        preRegisterShowId
        patternConsent
        hippaConsent
        insuranceScan
        createdAt
        updatedBy
        updatedByName
        lastUpdatedTest
        autoRelease
        lastUsed
        isPrimaryProfile
        parentRelation
        note
        totalTest
        _version
        _deleted
        _lastChangedAt
        updatedAt
        Client {
          id
          name
          contact_name
          contact_phone
          phone_number
          contact_email
          hippa
          hippaFile
          resultType
          showOnSummaryScreen
          whiteLabel
          whiteLabelPackage
          isArchive
          positiveCount
          negativeCount
          postitiveDate
          totalTest
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        Tests {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteTestConsent = /* GraphQL */ `
  mutation DeleteTestConsent(
    $input: DeleteTestConsentInput!
    $condition: ModelTestConsentConditionInput
  ) {
    deleteTestConsent(input: $input, condition: $condition) {
      id
      employeeID
      siteID
      isConsent
      isInsuranceCardScan
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      site {
        id
        name
        clientID
        contact_name
        contact_phone
        phone_number
        contact_email
        safeCampAdmin
        antigen
        automateTestType
        user_id
        show_contacts
        insurance_required
        sendInsuranceCard
        resetTestDay
        startDate
        endDate
        orderKitDate
        orderKitType
        orderQuantity
        enableShipment
        showOnSummaryScreen
        messageTested
        messageResulted
        messagePositive
        messageReTest
        printLabel
        isArchive
        isLucira
        isPCRCustody
        vaccinationCard
        externalTest
        externalTestType
        externalTestDuration
        externalTestAdmit
        patternTesting
        isHRPR
        patternHippa
        preRegistration
        admit
        positiveCount
        negativeCount
        postitiveDate
        totalTest
        totalPR
        totalOrdered
        totalExtTest
        totalCheckIn
        totalGuest
        totalStaff
        status
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        Tests {
          nextToken
          startedAt
        }
      }
      employee {
        id
        first
        last
        dob
        id_number
        clientID
        street
        street2
        city
        state
        zip
        country
        countryCode
        phone_number
        sex
        email
        isVaccinated
        insurance_name
        insurance_number
        insurance_img_front
        insurance_img_back
        subID
        site_name
        isNew
        isPreRegistered
        medflowDomain
        mdID
        schrID
        whiteGlove
        employeeType
        profileImage
        vaccinationCardUpload
        vaccinationCardImage
        vaccinationDetails
        vaccinationType
        vaccinationDate
        vaccinationLocation
        vaccinationDateS
        vaccinationLocationS
        isBooster
        BoosterShotCardImage
        isExternalTest
        externalTestImage
        externalTestDate
        externalTestType
        externalTestResult
        preRegisterShowId
        patternConsent
        hippaConsent
        insuranceScan
        createdAt
        updatedBy
        updatedByName
        lastUpdatedTest
        autoRelease
        lastUsed
        isPrimaryProfile
        parentRelation
        note
        totalTest
        _version
        _deleted
        _lastChangedAt
        updatedAt
        Client {
          id
          name
          contact_name
          contact_phone
          phone_number
          contact_email
          hippa
          hippaFile
          resultType
          showOnSummaryScreen
          whiteLabel
          whiteLabelPackage
          isArchive
          positiveCount
          negativeCount
          postitiveDate
          totalTest
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        Tests {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createTestTypes = /* GraphQL */ `
  mutation CreateTestTypes(
    $input: CreateTestTypesInput!
    $condition: ModelTestTypesConditionInput
  ) {
    createTestTypes(input: $input, condition: $condition) {
      id
      name
      totalTime
      firstAlert
      secondAlert
      duration
      hrCounter
      siteID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateTestTypes = /* GraphQL */ `
  mutation UpdateTestTypes(
    $input: UpdateTestTypesInput!
    $condition: ModelTestTypesConditionInput
  ) {
    updateTestTypes(input: $input, condition: $condition) {
      id
      name
      totalTime
      firstAlert
      secondAlert
      duration
      hrCounter
      siteID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteTestTypes = /* GraphQL */ `
  mutation DeleteTestTypes(
    $input: DeleteTestTypesInput!
    $condition: ModelTestTypesConditionInput
  ) {
    deleteTestTypes(input: $input, condition: $condition) {
      id
      name
      totalTime
      firstAlert
      secondAlert
      duration
      hrCounter
      siteID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createEmployeeBarCodes = /* GraphQL */ `
  mutation CreateEmployeeBarCodes(
    $input: CreateEmployeeBarCodesInput!
    $condition: ModelEmployeeBarCodesConditionInput
  ) {
    createEmployeeBarCodes(input: $input, condition: $condition) {
      id
      employeeID
      barcode
      phone_number
      licenceNumber
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const updateEmployeeBarCodes = /* GraphQL */ `
  mutation UpdateEmployeeBarCodes(
    $input: UpdateEmployeeBarCodesInput!
    $condition: ModelEmployeeBarCodesConditionInput
  ) {
    updateEmployeeBarCodes(input: $input, condition: $condition) {
      id
      employeeID
      barcode
      phone_number
      licenceNumber
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const deleteEmployeeBarCodes = /* GraphQL */ `
  mutation DeleteEmployeeBarCodes(
    $input: DeleteEmployeeBarCodesInput!
    $condition: ModelEmployeeBarCodesConditionInput
  ) {
    deleteEmployeeBarCodes(input: $input, condition: $condition) {
      id
      employeeID
      barcode
      phone_number
      licenceNumber
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const createEmployeeTestCounter = /* GraphQL */ `
  mutation CreateEmployeeTestCounter(
    $input: CreateEmployeeTestCounterInput!
    $condition: ModelEmployeeTestCounterConditionInput
  ) {
    createEmployeeTestCounter(input: $input, condition: $condition) {
      id
      counter
      employeeCounter
      patternQuestion
      totalTest
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateEmployeeTestCounter = /* GraphQL */ `
  mutation UpdateEmployeeTestCounter(
    $input: UpdateEmployeeTestCounterInput!
    $condition: ModelEmployeeTestCounterConditionInput
  ) {
    updateEmployeeTestCounter(input: $input, condition: $condition) {
      id
      counter
      employeeCounter
      patternQuestion
      totalTest
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteEmployeeTestCounter = /* GraphQL */ `
  mutation DeleteEmployeeTestCounter(
    $input: DeleteEmployeeTestCounterInput!
    $condition: ModelEmployeeTestCounterConditionInput
  ) {
    deleteEmployeeTestCounter(input: $input, condition: $condition) {
      id
      counter
      employeeCounter
      patternQuestion
      totalTest
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createExternalTest = /* GraphQL */ `
  mutation CreateExternalTest(
    $input: CreateExternalTestInput!
    $condition: ModelExternalTestConditionInput
  ) {
    createExternalTest(input: $input, condition: $condition) {
      id
      status
      clientID
      employeeID
      siteID
      owner
      reason
      employee_demographics
      site_name
      phoneNumber
      tester_name
      email
      vaccineCardFile
      vaccineCardFileBack
      test_type
      result
      resultDateTime
      externalTestFile
      createdAt
      prID
      isPosted
      testNow
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const updateExternalTest = /* GraphQL */ `
  mutation UpdateExternalTest(
    $input: UpdateExternalTestInput!
    $condition: ModelExternalTestConditionInput
  ) {
    updateExternalTest(input: $input, condition: $condition) {
      id
      status
      clientID
      employeeID
      siteID
      owner
      reason
      employee_demographics
      site_name
      phoneNumber
      tester_name
      email
      vaccineCardFile
      vaccineCardFileBack
      test_type
      result
      resultDateTime
      externalTestFile
      createdAt
      prID
      isPosted
      testNow
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const deleteExternalTest = /* GraphQL */ `
  mutation DeleteExternalTest(
    $input: DeleteExternalTestInput!
    $condition: ModelExternalTestConditionInput
  ) {
    deleteExternalTest(input: $input, condition: $condition) {
      id
      status
      clientID
      employeeID
      siteID
      owner
      reason
      employee_demographics
      site_name
      phoneNumber
      tester_name
      email
      vaccineCardFile
      vaccineCardFileBack
      test_type
      result
      resultDateTime
      externalTestFile
      createdAt
      prID
      isPosted
      testNow
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const createEmployeeLogin = /* GraphQL */ `
  mutation CreateEmployeeLogin(
    $input: CreateEmployeeLoginInput!
    $condition: ModelEmployeeLoginConditionInput
  ) {
    createEmployeeLogin(input: $input, condition: $condition) {
      id
      employeeID
      deviceID
      deviceType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateEmployeeLogin = /* GraphQL */ `
  mutation UpdateEmployeeLogin(
    $input: UpdateEmployeeLoginInput!
    $condition: ModelEmployeeLoginConditionInput
  ) {
    updateEmployeeLogin(input: $input, condition: $condition) {
      id
      employeeID
      deviceID
      deviceType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteEmployeeLogin = /* GraphQL */ `
  mutation DeleteEmployeeLogin(
    $input: DeleteEmployeeLoginInput!
    $condition: ModelEmployeeLoginConditionInput
  ) {
    deleteEmployeeLogin(input: $input, condition: $condition) {
      id
      employeeID
      deviceID
      deviceType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createTestSession = /* GraphQL */ `
  mutation CreateTestSession(
    $input: CreateTestSessionInput!
    $condition: ModelTestSessionConditionInput
  ) {
    createTestSession(input: $input, condition: $condition) {
      id
      siteID
      clientID
      sessionStart
      sessionEnd
      activeTester
      Tester
      startedBy
      startedByName
      status
      endedBy
      endedByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateTestSession = /* GraphQL */ `
  mutation UpdateTestSession(
    $input: UpdateTestSessionInput!
    $condition: ModelTestSessionConditionInput
  ) {
    updateTestSession(input: $input, condition: $condition) {
      id
      siteID
      clientID
      sessionStart
      sessionEnd
      activeTester
      Tester
      startedBy
      startedByName
      status
      endedBy
      endedByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteTestSession = /* GraphQL */ `
  mutation DeleteTestSession(
    $input: DeleteTestSessionInput!
    $condition: ModelTestSessionConditionInput
  ) {
    deleteTestSession(input: $input, condition: $condition) {
      id
      siteID
      clientID
      sessionStart
      sessionEnd
      activeTester
      Tester
      startedBy
      startedByName
      status
      endedBy
      endedByName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
