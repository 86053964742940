import React, { useState, useEffect, useMemo, useContext } from "react";
import { Button, Card, Table, Row, Col, DropdownButton, Dropdown, Modal, Container } from "react-bootstrap";
import { PDFViewer } from "@react-pdf/renderer";
import SitePDF from "../components/SitePDF";
import API from "../api";
import { Link } from "react-router-dom";

import ClientDashboard from "./ClientDashboard";
import { AppContext } from "../context/app-context";

const HealthAndSafetyHome = ({ tests, sites }) => {
  const [client, setClient] = useState();
  const [pdf, setPdf] = useState();
  const [showPdf, setShowPdf] = useState(false);
  const [isClient, setIsClient] = useState(false);
  const appContext = useContext(AppContext);

  const positiveTests = (tests) => {
    const positiveTests = tests.filter((test) => {
      return test.result?.toLowerCase() === "positive" && test.status.toLowerCase() === "processed";
    });
    return positiveTests;
  };

  const negativeTests = (tests) => {
    const negativeTests = tests.filter((test) => {
      return test.result?.toLowerCase() === "negative";
    });
    return negativeTests.length;
  };

  const displayPdf = (site) => {
    // const thisTestsLab = labs.find((lab) => lab.id === test.labID);
    // setTestsLab(thisTestsLab);
    setPdf(site);
    setShowPdf(true);
  };

  const lastPositiveTest = (tests) => {
    if (tests.length > 0) {
      const max = positiveTests(tests).reduce((prev, current) => {
        return new Date(prev.batch) > new Date(current.batch) ? prev : current;
      });
      return max;
    }
  };
  useEffect(() => {
    console.log("Sites", sites);
    if (appContext.user.isClient()) setIsClient(true);
    const getClient = async (site) => {
      const client = await API.getClientById(site.clientID);
      setClient(client);
    };
    if (sites.length > 0) getClient(sites[0]);
  }, [sites]);

  const showStartDate = (startDate) => {
    if (startDate) {
      return new Date(startDate).toDateString();
    }
    return "N/A";
  };

  const getShowRunningDays = (startDate) => {
    if (startDate) {
      return Math.ceil((new Date() - new Date(startDate).getTime()) / (1000 * 3600 * 24));
    }
    return "N/A";
  };

  const lastPositiveTestDate = (testDate) => {
    if (!testDate) return "N/A";
    const date = new Date(testDate);
    const currentDate = new Date();
    const dayLastPositive = Math.floor((currentDate.getTime() - date.getTime()) / (1000 * 3600 * 24));
    return `${date.toDateString()} (${dayLastPositive} days ago)`;
  };

  const totalTestOfShow = (tests) => {
    const ids = tests.filter(
      (test) => test.result?.toLowerCase() === "positive" && test.status.toLowerCase() === "pending"
    ).map((t)=> t.id);
    return tests.filter((t)=> !ids.includes(t.id)).length;
  };

  const summary = (site, index) => {
    return (
      <Card key={index}>
        <Card.Header>
          <Card.Title
            as="h3"
            style={{
              marginBottom: 10,
              fontWeight: "bold",
              color: "#565656",
              textAlign: "center",
              // textDecoration: "underline",
            }}
          >
            {site.name} Summary
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <div style={{ marginTop: 20, marginBottom: 50, padding: 20 }}>
            <div className="dashboard-page">
              <div className="dashboard-tab">
                <h4>Show Start Date</h4>
                <div className="box">
                  <p>{showStartDate(site.startDate)}</p>
                </div>
              </div>
              <div className="dashboard-tab">
                <h4>Total Tests</h4>
                <div className="box">
                  <p>
                    {site.tests.length !== 0 ? (
                      <Link
                        style={{ color: "#A82632" }}
                        to={{
                          pathname: "/admin/totaltests",
                        }}
                      >
                        {totalTestOfShow(site.tests)}
                      </Link>
                    ) : (
                      site.tests.length
                    )}
                  </p>
                </div>
              </div>
              <div className="dashboard-tab">
                <h4>Last Positive Test</h4>
                <div className="box">
                  <p>
                    {positiveTests(site.tests).length === 0 ? (
                      "No Positive Tests"
                    ) : (
                      <Link
                        style={{ color: "#A82632" }}
                        to={{
                          pathname: "/admin/positivetests",
                          state: { name: lastPositiveTest(positiveTests(site.tests)).barcode, term: "barcode" },
                        }}
                      >
                        {lastPositiveTestDate(lastPositiveTest(positiveTests(site.tests)).batch)}
                      </Link>
                    )}
                  </p>
                </div>
              </div>
              <div className="dashboard-tab">
                <h4>Show Running Days</h4>
                <div className="box">
                  <p>{getShowRunningDays(site.startDate)}</p>
                </div>
              </div>
              <div className="dashboard-tab">
                <h4>Positive Tests</h4>
                <div className="box">
                  <p>
                    {positiveTests(site.tests).length !== 0 ? (
                      <Link
                        style={{ color: "#A82632" }}
                        to={{
                          pathname: "/admin/positivetests",
                        }}
                      >
                        {positiveTests(site.tests).length}
                      </Link>
                    ) : (
                      positiveTests(site.tests).length
                    )}
                  </p>
                </div>
              </div>
              <div className="dashboard-tab">
                <h4>Negative Tests</h4>
                <div className="box">
                  <p>
                    {negativeTests(site.tests) !== 0 ? (
                      <Link
                        style={{ color: "#A82632" }}
                        to={{
                          pathname: "/admin/totaltests",
                          state: { negativeTests: true },
                        }}
                      >
                        {negativeTests(site.tests)}
                      </Link>
                    ) : (
                      negativeTests(site.tests)
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  };
  return showPdf ? (
    <Container fluid>
      <Row>
        <Col md="12">
          <Card
            style={{ paddingLeft: 30, marginLeft: "auto", marginRight: "auto" }}
            className="strpied-tabled-with-hover"
          >
            <Card.Header>
              <Row style={{ alignItems: "center" }}>
                <Card.Title as="h4">Showing Summary for {pdf.name}</Card.Title>
                <Button style={{ marginLeft: 30 }} onClick={() => setShowPdf(false)}>
                  Close
                </Button>
              </Row>
            </Card.Header>
            <Card.Body className="table-full-width table-responsive px-0">
              <PDFViewer style={{ flex: 1, width: 500, height: 750 }}>
                <SitePDF site={pdf} client={client} />
              </PDFViewer>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  ) : (
    <>
      <Container fluid style={{ flex: 1 }}>
        {isClient ? (
          <ClientDashboard
            sites={sites}
            showStartDate={showStartDate}
            positiveTests={positiveTests}
            lastPositiveTest={lastPositiveTest}
            getShowRunningDays={getShowRunningDays}
            negativeTests={negativeTests}
            lastPositiveTestDate={lastPositiveTestDate}
          />
        ) : (
          <Row>
            <Col md="12">{sites && sites.map((site, index) => summary(site, index))}</Col>
          </Row>
        )}
      </Container>
    </>
  );
};

export default HealthAndSafetyHome;
