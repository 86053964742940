/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import Test from "views/Test";
import ClientsView from "views/GraphQL/ClientsView";
import LabsView from "views/GraphQL/LabsView";
import SitesView from "views/GraphQL/SitesView";
import Hipaa from "views/Hipaa";
import PendingReleaseTestListView from "views/GraphQL/PendingReleaseTestListView";
import ExternalTest from "views/ExternalTest";
import EmailStatusTestList from "views/EmailStatusTestList";
import Typography from "views/Typography.js";
import Icons from "views/Icons.js";
import Maps from "views/Maps.js";
import Notifications from "views/Notifications.js";
import Upgrade from "views/Upgrade.js";
import UsersView from "views/GraphQL/UsersView";
import User from "views/Users";
import Crews from "views/Crews";
import ClientTests from "views/ClientTests";
import UnprocessedTestsApi from "views/GraphQL/UnprocessedTestsApi";
import TestTypesSetting from "views/TestTypeSetting/TestTypesSetting";
import AdminTotalTestApi from "views/GraphQL/AdminTotalTestApi";
import TestListAdminApi from "views/GraphQL/TestListAdminApi";
import ExpiredTestApi from "views/GraphQL/ExpiredTestsApi";
import DeleteTestApi from "views/GraphQL/DeleteTestApi";
import ExternalTestCrew from "views/ExternelTestCrew";
import CrewProfile from "components/CrewProfile";
import PreRegistrationView from "views/PreRegistrationView";
import GenericPreRegistration from "views/GenericPreRegistration";
import EmployeeListing from "views/EmployeeListing";
import InvalidTestListView from "views/GraphQL/InvalidTestListView";
import MainCrewLogs from "views/MainCrewLogs";
import ProfilesView from "views/GraphQL/ProfilesView";
import ProfileEmployeeTest from "views/ProfileEmployeeTest";
import AdminDashboard from "views/DashboardCharts/AdminDashboard";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-chart-pie-35",
    component: AdminDashboard,
    layout: "/admin",
  },
  {
    path: "/pendingrelease",
    name: "Pending Release",
    icon: "nc-icon nc-notes",
    component: PendingReleaseTestListView,
    layout: "/admin",
  },
  // {
  //   path: "/inconclusivetests",
  //   name: "Inconclusive",
  //   icon: "nc-icon nc-notes",
  //   component: InvalidTestListView,
  //   layout: "/admin",
  // },
  {
    path: "/unprocessedtests",
    name: "Unprocessed Tests",
    icon: "nc-icon nc-notes",
    component: UnprocessedTestsApi,
    layout: "/admin",
  },
  {
    path: "/senttest",
    name: "Waiting Results",
    icon: "nc-icon nc-notes",
    component: UnprocessedTestsApi,
    layout: "/admin",
  },
  {
    path: "/alltests",
    name: "Completed Tests",
    icon: "nc-icon nc-check-2",
    component: TestListAdminApi,
    layout: "/admin",
  },
  {
    path: "/totaltests",
    name: "All Tests",
    icon: "nc-icon nc-check-2",
    component: AdminTotalTestApi,
    layout: "/admin",
  },
  // {
  //   path: "/expiredtests",
  //   name: "Expired Tests",
  //   icon: "nc-icon nc-check-2",
  //   component: ExpiredTestApi,
  //   layout: "/admin",
  // },
  {
    path: "/deletedtests",
    name: "Deleted Tests",
    icon: "nc-icon nc-check-2",
    component: DeleteTestApi,
    layout: "/admin",
  },
  /*  {
    path: "/emailstatus",
    name: "Email Status",
    icon: "nc-icon nc-check-2",
    component: EmailStatusTestList,
    layout: "/admin",
  }, */
  {
    path: "/externaltests",
    name: "External / Vacc Admit",
    icon: "nc-icon nc-circle-09",
    component: ExternalTest,
    layout: "/admin",
  },
  {
    path: "/preregistration",
    name: "Pre Registration",
    icon: "nc-icon nc-atom",
    component: PreRegistrationView,
    layout: "/admin",
  },
  {
    path: "/genericpreregistration",
    name: "Generic Pre-Reg",
    icon: "nc-icon nc-atom",
    component: GenericPreRegistration,
    layout: "/admin",
  },
  {
    path: "/test/:testId",
    name: "Test",
    icon: "nc-icon nc-circle-09",
    component: Test,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/employee/:employeeId",
    name: "Profile Employee Test",
    icon: "nc-icon nc-circle-09",
    component: ProfileEmployeeTest,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/externaltest/:testId",
    name: "Test",
    icon: "nc-icon nc-circle-09",
    component: ExternalTestCrew,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/crewprofile/:empId",
    name: "CrewProfile",
    icon: "nc-icon nc-circle-09",
    component: CrewProfile,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/users",
    name: "System User",
    icon: "nc-icon nc-badge",
    component: UsersView,
    layout: "/admin",
  },
  {
    path: "/profiles",
    name: "Crew Profiles",
    icon: "nc-icon nc-badge",
    component: ProfilesView,
    layout: "/admin",
  },

  // {
  //   path: "/crews",
  //   name: "Crew",
  //   icon: "nc-icon nc-badge",
  //   component: Crews,
  //   layout: "/admin",
  // },
  //{
  //   path: "/crewstest",
  //   name: "Main Crew Info",
  //   icon: "nc-icon nc-badge",
  //   component: MainCrewLogs,
  //   layout: "/admin",
  // },
  // {
  //   path: "/employees",
  //   name: "Employee",
  //   icon: "nc-icon nc-badge",
  //   component: EmployeeListing,
  //   layout: "/admin",
  // },
  {
    path: "/clients",
    name: "Clients",
    icon: "nc-icon nc-circle-09",
    component: ClientsView,
    layout: "/admin",
  },
  {
    path: "/clients/:clientId/tests",
    name: "ClientTests",
    icon: "nc-icon nc-circle-09",
    component: ClientTests,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/shows",
    name: "Shows",
    icon: "nc-icon nc-tv-2",
    component: SitesView,
    layout: "/admin",
  },
  {
    path: "/labs",
    name: "Labs",
    icon: "nc-icon nc-atom",
    component: LabsView,
    layout: "/admin",
  },
  {
    path: "/testtypes",
    name: "Settings",
    icon: "nc-icon nc-notes",
    component: TestTypesSetting,
    layout: "/admin",
  },
  // {
  //   path: "/hipaa",
  //   name: "HIPAA",
  //   icon: "nc-icon nc-paper-2",
  //   component: Hipaa,
  //   layout: "/admin",
  // }
];

export default dashboardRoutes;
