import React, { useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,

} from "chart.js";
import { Bar , Line , Pie , Doughnut} from "react-chartjs-2";


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const DashboardPieChart = (props) => {

  const [colors, setColors] = useState(['rgb(120, 28, 129)',  'rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(255, 206, 86, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(153, 102, 255, 0.2)', 'rgba(255, 159, 64, 0.2)', 'rgb(64, 67, 153)', 'rgb(72, 139, 194)', 'rgb(107, 178, 140)', 'rgb(159, 190, 87)', 'rgb(210, 179, 63)', 'rgb(231, 126, 49)', 'rgb(217, 33, 23)', 'rgb(210, 33, 12)', 'rgb(117, 13, 12)', 'rgb(227, 18, 23)', 'rgb(217, 33, 62)', 'rgb(713, 23,534)', 'rgb(545, 234, 341)', 'rgb(570, 8, 42)', 'rgb(171, 65, 34)', 'rgb(874, 28, 21)', 'rgb(172, 13, 19)', 'rgb(324, 45, 43)', 'rgb(435, 23, 84)', 'rgb(143, 67, 98)', 'rgb(317, 23, 32)', 'rgb(117, 73, 82)']);
  const [legendcolor, setLegend] = useState([]);
    const {chartData} = props;

    console.log("chartData::::",chartData)

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Tests Stats",
      },
    },
  };

  const labels = [
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
    "Sun",
  ];

  const data = {
    labels,
    datasets: [
        {
          label: '# of Tests',
          data: [12, 19, 3], //place chartData here
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
          ],
          borderWidth: 1,
        },
      ],

   
  };

  return (
    <>
      <Pie options={options} data={data} />
    </>
  );
};



export default DashboardPieChart;
