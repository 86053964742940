/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import Test from "views/Test";
import TotalTestsApi from "views/GraphQL/TotalTestsApi";
import PositiveTestsApi from "views/GraphQL/PositiveTestsApi";
import HSCrew from "views/GraphQL/HSCrew";
import CrewQRCode from "views/CrewQRCode";
import Typography from "views/Typography.js";
import Icons from "views/Icons.js";
import Maps from "views/Maps.js";
import Notifications from "views/Notifications.js";
import Upgrade from "views/Upgrade.js";
import QuarantineNew from "views/QuarantineNew.js";
import Settings from "views/Settings.js";

const dashboardRoutes = [
  {
    path: "/totaltests",
    name: "Total Tests",
    icon: "nc-icon nc-notes",
    component: TotalTestsApi,
    layout: "/admin",
  },
  {
    path: "/positivetests",
    name: "Positive Tests",
    icon: "nc-icon nc-notes",
    component: PositiveTestsApi,
    layout: "/admin",
  },
  {
    path: "/quarantine",
    name: "Quarantine",
    icon: "nc-icon nc-notes",
    component: QuarantineNew,
    layout: "/admin",
  },
  /* {
    path: "/settings",
    name: "Settings",
    icon: "nc-icon nc-notes",
    component: Settings,
    layout: "/admin",
  }, */
  {
    path: "/crew",
    name: "Crew",
    icon: "nc-icon nc-circle-09",
    component: HSCrew,
    layout: "/admin",
  },
  {
    path: "/qrview",
    name: "QRCode",
    icon: "nc-icon nc-circle-09",
    component: CrewQRCode,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/test/:testId",
    name: "Test",
    icon: "nc-icon nc-circle-09",
    component: Test,
    layout: "/admin",
    redirect: true,
  },

  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "nc-icon nc-atom",
  //   component: Icons,
  //   layout: "/admin",
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "nc-icon nc-pin-3",
  //   component: Maps,
  //   layout: "/admin",
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   icon: "nc-icon nc-bell-55",
  //   component: Notifications,
  //   layout: "/admin",
  // },
];

export default dashboardRoutes;
