import React, { useState, useContext, useMemo, useRef, useCallback } from "react";

// react-bootstrap components
import { Badge, Button, Card, Table, Container, Row, Col, Form } from "react-bootstrap";
import { DataStore } from "@aws-amplify/datastore";
import { Storage } from "aws-amplify";
import { Test } from "models";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import API from "api";
import GQAPI from "views/GraphQL/gqApi";

import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import { Importer, ImporterField } from "react-csv-importer";
import "react-csv-importer/dist/index.css";
import api from "api";
import SweetAlert from "react-bootstrap-sweetalert";
import { AppContext } from "context/app-context";
import Filter from "components/Filter";
import TimeFilter from "components/TimeFilter";
import FilterSelect from "components/FilterSelect";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import { Bounce } from "react-activity";
import "react-activity/dist/Bounce.css";
import MFPagination from "components/Pagination/MFPagination";
import { PAGE_LIMIT, TEST_STATUS, RESULT_VALUE } from "constant";
import HeaderItem from "components/Table/HeaderItem";
import moment from "moment";
import {
  formatTest,
  changeSort,
  getDemoGraphics,
  setPhoneNo,
  formatPhoneNumber,
  calculateTdWidth,
  sortingFilterInLC,
  formatUTCDate,
  sortTestList,
  connectedIDInLocalStorage,
} from "utils";
import ExportToExcel from "components/ExportToExcel";
import TestPDFLayout from "components/PDF/TestPDFLayout";
import { CSVReader, jsonToCSV } from "react-papaparse";
import { debounce } from "debounce";
import { useInitialRender } from "components/useInitialRender";
import Loader from "../../components/Loader/Loader";

function TableListApiNew(props) {
  console.log('TableListApi_New')
  const componentRef = useRef();
  const mainWidth = useRef();
  const [width, setWidth] = useState(0);
  const [openConfirmation, setOpenConfirmation] = useState(false);


  useEffect(() => {
    setWidth(componentRef.current.offsetWidth);
  }, [componentRef]);

  const handleResize = () => {
    if (componentRef.current) setWidth(componentRef.current.offsetWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => handleResize(), [mainWidth]);

  const tdWidth = calculateTdWidth(width - 130, 10);
  const importResult = [];
  const [totalRecord, setTotalRecord] = useState(0);
  const [tests, setTests] = useState([]);
  const [testDownloadModel, setTestDownloadModel] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [filteredTests, setFilteredTests] = useState([]);
  const [adminsPhones, setAdminsPhones] = useState([]);
  const [timeFilter, setTimeFilter] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const [importLength, setImportLength] = useState();
  const [loading, setLoading] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [allSelected, setAllSelected] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const { filterDates } = TimeFilter;
  const appContext = useContext(AppContext);
  const location = useLocation();
  const initialRender = useInitialRender();
  

  const isProcessingCall = location.pathname.includes("processing") ? true : false;
  const labID = window.location?.pathname?.split('/').pop();

  const searchTerms = [
    "First Name",
    "Last Name",
    "Date of Birth",
    "Email",
    "Phone",
    "Show",
    "Tester",
    "Accession No",
    "isVaccinated",
    "White Glove",
    "Pattern",
    "Capacity",
  ];
  const selectedItem = [
    "createdAt",
    "firstName",
    "lastName",
    "email",
    "dob",
    "phoneNumber",
    "site_name",
    "status",
    "test_type",
    "accession_no",
    "tester_name",
    "isVaccinated",
    "employeeType",
    "whiteGlove",
    "patternTestAnswer",
  ];
  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(sortBy === by ? !sortDescending : true);
  };
  const fetchFromApi = useCallback(
    debounce((filter, timeFilter) => {
      loadTestFromPG(filter, timeFilter);
    }, 800),
    []
  );

  const testsToMap = useMemo(() => {
    const list = filteredTests ? [...filteredTests] : [];
    const ttlPages = list.length > PAGE_LIMIT ? Math.ceil(list.length / PAGE_LIMIT) : 1;
    setTotalPages(ttlPages);
    if (list.length < PAGE_LIMIT || currentPage > ttlPages) {
      setCurrentPage(1);
    }
    if (ttlPages > 1) {
      const indexOfLastUser = currentPage * PAGE_LIMIT;
      const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
      return list.slice(indexOfFirstUser, indexOfLastUser);
    }
    return list;
  }, [filteredTests, currentPage]);

  const formatTestAsPerLab = (items) => {
console.log("formatTestAsPerLab")
    if (appContext.labs && items.length > 0) {
      const lab = appContext.labs;
      const [formattedTests] = API.formatTests(lab, items);
      setTestDownloadModel(formattedTests);
    }
  };

  const handleCheckboxChange = (e, id) => {
    const filteredList = checkboxes.filter((c) => c !== id);
    if (e.target.checked) {
      filteredList.push(id);
    }
    setCheckboxes(filteredList);
    const filterTest = tests.filter((test) => filteredList?.includes(test.id));
    formatTestAsPerLab(filterTest.length === 0 ? tests : filterTest);
  };

  const toggleCheckboxes = (val) => {
    setCheckboxes(val ? filteredTests.map((t) => t.id) : []);
    formatTestAsPerLab(filteredTests.length === 0 ? tests : filteredTests);
  };

  const updateTestResult = async (result) => {
    const updateSelectedTestResult = filteredTests.filter((test) => checkboxes.includes(test.id));
    const ttlTest = updateSelectedTestResult.length;
    if (!ttlTest) return;
    setLoading(true);
    try {
      const params = updateSelectedTestResult.map((t) => {
        return {
          id: t.id,
          result: RESULT_VALUE[result.toLowerCase()],
          status: result === "positive" ? "Pending" : "Processed",
          timerStatus: result === "positive" ? "Pending" : "Processed",
          resultDateTime: moment().utc().toISOString(),
          resultDate: moment().utc().format("YYYY-MM-DD"),
          StampBy: appContext.user.sub,
          StampByName: appContext.user.name,
          sequenceNo: t.sequenceNo,
        };
      });
      await GQAPI.updateTestResult(params);
      appContext.showSuccessMessage(`${ttlTest} test${ttlTest === 1 ? "" : "s"} has been resulted ${result}`);
      const newList = tests.filter((t) => !checkboxes.includes(t.id));
      setTests(newList);
      refreshTestList(newList);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
    setCheckboxes([]);
  };
  const handleConfirm = async (isConfirm) => {
    setOpenConfirmation(false);
    if (!isConfirm) {
      return;
    }
    updateTestResult("positive");
  };
  const handleOnError = (err) => {
    appContext.showErrorMessage(err.message);
  };

  const handleOnRemoveFile = (data) => {
    console.log("Remove Data", data);
  };

  const handleCSVOnDrop = async (csvData) => {
    try {
      setLoading(true);
      const fileName = `portal/${moment().utc().format("YYYY_MM_DD")}_${appContext?.userLabName?.replace(
        /(:|\s+)/g,
        "_"
      )}_${appContext?.user?.name?.replace(/(:|\s+)/g, "_")}.csv`;
      const val = csvData.map((csv) => csv.data);
      const csv = jsonToCSV(val);
      await Storage.put(fileName, csv, { bucket: "results-from-labs" });

      const updatedId = [];
      const params = [];
      let ttlRecord = csvData.length;
      console.log("csv data", csvData);
      setModalShow(false);
      for (let index = 0; index < ttlRecord; index++) {
        const { data } = csvData[index];
        const row = { ...data };
        let id = row.id || row.accession_number || row.barcode;

        if (id && updatedId.indexOf(id) === -1) {
          const test = await api.findTestByBarcode(id);
          console.log("Test", test);
          if (test && row.result) {
            let result = row.result.toLowerCase();
            if (
              result === "positive" ||
              result === "negative" ||
              result === "detected" ||
              result === "not detected" ||
              result === "notdetected" ||
              result === "inconclusive"
            ) {
              if (result === "detected") result = "positive";
              else if (result === "not detected" || result === "notdetected") result = "negative";
              updatedId.push(id);
              try {
                params.push({
                  id: test.id,
                  result: RESULT_VALUE[result],
                  status: result === "positive" || result === "fail" ? "Pending" : "Processed",
                  timerStatus: result === "positive" || result === "fail" ? "Pending" : "Processed",
                  resultDateTime: moment().utc().toISOString(),
                  resultDate: moment().utc().format("YYYY-MM-DD"),
                  StampBy: appContext.user.sub,
                  StampByName: appContext.user.name,
                  sequenceNo: test.sequenceNo,
                });
              } catch (err) {
                console.log(err);
              }
            }
          }
        }
      }
      console.log("params", params);
      if (params.length > 0) {
        await GQAPI.updateTestResult(params);
      }

      const ids = params.map((t) => t.id);
      const newList = tests.filter((t) => ids.indexOf(t.id) === -1);
      setTests(newList);
      refreshTestList(newList);

      if (ttlRecord > 0) {
        setImportLength(updatedId.length);
        setShowAlert(true);
      }
    } catch (err) {
      console.log("Err", err);
      setLoading(false);
    }
    setLoading(false);
  };

  const filterTests = (searchTerm, searchInput) => {
    let newTerm;
    switch (searchTerm) {
      case "date_created":
        newTerm = "createdAt";
        break;
      case "crew_id":
        newTerm = "employeeID";
        break;
      case "show":
        newTerm = "site_name";
        break;
      default:
        newTerm = searchTerm;
    }
    return setFilteredTests(tests.filter((test) => test[newTerm].includes(searchInput)));
  };

  useEffect(() => {
    const savedFilters = appContext.testFilter;
    if (
      savedFilters &&
      ((isProcessingCall && savedFilters.location === "processing") ||
        (!isProcessingCall && savedFilters.location === "tests"))
    ) {
      if (Object.keys(savedFilters.filter).length > 0) {
        setFilter(savedFilters.filter);
        setShowFilter(true);
      }
    }
    const sortingFilters = sortingFilterInLC.get();
    if (isProcessingCall && sortingFilters.processing.sortBy) {
      setSortDescending(sortingFilters.processing.sortDescending);
      setSortBy(sortingFilters.processing.sortBy);
    } else if (sortingFilters.tests.sortBy) {
      setSortDescending(sortingFilters.tests.sortDescending);
      setSortBy(sortingFilters.tests.sortBy);
    }
  }, []);

  useEffect(() => {
    if (initialRender) return;
    if (appContext.eventObject && appContext.eventObject !== 0) {
      const eventType = appContext.eventObject.opType;
      const model = formatTest(appContext.eventObject.element);
      let isRelevantTest = false;

      if (!isProcessingCall && model.status === TEST_STATUS.New) {
        isRelevantTest = true;
      } else if (isProcessingCall && (model.status === TEST_STATUS.processing || model.status === TEST_STATUS.Sent)) {
        isRelevantTest = true;
      }
      const newList = [...filteredTests];
      if (eventType === "INSERT" && isRelevantTest) {
        newList.unshift(model);
      } else if (eventType === "UPDATE") {
        const index = newList.findIndex((t) => t.id === model.id);
        if (index !== -1) {
          if (isRelevantTest) {
            newList.splice(index, 1, model);
          } else {
            newList.splice(index, 1);
          }
        }
      }
      const testAfterFilter = filterDates(nestedFilter([...newList], filter), timeFilter);
      if (filteredTests.length < testAfterFilter.length && eventType === "INSERT" && isRelevantTest) {
        setTotalRecord(totalRecord + 1);
      } else if (filteredTests.length > testAfterFilter.length) {
        setTotalRecord(totalRecord - 1);
      }
      refreshTestList(testAfterFilter);
    }
  }, [appContext.eventObject]);

  useEffect(() => {
    const prevSortingState = sortingFilterInLC.get();
    prevSortingState[isProcessingCall ? "processing" : "tests"] = { filter, timeFilter, sortBy, sortDescending };
    sortingFilterInLC.save(prevSortingState);
    if (filteredTests.length === 0) return;
    changeSort(
      sortBy,
      sortDescending,
      filteredTests,
      setFilteredTests,
      appContext.getSiteName,
      appContext.getLabName,
      appContext.getClientName
    );
  }, [sortBy, sortDescending]);

  useEffect(() => {
    fetchFromApi({ ...filter }, { ...timeFilter }, 1);
  }, [filter, timeFilter]);

  const loadTestFromPG = async (params, dateFilter) => {
    setLoading(true);
    const filterParam = params;

    const connectedID = labID || 'L';
    Object.assign(filterParam, { page: pageNo, limit: 2000, labID: labID , result: "empty", test_type: ["PCR"] });
    
    if (dateFilter) {
      const { start, end } = dateFilter;
      if (start)
        Object.assign(filterParam, { startDate: moment(start.format("YYYY-MM-DD")).startOf("day").toISOString() });
      if (end) Object.assign(filterParam, { endDate: moment(end.format("YYYY-MM-DD")).endOf("day").toISOString() });
    }
   
    const pgList = await API.getTestListFromPG(filterParam);
    setLoading(false);
    const ttlTests = tests.concat(pgList.rows.map((t) => formatTest(t)));

    const filterData = isProcessingCall
      ? ttlTests.filter((t) => t.status === TEST_STATUS.Processing || t.status === TEST_STATUS.Sent)
      : ttlTests.filter((t) => t.status === TEST_STATUS.New);

    if (pageNo === 1) {
      setTotalRecord(filterData);
    }
    setTests(filterData);
    refreshTestList(filterData);
  };

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filter).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length) {
          return true;
        }

        if (key === "phoneNumber") {
          return obj[key] && obj[key].replace(/\D/g, "").includes(filters[key].replace(/\D/g, ""));
        }

        if (key === "Show") {
          return obj.site_name && obj.site_name.toLowerCase().includes(filters[key]?.toLowerCase());
        }
        if (key === "sequenceNo") {
          return obj[key] && obj["sequenceNo"].toString().includes(filters[key]);
        }
        if (key === "referenceID") {
          return obj[key] && obj["referenceID"].toString().includes(filters[key]);
        }
        if (key === "dob") {
          return obj[key] && obj[key] === filters[key];
        }

        if (key === "test_type" && filters[key] && filters[key].length > 0) {
          return obj[key] && filters[key].indexOf(obj[key]) !== -1;
        }
        return obj[key] && obj[key].toLowerCase().includes(filters[key].toLowerCase());
      });
    });
   
    return models;
  };

  const refreshTestList = (list) => {
    if (sortBy) {
      setFilteredTests(
        sortTestList(
          sortBy,
          sortDescending,
          list,
          appContext.getSiteName,
          appContext.getLabName,
          appContext.getClientName,
          appContext.parseResult
        )
      );
    } else {
      setFilteredTests(list);
    }
  };

  const handleChangePage = (page, direction) => {
    setCurrentPage(page);
    const currentRecord = filteredTests.length;
    if (direction === "next" && page === totalPages && totalRecord > 0 && currentRecord < totalRecord) {
      setPageNo(pageNo + 1);
    }
  };

  const handleSaveFilter = ( e, testItem) => {
    // console.log('eeeeee::::::' , e);
    // console.log("test:::::" , testItem);
    
    // let obj = {
    //   type: "siteTotalTests",
    //   test: testItem
    // };
    
    // localStorage.setItem("state" , JSON.stringify(obj));
    appContext.setTestFilter({ location: isProcessingCall ? "processing" : "tests", filter, timeFilter });
  };
  function TableRow({ item }) {
    return (
      <tr>
        <td className="ellipsis" style={{ textAlign: "center", width: "50px", maxWidth: "50px", minWidth: "50px" }}>
          <Form.Check>
            <Form.Check.Input
              type="checkbox"
              value={item.id}
              checked={checkboxes.indexOf(item.id) !== -1}
              onChange={(e) => handleCheckboxChange(e, item.id)}
            ></Form.Check.Input>
          </Form.Check>
        </td>
        <td className="ellipsis">{formatUTCDate(item.createdAt)}</td>
        <td className="ellipsis" title={item.firstName}>
          {item.firstName}
        </td>
        <td className="ellipsis" title={item.lastName}>
          {item.lastName}
        </td>
        <td className="ellipsis" title={item.dob}>
          {item.dob}
        </td>
        <td
          className="table-column ellipsis"
          style={{
            textDecoration: "underline",
            color: "#A82632",
          }}
          onMouseOver={(e) => {
            e.target.style.cursor = "pointer";
            e.target.style.textDecoration = "underline";
          }}
          onClick={() => test?.email && window.open(`mailto:${item.email}`)}
          title={item.email}
        >
          {item.email}
        </td>
        <td
          className="table-column ellipsis"
          style={{ textDecoration: "underline", color: "#A82632" }}
          onMouseOver={(e) => {
            e.target.style.cursor = "pointer";
            e.target.style.textDecoration = "none";
          }}
          onMouseLeave={(e) => {
            e.target.style.textDecoration = "underline";
          }}
          onClick={() => {
            item.phoneNumber && window.open(`tel:${item.phoneNumber}`);
          }}
          title={item.phoneNumber && formatPhoneNumber(item.phoneNumber)}
        >
          {item.phoneNumber && formatPhoneNumber(item.phoneNumber)}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }} title={item.site_name}>
          {item.site_name}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }} title={item.status}>
          {item.status}
        </td>

        <td className="ellipsis" style={{ textAlign: "center" }} title={item.test_type}>
          {item.test_type}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }} title={item.barcode}>
          {item.barcode}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }} title={item.tester_name}>
          {item.tester_name}
        </td>
        <td>
          <Link
           style={{ color: "#42cef5" }}
         
            to={{ pathname: "/lab/test/" + labID + "/" + item.id, state: { labPortal: true , test: item} }}
            onClick={(e) => handleSaveFilter(e , item)}
            onContextMenu={(e) => handleSaveFilter(e , item)}
          >
            View
          </Link>
        </td>

        {/* <td>
          <DropdownButton id="dropdown-basic-button" title="Update Result">
            <Dropdown.Item href="#/action-1">Positive</Dropdown.Item>
            <Dropdown.Item href="#/action-2">Negative</Dropdown.Item>
          </DropdownButton>
        </td> */}
      </tr>
    );
  }

  const [showAlert, setShowAlert] = useState(false);
  const hideAlert = () => {
    setShowAlert(false);
    if (importResult > 0) setTimeout(() => appContext.resetNewTests(), 1500);
  };

  const handleCSVClick = async () => {

    console.log("handleCSVClick:")
    // if (testDownloadModel.length === 0) return;
    // if (isProcessingCall) return;
    try {
      console.log("In Try!!!!!!")
      setLoading(true);
      const testList = filteredTests.filter(
        (test) => (test.status === "Sent" || test.status === "New") && checkboxes.includes(test.id)
      );
      console.log("testList::" , testList)
      const params = testList?.map((t) => {
        return {
          id: t.id,
          status: "Processing",
          sequenceNo: t.sequenceNo,
        };
      });

      await GQAPI.updateStatusByLab(params);
      setCheckboxes([]);
      const newList = filteredTests.filter((test) => !checkboxes.includes(test.id));
      setTests(newList);
      // refreshTestList(newList);
    } catch (error) {
      appContext.showErrorMessage("Fail to download the tests");
      console.log("[LAB_UPDATE_STATUS] Fail to update the status", error);
    }
    setLoading(false);
  };


  return (
    <>
      {!loading ? (
        <Container fluid>
          {importLength > 0 ? (
            <SweetAlert
              show={showAlert}
              success
              title="Success"
              onConfirm={() => {
                hideAlert();
              }}
            >
              Success! Uploaded {importLength} Test Result
              {importLength > 1 && "s"}
            </SweetAlert>
          ) : (
            <SweetAlert
              show={showAlert}
              error
              title="Error"
              onConfirm={() => {
                hideAlert();
              }}
            >
              Fail to upload test result
            </SweetAlert>
          )}
          {openConfirmation && (
            <ConfirmationModal
              show={openConfirmation}
              title="Result Positive"
              message="Are you sure, to result positive ?"
              handleConfirm={handleConfirm}
            />
          )}
          <div className="topHeaderButtons">
            <Col></Col>
            <Col></Col>
          </div>

          <Row>
            <Col md="12">
              {modalShow && (
                <CSVReader
                  onDrop={handleCSVOnDrop}
                  onError={handleOnError}
                  addRemoveButton
                  config={{
                    header: true,
                    skipEmptyLines: true,
                  }}
                  onRemoveFile={handleOnRemoveFile}
                >
                  <span>Drop CSV file here or click to upload.</span>
                </CSVReader>
              )}
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <Card.Title
                    as="h4"
                    style={{
                      marginBottom: 10,
                      fontWeight: "bold",
                      color: "#565656",
                    }}
                  >
                    {isProcessingCall ? "Tests Processing" : "New Tests"} ({filteredTests.length})
                  </Card.Title>

                  {!isProcessingCall && (
                    <p className="card-category" style={{ marginBottom: 15 }}>
                      Download CSV file, Add a "result" column, then upload file to send results
                    </p>
                  )}
                  <div className="buttonHeader">
                    <Button className="headerButton" onClick={() => setShowFilter(!showFilter)}>
                      Filter
                    </Button>

                    <FilterSelect setTimeFilter={setTimeFilter} />
                    <Button
                      className="headerButton"
                      onClick={() => {
                        if (filteredTests.length > 0) {
                          setAllSelected(!allSelected);
                          toggleCheckboxes(!allSelected);
                        }
                      }}
                    >
                      {!allSelected ? "Select All" : "Deselect All"}
                    </Button>
                    {isProcessingCall && appContext.labs && (
                      <Button className="headerButton labDownloadTest" disabled={checkboxes.length === 0}>
                        <CSVLink
                          className="headerButton downloadTest"
                          onClick={handleCSVClick}
                          filename={"newBatch.csv"}
                          data={testDownloadModel}
                        >
                          Results File
                        </CSVLink>
                      </Button>
                    )}
                    {!isProcessingCall ? (
                      <>
                        <Button className="headerButton labDownloadTest" disabled={checkboxes.length === 0}>
                          <CSVLink
                            className="headerButton downloadTest"
                            onClick={handleCSVClick}
                            filename={"newBatch.csv"}
                            data={testDownloadModel}
                          >
                            Download New Tests
                          </CSVLink>
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          className="headerButton"
                          onClick={() => {
                            setModalShow(!modalShow);
                            // props.history.push('/admin/processedtable')
                          }}
                        >
                          Upload Results
                        </Button>
                        <Button
                          className="headerButton"
                          disabled={checkboxes.length === 0}
                          onClick={() => setOpenConfirmation(true)}
                        >
                          Set Positive
                        </Button>
                        <Button
                          className="headerButton"
                          disabled={checkboxes.length === 0}
                          onClick={() => updateTestResult("negative")}
                        >
                          Set Negative
                        </Button>
                        <Button
                          className="headerButton"
                          disabled={checkboxes.length === 0}
                          onClick={() => updateTestResult("inconclusive")}
                        >
                          Set Inconclusive
                        </Button>
                      </>
                    )}

                    {filteredTests.length > 0 && (
                      <>
                        <ExportToExcel
                          items={filteredTests}
                          appContext={appContext}
                          selectedItem={selectedItem}
                          //filter={filter}
                          filter={{...filter, labID: labID , result: "empty", test_type: ["PCR"]}}
                          timeFilter={timeFilter}
                          title={isProcessingCall ? "Tests Processing" : "New Tests"}
                          sortBy={sortBy}
                        />
                        <TestPDFLayout
                          tests={filteredTests}
                          filter={{...filter, labID: labID , result: "empty", test_type: ["PCR"]}}
                          timeFilter={timeFilter}
                          selectedItem={selectedItem}
                          title={isProcessingCall ? "Tests Processing" : "New Tests"}
                          appContext={appContext}
                          sortBy={sortBy}
                        />
                      </>
                    )}
                  </div>

                  {showFilter && (
                    <Filter filterTerms={searchTerms} setFilter={setFilter} filter={filter} isUsersTable={false} />
                  )}

                  <MFPagination
                    timeFilter={timeFilter}
                    currentPage={currentPage}
                    handleChangePage={handleChangePage}
                    totalPages={totalPages}
                    showSelectedRecord
                    totalSelected={checkboxes.length}
                  />
                </Card.Header>

                <Card.Body className="table-full-width px-0 desktop-noScroll">
                  <div className="table-responsive pendingReleaseTable">
                    <Table className="table-hover table-striped">
                      <thead ref={componentRef}>
                        <tr>
                          <th></th>
                          <HeaderItem
                            width={tdWidth}
                            ItemKey="createdAt"
                            title="Date Collected"
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                          />
                          <HeaderItem
                            width={tdWidth}
                            ItemKey="firstName"
                            title="First Name"
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                          />
                          <HeaderItem
                            width={tdWidth}
                            ItemKey="lastName"
                            title="Last Name"
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                          />
                          <HeaderItem
                            width={tdWidth}
                            ItemKey="dob"
                            title="DoB"
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                          />
                          <HeaderItem
                            width={tdWidth}
                            ItemKey="email"
                            title="Email"
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                          />
                          <HeaderItem
                            width={tdWidth}
                            ItemKey="phoneNumber"
                            title="Phone"
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                          />
                          <HeaderItem
                            width={tdWidth}
                            ItemKey="site_name"
                            title="Show"
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                            aligned="centered"
                          />

                          <HeaderItem
                            width={tdWidth}
                            ItemKey="status"
                            title="Status"
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                            aligned="centered"
                          />

                          <HeaderItem
                            width={tdWidth}
                            ItemKey="test_type"
                            title="Test Type"
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                            showFilter={false}
                            aligned="centered"
                          />
                          <HeaderItem
                            width={tdWidth}
                            ItemKey="barcode"
                            title="Accession No"
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                            aligned="centered"
                          />
                          <HeaderItem
                            width={tdWidth}
                            ItemKey="tester_name"
                            title="Tester "
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                            aligned="centered"
                          />
                          {
                            <th
                              className="border-0 text-grey"
                              style={{ fontWeight: "bold", width: "80px", maxWidth: "80px" }}
                            >
                              Actions
                            </th>
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {testsToMap &&
                          testsToMap.map((test) => {
                            return <TableRow key={test.id} item={test} />;
                          })}
                      </tbody>
                    </Table>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default TableListApiNew;
