import React, { useEffect, useRef, useState, useContext } from "react";
import { AppContext } from "context/app-context";
import { isValidFile } from "../../utils";
import Compressor from "compressorjs";

const ImageInput = (props) => {
  const { selectedImage, setSelectedImage, type, title, cssClass, showCropDialog, disabled, setCopyImage, cameraIcon } = props;
  const [image, setImage] = useState(selectedImage);
  const appContext = useContext(AppContext);

  useEffect(() => {
    if (selectedImage instanceof Object) {
      setImage(URL.createObjectURL(selectedImage));
    } else {
      setImage(selectedImage);
    }
  }, [selectedImage]);
  console.log("Selected Image ", image);
  const inputFile = useRef();

  const handleImageClick = () => {
    if (inputFile.current && title !== "profile") {
      inputFile.current.click();
    }
  };

  const onChangeImage = async (event) => {
    console.log("event", event.currentTarget.files);
    if (event.currentTarget.files && event.currentTarget.files.length > 0) {
      const file = event.currentTarget.files[0];
      //   const isValid = await isValidImageHeightWidth(file);
      //   if (!isValid) {
      //     alert("File resolution should be upto 150px*150px");
      //     return;
      //   }
      console.log("File", file);
      const message = isValidFile(file);
      if (message.length > 0) {
        appContext.showErrorMessage(message);
        event.target.value = null;
        return;
      }
      new Compressor(file, {
        quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
        maxWidth: 800,
        convertSize: 1000000,
        success: (compressedResult) => {
          // compressedResult has the compressed file.
          // Use the compressed file to upload the images to your server.
          setCopyImage ? setCopyImage(compressedResult) : setSelectedImage(compressedResult);
          console.log("compressedResult", compressedResult);
          // setImage(URL.createObjectURL(compressedResult));
        },
      });

      if (showCropDialog) showCropDialog(true);
      event.target.value = null;
    }
  };

  if (type === "profile") {
    return (
      <div className="form-group-wrapper justify-content-center">
        <div className="inputFileWrapper positionRelative" onClick={handleImageClick}>
          {/* <i className="fas fa-times-circle"></i> */}
          {image && <img src={image} />}
          <div className="profileEditImage">
            <i
              className={`fas ${cameraIcon ? 'fa-camera' : 'fa-edit'}`}
              onMouseLeave={(e) => (e.target.style.color = "black")}
              onMouseOver={(e) => {
                e.target.style.color = "blue";
                e.target.style.cursor = "pointer";
              }}
              aria-hidden="true"
            />
          </div>

          <input
            type="file"
            ref={inputFile}
            accept="image/*"
            onChange={(e) => onChangeImage(e)}
            style={{ display: "none" }}
          />
        </div>
      </div>
    );
  }
  console.log("image", image);
  return (
    <div className="form-group-wrapper">
      {title && <label className="modalLineHeaders">{`${title}:`}</label>}
      <div className={`inputFileWrapperFull d-block ${cssClass}`} onClick={handleImageClick}>
        {image && <img src={image} />}
        <input
          type="file"
          //   ref={inputFile}
          accept="image/*"
          disabled={disabled || false}
          onChange={(e) => onChangeImage(e)}
        />
      </div>
    </div>
  );
};

export default ImageInput;
