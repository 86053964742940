import React from "react";
import { Button, Modal } from "react-bootstrap";
import { formatQuaratineDate } from "../../utils";
import moment from "moment";
import { EXPECTED_QUARANTINE_DAYS } from "constant";

const QuarantineDetailsModal = (props) => {
  const { test, handleClose } = props;

  const getQuarantineDays = (test) => {
    const startDate = moment(test.quarantinedStart);
    const currentDate = moment();
    return currentDate.diff(startDate, "days");
  };

  return (
    <>
      <Modal
        show
        animation={true}
        onHide={() => {
          handleClose();
        }}
        style={{ paddingLeft: "0" }}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            Quarantine Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: 0,
          }}
        >
          <div className="form-group-wrapper">
            <label className="modalLineHeaders">Start Date: </label>
            <input
              className="modalInput"
              type="date"
              value={formatQuaratineDate(test?.quarantinedStart)}
              placeholder="Start Date"
              style={{ width: "100%", marginBottom: "10px" }}
              disabled
            />
          </div>
          <div className="form-group-wrapper">
            <label className="modalLineHeaders">Expected End Date: </label>
            <input
              className="modalInput"
              type="Date"
              value={moment(test.quarantinedStart).add(EXPECTED_QUARANTINE_DAYS, "days").format("YYYY-MM-DD")}
              placeholder="End Date"
              style={{ width: "100%", marginBottom: "10px" }}
              disabled
            />
          </div>
          <div className="form-group-wrapper">
            <label className="modalLineHeaders">Days in Quarantine:</label>
            <input
              className="modalInput"
              type="number"
              value={getQuarantineDays(test)}
              style={{ width: "100%", marginBottom: "10px" }}
              disabled
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            className="modalButtons"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default QuarantineDetailsModal;
