import React, { useEffect, useRef, useState } from "react";
import HeaderItem from "./HeaderItem";
import { TEST_HEADER_TYPE } from "../../constant";
import { useLocation } from "react-router";
import { calculateTdWidth } from "../../utils";

const ProfileHeader = (props) => {
  const { type, flipSort, sortBy, sortDescending, cssClass, widthCol } = props;
  const location = useLocation();

  const componentRef = useRef();
  const [width, setWidth] = useState(0);
  useEffect(() => {
    setWidth(componentRef.current.offsetWidth);
  }, [componentRef]);

  const handleResize = () => {
    setWidth(componentRef.current.offsetWidth);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const calculateColumns = () => {
    let col = location.pathname === "/admin/profiles" ? 6 : 6;
    if (type !== TEST_HEADER_TYPE.pending) col += 1;
    if (type !== TEST_HEADER_TYPE.unprocessed) col += 1;
    return col;
  };

  const tdWidth = calculateTdWidth(width - 60, calculateColumns());

  return (
    <thead ref={componentRef}>
      <tr className={cssClass}>
        <th style={{ width: `${widthCol || 50}px`, maxWidth: `${widthCol || 50}px` }}></th>

        <HeaderItem
          width={tdWidth - 50}
          ItemKey="firstName"
          title="First Name"
          flipSort={flipSort}
          sortBy={sortBy}
          sortDescending={sortDescending}
        />
        <HeaderItem
          width={tdWidth - 50}
          ItemKey="lastName"
          title="Last Name"
          flipSort={flipSort}
          sortBy={sortBy}
          sortDescending={sortDescending}
        />
        <HeaderItem
          width={tdWidth - 60}
          ItemKey="email"
          title="Email"
          flipSort={flipSort}
          sortBy={sortBy}
          sortDescending={sortDescending}
          aligned="centered"
        />

        <HeaderItem
          width={tdWidth}
          ItemKey="phoneNumber"
          title="Phone"
          flipSort={flipSort}
          sortBy={sortBy}
          sortDescending={sortDescending}
        />
        <HeaderItem
          width={tdWidth}
          ItemKey="dob"
          title="DoB"
          flipSort={flipSort}
          sortBy={sortBy}
          sortDescending={sortDescending}
          aligned="centered"
        />
        {/* <HeaderItem
          width={tdWidth}
          ItemKey="isVaccinated"
          title="isVaccinated"
          flipSort={flipSort}
          sortBy={sortBy}
          sortDescending={sortDescending}
          aligned="centered"
        /> */}
        <HeaderItem
          width={tdWidth}
          ItemKey="street"
          title="Address"
          flipSort={flipSort}
          sortBy={sortBy}
          sortDescending={sortDescending}
          aligned="centered"
        />
        <HeaderItem
          width={tdWidth}
          ItemKey="id_number"
          title="Id_Number"
          flipSort={flipSort}
          sortBy={sortBy}
          sortDescending={sortDescending}
          aligned="centered"
        />
        <HeaderItem
          width={tdWidth}
          ItemKey="site_name"
          title="Show"
          flipSort={flipSort}
          sortBy={sortBy}
          sortDescending={sortDescending}
          aligned="centered"
        />
        <HeaderItem
          width={tdWidth}
          ItemKey="lastUsed"
          title="Last Test Date"
          flipSort={flipSort}
          sortBy={sortBy}
          sortDescending={sortDescending}
          aligned="centered"
        />
        <HeaderItem
          width={tdWidth}
          ItemKey="totalTest"
          title="Total Tests"
          flipSort={flipSort}
          sortBy={sortBy}
          sortDescending={sortDescending}
          aligned="centered"
        />
        <th
          className="ellipsis border-0 centered text-grey"
          style={{ fontWeight: "bold", width: tdWidth, maxWidth: tdWidth }}
        >
          Actions
        </th>
      </tr>
    </thead>
  );
};

export default ProfileHeader;
