import React, { useState } from "react";
import {
  DropdownButton,
  Dropdown,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import Select from "react-select";

const SearchBar = ({
  searchTerms,
  filter,
  setSearchInput,
  setSearchTerm,
  searchTerm,
  searchInput,
  placeholder,
  triggerFilter,
  setTriggerFilter,
}) => {
  const normalizeTerm = (term) => {
    let words = term.split("_");
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
    return words.join(" ");
  };

  const handleChange = (e) => {
    setSearchInput(e.target.value);
    filter(searchTerm, e.target.value);
  };

  const handleDropChange = (selectedOption) => {
    setSearchTerm(selectedOption);
  };

  const searchOptions = [
    { value: "lab", label: "Lab" },
    { value: "name", label: "Name" },
    { value: "contact_name", label: "Contact Name" },
    { value: "contact_email", label: "Contact Email" },
    { value: "contact_phone", label: "Contact Phone" },
  ];

  return (
    <div>
      {triggerFilter &&
        (setSearchInput(searchInput),
        filter(searchTerm, searchInput),
        setTriggerFilter(false))}
      <InputGroup>
        <Select
          options={searchOptions}
          blurInputOnSelect={true}
          defaultValue={null}
          menuPlacement="auto"
          placeholder="Search..."
          className="searchFilterButton"
          onChange={handleDropChange}
        />
        {/* <DropdownButton title={normalizeTerm(searchTerm)}>
          {searchTerms.map((term) => (
            <Dropdown.Item key={term} onClick={() => setSearchTerm(term)}>
              {normalizeTerm(term)}
            </Dropdown.Item>
          ))}
        </DropdownButton> */}
        <FormControl
          className="searchInput"
          placeholder={placeholder}
          value={searchInput}
          onChange={(e) => handleChange(e)}
        />
      </InputGroup>
    </div>
  );
};

export default SearchBar;
