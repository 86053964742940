import React, { useState, useEffect } from "react";
import { Modal, Button, Table, Row, Col } from "react-bootstrap";
import API from "api";
import { Importer, ImporterField } from "react-csv-importer";
import { parseBooleanValue } from "../../utils";
import "react-csv-importer/dist/index.css";

const LabBarCode = (props) => {
  const importResult = [];
  const [totalBarCode, setTotalBarCode] = useState([]);
  const { id, handleClose } = props;

  useEffect(() => {
    getBarCode();
  }, []);

  const getBarCode = async () => {
    const data = await API.getLabBarCodes(id);
    setTotalBarCode(data);
  };

  return (
    <Modal show animation={true} onHide={() => handleClose()} centered size={"xl"}>
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          Lab Bar Codes {totalBarCode ? `(${totalBarCode.length})` : ""}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="labsModalWrapper">
          <div className="form-group-wrapper d-block">
            <div className="table-responsive">
              <Table className="table-hover table-striped">
                <thead>
                  <tr>
                    <th
                      className={`border-0 centered`}
                      style={{
                        color: "grey",
                        fontWeight: "bold",
                      }}
                    >
                      <div className="d-flex justify-content-start align-items-center">
                        <span>#</span>
                      </div>
                    </th>
                    <th
                      className={`border-0 centered`}
                      style={{
                        color: "grey",
                        fontWeight: "bold",
                      }}
                    >
                      <div className="d-flex justify-content-start align-items-center">
                        <span>Barcode</span>
                      </div>
                    </th>
                    <th
                      className={`border-0 centered`}
                      style={{
                        color: "grey",
                        fontWeight: "bold",
                      }}
                    >
                      <div className="d-flex justify-content-start align-items-center">
                        <span>Available</span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {totalBarCode.map((barCode, index) => {
                    return (
                      <tr key={index}>
                        <td style={{ textAlign: "center" }}>{index + 1}</td>
                        <td style={{ textAlign: "center" }}>{barCode.barcode}</td>
                        <td style={{ textAlign: "center" }}>{parseBooleanValue(barCode.available)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
          <div className="form-group-wrapper d-block uploadFilesArea">
            <Importer
              chunkSize={10000} // optional, internal parsing chunk size in bytes
              assumeNoHeaders={false} // optional, keeps "data has headers" checkbox off by default
              restartable={false} // optional, lets user choose to upload another file when import is complete
              delimiter=" "
              processChunk={async (rows, { startIndex }) => {
                console.log("In Import Result ", rows);
                importResult.push(rows);
              }}
              onClose={async ({ file, preview, fields, columnFields }) => {
                const data = importResult.flat();
                console.log("In ON Close Import Result ", data);
                if (data.length > 0) {
                  await API.updateLabBarCodes(data, id);
                  handleClose();
                }
              }}
            >
              <ImporterField name="barcode" label="Barcode" />
            </Importer>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={() => handleClose()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LabBarCode;
