import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import Select from "react-select";
import countriesOption from "countries";
import { Button, Modal } from "react-bootstrap";
import {
  toTitleCase,
  FormatAWSDate,
  makeAlphabetInput,
  convertToLower,
  isValidEmail,
  makeBarcode,
  formatDoBForDemo,
  isValidPhoneWithCode,
  isValidIdNum,
  getPhoneNo,
  isValidName,
  isValidZipCode,
  validSpace,
  formatZip,
  isValidState,
  isValidDobField,
  formatPhoneNumber,
  formatPhoneWithOutSpaces,
} from "../../utils";
import ErrorMessage from "components/Message/ErrorMessage";
import Loader from "components/Loader/Loader";
import PhoneNoInput from "components/Phone/PhoneNoInput";
import EmployeeSearchRecord from "components/EmployeeSearchRecord";
import api from "api";
import GQAPI from "views/GraphQL/gqApi";
import { externalTypesOptions, countryListAllIsoData, TEST_TYPE_VALUE } from "constant";
import SingleDatePicker from "Date/SingleDatePicker";
import moment from "moment";
import AutoCompleteAddress from "components/AutoCompleteAddress";
import { AppContext } from "context/app-context";

const TestModal = (props) => {
  const { newTestCreate, handleCloseCreateTest, createTest, setTestCreate, clients, sites, labs, isNew, users } = props;
  //  console.log("newTestCreate:::" , newTestCreate)
  const [loading, setLoading] = useState(false);
  const [recordData, setRecordData] = useState([]);
  const [showPopOver, setShowPopOver] = useState(false);
  const [selectSearchdata, setSelectSearchData] = useState(null);
  const [show, setShow] = useState("");
  const [error, setError] = useState("");
  const [disableVaccination, setDisabledVaccination] = useState(false);
  const [onSelectedCreateTestType, setOnSelectedCreateTestType] = useState({});
  const [onSelectedTester, setOnSelectedTester] = useState({});
  const childNode = useRef();
  const [lab, setLabs] = useState([]);
  const appContext = useContext(AppContext);

  const getSiteOptions = useMemo(() => {
    if (!newTestCreate.clientID) return [];
    return sites
      .filter((s) => s.clientID === newTestCreate.clientID)
      .map((l) => {
        return { value: l.id, label: l.name };
      });
  }, [newTestCreate.clientID]);

  const usersData = useMemo(() => {
    if (!users) return;
    return users
      .filter((u) => u.roles?.includes("Tester") || u.roles?.includes("Admin"))
      .map((u) => {
        return {
          value: u.userID,
          label: `${u.firstName} ${u.lastName}`,
          phone_number: u.phone_number?.replace(/ /g, ""),
        };
      });
  }, [users]);

  const getSelectedTester = (onChangeTesterData) => {
    setTestCreate({
      ...newTestCreate,
      createdBy: onChangeTesterData.value,
      tester_name: onChangeTesterData.label,
      testerPhone: onChangeTesterData.phone_number,
    });
    setOnSelectedTester(onChangeTesterData);
  };

  useEffect(() => {
    if (newTestCreate?.test_type) {
      const TEST_TYPE_VALUE = {
        Lucira: "Lucira",
        PCR: "PCR",
        Molecular: "Molecular",
        Rapid_PCR: "Other",
        Rapid_Antigen: "Antigen",
        Rapid_Flu: "RapidFlu",
      };

      const TEST_TYPE_LABEL = {
        Lucira: "Lucira",
        PCR: "PCR",
        Molecular: "Molecular",
        Other: "Rapid PCR",
        Antigen: "Rapid Antigen",
        RapidFlu: "Rapid Flu",
      };

      let type = TEST_TYPE_VALUE[newTestCreate?.test_type.replace(/ /g, "_")];
      if (type === undefined) {
        type = newTestCreate?.test_type;
      }

      setOnSelectedCreateTestType({ label: TEST_TYPE_LABEL[type], value: type });
      setTestCreate({
        ...newTestCreate,
        test_type: type,
        employee_demographics: {
          ...newTestCreate.employee_demographics,
          country: "United States",
          isoCode: "US",
          phoneNumber: formatPhoneWithOutSpaces(
            newTestCreate.employee_demographics.phoneNumber,
            newTestCreate.employee_demographics.countryCode
          ),
        },
      });
    } else {
      setTestCreate({
        ...newTestCreate,
      });
    }
    if (newTestCreate?.tester_name) {
      setOnSelectedTester({ label: newTestCreate?.tester_name, value: newTestCreate?.tester_name });
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setShow("");
    }, 4000);
  }, [show]);

  useEffect(() => {
    // setSite(appContext.sites);
    setLabs(appContext.labs);
  }, [appContext.sites, appContext.labs]);

  const handleUpdateAddress = (val, flag) => {
    if (!flag) {
      setTestCreate({
        ...newTestCreate,
        employee_demographics: { ...newTestCreate.employee_demographics, street: val },
      });
    } else {
      const addressObj = {
        street: val.street,
        country: val.country,
        city: val.city,
        zip: val.zipcode,
        state: val.state,
        isoCode: val.isoCode,
      };
      setTestCreate({
        ...newTestCreate,
        employee_demographics: { ...newTestCreate.employee_demographics, ...addressObj },
      });
    }
  };

  const handlePhoneNo = (e) => {
    setTestCreate({
      ...newTestCreate,
      employee_demographics: { ...newTestCreate.employee_demographics, phoneNumber: e },
      phoneNumber: e,
    });
  };

  const searchPreReg = async (data, type) => {
    if (!data) return;
    setLoading(true);
    let phoneNo = data;
    let countryCode = "";
    if (phoneNo && type === "Phone") {
      const [cc, ph] = getPhoneNo(data);
      phoneNo = ph;
      countryCode = cc;
    }
    let env = window.location.host.includes("localhost") ? "localhost" : "remote";

    let record = [];
    try {
      record = await api.getPreRegisterData(phoneNo, env, type, countryCode);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }

    if (record && record.length === 1) {
      populate(record[0]);
      return;
    }
    if (record && record.length > 1) {
      setRecordData(
        record.map((e) => {
          return { ...e, first: toTitleCase(e.first), last: toTitleCase(e.last) };
        })
      );
      setShowPopOver(!showPopOver);
    }
  };

  const populate = (record) => {
    setShowPopOver(false);
    setSelectSearchData(null);
    const {
      zip,
      state,
      sex,
      fetchFrom,
      city,
      whiteGlove,
      isVaccinated,
      employeeType,
      street,
      phone_number,
      dob,
      last,
      first,
      email,
      id_number,
      id,
      country,
      countryCode,
    } = record;

    const [ccCode, phno] = getPhoneNo(phone_number, countryCode);
    const val = countryListAllIsoData.find((f) => f.name === country) || { code: "US" };

    const obj = {
      employee_demographics: {
        email: email,
        firstName: first,
        lastName: last,
        dob: formatDoBForDemo(dob),
        phoneNumber: `${ccCode}${phno}`,
        countryCode: ccCode,
        address: "",
        employeeType: employeeType,
        isVaccinated: isVaccinated || false,
        whiteGlove: whiteGlove,
        city: city,
        country: country || "United States",
        isoCode: val.code,
        state: state,
        street: street,
        street2: "",
        zip: zip,
        isLucira: false,
        sex: sex,
        testerDes: "",
        idNumber: id_number,
        status: "Pending",
      },
      phoneNumber: phone_number,
      email: email,
      employeeID: id,
    };

    setTestCreate({
      ...newTestCreate,
      employee_demographics: obj.employee_demographics,
      email: obj.email,
      phoneNumber: obj.phoneNumber,
      employeeID: obj.employeeID,
    });

    setDisabledVaccination(isVaccinated || false);
  };
  
  const onHandleSave = async () => {
    let newTestObj = { ...newTestCreate };
    if (!newTestCreate.employee_demographics.firstName) {
      setError("First name is required");
      return;
    }
    if (!isValidName(newTestCreate.employee_demographics.firstName)) {
      setError("First name is invalid must contains at least 2 letters and Name should be realistic");
      return;
    }
    if (!newTestCreate.employee_demographics.lastName) {
      setError("Last name is required");
      return;
    }
    if (!isValidName(newTestCreate.employee_demographics.lastName)) {
      setError("Last name is invalid must contains at least 2 letters and Name should be realistic");
      return;
    }
    if (!newTestCreate.employee_demographics.email) {
      setError("Email name is required");
      return;
    }

    if (!isValidEmail(newTestCreate.employee_demographics.email)) {
      setError("Invalid Email");
      return;
    }

    if (!newTestCreate.employee_demographics.dob) {
      setError("Date of birth is required");
      return;
    }
    const startDate = moment("1900-01-01").startOf("day").format("YYYY-MM-DD");
    const endDate = moment().endOf("day").format("YYYY-MM-DD");
    if (
      !isValidDobField(newTestCreate.employee_demographics.dob, "MMDDYYYY") ||
      !(
        moment(FormatAWSDate(newTestCreate.employee_demographics?.dob)).isSame(moment().format("YYYY-MM-DD")) ||
        moment(FormatAWSDate(newTestCreate.employee_demographics?.dob)).isBetween(startDate, endDate)
      )
    ) {
      setError("Date of Birth is Invalid");
      return;
    }
    if (!newTestCreate.employee_demographics.phoneNumber) {
      setError("Phone number is required");
      return;
    }
    if (!isValidPhoneWithCode(newTestCreate.employee_demographics.phoneNumber)) {
      setError("Phone number is invalid");
      return;
    }

    if (!newTestCreate.employee_demographics.sex) {
      setError("Gender is required");
      return;
    }

    if (!newTestCreate.employee_demographics.idNumber) {
      setError("License No. is required");
      return;
    }

    if (!isValidIdNum(newTestCreate.employee_demographics.idNumber)) {
      setError(`Invalid license no must contains at least 5 characters`);
      return;
    }

    if (!newTestCreate.employee_demographics.street.trim()) {
      setError("Address is required");
      return;
    }
    if (!newTestCreate.employee_demographics.country) {
      setError("Country is Required");
      return;
    }
    if (newTestCreate.employee_demographics.zip === null || !newTestCreate.employee_demographics.zip) {
      setError("Zipcode is required");
      return;
    }

    if (!isValidZipCode(newTestCreate.employee_demographics.isoCode || "US", newTestCreate.employee_demographics.zip)) {
      setError("Zipcode is invalid");
      return;
    }

    if (!newTestCreate.employee_demographics.state.trim()) {
      setError("State is required");
      return;
    }
    if (!newTestCreate.clientID) {
      setError("Client is required");
      return;
    }
    if (!newTestCreate.siteID) {
      setError("Site is required");
      return;
    }

    if (!newTestCreate.labID) {
      setError("Lab is required");
      return;
    }

    if (!newTestCreate.test_type || newTestCreate.test_type == "" || newTestCreate.test_type == "undefined") {
      setError("Test Type is required");
      return;
    }

    if (isNew === false) {
      setLoading(true);
      createTest(newTestObj);
      return;
    }

    setLoading(true);
    if (newTestCreate.sequenceNo) {
      let sequenceData = await GQAPI.getTestBySequenceNoByAPI(newTestCreate.sequenceNo);
      if (sequenceData == newTestCreate.sequenceNo || sequenceData < newTestCreate.sequenceNo) {
        setError("Sequence No. Already Exist");
        setLoading(false);
        return;
      }
      let barcode = makeBarcode(newTestObj.employee_demographics.idNumber, newTestCreate.sequenceNo);
      newTestObj = { ...newTestObj, barcode };
    }

    let env = "";
    if (window.location.host.includes("localhost") || window.location.host.includes("test")) {
      env = "localhost";
    } else {
      env = "remote";
    }

    try {
      const [ccCode, phoneNo, completePhone] = getPhoneNo(
        newTestCreate?.employee_demographics?.phoneNumber,
        newTestCreate?.employee_demographics?.countryCode
      );

      let record = await api.getPreRegisterData(phoneNo, env);
      let newObject = record.filter(
        (obj) =>
          (obj.phone_number === phoneNo || obj.phone_number === `${ccCode}${phoneNo}`) &&
          obj.first.toLowerCase() === newTestCreate?.employee_demographics?.firstName.trim().toLowerCase() &&
          obj.last.toLowerCase() === newTestCreate?.employee_demographics?.lastName.trim().toLowerCase() &&
          obj.email.toLowerCase() === newTestCreate?.employee_demographics?.email.trim().toLowerCase() &&
          obj?.idNumber.toLowerCase() === newTestCreate?.employee_demographics?.idNumber.trim().toLowerCase() &&
          moment(obj.dob).format("MMDDYYYY") === newTestCreate?.employee_demographics?.dob.trim().replaceAll("-", "")
      );

      if (newObject.length > 0 && newObject[0].fetchFrom === "Employee") {
        populate(newObject[0]);
      } else {
        const newEmployee = await GQAPI.createNewEmployee({
          ...newTestCreate?.employee_demographics,
          countryCode: ccCode,
          phoneNumber: phoneNo,
        });

        if (newEmployee.id) {
          newTestObj = { ...newTestObj, employeeID: newEmployee.id };
        }
      }
      let newSequenceNo;
      if (!newTestCreate?.sequenceNo) {
        newSequenceNo = await GQAPI.getTestAutoNumber();
        const barcode = makeBarcode(newTestObj.idNumber, newSequenceNo.counter);
        newTestObj = { ...newTestObj, sequenceNo: newSequenceNo.counter, barcode };
      }

      createTest(newTestObj);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const handleTestType = (e) => {
    setOnSelectedCreateTestType({ label: e.label, value: e.value });
    setTestCreate({ ...newTestCreate, test_type: e.value });
  };

  //let dataCreate = moment(newTestCreate?.createdAt).format("YYYY-MM-DDTHH:mm");

  return (
    <>
      <Modal show animation={true} onHide={handleCloseCreateTest} size="xl" style={{ paddingLeft: "0" }} centered>
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            {isNew === false ? "Update Test" : "Create Test"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: 0,
          }}
        >
          <div className="createClientsWrapper">
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">First Name: </label>
              <input
                className="modalInput"
                placeholder="First Name"
                style={{
                  width: "100%",
                }}
                value={newTestCreate?.employee_demographics?.firstName}
                onChange={(e) => {
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: {
                      ...newTestCreate.employee_demographics,
                      firstName: makeAlphabetInput(e.target.value),
                    },
                  });
                }}
              />
            </div>
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Last Name: </label>
              <input
                className="modalInput"
                placeholder="Last Name"
                value={newTestCreate?.employee_demographics?.lastName}
                onChange={(e) =>
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: {
                      ...newTestCreate.employee_demographics,
                      lastName: makeAlphabetInput(e.target.value),
                    },
                  })
                }
              />
            </div>
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Email: </label>
              <input
                className="modalInput"
                placeholder="Email"
                style={{
                  width: "100%",
                  flexShrink: "initial",
                }}
                value={newTestCreate?.employee_demographics?.email}
                onChange={(e) =>
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: {
                      ...newTestCreate.employee_demographics,
                      email: convertToLower(e.target.value),
                    },
                    email: convertToLower(e.target.value),
                  })
                }
              />
              <i
                ref={childNode}
                className="fas fa-search cursor-pointer"
                style={{ cursor: "pointer" }}
                onClick={() => searchPreReg(newTestCreate?.employee_demographics?.email, "Email")}
              />
            </div>
            <div className="form-group-wrapper dateWrapper">
              <label className="modalLineHeaders">DoB: </label>
              <input
                className="modalInput"
                type="date"
                value={FormatAWSDate(newTestCreate.employee_demographics?.dob)}
                placeholder="Date of Birth"
                onChange={(e) =>
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: {
                      ...newTestCreate.employee_demographics,
                      dob: moment(e.target.value).format("MMDDYYYY"),
                    },
                  })
                }
                min={moment("1900-01-01").format("YYYY-MM-DD")}
                max={moment().format("YYYY-MM-DD")}
              />
              {/* <SingleDatePicker
                onApply={(event, picker) => {
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: {
                      ...newTestCreate.employee_demographics,
                      dob: moment(picker.startDate).format("MMDDYYYY"),
                    },
                  });
                }}
                value={formatDateOfBirthDOB(newTestCreate.employee_demographics?.dob)}
                startDate={formatDateOfBirthDOB(newTestCreate.employee_demographics?.dob)}
                currentDate={true}
              /> */}
            </div>

            <div className="form-group-wrapper phoneInput">
              <label className="modalLineHeaders">Phone #: </label>
              <PhoneNoInput
                handleChange={handlePhoneNo}
                value={formatPhoneNumber(
                  newTestCreate?.employee_demographics?.phoneNumber,
                  newTestCreate?.employee_demographics?.countryCode
                )}
              />
              <i
                ref={childNode}
                className="fas fa-search cursor-pointer"
                style={{ cursor: "pointer" }}
                onClick={() => searchPreReg(newTestCreate?.employee_demographics?.phoneNumber, "Phone")}
              />
            </div>
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Gender: </label>
              <div className="d-flex justify-content-between w-100">
                <span className="radioBox-wrapper">
                  <input
                    type="radio"
                    className="radioButton"
                    name="sex"
                    checked={newTestCreate?.employee_demographics?.sex == "M"}
                    onChange={(e) =>
                      setTestCreate({
                        ...newTestCreate,
                        employee_demographics: {
                          ...newTestCreate.employee_demographics,
                          sex: e.target.checked && "M",
                        },
                      })
                    }
                  />
                  <span className="radioButton">Male</span>
                </span>
                <span className="radioBox-wrapper">
                  <input
                    type="radio"
                    className="radioButton"
                    name="sex"
                    checked={newTestCreate?.employee_demographics?.sex == "F"}
                    onChange={(e) =>
                      setTestCreate({
                        ...newTestCreate,
                        employee_demographics: {
                          ...newTestCreate.employee_demographics,
                          sex: e.target.checked && "F",
                        },
                      })
                    }
                  />
                  <span className="radioButton">Female</span>
                </span>
                <span className="radioBox-wrapper">
                  <input
                    type="radio"
                    className="radioButton"
                    name="sex"
                    checked={newTestCreate?.employee_demographics?.sex == "X"}
                    onChange={(e) =>
                      setTestCreate({
                        ...newTestCreate,
                        employee_demographics: {
                          ...newTestCreate.employee_demographics,
                          sex: e.target.checked && "X",
                        },
                      })
                    }
                  />
                  <span className="radioButton">Not Specified</span>
                </span>
              </div>
            </div>
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">DL/Passport #: </label>
              <input
                className="modalInput"
                placeholder="License/Passport"
                style={{
                  width: "100%",
                  flexShrink: "initial",
                }}
                maxLength={16}
                value={newTestCreate?.employee_demographics?.idNumber}
                onChange={(e) =>
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: { ...newTestCreate.employee_demographics, idNumber: e.target.value },
                  })
                }
              />
              <i
                ref={childNode}
                className="fas fa-search cursor-pointer"
                style={{ cursor: "pointer" }}
                onClick={() => searchPreReg(newTestCreate?.employee_demographics?.idNumber, "License")}
              />
            </div>

            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Address: </label>
              <AutoCompleteAddress
                value={newTestCreate?.employee_demographics?.street}
                cssClass={"modalInput"}
                handleChange={handleUpdateAddress}
              />
              {/* <input
                ref={ref}
                value={newTestCreate?.employee_demographics?.street}
                placeholder="Enter Address"
                className="modalInput"
                onChange={(e) =>
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: {
                      ...newTestCreate.employee_demographics,
                      street: validSpace(e.target.value),
                    },
                  })
                }
              /> */}
            </div>
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">City: </label>
              <input
                className="modalInput"
                placeholder="City "
                value={newTestCreate?.employee_demographics?.city}
                onChange={(e) =>
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: { ...newTestCreate.employee_demographics, city: validSpace(e.target.value) },
                  })
                }
              />
            </div>
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Zip: </label>
              <input
                className="modalInput"
                placeholder="Zip"
                maxLength={10}
                value={newTestCreate?.employee_demographics?.zip}
                onChange={(e) => {
                  let val = e.target.value;
                  if (val) val = val.toUpperCase();
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: {
                      ...newTestCreate.employee_demographics,
                      zip: newTestCreate.employee_demographics.isoCode === "US" ? formatZip(val) : validSpace(val),
                    },
                  });
                }}
              />
            </div>
            {loading && <Loader />}
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">State: </label>
              <input
                className="modalInput"
                placeholder="State"
                maxLength={35}
                value={newTestCreate?.employee_demographics?.state}
                onChange={(e) =>
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: {
                      ...newTestCreate.employee_demographics,
                      state: e.target.value.toUpperCase(),
                    },
                  })
                }
              />
            </div>
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Country: </label>
              <Select
                options={countriesOption}
                blurInputOnSelect={true}
                defaultValue={{ label: "United States", value: "United States" }}
                menuPlacement="auto"
                className="w-100 siteSelector"
                placeholder="Select Country"
                value={
                  newTestCreate.employee_demographics.country
                    ? {
                        label: newTestCreate.employee_demographics.country,
                        value: newTestCreate.employee_demographics.country,
                      }
                    : null
                }
                onChange={(e) => {
                  const country = countryListAllIsoData.find((f) => f.name === e.value) || { code: "US" };
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: {
                      ...newTestCreate.employee_demographics,
                      country: e.value,
                      isoCode: country.code,
                    },
                  });
                }}
              />
            </div>
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Client </label>
              <Select
                className="w-100 siteSelector"
                options={clients.map((l) => {
                  return { value: l.id, label: l.name };
                })}
                blurInputOnSelect={true}
                defaultValue={null}
                menuPlacement="auto"
                value={
                  newTestCreate.clientID
                    ? {
                        label: newTestCreate.clientName,
                        value: newTestCreate.clientID,
                      }
                    : null
                }
                placeholder="Select Client"
                onChange={(e) =>
                  setTestCreate({
                    ...newTestCreate,
                    clientID: e.value,
                    clientName: e.label,
                    siteID: null,
                    site_name: null,
                  })
                }
              />
            </div>
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Site </label>
              <Select
                className="w-100 siteSelector"
                options={getSiteOptions}
                blurInputOnSelect={true}
                defaultValue={null}
                menuPlacement="auto"
                placeholder="Select Site"
                value={
                  newTestCreate.siteID
                    ? {
                        label: newTestCreate.site_name,
                        value: newTestCreate.siteID,
                      }
                    : null
                }
                onChange={(e) => setTestCreate({ ...newTestCreate, siteID: e.value, site_name: e.label })}
              />
            </div>
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Lab </label>
              <Select
                className="w-100 siteSelector"
                options={labs?.map((l) => {
                  return { value: l.id, label: l.name };
                })}
                blurInputOnSelect={true}
                defaultValue={null}
                menuPlacement="auto"
                placeholder="Select Lab"
                value={
                  newTestCreate.labID
                    ? {
                        label: newTestCreate.labName,
                        value: newTestCreate.labID,
                      }
                    : null
                }
                onChange={(e) => setTestCreate({ ...newTestCreate, labID: e.value, labName: e.label })}
              />
            </div>
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Test Type </label>
              <Select
                className="w-100 siteSelector"
                options={externalTypesOptions}
                blurInputOnSelect={true}
                defaultValue={null}
                menuPlacement="auto"
                placeholder="Test Type"
                value={
                  onSelectedCreateTestType.value
                    ? {
                        label: onSelectedCreateTestType.label,
                        value: onSelectedCreateTestType.value,
                      }
                    : null
                }
                onChange={(e) => handleTestType(e)}
              />
            </div>
            {/* <div className="form-group-wrapper">
              <label className="modalLineHeaders">Sequence: </label>
              <input
                className="modalInput"
                type="number"
                placeholder="Sequence No"
                style={{
                  width: "100%",
                }}
                value={newTestCreate?.sequenceNo}
                onChange={(e) => setTestCreate({ ...newTestCreate, sequenceNo: e.target.value })}
              />
            </div> */}
            <div className="form-group-wrapper dateWrapper">
              <label className="modalLineHeaders">Created At: </label>
              {/* <input
                className="modalInput"
                type="date"
                placeholder="Created At"
                style={{ width: "100%", marginBottom: "10px" }}
                value={newTestCreate?.createdAt}
                onChange={(e) => {
                  let value = new Date(e.target.value).toISOString();
                  setTestCreate({ ...newTestCreate, createdAt: value });
                }}
              /> */}
              <input
                className="modalInput"
                type="datetime-local"
                value={newTestCreate?.createdAt}
                style={{ width: "100%", marginBottom: "10px" }}
                onChange={(e) => setTestCreate({ ...newTestCreate, createdAt: e.target.value })}
                max={moment().format("YYYY-MM-DDTHH:mm")}
              />
            </div>
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Vaccinated: </label>
              <div className="d-flex">
                <span className="radioBox-wrapper">
                  <input
                    type="radio"
                    className="radioButton"
                    name="isVaccinated"
                    checked={newTestCreate?.employee_demographics?.isVaccinated}
                    disabled={disableVaccination}
                    onChange={(e) =>
                      setTestCreate({
                        ...newTestCreate,
                        employee_demographics: {
                          ...newTestCreate.employee_demographics,
                          isVaccinated: e.target.checked && true,
                        },
                      })
                    }
                  />
                  <span className="radioButton">Yes</span>
                </span>
                <span className="radioBox-wrapper">
                  <input
                    type="radio"
                    className="radioButton"
                    name="isVaccinated"
                    disabled={disableVaccination}
                    checked={!newTestCreate?.employee_demographics?.isVaccinated}
                    onChange={(e) =>
                      setTestCreate({
                        ...newTestCreate,
                        employee_demographics: {
                          ...newTestCreate.employee_demographics,
                          isVaccinated: e.target.checked && false,
                        },
                      })
                    }
                  />
                  <span className="radioButton">No</span>
                </span>
              </div>
            </div>
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Employee Type: </label>
              <div className="d-flex">
                <span className="radioBox-wrapper">
                  <input
                    type="radio"
                    className="radioButton"
                    name="employeeType"
                    checked={newTestCreate?.employee_demographics?.employeeType == "Staff"}
                    onChange={(e) =>
                      setTestCreate({
                        ...newTestCreate,
                        employee_demographics: {
                          ...newTestCreate.employee_demographics,
                          employeeType: e.target.checked && "Staff",
                        },
                      })
                    }
                  />
                  <span className="radioButton">Staff</span>
                </span>
                <span className="radioBox-wrapper">
                  <input
                    type="radio"
                    className="radioButton"
                    name="employeeType"
                    checked={newTestCreate?.employee_demographics?.employeeType == "Guest"}
                    onChange={(e) =>
                      setTestCreate({
                        ...newTestCreate,
                        employee_demographics: {
                          ...newTestCreate.employee_demographics,
                          employeeType: e.target.checked && "Guest",
                        },
                      })
                    }
                  />
                  <span className="radioButton">Guest</span>
                </span>
              </div>
            </div>
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">White Glove: </label>
              <div className="d-flex">
                <span className="radioBox-wrapper">
                  <input
                    type="radio"
                    className="radioButton"
                    name="whiteGlove"
                    checked={newTestCreate?.employee_demographics?.whiteGlove}
                    onChange={(e) =>
                      setTestCreate({
                        ...newTestCreate,
                        employee_demographics: {
                          ...newTestCreate.employee_demographics,
                          whiteGlove: e.target.checked && true,
                        },
                      })
                    }
                  />
                  <span className="radioButton">Yes</span>
                </span>
                <span className="radioBox-wrapper">
                  <input
                    type="radio"
                    className="radioButton"
                    name="whiteGlove"
                    checked={!newTestCreate?.employee_demographics?.whiteGlove}
                    onChange={(e) =>
                      setTestCreate({
                        ...newTestCreate,
                        employee_demographics: {
                          ...newTestCreate.employee_demographics,
                          whiteGlove: e.target.checked && false,
                        },
                      })
                    }
                  />
                  <span className="radioButton">No</span>
                </span>
              </div>
            </div>

            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Result: </label>
              <div className="d-flex justify-content-between w-100">
                <span className="radioBox-wrapper">
                  <input
                    type="radio"
                    className="radioButton"
                    name="result"
                    value="Positive"
                    checked={newTestCreate?.result == "Positive"}
                    onChange={(e) =>
                      setTestCreate({
                        ...newTestCreate,
                        result: e.target.value,
                        status: "Pending",
                      })
                    }
                  />
                  <span className="radioButton">Positive</span>
                </span>
                <span className="radioBox-wrapper">
                  <input
                    type="radio"
                    className="radioButton"
                    name="result"
                    value="Negative"
                    checked={newTestCreate?.result == "Negative"}
                    onChange={(e) =>
                      setTestCreate({
                        ...newTestCreate,
                        result: e.target.value,
                        status: "Processed",
                      })
                    }
                  />
                  <span className="radioButton">Negative</span>
                </span>
                <span className="radioBox-wrapper">
                  <input
                    type="radio"
                    className="radioButton"
                    name="result"
                    value="UnResult"
                    checked={newTestCreate?.result == "UnResult"}
                    onChange={(e) =>
                      setTestCreate({
                        ...newTestCreate,
                        result: e.target.value,
                        status: "Processed",
                      })
                    }
                  />
                  <span className="radioButton">UnResult</span>
                </span>
                <span className="radioBox-wrapper" style={{ display: "flex" }}>
                  <input
                    type="radio"
                    className="radioButton"
                    name="result"
                    value="Inconclusive"
                    checked={newTestCreate?.result == "Inconclusive"}
                    onChange={(e) =>
                      setTestCreate({
                        ...newTestCreate,
                        result: e.target.value,
                        status: "Processed",
                      })
                    }
                  />
                  <span className="radioButton">Inconclusive</span>
                </span>
              </div>
            </div>
            {isNew === false && (
              <>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Tester </label>
                  <Select
                    className="w-100 siteSelector"
                    options={usersData}
                    blurInputOnSelect={true}
                    defaultValue={null}
                    menuPlacement="auto"
                    //value={getSelectedTester()}
                    placeholder="Select Tester"
                    value={
                      onSelectedTester.value
                        ? {
                            label: onSelectedTester.label,
                            value: onSelectedTester.value,
                          }
                        : null
                    }
                    onChange={(e) => getSelectedTester(e)}

                    // onChange={(e) =>
                    //   setTestCreate({
                    //     ...newTestCreate,
                    //     createdBy: e.value,
                    //     tester_name: e.label,
                    //     testerPhone: e.phone_number,
                    //   })
                    // }
                  />
                </div>
              </>
            )}

            {showPopOver && recordData.length > 1 && (
              <EmployeeSearchRecord
                data={recordData}
                handleSelect={populate}
                handleClose={() => {
                  setShowPopOver(false), setSelectSearchData(null);
                }}
                setSelectSearchData={setSelectSearchData}
                selectSearchdata={selectSearchdata}
              />
            )}
          </div>
        </Modal.Body>
        <div className="px-4" style={{ height: "3vh" }}>
          {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
        </div>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            className="modalButtons"
            onClick={() => {
              handleCloseCreateTest();
            }}
          >
            Close
          </Button>
          <Button
            style={{ marginBottom: 10 }}
            variant="primary"
            disabled={loading}
            className="modalButtons"
            onClick={onHandleSave}
          >
            {isNew === false ? "Update" : "Create"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TestModal;
