import React from "react";

const TableRow = ({ item, handleEdit }) => {
  return (
    <tr>
      <td className="icon">
        <i
          className="fas fa-edit"
          onMouseLeave={(e) => (e.target.style.color = "black")}
          onMouseOver={(e) => {
            e.target.style.color = "blue";
            e.target.style.cursor = "pointer";
          }}
          aria-hidden="true"
          onClick={() => handleEdit(item)}
        />
      </td>
      <td className="ellipsis">{item.name}</td>
      <td className="ellipsis">{item.totalTime}</td>
      <td className="ellipsis">{item.firstAlert}</td>
      <td className="ellipsis">{item.secondAlert}</td>
      <td className="ellipsis">{item.duration}</td>
    </tr>
  );
};

export default TableRow;
