import React, { useState, useEffect, useRef, useMemo } from "react";
import logoImage from "../../assets/img/safecamp_BRAND.1-nobg.png";
import PhoneNoInput from "components/Phone/PhoneNoInput";
import { formateDateLL } from "utils";

const ShowSelection = (props) => {
  const { shows, handleShowSelection } = props;
  const [error, setError] = useState("");
  const [selectedShow, setSelectedShow] = useState("");
 
  const showErrorMessage = (message) => {
    setError(message);
    setTimeout(() => setError(""), 2000);
  };

  const handleSubmit = () => {
    if (!selectedShow) {
      showErrorMessage("Select the show");
      return;
    }
    handleShowSelection(selectedShow);
  };

  const onChangeSelection = (e) => setSelectedShow(e);

  return (
    <div className="registerFormWrapper">
      <div className="registerWrapper">
        <div className="text-center">
          <img src={logoImage} alt="logo" className="logo-width" />
        </div>
        <div className="codeScannerWrapper codeScannerPhoneInput">
          <div className="registered-successful">
            <h4 className="w-100 text-center mt-5">
              <b>Please select the show</b>
            </h4>
            <div className="custom-radio-btn-wrapper">
              {shows.map((s) => {
                return (
                  <div key={s.id}>
                    <input
                      id={s.id}
                      className="radio-custom"
                      name="radio-group"
                      type="radio"
                      value={s.id}
                      onChange={()=>onChangeSelection(s)}
                    />
                    <label htmlFor={s.id} className="radio-custom-label">
                      <div className="d-inline-flex flex-column align-items-start">
                        <span>{s.showName}</span>
                      </div>
                    </label>
                  </div>
                );
              })}
            </div>

            {error && (
              <div className="error-message">
                <h6>{error}</h6>
              </div>
            )}
            <div className="mt-5 w-50 mx-auto">
              <button type="submit" className="text-uppercase w-100 btn signup-btn" onClick={handleSubmit}>
                Proceed
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowSelection;
