import React from "react";
import { Document, Page, Text, View, Image, StyleSheet } from "@react-pdf/renderer";
import { formatPhoneNumber, formatZipCode, formatDateOfBirthDOB, formatDateMDY } from "utils";

const RequisitionPDF = ({ test, lab, signature, demographics }) => {
  const data = {
    maintainAspectRatio: false,
    responsive: false,
    labels: ["a", "b", "c", "d"],
    datasets: [
      {
        data: [300, 50, 100, 50],
        backgroundColor: ["red", "white"],
        hoverBackgroundColor: ["red", "white"],
      },
    ],
  };

  const today = new Date();

  const time =
    today.getHours() > 12
      ? `${today.getHours() - 12}:${today.getMinutes()} PM`
      : `${today.getHours()}:${today.getMinutes()} AM`;

  return (
    <Document title={`${demographics?.firstName} ${demographics?.lastName}`}>
      {console.log(test, demographics)}
      <Page size="A4" style={styles.page}>
        <View style={{ flex: 1, flexDirection: "row", borderWidth: 2, padding: 0, alignItems: "stretch" }}>
          <View style={{ flex: 1, top: 0, bottom: 0, position: "relative" }}>
            <Text style={{ backgroundColor: "black", color: "white", textAlign: "center", fontSize: 14 }}>
              CLIENT INFORMATION
            </Text>
            <Text style={{ padding: 10 }}>SHOW {test?.site_name}</Text>
          </View>
          <View style={{ ...styles.hr, height: 135 }} />
          <View style={{ flex: 1 }}>
            <Text style={{ backgroundColor: "black", color: "white", textAlign: "center", fontSize: 14 }}>
              LAB TEST REQUISITION
            </Text>
            <View style={{ textAlign: "right", padding: 10 }}>
              <Text style={{ fontWeight: "bold" }}>{lab?.name}</Text>
              <Text style={{ fontSize: 14 }}>{lab?.street}</Text>
              <Text style={{ fontSize: 14 }}>{lab?.city_state_zip}</Text>
              <Text style={{ fontSize: 14 }}>P: {formatPhoneNumber(lab?.contact_phone)}</Text>
            </View>
            {lab && lab.logoSrc && (
              <View style={{ textAlign: "left", padding: 10, position: "absolute", top: "18px" }}>
                <Image style={{ width: "50px", height: "50px", top: 0, objectFit: "contain" }} src={lab.logoSrc} />
              </View>
            )}
          </View>
        </View>
        <View style={{ flex: 2, borderWidth: 2 }}>
          <Text style={{ backgroundColor: "black", color: "white", textAlign: "center", fontSize: 14 }}>
            PATIENT'S INFORMATION
          </Text>
          <View style={{ flexDirection: "row" }}>
            <View style={{ flex: 1 }}>
              <View style={{ flexDirection: "row", flex: 1, borderBottomWidth: 1 }}>
                <View style={{ flex: 1 }}>
                  <Text style={{ fontSize: 8 }}>Last Name</Text>
                  <Text style={{ fontSize: 12 }}>{demographics?.lastName}</Text>
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={{ fontSize: 8 }}>First Name</Text>
                  <Text style={{ fontSize: 12 }}>{demographics?.firstName}</Text>
                </View>
              </View>
              <View style={{ borderBottomWidth: 1, flex: 1 }}>
                <Text style={{ fontSize: 8 }}>Address</Text>
                <Text style={{ fontSize: 12 }}>{demographics?.street}</Text>
              </View>
              <View style={{ flexDirection: "row", flex: 1, borderBottomWidth: 1 }}>
                <View style={{ flex: 1 }}>
                  <Text style={{ fontSize: 8 }}>City</Text>
                  <Text style={{ fontSize: 12 }}>{demographics?.city}</Text>
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={{ fontSize: 8 }}>State</Text>
                  <Text style={{ fontSize: 12 }}>{demographics?.state}</Text>
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={{ fontSize: 8 }}>ZIP</Text>
                  <Text style={{ fontSize: 12 }}>{demographics?.zip}</Text>
                </View>
              </View>
              <View style={{ flexDirection: "row", flex: 3 }}>
                <View style={{ flex: 1 }}>
                  <Text style={{ backgroundColor: "black", color: "white", textAlign: "center", fontSize: 14 }}>
                    BILLING
                  </Text>
                </View>
                <View style={{ flex: 1 }}>
                  <View style={{ borderBottomWidth: 1, flex: 1 }}>
                    <Text style={{ fontSize: 8 }}>Insurance Company</Text>
                    <Text style={{ fontSize: 12 }}>{demographics?.insurance_name}</Text>
                  </View>
                  <View style={{ borderBottomWidth: 1, flex: 1 }}>
                    <Text style={{ fontSize: 8 }}>Subscriber ID #</Text>
                    <Text style={{ fontSize: 12 }}>{demographics?.insurance_number}</Text>
                  </View>
                  <View style={{ borderBottomWidth: 1, flex: 1 }}>
                    <Text style={{ fontSize: 8 }}>Medical #</Text>
                    {/* <Text style={{ fontSize: 12 }}>{demographics?.insurance_number}</Text> */}
                  </View>
                  <View style={{ flex: 1 }}>
                    <Text style={{ fontSize: 8 }}>Medicare #</Text>
                    {/* <Text style={{ fontSize: 12 }}>{demographics?.insurance_number}</Text> */}
                  </View>
                </View>
              </View>
            </View>
            <View style={{ ...styles.hr, height: 255 }} />
            <View style={{ flex: 1 }}>
              {/* <Text style={{ backgroundColor: 'black', color: 'white', textAlign: 'center', fontSize: 14 }}>ICD-10 CODES REQUIRED</Text> */}
              {/* <View style={{ flexDirection: 'row', flex: 1, borderBottomWidth: 1 }}>
                            <Text style={{ borderWidth: 1, flex: 1 }}></Text>
                            <Text style={{ borderWidth: 1, flex: 1 }}></Text>
                            <Text style={{ borderWidth: 1, flex: 1 }}></Text>
                        </View>
                        <View style={{ flexDirection: 'row', flex: 1 }}>
                            <Text style={{ borderWidth: 1, flex: 1 }}></Text>
                            <Text style={{ borderWidth: 1, flex: 1 }}></Text>
                            <Text style={{ borderWidth: 1, flex: 1 }}></Text>
                        </View> */}
              <View style={{ flexDirection: "row", flex: 1, borderBottomWidth: 1 }}>
                <View style={{ flex: 1 }}>
                  <Text style={{ fontSize: 8 }}>Date of Birth</Text>
                  <Text style={{ fontSize: 12 }}>{formatDateOfBirthDOB(demographics?.dob)}</Text>
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={{ fontSize: 8 }}>Sex</Text>
                  <Text style={{ fontSize: 12 }}>{demographics?.sex}</Text>
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={{ fontSize: 8 }}>Date Collected</Text>
                  <Text style={{ fontSize: 12 }}>{formatDateMDY(test.createdAt)}</Text>
                </View>
                <Text style={{ fontSize: 8 }}>Time Collected</Text>
              </View>
              <View style={{ flex: 1, borderBottomWidth: 1 }}>
                <Text style={{ fontSize: 8 }}>Patient's Phone #</Text>
                <Text style={{ fontSize: 12 }}>{formatPhoneNumber(test.phoneNumber)}</Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={{ fontSize: 8 }}>Email Address</Text>
                <Text style={{ fontSize: 12 }}>{test.email}</Text>
              </View>
            </View>
          </View>
        </View>
        <View style={{ flex: 3, borderWidth: 2, borderColor: "black" }}>
          <Text style={{ backgroundColor: "black", color: "white", textAlign: "center", fontSize: 14 }}>
            STANDING ORDER FORM
          </Text>
          <View style={{ flexDirection: "row", flex: 2 }}>
            <View style={{ flex: 1, paddingHorizontal: 20 }}>
              <View style={{ borderBottomWidth: 1, borderBottomStyle: "dashed", marginVertical: 10 }} />
              <View style={{ flexDirection: "row" }}>
                <View style={{ borderWidth: 3, height: 16, width: 20 }} />
                <Text style={{ fontSize: 12, marginLeft: 2 }}>7060 - COVID BY PCR SWAB</Text>
              </View>
              <View style={{ flexDirection: "row", marginLeft: 30, marginTop: 5 }}>
                <View style={{ borderWidth: 3, height: 16, width: 20 }} />
                <Text style={{ fontSize: 12, marginRight: 30 }}>ORAL</Text>
                <View style={{ borderWidth: 3, height: 16, width: 20 }} />
                <Text style={{ fontSize: 12, marginRight: 30 }}>NASAL</Text>
              </View>
              <View style={{ borderBottomWidth: 1, borderBottomStyle: "dashed", marginVertical: 10 }} />
              <View style={{ flexDirection: "row" }}>
                <View style={{ borderWidth: 3, height: 16, width: 20 }} />
                <Text style={{ fontSize: 12, marginLeft: 2 }}>7043 - COVID-19 IgG / IgM</Text>
              </View>
              <View style={{ borderBottomWidth: 1, borderBottomStyle: "dashed", marginVertical: 10 }} />
              <Text style={{ fontSize: 6, lineHeight: 2 }}>
                *NEGATIVE results do not rule out SARS-CoV-2 infection, patricularlyin those who have been in contact
                with the virus. Follow-uptesting with a molecular diagnostic should be considered to ruleout infection
                in these individuals.*Results from antibody testing should not be used as the solebasis to diagnose or
                exclude SARS-CoV-2 infection or inform infection status. *Positive results may be due to past or present
                infection withnon-SARS-CoV-2 corona virus strains, such as HKU1, NL63,OC43, or 229E.
              </Text>
              <Text style={{ fontSize: 6, alignSelf: "center" }}>
                *This test is FDA EUA cleared, but not FDA approved.*
              </Text>
              <Text style={{ fontSize: 6, alignSelf: "center", marginTop: 10 }}>
                * This test has been performed by a CLIA laboratory withhigh complexity certification.*
              </Text>
            </View>
            <View style={{ flex: 1, paddingHorizontal: 20, paddingVertical: 10 }}>
              <Text style={{ fontSize: 6, lineHeight: 2 }}>
                *PLEASE NOTE: Standing orders will expire 12 months from the date issued unless otherwise specified on
                the requistion.*
              </Text>
              <View style={{ flexDirection: "row", borderWidth: 2 }}>
                <View style={{ flex: 1, borderRightWidth: 2 }}>
                  <Text style={{ backgroundColor: "black", color: "white", fontSize: 8 }}>ORDER START DATE:</Text>
                  <View style={{ height: 20 }}></View>
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={{ backgroundColor: "black", color: "white", fontSize: 8 }}>ORDER START DATE:</Text>
                  <View style={{ height: 20 }}></View>
                </View>
              </View>
              <View style={{ flex: 3, borderWidth: 2, marginTop: 10 }}>
                <Text style={{ textAlign: "center", fontSize: 10, borderBottomWidth: 2 }}>
                  HAVING SYMPTOMS? (PLEASE MARK ALL THAT APPLY)
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              borderWidth: 1,
              marginHorizontal: 10,
              marginVertical: 20,
              flex: 1,
              justifyContent: "space-evenly",
            }}
          >
            <Text style={{ fontSize: 6, textAlign: "center" }}>
              I GIVE CONSENT TO MY EMPLOYER AND {lab?.name} LAB TO TEST THE ORAL/NASAL SAMPLE I HAVE PROVIDED FOR THE
              PRESENCE OF SARS-COV-2 THROUGH THE USE OF AN RT-PCR TEST. I CONSENT TO THE RELEASE OF THE TEST RESULTS TO
              MY EMPLOYER AND TO STATE AND LOCAL OFFICALS, AS REQUIRED BY LAW. I UNDERSTAND THAT A POSITIVE RESULT OR A
              REFUSAL TO BE TESTED AND SUBMIT MY SAMPLE MAY RESULT IN TERMINIATION OR SUSPENSION OF EMPLOYMENT UNTIL I
              HAVE BEEN RE-TESTED WITH A NEGATIVE RESULT.{" "}
            </Text>
            <Text style={{ fontSize: 6 }}>
              BY SIGNING BELOW, I HEREBY ACKNOWLEDGE THAT I HAVE COMPLETELY READ AND FULLY UNDERSTAND ALL THE
              INFORMATION CONTAINED ON THIS FORM.
            </Text>
            <View style={{ flexDirection: "row" }}>
              <Text style={{ backgroundColor: "#5AB6D4", flex: 1, fontSize: 12 }}>PATIENT SIGNATURE:</Text>
              <Text style={{ backgroundColor: "#5AB6D4", flex: 1, fontSize: 12 }}>DATE:</Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <View style={{ flex: 1 }}>
                {signature && (
                  <Image
                    style={{
                      width: 150,
                      height: 40,
                      borderBottomWidth: 1,
                      borderBottomStyle: "dashed",
                      marginLeft: 10,
                    }}
                    source={{
                      uri: signature,
                      headers: { Pragma: "no-cache", "Cache-Control": "no-cache" },
                    }}
                  />
                )}
              </View>
              <Text style={{ flex: 1, fontSize: 14, textAlign: "center" }}>
                {new Date(test.createdAt).toDateString()}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    flex: 1,
    backgroundColor: "white",
    paddingVertical: 10,
    paddingHorizontal: 10,
  },
  infoText: {
    fontSize: 12,
  },
  patientInfo: {
    flexDirection: "row",
    justifyContent: "space-around",
  },
  infoCol: {
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  hr: {
    borderLeftColor: "black",
    borderLeftWidth: 1,
    // width: "100%",
  },
  address: {
    marginLeft: 50,
    marginVertical: 20,
  },
});

export default RequisitionPDF;
