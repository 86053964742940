import React, { useState, useContext, useEffect } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { AppContext } from "context/app-context";
import api from "api";
import Loader from "components/Loader/Loader";
import { formatPhoneNumber, parseBooleanValue, formatDOB } from "utils";
import { GENDER_TEXT } from "constant";
import moment from "moment";
import { formatTest } from "utils";

const EmployeeCrewProfileMergedLogsModal = (props) => {
  const { data, handleCloseMergedProfileLogs } = props;

  const [tests, setTests] = useState([]);
  const [loading, setLoading] = useState(false);
  const appContext = useContext(AppContext);


  useEffect(() =>{
    
    if(data?.test){
        const tests = data?.test?.map((t)=> formatTest(t));
        setTests(tests);
    }
  }, [data?.test])
  return (
    <>
      {!loading ? (
        <Modal show animation={true} onHide={() => handleCloseMergedProfileLogs()} size="2xl" >
          <Modal.Header closeButton>
            <Modal.Title className="my-0" id="contained-modal-title-vcenter">
             Combined Profiles
            </Modal.Title>
          </Modal.Header>


          <Modal.Body style={{ paddingTop: 0 }} >
      
                <div className="d-flex justify-content-center">
                    <h4>
                    Success:  You have deleted <b>{data?.deletedIds?.length}</b>  Duplicate Profiles and combined <b>{data?.test?.length}</b> tests
                    </h4>
             

            </div>
            <Form>
              <div className="form-group-wrapper d-block">
                <div className="table-responsive">
                  <Table className="table-hover table-striped">
                    <thead>
                      <tr>
                        <th
                          className={`border-0`}
                          style={{
                            color: "grey",
                            fontWeight: "bold",
                          }}
                        >
                          <div>
                            <span>First Name</span>
                          </div>
                        </th>
                        <th
                          className={`border-0  `}
                          style={{
                            color: "grey",
                            fontWeight: "bold",
                          }}
                        >
                          <div>
                            <span>Last Name</span>
                          </div>
                        </th>
                        <th
                          className={`border-0  `}
                          style={{
                            color: "grey",
                            fontWeight: "bold",
                          }}
                        >
                          <div>
                            <span>Email</span>
                          </div>
                        </th>
                        <th
                          className={`border-0  `}
                          style={{
                            color: "grey",
                            fontWeight: "bold",
                          }}
                        >
                          <div>
                            <span>Phone Number</span>
                          </div>
                        </th>

               
                        <th
                          className={`border-0  `}
                          style={{
                            color: "grey",
                            fontWeight: "bold",
                            textAlign: "center"
                          }}
                        >
                          <div>
                            <span>Sequence No</span>
                          </div>
                        </th>
                        <th
                          className={`border-0  `}
                          style={{
                            color: "grey",
                            fontWeight: "bold",
                            textAlign: "center"
                          }}
                        >
                          <div>
                            <span>Test Type</span>
                          </div>
                        </th>
                        <th
                          className={`border-0  `}
                          style={{
                            color: "grey",
                            fontWeight: "bold",
                            textAlign: "center"
                          }}
                          
                        >
                          <div>
                            <span>Result</span>
                          </div>
                        </th>
                    
                      </tr>
                    </thead>
                    <tbody>
                      { 
                        tests?.map((testData, index) => {
                        return (
                          <tr >
                            <td className="ellipsis" title={testData.firstName}>
                              {testData.firstName}
                            </td>
                            <td className="ellipsis" title={testData.lastName}>
                              {testData.lastName}
                            </td>
                            <td
                              className="ellipsis"
                              style={{
                                textDecoration: "underline",
                                color: "#A82632",
                              }}
                              onMouseOver={(e) => {
                                e.target.style.cursor = "pointer";
                                e.target.style.textDecoration = "none";
                              }}
                              onMouseLeave={(e) => {
                                e.target.style.textDecoration = "underline";
                              }}
                              onClick={() => testData?.email && window.open(`mailto:${testData.email}`)}
                              title={testData.email}
                            >
                              {testData.email}
                            </td>
                            <td
                              className="ellipsis"
                              style={{ textDecoration: "underline", color: "#A82632" }}
                              onMouseOver={(e) => {
                                e.target.style.cursor = "pointer";
                                e.target.style.textDecoration = "none";
                              }}
                              onMouseLeave={(e) => {
                                e.target.style.textDecoration = "underline";
                              }}
                              onClick={() => {
                                testData.phoneNumber && window.open(`tel:+1${testData.phoneNumber.replace(/\D/g, "")}`);
                              }}
                              title={formatPhoneNumber(testData.phoneNumber)}
                            >
                              {formatPhoneNumber(testData.phoneNumber)}
                            </td>
                          
                            <td className="ellipsis" title={testData.sequenceNo}>
                              {testData.sequenceNo}
                            </td>
                            <td className="ellipsis"  title={testData?.test_type}>
                              {testData?.test_type}
                            </td>
                            <td className="ellipsis"  title={testData?.result}>
                              {testData?.result}
                            </td>
                           
                           
                          </tr>
                        );
                      })
                      }
                    </tbody>
                  </Table>
                </div>
              </div>
            </Form>
          </Modal.Body>

          <Modal.Footer className="flex-row-reverse">
         
            <Button variant="primary" onClick={() => handleCloseMergedProfileLogs(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default EmployeeCrewProfileMergedLogsModal;
