import React, { useState, useEffect, useContext } from "react";
// import logoImage from "../../assets/img/safecamp_BRAND.1-nobg.png";
import { useHistory, Redirect } from "react-router-dom";
import { logoImage, CONFIG } from "constant";
import {
  phoneFormatter,
  setPhoneNo,
  medFlowInLocalStorage,
  medFlowPortalTitle,
  sortingFilterInLC,
  personalisationInLC,
  getPersonalizationIds,
  connectedIDInLocalStorage,
  roleInLocalStorage,
} from "utils";
import { Auth } from "aws-amplify";
import API from "api";
import SweetAlert from "react-bootstrap-sweetalert";
import { defaultPersonalization, HEALTH_AND_SAFTEY } from "constant";
import PhoneNoInput from "components/Phone/PhoneNoInput";
import MultiRole from "./MultiRole";
import { Config } from "aws-sdk";

const Login = () => {
  const [phone, setPhone] = useState("+1");
  const [password, setPassword] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [userId, setUserId] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);
  const [multiRole, setMultiRole] = useState(false);
  const [rolesData, setRolesData] = useState([]);
  const [userRoles, setUserRoles] = useState([]);

  let history = useHistory();

  useEffect(() => {
    const getUSer = async () => {
      const user = await API.getCurrentUser();
      if (user) redirectToAdmin();
    };
    getUSer();
  }, []);

  const handleMultiRole = () => {
    setMultiRole(true);
  };

  const handleRoleSelection = async (obj, uid) => {
    console.log("In handleRoleSelection", obj, uid);
    let role = obj.role;
    let type = "A";
    if (role === "Labs" || role === "Lab") {
      type = "L";
    } else if (role === "Employers" || role === "Employer" || role === HEALTH_AND_SAFTEY) {
      type = "E";
    } else if (role === "Clients" || role === "Client") {
      type = "C";
    }

    // get connected Id
    medFlowInLocalStorage.save(type);
    medFlowPortalTitle.save(obj.title);
    connectedIDInLocalStorage.save(obj.relatedId);
    sortingFilterInLC.save(sortingFilterInLC.get());
    roleInLocalStorage.save(role);

    if (type === "A") {
      let data = null;
      try {
        data = await API.getUserPersonalisation(userId || uid);
      } catch (e) {
        console.log(e);
      }
      if (data && data.length > 0) {
        personalisationInLC.save("", JSON.parse(data[0].personalisation));
      } else {
        personalisationInLC.save("", defaultPersonalization());
      }
    }
    redirectToAdmin();
  };

  const handleSubmit = async () => {
    setMultiRole(false);
    if (!phone) {
      setPhoneError("please enter the phone");
      return;
    }
    if (!password) {
      setPhoneError("please enter the password");
      return;
    }

    try {
      setDisableBtn(true);
      const user = await Auth.signIn(phone, password);
      console.log("user", user);
      const roles = user.signInUserSession.accessToken.payload["cognito:groups"] || [];

      let getUserRoles = await API.getEmployeeRoles(user?.username);
      let availableRoles = JSON.parse(getUserRoles[0]?.roles);
      let flag = false;

      if (CONFIG.url && CONFIG.isWhiteLabel) {
        availableRoles = availableRoles.filter((role) => role.relatedId == CONFIG.whiteLabelID);
        if (availableRoles.length === 0) {
          console.log("User is not authorized");

          setDisableBtn(false);
          setPhoneError("Not Authorized!!!");
          await Auth.signOut();
          return;
        }
      } else {
        availableRoles = availableRoles
          .filter((role) => !role.isWhiteLabelLab)
          .filter((value, index, self) => index === self.findIndex((t) => t.relatedId === value.relatedId));
      }

      getUserRoles = availableRoles;
      let connectedID = user.attributes["custom:connectedID"];
      if (getUserRoles.length > 0) {
        let userRole = getUserRoles;
        setUserRoles(userRole);

        if (userRole?.length > 1) {
          setUserId(user?.username);
          let rolesGet = userRole.filter((role) => ["Lab", "Admin", "Client", HEALTH_AND_SAFTEY].includes(role.role));
          let roleArrFormat = [];
          if (rolesGet.length === 1) {
            handleRoleSelection(rolesGet[0], user?.username);
            return;
          }

          if (rolesGet) {
            roleArrFormat = rolesGet.map((element) => {
              if (element.role === HEALTH_AND_SAFTEY) {
                return { ...element, role: "Employers" };
              }
              return { ...element, role: `${element.role}s` };
            });
          }
          setRolesData(roleArrFormat);
          handleMultiRole();
          return;
        } else if (userRole?.length === 1 && userRole[0]?.relatedId) {
          connectedID = userRole[0]?.relatedId;
          setRolesData([]);
        } else {
          setRolesData([]);
        }
      }

      let type = "A";
      if (roles.some((role) => role === "Labs")) {
        // if(flag && CONFIG.isWhiteLabel){
        //   type = "L";
        // } else {
        //   type = "";
        // }
        type = "L";
      } else if (roles.some((role) => role === "Employers")) {
        type = "E";
      } else if (roles.some((role) => role === "Clients")) {
        type = "C";
      }
      medFlowPortalTitle.save(getUserRoles[0]?.title || "");
      medFlowInLocalStorage.save(type);
      connectedIDInLocalStorage.save(connectedID);
      sortingFilterInLC.save(sortingFilterInLC.get());

      if (type === "A") {
        let data = null;
        try {
          data = await API.getUserPersonalisation(user.username);
        } catch (e) {
          console.log(e);
        }
        if (data && data.length > 0) {
          personalisationInLC.save("", JSON.parse(data[0].personalisation));
        } else {
          personalisationInLC.save("", defaultPersonalization());
        }
      }
      redirectToAdmin();
    } catch (error) {
      console.log("Error", error);
      setDisableBtn(false);
      setUserId("");
      setUserRoles([]);
      setRolesData([]);
      setPhoneError(error.message);
    }
  };

  const handleKeyDownEvent = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const redirectToAdmin = () => {
    if (history) history.push("/admin");
    else window.location.reload();
  };

  const isLoggedIn = medFlowInLocalStorage.get();
  if (isLoggedIn) redirectToAdmin();

  return (
    <div>
      {!multiRole ? (
        <div className="loginWrapper">
          <div className="loginInner">
            <h3 className="w-100 text-center mt-0">Sign into your account</h3>
            <div className="text-center">
              <img src={logoImage} alt="logo" className="logo-width" />
            </div>
            <div className="form-wrapper">
              <div className="form-group">
                <label className="mb-1">Phone Number:</label>
                <PhoneNoInput value={phone} handleChange={(e) => setPhone(e)} cssCode={"w-100"} />
                {/* <input
              value={phoneFormatter(phone)}
              type="text"
              maxLength={15}
              className="form-control"
              placeholder="phone number"
              onChange={(e) => setPhone(phoneFormatter(e.target.value))}
            /> */}
              </div>
              <div className="form-group mt-3">
                <label className="mb-1">Password:</label>
                <input
                  minLength={8}
                  value={password}
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  onKeyDown={handleKeyDownEvent}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
            {/* <p className="my-3">
          Forgot your password?{" "}
          <a href="/" className="resetPassword">
            Reset Password
          </a>
        </p> */}
            <div className="mt-5">
              <p>
                I have read and agree to the
                <a
                  className="text-decoration-none"
                  target="_blank"
                  href="https://medflow-images.s3.eu-west-1.amazonaws.com/PrivacyPolicy-MedFlow.pdf"
                >
                  &nbsp; Privacy Policy
                </a>
                &nbsp; and &nbsp;
                <a
                  className="text-decoration-none"
                  href="https://medflow-images.s3.eu-west-1.amazonaws.com/TermsofUse-MedFlow.pdf"
                  target="_blank"
                >
                  Terms and Use &nbsp;
                </a>
              </p>
              <button
                type="submit"
                disabled={disableBtn}
                className="text-uppercase w-100 btn signin-btn"
                onClick={handleSubmit}
              >
                Sign In
              </button>
            </div>
          </div>
          {phoneError && (
            <SweetAlert show={phoneError ? true : false} error title="Error" onConfirm={() => setPhoneError("")}>
              {phoneError}
            </SweetAlert>
          )}
        </div>
      ) : (
        <MultiRole roles={rolesData} handleRoleSelection={handleRoleSelection} />
      )}
      ;
    </div>
  );
};

export default Login;
