import React from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";

const ProfileTableHeader = (props) => {
  return (
    <thead>
      <tr>
        <th
          className="border-0 ellipsis"
          align="center"
              style={{
                  width: '9%',
            color: "grey",
            fontWeight: "bold",
          }}
        >
            First Name
        </th>
        <th
            align="center"
          className="border-0"
          style={{
              width: '9%',
            color: "grey",
            fontWeight: "bold",
          }}
        >
            Last Name
        </th>
        <th
          className="border-0 text-center"
          style={{
              width: '11%',
            color: "grey",
            fontWeight: "bold",
          }}
        >
            Email
        </th>
        <th
            align="center"
          className="border-0 ellipsis"
          style={{
              width: '12%',
            color: "grey",
            fontWeight: "bold",
          }}
        >
            Phone Number
        </th>
        <th
          className="border-0 text-center ellipsis"
          style={{
              width: '9%',
            color: "grey",
            fontWeight: "bold",
          }}
        >
            DOB
        </th>
        <th
            align="center"
          className="border-0 ellipsis"
          style={{
              width: '9%',
            color: "grey",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
            Address
        </th>
        <th
            align="center"
          className="border-0 ellipsis"
          style={{
              width: '9%',
            color: "grey",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
            ID_Number
        </th>
        <th
            align="center"
          className="border-0 ellipsis"
          style={{
              width: '6%',
            color: "grey",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
            Tests
        </th>

        <th
            align="center"
          className="border-0 ellipsis"
          style={{
            color: "grey",
            fontWeight: "bold",
            textAlign: "center",
              width: '8%',
          }}
        >
            Last Show
        </th>

        <th
            align="center"
          className="border-0 ellipsis"
          style={{
            color: "grey",
            fontWeight: "bold",
            textAlign: "center",
              width: '11%'
          }}
        >
            Last Test Date
        </th>
        <th
            align="center"
          className="border-0 ellipsis"
          style={{
              width: '7%',
            color: "grey",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Actions
        </th>
      </tr>
    </thead>
  );
};

export default ProfileTableHeader;