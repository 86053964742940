import React, { useState, useEffect } from "react";
import API from "api";

const BarCodeAlert = (props) => {
  const { id, labData, title } = props;
  const [totalBarCode, setTotalBarCode] = useState(201);

  useEffect(() => {
    getBarCode();
  }, []);

  const getBarCode = async () => {
    const data = await API.getLabBarCodes(id);
    setTotalBarCode(data.length);
  };

  return (
    <span style={{ color: "red", fontWeight: "bold" }}>
      {totalBarCode < labData.barCodeAlertLimit ? <span title={title}>Alert</span> : "" }
    </span>
  );
};

export default BarCodeAlert;
