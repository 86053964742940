import React, { useState, useEffect } from "react";
import FullPageImageView from "components/FullPageImageView";
import imagePlaceholder from '../../assets/img/imagePlcaholder.png';
const ImageView = (props) => {
    const {imgSrc, crewProfile, cssClass} = props;
    const [showImage, setShowImage] = useState(true);
    const [fullView, setFullView] = useState("");

    useEffect(() => {
        if(!imgSrc) {
            setShowImage(false);
        }
    }, [imgSrc]);
  const handleOnError = () => {
    console.log("image Error");
    setShowImage(false);
  };
  const handleImageClick = (image) => {
    setFullView(image);
  };

  if (showImage) {
    return (
      <>
        <FullPageImageView image={fullView} handleClose={() => setFullView("")} />
        <div className={`${crewProfile ? "inputFileWrapperFull d-block" : ""} ${cssClass}`}>
            <img
                className={crewProfile ? "":"imageView"}
                src={imgSrc instanceof Object ? URL.createObjectURL(imgSrc) : imgSrc }
                onError={handleOnError}
                onClick={() => handleImageClick(imgSrc instanceof Object ? URL.createObjectURL(imgSrc) : imgSrc)}
            />

        </div>
      </>
    );
  }
  return <> <img src={imagePlaceholder} className="imagePlaceholder" alt="image placholder" /></>;
};

export default ImageView;
