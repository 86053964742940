/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Test from "views/Test";
import TableListApi from "views/GraphQL/TableListApi";
import ProcessedTestListApi from "views/GraphQL/ProcessedTestListApi";
import Typography from "views/Typography.js";
import Icons from "views/Icons.js";
import Maps from "views/Maps.js";
import Notifications from "views/Notifications.js";
import Upgrade from "views/Upgrade.js";
import Settings from "views/Settings.js";

const dashboardRoutes = [
  {
    path: "/tests",
    name: "New Tests",
    icon: "nc-icon nc-notes",
    component: TableListApi,
    layout: "/admin",
  },
  {
    path: "/processing",
    name: "Tests Processing",
    icon: "nc-icon nc-notes",
    component: TableListApi,
    layout: "/admin",
  },
  {
    path: "/processedtests",
    name: "Processed Tests",
    icon: "nc-icon nc-check-2",
    component: ProcessedTestListApi,
    layout: "/admin",
  },
  
  {
    path: "/test/:testId",
    name: "Test",
    icon: "nc-icon nc-circle-09",
    component: Test,
    layout: "/admin",
    redirect: true,
  },
  
];

export default dashboardRoutes;
