import React from "react";

const PersonalizationItem = (props) => {
  const { item, handleCheck } = props;
  return (
    <div className="personalizationCheckbox">
      <input
        type="checkBox"
        onChange={(e) => handleCheck(e)}
        value={item.itemKey}
        defaultChecked={item.isCheck}
      ></input>
      <label className="ml-2">{item.title}</label>
    </div>
  );
};

export default PersonalizationItem;
