import React, { useState, useContext, useRef } from "react";

// react-bootstrap components
import { Button, Card, Table, Row, Col, DropdownButton, Dropdown, Modal, Form } from "react-bootstrap";
import { DataStore } from "@aws-amplify/datastore";
import { useEffect, useMemo } from "react";
import api from "api";
import { Auth } from "aws-amplify";
import SearchBar from "components/SearchBar";
import SortIcon from "components/SortIcon";
import parsePhoneNumber, { AsYouType } from "libphonenumber-js";
import { Link, useHistory } from "react-router-dom";
import Filter from "components/Filter";
import {
  changeSort,
  getCrewsName,
  formatPhoneNumber,
  capitalizeLetter,
  sortingFilterInLC,
  calculateTdWidth,
  formatDateOfBirthDOB,
  connectedIDInLocalStorage,
} from "utils";
import MFPagination from "components/Pagination/MFPagination";
import ImportCrewPhoneModal from "components/Modal/ImportCrewPhoneModal";

import { AppContext } from "context/app-context";
import { PAGE_LIMIT } from "constant";
import moment from "moment";
import QuarantineDetailsModal from "components/Modal/QuarantineDetailsModal";
import ExportCrewToExcel from "components/ExportCrewToExcel";
import { FormatAWSDate } from "utils";
import { getPhoneNo } from "utils";
import EmployeeDemoChange from "components/Modal/EmployeeDemoChange";
import Loader from "components/Loader/Loader";

const HSCrew = (props) => {
  const componentRef = useRef();
  const mainWidth = useRef();
  const [width, setWidth] = useState(0);
  const history = useHistory();
  useEffect(() => {
    setWidth(componentRef.current.offsetWidth);
  }, [componentRef]);

  const handleResize = () => {
    if (componentRef.current) setWidth(componentRef.current.offsetWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => handleResize(), [mainWidth]);

  const tdWidth = calculateTdWidth(width - 45, 10);

  // const [admins, setAdmins] = useState([]);
  // const [labs, setLabs] = useState([]);
  // const [testers, setTesters] = useState([]);
  // const [employees, setEmployees] = useState([]);
  const [crew, setCrew] = useState([]);
  const [filteredCrew, setFilteredCrew] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [searchTerm, setSearchTerm] = useState("email");
  const [loading, setLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [openCreator, setOpenCreator] = useState(false);
  const [selectedCrew, setSelectedCrew] = useState(false);
  const [preLoadCrew, setPreLoadCrew] = useState(false);
  const [openDetailsQuarantine, setOpenDetailsQuarantine] = useState(false);
  const [selectedQuarantine, setSelectedQuarantine] = useState("");
  const [checkboxes, setCheckboxes] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [showDemo, setShowDemo] = useState(false);

  const searchTerms = [
    "First Name",
    "Last Name",
    "Date of Birth",
    "Email",
    "Phone",
    "Show",
    "Total Tests",
    "Quarantined",
  ];
  const selectedItem = [
    "firstName",
    "lastName",
    "dob",
    "email",
    "isVaccinated",
    "isBooster",
    "phoneNumber",
    "gender",
    "idNumber",
    "street",
    "street2",
    "city",
    "state",
    "zip",
    "jobTitle",
    "location",
    "region",
    "callTime",
    "department",
    "country",
    "zone",
  ];
  const appContext = useContext(AppContext);
  const sortCrew = () => {
    if (sortBy === "lastTest") {
      setFilteredCrew(
        sortDescending
          ? [...crew].sort((a, b) => (a[sortBy].date < b[sortBy].date ? 1 : b[sortBy].date < a[sortBy].date ? -1 : 0))
          : [...crew].sort((a, b) => (b[sortBy].date < a[sortBy].date ? 1 : a[sortBy].date < b[sortBy].date ? -1 : 0))
      );
    } else {
      setFilteredCrew(
        sortDescending
          ? [...crew].sort((a, b) => (a[sortBy] < b[sortBy] ? 1 : b[sortBy] < a[sortBy] ? -1 : 0))
          : [...crew].sort((a, b) => (b[sortBy] < a[sortBy] ? 1 : a[sortBy] < b[sortBy] ? -1 : 0))
      );
    }
  };

  const sortCrewData = (list) =>
    sortDescending
      ? [...list].sort((a, b) => (a[sortBy] < b[sortBy] ? 1 : b[sortBy] < a[sortBy] ? -1 : 0))
      : [...list].sort((a, b) => (b[sortBy] < a[sortBy] ? 1 : a[sortBy] < b[sortBy] ? -1 : 0));

  const SetSortIcon = ({ sortName }) => {
    return (
      <SortIcon
        sortName={sortName}
        sortBy={sortBy}
        setSortBy={setSortBy}
        sortDescending={sortDescending}
        setSortDescending={setSortDescending}
      />
    );
  };

  const formatUserObject = (user, role) => {
    const newUser = {};
    const email = user.Attributes.find((attr) => {
      return attr.Name === "email";
    }).Value;
    newUser.email = email;
    const phone = user.Attributes.find((attr) => {
      return attr.Name === "phone_number";
    }).Value;
    const firstName = user.Attributes.find((attr) => {
      return attr.Name === "custom:firstName";
    })?.Value;
    const lastName = user.Attributes.find((attr) => {
      return attr.Name === "custom:lastName";
    })?.Value;

    newUser.name = firstName && lastName ? `${firstName} ${lastName}` : "-";
    newUser.phone_number = formatPhoneNumber(phone);
    newUser.username = user.Username;
    newUser.status = user.UserStatus;
    const obj = Object.assign({ role: role }, newUser);
    return obj;
  };

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filters).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length) {
          return true;
        }

        if (key === "First Name") {
          return obj.firstName && obj.firstName.toLowerCase().includes(filters[key].toLowerCase());
        }

        if (key === "Last Name") {
          return obj.lastName && obj.lastName.toLowerCase().includes(filters[key].toLowerCase());
        }

        if (key === "phoneNumber") {
          return obj.phoneNumber && obj.phoneNumber.replace(/\D/g, "").includes(filters[key].toLowerCase());
        }
        return obj[key] && obj[key].toLowerCase().includes(filters[key].toLowerCase());
      });
    });

    return models;
  };

  const formatter = new AsYouType("US");

  const handleClose = () => {
    setOpenCreator(false);
  };
  const handleSave = async () => {
    const prevCrew = crew.find((c) => c.employeeID === selectedCrew.employeeID);
    if (
      prevCrew &&
      (prevCrew.firstName !== selectedCrew.firstName ||
        prevCrew.lastName !== selectedCrew.lastName ||
        prevCrew.email !== selectedCrew.email)
    ) {
      try {
        setLoading(true);
        await api.updateCrewInfo(selectedCrew);
        setTimeout(() => window.location.reload(), 4000);
        setOpenCreator(false);
        setSelectedCrew({});
        appContext.showSuccessMessage("Crew updated successfully");
      } catch (err) {
        setLoading(false);
        appContext.showErrorMessage(err.message);
      }
    } else {
      setOpenCreator(false);
    }
  };
  const handleChangePage = (page) => setCurrentPage(page);

  const getCrewMembers = (tests) => {
    const allTests = tests.map((test) => {
      if (typeof test.employee_demographics === "string") {
        return {
          ...JSON.parse(test.employee_demographics),
          phoneNumber: formatPhoneNumber(test.phoneNumber, test.countryCode),
          phone: test.phoneNumber,
          email: test.email,
          date: test.createdAt,
          barcode: test.barcode,
          dob: formatDateOfBirthDOB(test.dob),
          employeeID: test.employeeID,
          quarantinedStart: test.quarantinedStart,
          quarantinedEnd: test.quarantinedEnd,
          isQuarantined: test.quarantinedStart && !test.quarantinedEnd ? "X" : "-",
          site_name: test.site_name,
        };
      }
      return {
        ...test.employee_demographics,
        dob: formatDateOfBirthDOB(test.employee_demographics.dob),
        phoneNumber: formatPhoneNumber(test.phoneNumber, test.countryCode),
        phone: test.phoneNumber,
        email: test.email,
        date: test.createdAt,
        barcode: test.barcode,
        employeeID: test.employeeID,
        quarantinedStart: test.quarantinedStart,
        quarantinedEnd: test.quarantinedEnd,
        isQuarantined: test.quarantinedStart && !test.quarantinedEnd ? "X" : "-",
        site_name: test.site_name,
      };
    });

    const dupes = [];
    const dupeCount = {};
    const lastTest = {};
    const crew = allTests
      .filter((el) => {
        if (dupes.indexOf(`${el.phoneNumber}-${el.dob}`) === -1) {
          dupes.push(`${el.phoneNumber}-${el.dob}`);
          dupeCount[`${el.phoneNumber}-${el.dob}`] = 1;
          lastTest[el.phoneNumber] = el;
          return true;
        }

        dupeCount[`${el.phoneNumber}-${el.dob}`]++;
        if (new Date(lastTest[el.phoneNumber].date) < new Date(el.date)) {
          lastTest[el.phoneNumber] = el;
        }
        return false;
      })
      .map((crew) => {
        return {
          ...crew,
          totalTests: dupeCount[`${crew.phoneNumber}-${crew.dob}`],
          lastTest: lastTest[crew.phoneNumber],
        };
      });
    console.log("dupeCount", dupeCount);
    console.log("Crew", crew);
    return crew;
  };

  useEffect(() => {
    if (!appContext) return;
    if (appContext.testFilter && appContext.testFilter.location === "crewlisting") {
      setFilter(appContext.testFilter.filter);
      setShowFilter(true);
    }
    loadPrevSorting();
    loadTestFromPG();
  }, []);

  useEffect(() => {
    crew.length > 0 && sortCrew();
    const prevSortingState = sortingFilterInLC.get();
    prevSortingState["crewhs"] = { filter, sortBy, sortDescending };
    sortingFilterInLC.save(prevSortingState);
  }, [sortBy, sortDescending]);

  useEffect(() => {
    if (crew.length > 0) setFilteredCrew(nestedFilter(crew, filter));
  }, [filter]);

  const loadPrevSorting = () => {
    const sortingFilters = sortingFilterInLC.get();
    if (sortingFilters.crewhs.sortBy) {
      setSortDescending(sortingFilters.crewhs.sortDescending);
      setSortBy(sortingFilters.crewhs.sortBy);
    }
  };

  const loadTestFromPG = async () => {
    setLoading(true);
    const id = connectedIDInLocalStorage.get();
    const startDate = moment().subtract(6, "days").startOf("day").utc().toISOString();
    const endDate = moment().endOf("day").utc().toISOString();
    try {
      const pgList = await api.getTestListFromPG({ siteID: id, limit: 1000 });
      console.log("pglist", pgList);
      const lists = sortCrewData(getCrewMembers(pgList.rows));
      setCrew(lists);
      setFilteredCrew(lists);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.message);
    }
  };

  const crewToMap = useMemo(() => {
    const list = filteredCrew ? [...filteredCrew] : [];
    const ttlPages = list.length > PAGE_LIMIT ? Math.ceil(list.length / PAGE_LIMIT) : 1;
    setTotalPages(ttlPages);
    if (list.length < PAGE_LIMIT) {
      setCurrentPage(1);
    }
    if (ttlPages > 1) {
      const indexOfLastUser = currentPage * PAGE_LIMIT;
      const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
      return list.slice(indexOfFirstUser, indexOfLastUser);
    }
    return list;
  }, [filteredCrew, currentPage]);

  const handleEditCrew = (crew) => {
    setSelectedCrew({ ...crew });
    setOpenCreator(true);
  };

  const handleSaveFilter = () => {
    appContext.setTestFilter({ location: "crewlisting", filter });
  };

  const getQuarantineDays = (test) => {
    if (!test.quarantinedStart || (test.quarantinedStart && test.quarantinedEnd)) return "-";
    const startDate = moment(test.quarantinedStart);
    if (test.quarantinedEnd) {
      const endDate = moment(test.quarantinedEnd);
      const days = endDate.diff(startDate, "days");
      return days;
    }
    const currentDate = moment();
    return currentDate.diff(startDate, "days");
  };

  const pageTitle = () => `Crew Members ${filteredCrew.length}`;
  const handleCheckboxChange = (e, id) => {
    const filteredList = checkboxes.filter((c) => c !== id);
    if (e.target.checked) {
      filteredList.push(id);
    }
    setCheckboxes(filteredList);
  };

  const toggleCheckboxes = (val) => setCheckboxes(val ? filteredCrew.map((t) => t.employeeID) : []);

  const TableRow = ({ user }) => {
    return (
      <tr>
        <td className="icon">
          <div className="d-flex align-items-center w-100 column-gap-10">
            <Form.Check.Input
              type="checkbox"
              value={user.employeeID}
              checked={checkboxes.indexOf(user.employeeID) !== -1}
              onChange={(e) => handleCheckboxChange(e, user.employeeID)}
            ></Form.Check.Input>
            <i
              className="fas fa-edit"
              onMouseLeave={(e) => (e.target.style.color = "black")}
              onMouseOver={(e) => {
                e.target.style.color = "blue";
                e.target.style.cursor = "pointer";
              }}
              aria-hidden="true"
              id={user.id}
              onClick={(e) => handleEditCrew(user)}
            />
          </div>
        </td>
        <td className="ellipsis">{capitalizeLetter(user.firstName)}</td>
        <td className="ellipsis">{capitalizeLetter(user.lastName)}</td>
        <td className="ellipsis" title={user.dob}>
          {user.dob}
        </td>
        <td
          className="ellipsis"
          style={{
            textDecoration: "underline",
            color: "#A82632",
          }}
          onMouseOver={(e) => {
            e.target.style.cursor = "pointer";
            e.target.style.textDecoration = "underline";
          }}
          onClick={() => user?.email && window.open(`mailto:${user.email}`)}
        >
          {user.email}
        </td>
        <td
          className="ellipsis"
          style={{ textDecoration: "underline", color: "#A82632" }}
          onMouseOver={(e) => {
            e.target.style.cursor = "pointer";
            e.target.style.textDecoration = "none";
          }}
          onMouseLeave={(e) => {
            e.target.style.textDecoration = "underline";
          }}
          onClick={() => {
            user.phoneNumber && window.open(`tel:+${user.phoneNumber.replace(/\D/g, "")}`);
          }}
        >
          {user.phoneNumber}
        </td>
        <td className="ellipsis"
          style={{ textAlign: "center" }}>{user.site_name}</td>
        <td style={{ textAlign: "center" }} className="ellipsis">
          {user.totalTests !== 0 ? (
            <Link
              onClick={handleSaveFilter}
              style={{ color: "#42cef5" }}
              to={{
                pathname: `/admin/totaltests`,
                state: {
                  name: user.phone?.replace(/[^A-Z0-9]/g, ""),
                  term: "phoneNumber",
                  dob: user.dob,
                  dobTerm: "dob",
                },
              }}
            >
              {user.totalTests}
            </Link>
          ) : (
            user.totalTests
          )}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          <Link
            onClick={handleSaveFilter}
            style={{ color: "#42cef5" }}
            to={{
              pathname: `/admin/totaltests`,
              state: { name: user.lastTest.barcode, term: "barcode" },
            }}
          >
            {new Date(user.lastTest.date).toDateString()}
          </Link>
        </td>
        {user.isQuarantined === "X" ? (
          <td style={{ textAlign: "center", color: "red" }}>X</td>
        ) : (
          <td style={{ textAlign: "center" }}>-</td>
        )}

        <td style={{ textAlign: "center" }}>
          {user.isQuarantined === "X" ? (
            <div
              style={{ textAlign: "center", textDecoration: "underline", color: "#A82632" }}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
                e.target.style.textDecoration = "none";
              }}
              onMouseLeave={(e) => {
                e.target.style.textDecoration = "underline";
              }}
              onClick={() => {
                setSelectedQuarantine(user);
                setOpenDetailsQuarantine(true);
              }}
            >
              {getQuarantineDays(user)}
            </div>
          ) : (
            "-"
          )}
        </td>
        <td style={{ textAlign: "center" }}>True</td>
      </tr>
    );
  };

  return (
    <div style={{ flex: 1 }}>
      {!loading ? (
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                    color: "#565656",
                  }}
                >
                  {pageTitle()}
                </Card.Title>
                <div className="buttonHeader">
                  <Button className="headerButton" onClick={() => setShowFilter(!showFilter)}>
                    Filter
                  </Button>
                  <Button className="headerButton" onClick={() => setPreLoadCrew(true)}>
                    Pre-Load
                  </Button>
                  <Button
                    className="headerButton"
                    onClick={() => {
                      if (filteredCrew && filteredCrew.length > 0) {
                        setAllSelected(!allSelected);
                        toggleCheckboxes(!allSelected);
                      }
                    }}
                  >
                    {!allSelected ? "Select All" : "Deselect All"}
                  </Button>
                  {
                    <Button
                      className="headerButton"
                      onClick={() =>
                        history.push({
                          pathname: "/admin/qrview",
                          state: { data: filteredCrew.filter((c) => checkboxes.includes(c.employeeID)) },
                        })
                      }
                    >
                      Show QR Code
                    </Button>
                  }
                  {filteredCrew.length > 0 && (
                    <ExportCrewToExcel
                    items={filteredCrew.map((e) => {
                      return {
                        ...e,
                        jobTitle: "",
                        location: "",
                        region: "",
                        callTime: "",
                        department: "",
                        country: "",
                        zone: "",
                      };
                    })}
                      selectedItem={selectedItem}
                      appContext={appContext}
                      filter={filter}
                      title="Crew User"
                      sortBy={sortBy}
                    />
                  )}
                </div>
                {showFilter && <Filter filterTerms={searchTerms} setFilter={setFilter} filter={filter} />}
                <MFPagination
                  currentPage={currentPage}
                  handleChangePage={handleChangePage}
                  totalPages={totalPages}
                  // showSelectedRecord
                  // totalSelected={checkboxes.length}
                />
              </Card.Header>

              <Card.Body className="table-full-width px-0 desktop-noScroll">
                <div className="table-responsive pendingReleaseTable">
                  <Table className="table-hover table-striped">
                    <thead ref={componentRef}>
                      <tr style={{ textAlign: "center" }}>
                        <th></th>
                        <th
                          style={{
                            color: sortBy === "firstName" ? "#A72632" : "grey",
                            fontWeight: "bold",
                            width: tdWidth,
                            maxWidth: tdWidth,
                          }}
                          className="border-0"
                          onClick={() => {
                            setSortBy("firstName");
                            setSortDescending(!sortDescending);
                          }}
                        >
                          <div>
                            <span>First Name</span>
                            <SetSortIcon sortName={"firstName"} />
                          </div>
                        </th>
                        <th
                          style={{
                            color: sortBy === "lastName" ? "#A72632" : "grey",
                            fontWeight: "bold",
                            width: tdWidth,
                            maxWidth: tdWidth,
                          }}
                          className="border-0"
                          onClick={() => {
                            setSortBy("lastName");
                            setSortDescending(!sortDescending);
                          }}
                        >
                          <div>
                            <span>Last Name</span>
                            <SetSortIcon sortName={"lastName"} />
                          </div>
                        </th>
                        <th
                          style={{
                            color: sortBy === "lastName" ? "#A72632" : "grey",
                            fontWeight: "bold",
                            width: tdWidth,
                            maxWidth: tdWidth,
                          }}
                          className="border-0"
                          onClick={() => {
                            setSortBy("dob");
                            setSortDescending(!sortDescending);
                          }}
                        >
                          <div>
                            <span>DoB</span>
                            <SetSortIcon sortName={"dob"} />
                          </div>
                        </th>
                        <th
                          style={{
                            color: sortBy === "email" ? "#A72632" : "grey",
                            fontWeight: "bold",
                            width: tdWidth,
                            maxWidth: tdWidth,
                          }}
                          className="border-0"
                          onClick={() => {
                            setSortBy("email");
                            setSortDescending(!sortDescending);
                          }}
                        >
                          <div>
                            <span>Email</span>
                            <SetSortIcon sortName={"email"} />
                          </div>
                        </th>
                        <th
                          style={{
                            color: sortBy === "phoneNumber" ? "#A72632" : "grey",
                            fontWeight: "bold",
                            width: tdWidth,
                            maxWidth: tdWidth,
                          }}
                          className="border-0"
                          onClick={() => {
                            setSortBy("phoneNumber");
                            setSortDescending(!sortDescending);
                            // setCrew(sortCrew(crew, sortBy));
                          }}
                        >
                          <div>
                            <span>Phone Number</span>
                            <SetSortIcon sortName={"phoneNumber"} />
                          </div>
                        </th>
                        <th
                          style={{
                            color: sortBy === "site_name" ? "#A72632" : "grey",
                            fontWeight: "bold",
                            width: tdWidth,
                            maxWidth: tdWidth,
                          }}
                          className="border-0 centered"
                          onClick={() => {
                            setSortBy("site_name");
                            setSortDescending(!sortDescending);
                            // setCrew(sortCrew(crew, sortBy));
                          }}
                        >
                          <div>
                            <span>Show</span>
                            <SetSortIcon sortName={"site_name"} />
                          </div>
                        </th>
                        <th
                          style={{
                            color: sortBy === "totalTests" ? "#A72632" : "grey",
                            fontWeight: "bold",
                            width: tdWidth,
                            maxWidth: tdWidth,
                          }}
                          className="border-0 centered"
                          onClick={() => {
                            setSortBy("totalTests");
                            setSortDescending(!sortDescending);
                            // setCrew(sortCrew(crew, sortBy));
                          }}
                        >
                          <div>
                            <span>Total Tests</span>
                            <SetSortIcon sortName={"totalTests"} />
                          </div>
                        </th>
                        <th
                          style={{
                            color: sortBy === "lastTest" ? "#A72632" : "grey",
                            fontWeight: "bold",
                            width: tdWidth,
                            maxWidth: tdWidth,
                          }}
                          className="border-0 centered"
                          onClick={() => {
                            setSortBy("lastTest");
                            setSortDescending(!sortDescending);
                            // setCrew(sortCrew(crew, sortBy));
                          }}
                        >
                          <div>
                            <span>Last Test Date</span>
                            <SetSortIcon sortName={"lastTest"} />
                          </div>
                        </th>
                        <th
                          className="centered text-grey ellipsis"
                          style={{ fontWeight: "bold", width: tdWidth, maxWidth: tdWidth }}
                        >
                          <span>Quarantined</span>
                        </th>
                        <th
                          className="centered text-grey ellipsis"
                          style={{ fontWeight: "bold", width: tdWidth, maxWidth: tdWidth }}
                        >
                          <span>Days Count</span>
                        </th>
                        <th
                          className="centered text-grey ellipsis"
                          style={{ fontWeight: "bold", width: tdWidth, maxWidth: tdWidth }}
                        >
                          <span>HIPAA Consent</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {crewToMap.map((user, index) => {
                        return <TableRow key={index} user={user} />;
                      })}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Modal
            show={openCreator}
            // style={{ transform: "translate(0, -30%)" }}
            animation={true}
            onHide={() => {
              setOpenCreator(false);
            }}
            centered
            size={"md"}
          >
            <Modal.Header closeButton>
              <Modal.Title className="my-0" id="contained-modal-title-vcenter">
                Update Crew
              </Modal.Title>
            </Modal.Header>

            <Modal.Body style={{ paddingTop: 0 }}>
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">First Name: </label>
                <input
                  value={selectedCrew.firstName}
                  className="modalInput"
                  placeholder="Client Name"
                  style={{ width: "100%" }}
                  onChange={(e) => setSelectedCrew({ ...selectedCrew, firstName: e.target.value })}
                />
              </div>
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Last Name: </label>
                <input
                  value={selectedCrew.lastName}
                  className="modalInput"
                  placeholder="Client Name"
                  style={{ width: "100%" }}
                  onChange={(e) => setSelectedCrew({ ...selectedCrew, lastName: e.target.value })}
                />
              </div>

              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Email: </label>
                <input
                  value={selectedCrew.email}
                  className="modalInput"
                  placeholder="Email"
                  style={{ width: "100%" }}
                  onChange={(e) => setSelectedCrew({ ...selectedCrew, email: e.target.value })}
                />
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button
                className="modalButtons"
                variant="secondary"
                onClick={() => {
                  handleClose();
                }}
              >
                Close
              </Button>
              <Button className="modalButtons" variant="primary" onClick={() => handleSave()}>
                Update
              </Button>
            </Modal.Footer>
          </Modal>

          {preLoadCrew && (
            <ImportCrewPhoneModal
              sites={appContext?.sites}
              user={appContext?.user}
              handleClose={() => setPreLoadCrew(false)}
            />
          )}
          {openDetailsQuarantine && (
            <QuarantineDetailsModal test={selectedQuarantine} handleClose={() => setOpenDetailsQuarantine(false)} />
          )}
          {showDemo && <EmployeeDemoChange handleClose={() => setShowDemo(false)} />}
        </Row>
      ) : (
        // <h3
        //   style={{
        //     display: "block",
        //     marginLeft: "auto",
        //     marginRight: "auto",
        //     paddingTop: 50,
        //     paddingBottom: 50,
        //   }}
        // >
        //   Loading...
        // </h3>
        <Loader />
      )}
    </div>
  );
};

export default HSCrew;
