import React from "react";
import Styles from "components/CheckBox/ViewCheckBox.module.css";
const ViewCheckBox = (props) => {
  const { id, bgColor, title } = props;

  const getColor = () => {
    switch (bgColor) {
      case "red":
        return Styles.red;
      case "blue":
        return Styles.blue;
      case "yellow":
        return Styles.yellow;
      case "orange":
        return Styles.orange;
      default:
        return Styles.green;
    }
  };
  return (
    <div title={title}>
      <input id={id} className={`${Styles.checkboxCustom}`} name="checkbox-1" type="checkbox" checked readOnly />
      <label htmlFor={id} className={`mb-0 ${getColor()} ${Styles.checkboxCustomLabel}`} />
    </div>
  );
};

export default ViewCheckBox;
